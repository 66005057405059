import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import NumberFormat from "react-number-format";
import { Redirect, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
 

const NotePageMassage = ({ 
  state,
  setModalsState,
  modalsState,
  params,
  originalService,
  service,
  notes,
  setNotes,
  services,
  originalNotes,
  appState,
  location,
  setState,
  setService
}) => {

  const MAX_VAL = 200;
  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value < MAX_VAL) return inputObj;
  };

  useEffect(() => {
    if (!state.cbffEnabled || state.cbffDenied || state.cbff !== "") {

      setState((state) => ({
        ...state,
        submitStatus: "ready",
      }));

    } else {
      setState((state) => ({
        ...state,
        submitStatus: "default",
      }));
    }
  }, [state.cbff, state.cbffEnabled, state.cbffDenied]);
  
  const handleRadioChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log("updating: ", name, value);
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  
  const openModal = async (event) => {
    event.preventDefault();
    setModalsState((modalsState) => ({
      ...modalsState,
      status: "visible",
    }));
  };

  
  const closeModal = () => {
    setModalsState((modalsState) => ({
      ...modalsState,
      status: "hidden",
    }));
  };

  const handleServiceChange = (Id) => (event) => {
    event.preventDefault();
    const newItem = services.find((item) => item.sessionTypeId === Id);
    setService(newItem);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (state.submitStatus === "sending") {
      return;
    }
    try {

      setState((state) => ({
        ...state,
        submitStatus: "sending",
      }));
      const sendNotes =
        notes.extras +
        "" +
        (originalNotes.email === notes.email
          ? ""
          : "Send photos to: " + notes.email);

        
      const payload =
        originalService.Id !== service.Id && originalNotes !== notes
          ? {
              SessionTypeId:
                service.Id === undefined ? service.sessionTypeId : service.Id,
              Notes:
                "Weeks: " +
                notes.weeks +
                "\n" +
                "Language: " +
                (notes.language === " null" ? "english" : notes.language)
                +
                "\n" +
                "Files: " +
                notes.files +
                "\n" +
                sendNotes,
            }
          : originalService.Id !== service.Id && originalNotes === notes
          ? {
              SessionTypeId:
                service.Id === undefined ? service.sessionTypeId : service.Id,
            }
          : {
              Notes:
                "Weeks: " +
                notes.weeks +
                "\n" +
                "Language: " +
                (notes.language === " null" ? "english" : notes.language) +
                "\n" +
                "Files: " +
                notes.files +
                "\n" +
                sendNotes,
            };

      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: appState.nav.siteId,
          authorization: appState.singleAuth.token,
          locationid: appState.nav.locationId,
          allowedpermissions: appState.singleAuth.allowedPermissions,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/appointments/${state.appointmendId}`,
        putMethod
      );
      const data = await response.json();
      if (response.ok) {
        try {
          const dynamoPayload = {
            appointmentId: parseInt(state.appointmendId),
            serviceName:
              service.Name === undefined ? service.name : service.Name,
            cbff:
              state.cbff === "yes" 
                ? "" +
                  moment().add(state.cbffWeeks, "week").format("MM/DD/YYYY")
                : "nA",
            files: "" + notes.files,
            weeks: "" + notes.weeks,
            notes: "" + notes.extras,
            email: notes.email,
            appointmentDate: state.startDateTime,
            locationId: appState.nav.locationId,
            clientPhone: state.MobilePhone,
            clientName: state.clientName,
            formFilled : state.YellowAlert === state.appointmendId,
          };

          const putDynamo = {
            method: "PUT",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(dynamoPayload),
          };
          const dynamoResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/dynamoDB/sites/${appState.nav.siteId}/sales`,
            putDynamo
          );
          const dynamoData = await dynamoResponse.json();
          if (dynamoResponse.ok) {
            const cbffPayload = {
              Client: {
                Id: state.clientId,
                RedAlert:
                  state.cbff === "yes"
                    ? (state.lastCbff ? "last - " : "") +
                      "CBFF before " +
                      moment().add(state.cbffWeeks, "week").format("MM/DD/YYYY")
                    : "",
              },
              SendEmail: true,
              CrossRegionalUpdate: false,
              Test: false,
            };
            const cbffUpdate = {
              method: "PUT",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                siteid: appState.nav.siteId,
                authorization: appState.singleAuth.token,
                locationid: appState.nav.locationId,
              },
              body: JSON.stringify(cbffPayload),
            };
            const cbffResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/api/clients/${state.clientId}`,
              cbffUpdate
            );
            const jsonResponse = await cbffResponse.json();
            if (cbffResponse.ok) {
              setState((state) => ({
                ...state,
                submitStatus: "sent",
              }));
            } else {
              setState((state) => ({
                ...state,
                submitStatus: "cbffFailed",
                cbffMessage:
                  "ComeBackForFree request error: " +
                  JSON.stringify(jsonResponse),
              }));
            }
          } else {
            setState((state) => ({
              ...state,
              submitStatus: "failed",
              submitMessage:
                "DynamoDB request failed: " + JSON.stringify(dynamoData),
            }));
          }
        } catch (error) {
          setState((state) => ({
            ...state,
            submitStatus: "failed",
            submitMessage: "DynamoDB request error: " + JSON.stringify(error),
          }));
        }
      } else {
        setState((state) => ({
          ...state,
          submitStatus: "failed",
          submitMessage: "Main request failed: " + JSON.stringify(data),
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        submitStatus: "failed",
        submitMessage: "Main request error: " + JSON.stringify(error),
      }));
    }
  };

  const handleInputChange = (name) => (event) => {
    setNotes((notes) => ({
      ...notes,
      [name]: event.target.value.trim(),
    }));
  };
  
  const handleChange = (event) => {
    setNotes((notes) => ({
      ...notes,
      language: " " + event.target.value.trim(),
    }));
  };
  
  const handleExtrasChange = (event) => {
    setNotes((notes) => ({
      ...notes,
      extras: event.target.value,
    }));
  };

  useEffect(() =>{
    setNotes((notes) => ({
      ...notes,
      files: 0,
    }));
  },[])

  
return(

  <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="lb-appt-lb-card-info px-3 py-3">
                <strong data-cy="client">{state.clientName}</strong>
                <br />
                <strong>Services:</strong>{" "}
                <span data-cy="services" className="lb-text-muted2">
                  {originalService.Name}
                </span>
                <br />
                <strong>Appointment date:</strong>
                <br />
                <span data-cy="dateStartAppointment" className="lb-text-muted2">
                  {state.apptDateMonthStart} {state.apptDateDateStart},{" "}
                  {state.apptDateYearStart}{" "}
                </span>
                <span data-cy="dateEndAppointment" className="lb-text-muted2">
                  {state.apptHourStart} {"-"} {state.apptHourFinish}
                </span>
                <br />
              </div>
            </div>
            <div className="col-6 lb-text-center">
              <Link
                to={`/appointments`}
                className="lb-btn lb-submitButton lb-no-hover lb-rounded-0 mx-3 mt-4 mb-2"
              >
                BACK
              </Link>
            </div>
          </div>
          <form>
            <div className="container mt-3 pb-4">
              <div className="row my-3 lb-text-center">
                <div className="col my-auto">
                  <h5 className="lb-font-bold">
                    Current service:{" "}
                    {service.Name === undefined ? service.name : service.Name}
                  </h5>
                </div>
                <div className="col my-auto">
                  <button
                    className="lb-btn lb-submitButton lb-rounded-0 mx-auto"
                    onClick={openModal}
                    data-cy={"edit-service"}
                  >
                    <span className="text-lb-numberButton">
                      <FontAwesomeIcon icon={faEdit} /> Edit service
                    </span>
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="lb-form-group">
                    <label className=".lb-text-muted">
                      <strong>Weeks</strong>
                      <span className="lb-requiredLabel">  </span>
                    </label>
                    <input
                      type="text"
                      className="lb-form-control lb-border-0 lb-inputBackground"
                      name="weeks"
                      value={notes.weeks}
                      onChange={handleInputChange("weeks")}
                      data-cy="weeks"
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="lb-form-group">
                    <label className=".lb-text-muted">
                      <strong>Language</strong>
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <select
                      className="lb-form-control lb-border-0 lb-inputBackground lb-w-100"
                      onChange={handleChange}
                      data-cy="language"
                      value={notes.language.trim()}
                    >
                      <option value="null">Select a Language</option>
                      <option value="english">English</option>
                      <option value="spanish">Español</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="lb-form-group">
                    <label className=".lb-text-muted">
                      <strong>Files</strong>
                      <span className="lb-requiredLabel">  </span>
                    </label>
                    <NumberFormat
                      value={notes.files}
                      className="lb-form-control lb-border-0 lb-inputBackground"
                      name="files"
                      decimalScale={0}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      isAllowed={withValueLimit}
                      data-cy="files"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label className=".lb-text-muted">
                    <strong>Appointments notes</strong>
                  </label>
                  <br />
                  <textarea
                    className="lb-w-100 lb-border-0 lb-inputBackground"
                    rows="5"
                    value={notes.extras}
                    onChange={handleExtrasChange}
                    data-cy="extras"
                  />
                </div>

                <div className="col-6">
                  <div className="lb-form-group">
                    <label className=".lb-text-muted">
                      <strong>Send photos to</strong>
                    </label>
                    <input
                      type="text"
                      className="lb-form-control lb-border-0 lb-inputBackground"
                      name="email"
                      value={notes.email}
                      onChange={handleInputChange("email")}
                      data-cy="email"
                    />
                  </div>
                  <div className="lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom">
                    <label className="lb-form-check-label mx-2">Yes</label>
                    <input
                      type="radio"
                      value={true}
                      name="isSendMail"
                      defaultChecked
                      className="lb-form-check-input"
                      onChange={handleRadioChange}
                      data-cy="email_yes"
                    />
                  </div>
                  <div className="lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom">
                    <label className="lb-form-check-label mx-2">No</label>
                    <input
                      type="radio"
                      value={false}
                      name="isSendMail"
                      className="lb-form-check-input"
                      onChange={handleRadioChange}
                      data-cy="email_no"
                    />
                  </div>
                  {state.cbffEnabled && !state.cbffDenied && (
                    <div className="lb-form-group">
                      <label>
                        <strong>Come back for free</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <br />
                      <div className="lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom">
                        <label className="lb-form-check-label mx-2">Yes</label>
                        <input
                          type="radio"
                          value="yes"
                          name="cbff"
                          className="lb-form-check-input"
                          onChange={handleRadioChange}
                          data-cy="cbff_yes"
                        />
                      </div>
                      <div className="lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom">
                        <label className="lb-form-check-label mr-2">No</label>
                        <input
                          type="radio"
                          value="no"
                          name="cbff"
                          className="lb-form-check-input"
                          onChange={handleRadioChange}
                          data-cy="cbff_no"
                        />
                      </div>
                    </div>
                  )}
                  {state.cbffDenied && state.cbffEnabled && (
                    <div
                      className="mt-1 lb-w-100 mx-auto mb-3 lb-form-submited-warning"
                      data-cy="cbff_limit"
                    >
                      <span>
                        The current client has already two consecutive come back
                        for free services, if there is the need to give another
                        come back for free please contact your manager, you may
                        continue the checkout process{" "}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {modalsState.status === "visible" && (
                <div
                  className="lb-service-overlay"
                  onClick={closeModal}
                  id="termsOverlay"
                >
                  <button
                    type="button"
                    id="closeTermsModal"
                    onClick={closeModal}
                    data-cy={"close-lb-modal"}
                    className="lb-close-button lb-text-center"
                  >
                    &times;
                  </button>
                  <div className="service-lb-modal">
                    <div
                      className="lb-modal-body py-3 px-4 text-justify"
                      data-cy="serviceModal"
                    >
                      <h3>Please select the service.</h3>
                      <div className="container">
                        {services.map((item) => {
                          if (
                            appState.singleAuth.allowedPermissions ||
                            item.price >= originalService.price
                          ) {
                            return (
                              <div
                                key={item.sessionTypeId}
                                className="row lb-serviceRow justify-content-between py-2 my-3"
                              >
                                <div className="col-9 my-auto">{item.name}</div>
                                <div className="col-3 lb-text-center">
                                  <div
                                    className="lb-btn lb-submitButton lb-rounded-0 mx-1"
                                    onClick={handleServiceChange(
                                      item.sessionTypeId
                                    )}
                                    data-cy={"service-" + item.sessionTypeId}
                                  >
                                    <span className="text-lb-numberButton">
                                      Select
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <br />
              <div className="row px-0 mt-3 pb-4">
                <div className="col no-gutters px-0 lb-text-center">
                  {(state.dicomStatus === "failed" ||
                    state.dicomStatus === "error") && (
                    <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-warning d-none">
                      <span>{state.dicomMessage}</span>
                    </div>
                  )}
                  {state.submitStatus === "failed" && (
                    <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-error">
                      <span>{state.submitMessage}</span>
                    </div>
                  )}
                  {state.submitStatus === "cbffFailed" && (
                    <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-error">
                      <span>{state.cbffMessage}</span>
                    </div>
                  )}
                  <Link
                    className={
                      "lb-btn lb-submitButton lb-no-hover lb-rounded-0 mx-3"
                    }
                    data-cy={"upload-" + params.id}
                    to={{
                      pathname: `/imgupload/${params.id}`,
                      state: {
                        email: state.email,
                        name: state.clientName,
                        service: state.services,
                        startDateTime: state.dateStartAppointment,
                        endDateTime: state.dateEndAppointment,
                        locationId: "" + appState.nav.locationId,

                        programId: location.state.programId,
                        sessionTypeId: location.state.sessionTypeId,
                      },
                    }}
                  >
                    Create video
                  </Link>
                  <button
                    type="submit"
                    className={
                      state.submitStatus === "default"
                        ? "lb-btn lb-submitButtonDisabled lb-rounded-0 mx-3"
                        : "lb-btn lb-submitButton lb-rounded-0 mx-3"
                    }
                    disabled={state.submitStatus === "default"}
                    onClick={handleSubmit}
                    data-cy="btn-save"
                  >
                    {state.submitStatus === "sending" ? (
                      <span className="text-lb-numberButton mx-4 my-2">
                        <FontAwesomeIcon spin icon={faSpinner} />
                      </span>
                    ) : (
                      <span className="text-lb-numberButton mx-4 my-2">
                        Proceed to checkout
                      </span>
                    )}
                    {(state.submitStatus === "sent" ||
                      state.submitStatus === "skipped") && (
                      <Redirect
                        type="submit"
                        to={{
                          pathname: `/checkout/${params.id}`,
                          state: {
                            clientFirstName: state.clientFirstName,
                            clientLastName: state.clientLastName,
                            clientPhone: state.MobilePhone,
                            endDateTime: state.endDateTime,
                            clientId: state.clientId,
                            clientName: state.clientName,
                            clientEmail: state.email,
                            startDateTime: state.startDateTime,
                            duration: state.duration,
                            sendMail: state.isSendMail === "true",
                            YellowAlert : state.YellowAlert,
                            files: notes.files,
                            arrived: location.state.arrived,
                            programId: state.ProgramId,
                            notes:
                              "Weeks: " +
                              notes.weeks +
                              "\n" +
                              "Language:" +
                              (notes.language === " null" ? "english" : notes.language)
                              +                           
                               "\n" +
                              "Files: " +
                              notes.files +
                              "\n" +
                              notes.extras +
                              "" +
                              (originalNotes.email === notes.email
                                ? ""
                                : "Send photos to: " + notes.email),
                          },
                        }}
                      ></Redirect>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
)
  };
  
  NotePageMassage.propTypes = {
    state : PropTypes.object.isRequired,
    setModalsState : PropTypes.func.isRequired,
    modalsState : PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    originalService:PropTypes.object.isRequired,
    service:PropTypes.object.isRequired,
    notes:PropTypes.object.isRequired,
    setNotes: PropTypes.func.isRequired,
    services: PropTypes.array.isRequired,
    originalNotes: PropTypes.object.isRequired,
    appState: PropTypes.object.isRequired,
    location : PropTypes.object.isRequired,
    setState : PropTypes.func.isRequired,
    setService: PropTypes.func.isRequired
  };
  export default NotePageMassage;
  