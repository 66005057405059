import React from "react";
import { PolarArea } from "react-chartjs-2";
import { PropTypes } from "prop-types";

const PolarAreaChart = ({
  labels,
  values,
  colors,
  borderColors,
  chartName,
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: labels,
        data: values,
        backgroundColor: colors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: chartName,
      },
      legend: {
        position: "bottom",
      },
    },
  };

  return <PolarArea options={options} data={data} />;
};

PolarAreaChart.propTypes = {
  labels: PropTypes.array,
  values: PropTypes.array,
  colors: PropTypes.array,
  borderColors: PropTypes.array,
  chartName: PropTypes.string,
};
export default PolarAreaChart;
