import React, { useEffect, useState } from "react";
// import { useParams, useLocation, Redirect, Link } from "react-router-dom";
import { useParams, useLocation, Link } from "react-router-dom";
import "../styles/bootstrap-grid.min.css";
import "../styles/global.css";
import "../App.css";
import logo from "../logo.png";
import { PropTypes } from "prop-types";
import NotePageUltraSound from "../components/notePageUltraSound";
import NotePageMassage from "../components/notePageMassage";

function NotePage({ appState }) {
  const [state, setState] = useState({
    clientFirstName: "",
    clientLastName: "",
    clientId: null,
    appointmendId: null,
    clientName: null,
    email: null,
    services: null,
    serviceId: null,
    dateStartAppointment: null,
    dateEndAppointment: null,
    apptDateYearStart: null,
    apptDateMonthStart: null,
    apptDateDateStart: null,
    apptHourStart: null,
    apptHourFinish: null,
    submitStatus: "default",
    submitMessage: "",
    cbff: "",
    cbffEnabled: "",
    cbffDenied: "",
    cbffMessage: "",
    cbffWeeks: "",
    lastCbff: null,
    status: "loading",
    message: "",
    startDateTime: "",
    endDateTime: "",
    isSendMail: "true",
    dicomStatus: "IDLE",
    dicomMessage: "",
    duration: null,
    YellowAlert: null,
    ProgramId: 0,
  });
  const [modalsState, setModalsState] = useState({
    status: "hidden",
  });

  const [services, setServices] = useState([]);
  const [service, setService] = useState({
    Type: "",
    DefaultTimeLength: "",
    Id: "",
    Name: "",
    NumDeducted: "",
    ProgramId: "",
  });
  const [notes, setNotes] = useState({
    weeks: 0,
    language: "null",
    files: "",
    extras: "",
    cbff: "",
    email: "",
  });
  const [originalNotes, setOriginalNotes] = useState({
    weeks: 0,
    language: "null",
    files: 0,
    extras: "",
    cbff: "",
    email: "",
  });
  const [originalService, setOriginalService] = useState({});
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const months = {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December",
    };
    const weeks = {
      early: 4,
      gender: 3,
      meet: 2,
      special: 2,
      come: 2,
    };
    getAppointment();
    async function getAppointment() {
      try {
        const getMethod = {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            siteid: appState.nav.siteId,
            authorization: appState.singleAuth.token,
            locationid: appState.nav.locationId,
          },
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/appointments/${params.id}`,
          getMethod
        );
        const appointment = await response.json();

        const ProgramId = appointment.ProgramId;
        if (response.ok === true) {
          const serviceName =
            appointment.Service.Name.toLowerCase().split(" ")[0];
          const clientId = appointment.Client.Id;
          const YellowAlert = parseInt(appointment.Client.YellowAlert);
          const appointmendId = appointment.Id;
          const clientFirstName = appointment.Client.FirstName;
          const clientLastName = appointment.Client.LastName;
          const clientName =
            appointment.Client.FirstName + " " + appointment.Client.LastName;
          const MobilePhone = appointment.Client.MobilePhone;
          const newEmail = appointment.Client.Email;
          const services = appointment.Service.Name;
          const serviceId = appointment.Service.Id;
          const dateStartAppointment = new Date(appointment.StartDateTime);
          const dateEndAppointment = new Date(appointment.EndDateTime);
          const startDateTime = appointment.StartDateTime;
          const endDateTime = appointment.EndDateTime;
          const duration = appointment.Duration;
          const apptDateYearStart = dateStartAppointment.getFullYear();
          const apptDateMonthStart = months[dateStartAppointment.getMonth()];
          const apptDateDateStart = dateStartAppointment.getDate();
          let apptHourStart = dateStartAppointment.getDate();
          let apptHourFinish = dateStartAppointment.getDate();
          const redAlert = "" + appointment.Client.RedAlert;
          const submitMessage = "";
          const cbff = "";
          const cbffDenied = redAlert.includes("last");
          const cbffEnabled =
            weeks["" + serviceName] !== undefined &&
            !(
              appointment.Service.Name.toLowerCase().includes(
                "meet your baby"
              ) && appointment.Service.Name.toLowerCase().includes("15 min")
            );
          const cbffMessage = "";
          const cbffWeeks = weeks["" + serviceName];
          const lastCbff =
            appointment.Service.Name === "Come back for free" &&
            redAlert.includes("CBFF");
          const minutes =
            dateStartAppointment.getMinutes() === 0
              ? "00"
              : dateStartAppointment.getMinutes();
          if (dateStartAppointment.getHours() >= 12) {
            if (dateStartAppointment.getHours() === 12) {
              apptHourStart = "12:" + minutes + " PM";
            } else {
              apptHourStart =
                dateStartAppointment.getHours() - 12 + ":" + minutes + " PM";
            }
          } else {
            if (dateStartAppointment.getHours() === 0) {
              apptHourStart = "12:" + minutes + " AM";
            } else {
              apptHourStart =
                dateStartAppointment.getHours() + ":" + minutes + " AM";
            }
          }
          /* PARA LA HORA FINAL */

          const minutesEnd =
            dateEndAppointment.getMinutes() === 0
              ? "00"
              : dateEndAppointment.getMinutes();
          if (dateEndAppointment.getHours() >= 12) {
            if (dateEndAppointment.getHours() === 12) {
              apptHourFinish = "12:" + minutesEnd + " PM";
            } else {
              apptHourFinish =
                dateEndAppointment.getHours() - 12 + ":" + minutesEnd + " PM";
            }
          } else {
            if (dateEndAppointment.getHours() === 0) {
              apptHourFinish = "12:" + minutesEnd + " AM";
            } else {
              apptHourFinish =
                dateEndAppointment.getHours() + ":" + minutesEnd + " AM";
            }
          }
          let files = "";
          if (appointment.Notes !== null) {
            let newExtras = "";
            const notesArray = appointment.Notes.split("\n");
            notesArray.forEach((item) => {
              if (item.toLowerCase().includes("weeks:")) {
                const splitedItem = item.split(":");
                setNotes((notes) => ({
                  ...notes,
                  weeks: splitedItem[1],
                }));
                setOriginalNotes((originalNotes) => ({
                  ...originalNotes,
                  weeks: splitedItem[1],
                }));
              } else {
                if (item.toLowerCase().includes("language:")) {
                  const splitedItem = item.split(":");
                  setNotes((notes) => ({
                    ...notes,
                    language: splitedItem[1].toLowerCase(),
                  }));
                  setOriginalNotes((originalNotes) => ({
                    ...originalNotes,
                    language: splitedItem[1].toLowerCase(),
                  }));
                } else {
                  if (item.toLowerCase().includes("files:")) {
                    const splitedItem = item.split(":");
                    files = splitedItem[1].trim();
                    setNotes((notes) => ({
                      ...notes,
                      files: splitedItem[1].trim(),
                    }));
                    setOriginalNotes((originalNotes) => ({
                      ...originalNotes,
                      files: splitedItem[1].trim(),
                    }));
                  } else {
                    if (item.toLowerCase().includes("cbff:")) {
                      const splitedItem = item.split(":");
                      setNotes((notes) => ({
                        ...notes,
                        cbff: splitedItem[1].trim(),
                      }));
                      setOriginalNotes((originalNotes) => ({
                        ...originalNotes,
                        cbff: splitedItem[1].trim(),
                      }));
                    } else {
                      newExtras += item.toString() + "\n";
                    }
                  }
                }
              }
              setNotes((notes) => ({
                ...notes,
                extras: newExtras,
              }));
              setOriginalNotes((originalNotes) => ({
                ...originalNotes,
                extras: newExtras,
              }));
            });
          }
          setNotes((notes) => ({
            ...notes,
            email: newEmail,
          }));
          setOriginalNotes((originalNotes) => ({
            ...originalNotes,
            email: newEmail,
          }));

          const submitStatus =
            cbffEnabled && (files !== "" || appointment.Notes === null)
              ? "default"
              : "ready";

          setState((state) => ({
            ...state,
            clientFirstName,
            clientLastName,
            clientId,
            appointmendId,
            clientName,
            email: newEmail,
            services,
            serviceId,
            dateStartAppointment,
            dateEndAppointment,
            apptDateYearStart,
            apptDateMonthStart,
            apptDateDateStart,
            apptHourStart,
            apptHourFinish,
            submitStatus,
            submitMessage,
            cbff,
            cbffDenied,
            cbffEnabled,
            cbffMessage,
            cbffWeeks,
            lastCbff,
            startDateTime,
            endDateTime,
            MobilePhone,
            duration,
            YellowAlert,
            ProgramId,
          }));

          const getMethod2 = {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              siteid: appState.nav.siteId,
              authorization: appState.singleAuth.token,
              locationid: appState.nav.locationId,
            },
          };
          const servicesResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/sessionTypes/${location.state.programId}`,
            getMethod2
          );
          const data = await servicesResponse.json();
          if (servicesResponse.ok) {
            setServices(data.services);

            const currentServicePrice = data.services.find(
              (element) => element.name === appointment.Service.Name
            ).price;
            const mutableService = appointment.Service;
            mutableService.price = currentServicePrice;
            setService(mutableService);
            setOriginalService(mutableService);
          } else {
            setState((state) => ({
              ...state,
              status: "no-services-found",
            }));
          }
          setState((state) => ({
            ...state,
            status: "ready",
          }));
        } else {
          setState((state) => ({
            ...state,
            status: "no-data-found",
            message: JSON.stringify(appointment),
          }));
        }
      } catch (error) {
        setState((state) => ({
          ...state,
          status: "error",
          message: "Onload page Error: " + JSON.stringify(error.message),
        }));
      }
    }
  }, [
    params.id,
    location.state.programId,
    appState.nav.siteId,
    appState.singleAuth.token,
    appState.nav.locationId,
  ]);

  return (
    <div className="content">
      {state.status === "loading" && (
        <div className="loading" data-cy="loading-message">
          <div className="lb-spinner" />
        </div>
      )}
      {(state.status === "error" || appState.nav.status === "logOutError") && (
        <div className="container mt-3">
          <div className="row">
            <div className="col">
              <h1 className="lb-text-center">Error</h1>
              <h2 className="lb-text-center">
                Seems like there has been an error
              </h2>
              <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                <span>
                  {appState.nav.status === "logOutError"
                    ? appState.nav.message
                    : state.message}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.status === "no-data-found" && (
        <div>
          <div className="container mt-3">
            <div className="row ">
              <div className="col-4 col-md-3 col-lg-2 mx-auto">
                <nav className="lb-navbar">
                  <nav className="lb-navbar">
                    <Link className="lb-navbar-brand" to="/">
                      <img
                        src={logo}
                        width="100%"
                        alt="Little Bellies Logo"
                      ></img>
                    </Link>
                  </nav>
                </nav>
              </div>
            </div>
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="col">
                <h1 className="lb-text-center">Nothing found</h1>
                <h2 className="lb-text-center">Seems like nothing was found</h2>
                <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                  <span>{state.message}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.status === "ready" &&
        (state.ProgramId === 3 ? (
          <NotePageMassage
            state={state}
            setModalsState={setModalsState}
            modalsState={modalsState}
            params={params}
            originalService={originalService}
            service={service}
            notes={notes}
            setNotes={setNotes}
            services={services}
            originalNotes={originalNotes}
            appState={appState}
            location={location}
            setState={setState}
            setService={setService}
          />
        ) : (
          <NotePageUltraSound
            state={state}
            setModalsState={setModalsState}
            modalsState={modalsState}
            params={params}
            originalService={originalService}
            service={service}
            notes={notes}
            setNotes={setNotes}
            services={services}
            originalNotes={originalNotes}
            appState={appState}
            location={location}
            setState={setState}
            setService={setService}
          />
        ))}
    </div>
  );
}
NotePage.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default NotePage;
