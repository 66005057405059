import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { parseNumberPhoneUS } from "../util/formatNumbers.js";

const FormDNA = ({
  state,
  formData,
  formSubmited,
  setFormSubmited,
  setModalsState,
  modalsState,
  setFormData,
  msgData,
}) => {
  const requiredFields = {
    firstName: useRef(null),
    lastName: useRef(null),
    waiver_checkbox_dna: useRef(null),
  };

  useEffect(() => {
    const filledFirstAppointmentData =
      ((formData.firstName !== null &&
        formData.firstName !== "" &&
        formData.lastName !== null &&
        formData.lastName !== "") ||
        formData.fullData) &&
      formData.waiver_checkbox_dna;

    if (filledFirstAppointmentData) {
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        required: "ready",
      }));
    } else {
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        required: "not-ready",
      }));
    }
  }, [formData]);

  useEffect(() => {
    if (modalsState.waiverSignatureImage !== "") {
      setFormData((formData) => ({
        ...formData,
        waiver_checkbox_dna: true,
      }));
    }
  }, [modalsState]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "phone_cel") {
      const phoneNumber = parseNumberPhoneUS(value);

      setFormData((formData) => ({
        ...formData,
        [name]: phoneNumber,
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
    }
  };

  const handleCheckbox = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const id = event.target.id;

    if (name === "waiver_checkbox_dna" || id === "waiver_link_dna") {
      setModalsState((modalsState) => ({
        ...modalsState,
        waiverCheckboxModal: "visible",
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formSubmited.status === "sending") {
      return;
    }
    const fields = ["firstName", "lastName", "waiver_checkbox_dna"];

    const firstRef = fields.map((name) => {
      let validFiel = false;

      if (name === "firstName" && formData[name] !== "") {
        validFiel = true;
      } else if (name === "lastName" && formData[name] !== "") {
        validFiel = true;
      } else if (name === "waiver_checkbox_dna" && formData[name]) {
        validFiel = true;
      } else {
        return name;
      }
      return validFiel;
    });

    const badValue = firstRef.find((i) => i !== true);

    if (!formData.fullData) {
      if (badValue !== undefined) {
        requiredFields[badValue].current.scrollIntoView();
      }
    }

    if (formSubmited.required !== "ready") {
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        inputRequiredClass: "lb-form-validated-error",
        attempt: true,
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        signatureDate: moment(new Date()).toString(),
      }));
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        status: "sending",
        inputRequiredClass: "",
        attempt: false,
      }));
      let pdfData = {};
      try {
        const formDataPdf = new FormData();
        const appointmentDate = new Date(msgData.appointmentDate);

        let pdfPayload = {};

        const firstAppointment =
          formData.firstAppointment || formData.missingData;
        if (firstAppointment) {
          pdfPayload = {
            clientId: "" + formData.client_id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            initialsFile: modalsState.initialsImage,
            signatureFile: modalsState.waiverSignatureImage,
            email: formData.email,
            mobilePhone: formData.phone_cel.replace(/[^0-9.]+/g, ""),
            siteId: state.siteId,
            locationId: formData.locationId,
            signatureDate: moment(new Date()).toString(),
            ipAddress: formData.ipv4,
            firstAppointment: formData.firstAppointment || formData.missingData, // esto se debe ajustar si falta algun campo obligatorio
            appointmentId: state.appointmentId,
            appointmentDate:
              appointmentDate.getMonth() +
              1 +
              " / " +
              appointmentDate.getDate() +
              " / " +
              appointmentDate.getFullYear(),
            programId: state.programId,
          };
        } else {
          pdfPayload = {
            clientId: "" + formData.client_id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            signatureFile: modalsState.waiverSignatureImage,
            initialsFile: modalsState.initialsImage,
            siteId: state.siteId,
            locationId: formData.locationId,
            signatureDate: moment(new Date()).toString(),
            ipAddress: formData.ipv4,
            firstAppointment: firstAppointment, // esto se debe ajustar si falta algun campo obligatorio
            appointmentId: state.appointmentId,
            appointmentDate:
              appointmentDate.getMonth() +
              1 +
              " / " +
              appointmentDate.getDate() +
              " / " +
              appointmentDate.getFullYear(),
            programId: state.programId,
          };
        }

        const keys = Object.keys(pdfPayload);

        keys.forEach((key, index) => {
          formDataPdf.append(`${key}`, pdfPayload[key]);
        });

        const pdfPutMethod = {
          method: "PUT",
          headers: {
            siteid: state.siteId,
          },
          body: formDataPdf,
        };
        const pdfResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/uploadPdf/signature`,
          pdfPutMethod
        );
        pdfData = await pdfResponse.json();

        if (pdfResponse.ok) {
          const bucketOk =
            pdfData.Aws.Key === pdfData.Aws.key &&
            pdfData.Aws.Key !== undefined;
          let mindBodyOk = false;
          if (
            (pdfData.MindBody.Error !== undefined &&
              pdfData.MindBody.Error.Message === "File already exists.") ||
            (pdfData.MindBody.FileName !== undefined &&
              pdfData.MindBody.FileName !== "")
          ) {
            mindBodyOk = true;
          }

          if (bucketOk && mindBodyOk) {
            try {
              const clientPayload = {
                id: "" + formData.client_id,
                firstName: formData.firstName,
                lastName: formData.lastName,
                name: formData.firstName + " " + formData.lastName,
                email: formData.email,
                mobilePhone: formData.phone_cel.replace(/[^0-9.]+/g, ""),
                siteId: state.siteId,
                locationId: formData.locationId,
              };
              const clientPutMethod = {
                method: "PUT",
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  siteid: state.siteId,
                },
                body: JSON.stringify(clientPayload),
              };
              const clientResponse = await fetch(
                `${process.env.REACT_APP_API_URL}/api/dynamoDB/clients`,
                clientPutMethod
              );
              const clientData = await clientResponse.json();
              if (clientResponse.ok) {
                try {
                  const payload = {
                    Client: {
                      FirstName: formData.firstName,
                      LastName: formData.lastName,
                      MobilePhone: formData.phone_cel.replace(/[^0-9.]+/g, ""),
                      Email: formData.email,
                      Id: formData.client_id,
                      YellowAlert: "" + state.appointmentId,
                    },
                    SendEmail: true,
                    CrossRegionalUpdate: false,
                    Test: false,
                  };
                  const putMethod = {
                    method: "PUT",
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      siteid: state.siteId,
                    },
                    body: JSON.stringify(payload),
                  };
                  const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/clients/${formData.client_id}`,
                    putMethod
                  );

                  const data = await response.json();
                  if (response.ok) {
                    setFormSubmited((formSubmited) => ({
                      ...formSubmited,
                      status: "success",
                    }));
                  } else {
                    setFormSubmited((formSubmited) => ({
                      ...formSubmited,
                      status: "responseError",
                      message: JSON.stringify(data),
                    }));
                  }
                } catch (error) {
                  setFormSubmited((formSubmited) => ({
                    ...formSubmited,
                    status: "requestError",
                    message: JSON.stringify(error.message),
                  }));
                }
              } else {
                setFormSubmited((formSubmited) => ({
                  ...formSubmited,
                  status: "responseError",
                  message: JSON.stringify(clientData),
                }));
              }
            } catch (error) {
              setFormSubmited((formSubmited) => ({
                ...formSubmited,
                status: "requestError",
                message: JSON.stringify(error.message),
              }));
            }
          } else {
            setFormSubmited((formSubmited) => ({
              ...formSubmited,
              status: "pdfError",
              message:
                "There was a problem creating the mindbody pdf, please try again",
            }));
          }
        } else {
          setFormSubmited((formSubmited) => ({
            ...formSubmited,
            status: "requestError",
            message: JSON.stringify(pdfData),
          }));
        }
      } catch (error) {
        console.log(error);
        if (pdfData.Aws !== undefined && pdfData.MindBody !== undefined) {
          setFormSubmited((formSubmited) => ({
            ...formSubmited,
            status: "requestError",
            message: JSON.stringify(error.message),
          }));
        } else {
          setFormSubmited((formSubmited) => ({
            ...formSubmited,
            status: "pdfError",
            message:
              "There was a problem creating the mindbody pdf, please try again",
          }));
        }
      }
    }
  };

  return (
    <div>
      <form>
        <div id="formContainer" className="container">
          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group" ref={requiredFields.firstName}>
                  <label>
                    First name / Nombre{" "}
                    <span className="lb-requiredLabel"> * </span>
                  </label>
                  <input
                    type="text"
                    className={
                      !formSubmited.attempt
                        ? "lb-form-control lb-border-0 lb-inputBackground"
                        : formData.firstName !== ""
                        ? "lb-form-control lb-border-0 lb-inputBackground"
                        : "lb-form-control " + formSubmited.inputRequiredClass
                    }
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    data-cy="firstName"
                  />
                </div>
              </div>
            </div>
          )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group" ref={requiredFields.lastName}>
                  <label>
                    Last name / Apellido{" "}
                    <span className="lb-requiredLabel"> * </span>
                  </label>
                  <input
                    type="text"
                    className={
                      !formSubmited.attempt
                        ? "lb-form-control lb-border-0 lb-inputBackground"
                        : formData.lastName !== ""
                        ? "lb-form-control lb-border-0 lb-inputBackground"
                        : "lb-form-control " + formSubmited.inputRequiredClass
                    }
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    data-cy="lastName"
                  />
                </div>
              </div>
            </div>
          )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
            <div className="row">
              <div className="col-6">
                <div className="lb-form-group">
                  <label>Email / Correo electronico </label>
                  <input
                    type="text"
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    data-cy="email"
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="lb-form-group">
                  <label>Phone - Cel / Teléfono de celular</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className={
                        "lb-form-control lb-border-0 lb-inputBackground col-3 col-sm-2"
                      }
                      value={"+1"}
                      readOnly
                    />
                    &nbsp;
                    <input
                      type="text"
                      className="lb-form-control lb-border-0 lb-inputBackground"
                      name="phone_cel"
                      value={formData.phone_cel}
                      onChange={handleChange}
                      data-cy="phone_cel"
                      maxLength={14}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row justify-content-around lb-text-center">
            <div className="col">
              <a href="#" id="waiver_link_dna" onClick={handleCheckbox}>
                Waiver
              </a>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col">
              <div
                className="lb-form-check lb-text-muted"
                ref={requiredFields.waiver_checkbox_dna}
              >
                <input
                  type="checkbox"
                  className={
                    !formSubmited.attempt
                      ? "lb-form-check-input"
                      : formData.waiver_checkbox_dna
                      ? "lb-form-check-input"
                      : "lb-form-check-input " + formSubmited.inputRequiredClass
                  }
                  onChange={handleCheckbox}
                  name="waiver_checkbox_dna"
                  id="waiver_checkbox_dna"
                  data-cy="waiver_checkbox_dna"
                  checked={formData.waiver_checkbox_dna}
                />
                <label
                  htmlFor="waiver_checkbox_dna"
                  className={
                    !formSubmited.attempt
                      ? "lb-form-check-label"
                      : formData.waiver_checkbox_dna
                      ? "lb-form-check-label"
                      : "lb-form-check-label-error"
                  }
                >
                  I agree with the waiver conditions
                  <span className="lb-requiredLabel"> * </span>
                </label>
              </div>
            </div>
          </div>

          {formSubmited.status === "success" && (
            <div
              className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-success"
              data-cy="formSubmitOk"
            >
              <span className="">
                Thank you for submitting the form, your information is now being
                processed. You can now close this page.
              </span>
            </div>
          )}
          {formSubmited.status === "responseError" && (
            <div className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error">
              <span className="">
                {formSubmited.message === ""
                  ? "Woops, this is weird. Something went wrong with the connection, please reload the page and try again."
                  : formSubmited.message}
              </span>
            </div>
          )}
          {formSubmited.status === "pdfError" && (
            <div
              className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error"
              data-cy="pdfErrorMsg"
            >
              <span className="">{formSubmited.message}</span>
            </div>
          )}
          {formSubmited.status === "requestError" && (
            <div className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error">
              <span className="">
                Woops, this is weird. Something went wrong with the form, please
                reload the page and try filling it again. {formSubmited.message}
              </span>
            </div>
          )}
          {formSubmited.required !== "ready" && formSubmited.attempt && (
            <div className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error">
              <span className="">
                Please fill all the required fields before submitting
              </span>
            </div>
          )}

          <div className="row px-0">
            <div className="col no-gutters px-0 lb-text-center">
              {formSubmited.status !== "success" && (
                <button
                  type="submit"
                  className={
                    formSubmited.required === "ready"
                      ? "lb-btn lb-submitButton p-3 pb-4 lb-w-100 no-gutters text-lb-btn-submit mb-2"
                      : "lb-btn lb-submitButtonDisabled p-3 pb-4 lb-w-100 no-gutters text-lb-btn-submit mb-2"
                  }
                  onClick={handleSubmit}
                  data-cy="cta-submit"
                >
                  {formSubmited.status === "sending" ? (
                    <div>
                      <FontAwesomeIcon spin icon={faSpinner} /> Loading...
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              )}
              {formSubmited.status === "success" &&
                state.bypass2fa === true && (
                  <Link
                    to={`/forms`}
                    className="lb-btn lb-submitButton lb-no-hover p-3 pb-4 lb-w-100 no-gutters text-lb-btn-submit mb-2"
                    data-cy="back-to-dashboard-submit"
                  >
                    GO BACK
                  </Link>
                )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

FormDNA.propTypes = {
  state: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  formSubmited: PropTypes.object.isRequired,
  setFormSubmited: PropTypes.func.isRequired,
  setModalsState: PropTypes.func.isRequired,
  modalsState: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  msgData: PropTypes.object.isRequired,
};
export default FormDNA;
