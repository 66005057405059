import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import { formatAmount } from "../../util/formatNumbers.js";
import logo from "../../logo.png";

const PdfTable = ({ sitesData, viewTableColumn, reportDate, totals }) => {
  const borderColor = '#bfbfbf';
  const borderStyle = 'solid';
  const widthStyle = {
    sales: "30%",
    madeBy: "18%",
    appointments: "11%",
    salesAndMadeBy: "12%",
    salesAndAppointment: "8%",
    madeByAndAppointment: "8%",
    allColumns: "7%"
  };
  const fontSizeStyle = {
    oneOrTwoColumnsHeader: 18,
    allColumnsHeader: 12,
    oneOrTwoColumnsCell: 16,
    allColumnsCell: 10,
  };

  const [columns, setColumns] = useState({
    sales: false,
    madeBy: false,
    appointments: false,
    salesAndMadeBy: false,
    salesAndAppointment: false,
    madeByAndAppointment: false,
    all: false,
  });

  useEffect(() => {

    if (viewTableColumn.sales) {
      setColumns((columns) => ({
        ...columns,
        sales: true
      }));
    } else {
      setColumns((columns) => ({
        ...columns,
        sales: false
      }));
    }
    if (viewTableColumn.madeBy) {
      setColumns((columns) => ({
        ...columns,
        madeBy: true
      }));
    } else {
      setColumns((columns) => ({
        ...columns,
        madeBy: false
      }));
    }
    if (viewTableColumn.appointments) {
      setColumns((columns) => ({
        ...columns,
        appointments: true
      }));
    } else {
      setColumns((columns) => ({
        ...columns,
        appointments: false
      }));
    }
    if (viewTableColumn.sales && viewTableColumn.madeBy) {
      setColumns((columns) => ({
        ...columns,
        sales: false,
        madeBy: false,
        salesAndMadeBy: true,
      }));
    } else {
      setColumns((columns) => ({
        ...columns,
        salesAndMadeBy: false,
      }));
    }
    if (viewTableColumn.sales && viewTableColumn.appointments) {
      setColumns((columns) => ({
        ...columns,
        sales: false,
        appointments: false,
        salesAndAppointment: true,
      }));
    } else {
      setColumns((columns) => ({
        ...columns,
        salesAndAppointment: false,
      }));
    }
    if (viewTableColumn.madeBy && viewTableColumn.appointments) {
      setColumns((columns) => ({
        ...columns,
        madeBy: false,
        appointments: false,
        madeByAndAppointment: true,
      }));
    } else {
      setColumns((columns) => ({
        ...columns,
        madeByAndAppointment: false,
      }));
    }
    if (viewTableColumn.madeBy && viewTableColumn.appointments && viewTableColumn.sales) {
      setColumns((columns) => ({
        ...columns,
        salesAndMadeBy: false,
        salesAndAppointment: false,
        madeByAndAppointment: false,
        all: true,
      }));
    } else {
      setColumns((columns) => ({
        ...columns,
        all: false,
      }));
    }

  }, [viewTableColumn])

  const styles = StyleSheet.create({
    text:{
      marginLeft: "10px"
    },
    body: {
      padding: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row"
    },
    tableCol1Header: {
      width: columns.sales ? widthStyle.sales :
        columns.madeBy ? widthStyle.madeBy :
          columns.appointments ? widthStyle.appointments :
            columns.salesAndMadeBy ? widthStyle.salesAndMadeBy :
              columns.salesAndAppointment ? widthStyle.salesAndAppointment :
                columns.madeByAndAppointment ? widthStyle.madeByAndAppointment :
                  widthStyle.allColumns,
      borderStyle: borderStyle,
      borderColor: borderColor,
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#c2608ea9',
      color: "white",
      textAlign: "center",
    },
    tableCol1: {
      width: columns.sales ? widthStyle.sales :
        columns.madeBy ? widthStyle.madeBy :
          columns.appointments ? widthStyle.appointments :
            columns.salesAndMadeBy ? widthStyle.salesAndMadeBy :
              columns.salesAndAppointment ? widthStyle.salesAndAppointment :
                columns.madeByAndAppointment ? widthStyle.madeByAndAppointment :
                  widthStyle.allColumns,
      borderStyle: borderStyle,
      borderColor: borderColor,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "center",
    },
    total: {
      width: columns.sales ? widthStyle.sales :
        columns.madeBy ? widthStyle.madeBy :
          columns.appointments ? widthStyle.appointments :
            columns.salesAndMadeBy ? widthStyle.salesAndMadeBy :
              columns.salesAndAppointment ? widthStyle.salesAndAppointment :
                columns.madeByAndAppointment ? widthStyle.madeByAndAppointment :
                  widthStyle.allColumns,
      borderWidth: 1,
      borderTopWidth: 0,
      textAlign: "center",
    },
    totalCol: {
      width: columns.sales ? widthStyle.sales :
        columns.madeBy ? widthStyle.madeBy :
          columns.appointments ? widthStyle.appointments :
            columns.salesAndMadeBy ? widthStyle.salesAndMadeBy :
              columns.salesAndAppointment ? widthStyle.salesAndAppointment :
                columns.madeByAndAppointment ? widthStyle.madeByAndAppointment :
                  widthStyle.allColumns,
    },
    tableCellHeader: {
      margin: 4,
      fontSize: columns.sales ? fontSizeStyle.oneOrTwoColumnsHeader :
      columns.madeBy ? fontSizeStyle.oneOrTwoColumnsHeader :
        columns.appointments ? fontSizeStyle.oneOrTwoColumnsHeader :
          columns.salesAndMadeBy ? fontSizeStyle.oneOrTwoColumnsHeader :
            columns.salesAndAppointment ? fontSizeStyle.oneOrTwoColumnsHeader :
              columns.madeByAndAppointment ? fontSizeStyle.oneOrTwoColumnsHeader :
              fontSizeStyle.allColumnsHeader,
      fontWeight: 500
    },
    tableCell: {
      margin: 4,
      fontSize: columns.sales ? fontSizeStyle.oneOrTwoColumnsCell :
      columns.madeBy ? fontSizeStyle.oneOrTwoColumnsCell :
        columns.appointments ? fontSizeStyle.oneOrTwoColumnsCell :
          columns.salesAndMadeBy ? fontSizeStyle.oneOrTwoColumnsCell :
            columns.salesAndAppointment ? fontSizeStyle.oneOrTwoColumnsCell :
              columns.madeByAndAppointment ? fontSizeStyle.oneOrTwoColumnsCell :
              fontSizeStyle.allColumnsCell,
    },
    logo: {
      width: 70,
      marginTop: 10,
      marginBottom: 20,
      heigth: 50,
      marginLeft: 10,
    },
    dateText: {
      marginTop: "30%",
      fontSize: 16,
      fontWeight: 500,
      marginLeft: "13%"
    },
    dateTextDiv: {
      width: "15%",
    },
    logoDiv: {
      width: "78%",
    }
  });
  return (
    <Document>
      <Page size={"A2"} style={styles.body}>

        <View style={styles.table}>
          <View style={styles.tableRow}>

            <View style={styles.logoDiv}>
              <Image src={logo} style={styles.logo} alt="Little Bellies Logo" />
            </View>
            <View style={styles.dateTextDiv}>
              <Text style={styles.dateText}>Date: {reportDate}</Text>
            </View>

          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1Header}>
              <Text style={styles.tableCellHeader}>SITE</Text>
            </View>
            {viewTableColumn.madeBy && (
              <>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>ONLINE</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>EMPATHY CENTER</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>MIND BODY</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>APPOINTMENTS BOOKED</Text>
                </View>
              </>
            )}
            {viewTableColumn.appointments && (
              <>
               <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>DICOM</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>ATTENDED</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>NO SHOW</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>PENDING</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>CBFF</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>CBFF GRANTED</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>APPOINTMENTS</Text>
                </View>
              </>
            )}
            {viewTableColumn.sales && (
              <>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>EXPECTED SALES</Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>SALES</Text>
                </View>
              </>
            )}
          </View>
          {sitesData.map((location, i) => {
            return (
              <View style={styles.tableRow} key={i}>
                <View style={styles.tableCol1}>
                  <Text style={styles.tableCell}>{location.siteName?.split("-")[1]}</Text>
                </View>
                {viewTableColumn.madeBy && (
                  <>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.origin.online}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.origin.callCenter}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.origin.mindBody}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{
                        location.data.origin.callCenter + location.data.origin.online +
                        (location.data.origin.mindBody ? location.data.origin.mindBody : 0)}
                      </Text>
                    </View>
                  </>
                )}
                {viewTableColumn.appointments && (
                  <>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.dicomOk}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.completed}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.noShow}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.confirmed + location.data.booked + location.data.arrived}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.cbff}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.cbffGranted}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{location.data.totalAppointments}</Text>
                    </View>
                  </>
                )}
                {viewTableColumn.sales && (
                  <>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>${formatAmount(location.data.totalExpectedSales)}</Text>
                    </View>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>${formatAmount(location.data.totalSales)}</Text>
                    </View>
                  </>
                )}
              </View>
            )
          })}
          <View style={styles.tableRow}>
            <View style={styles.totalCol}>
              <Text style={styles.tableCell}></Text>
            </View>
            {viewTableColumn.madeBy && (
              <>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.online}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.callCenter}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.mindBody}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.appointmentsBooked}</Text>
                </View>
              </>
            )}
            {viewTableColumn.appointments && (
              <>
               <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.dicomOk}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.completed}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.noShow}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.pending}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.cbff}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.cbffGranted}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>{totals.appointments}</Text>
                </View>
              </>
            )}
            {viewTableColumn.sales && (
              <>
                 <View style={styles.total}>
                  <Text style={styles.tableCell}>${formatAmount(totals.expectedSales)}</Text>
                </View>
                <View style={styles.total}>
                  <Text style={styles.tableCell}>${formatAmount(totals.sales)}</Text>
                </View>
              </>
            )}
          </View>
        </View>
      </Page>
    </Document>)
};

PdfTable.propTypes = {
  sitesData: PropTypes.array,
  viewTableColumn: PropTypes.object,
  reportDate: PropTypes.string,
  totals: PropTypes.object
};
export default PdfTable;
