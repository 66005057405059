import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DraggableBlock from "./draggableBlock";
import HoverCard from "./hoverCard";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

function DefaultBlockW({
  getBlockClass,
  handleClick,
  setHoverIndex,
  handleChangeBlockOnDrop,
  getBlockDescription,
  handleClose,
  handleSidebarChangeWeek,
  setConfirmationAction,
  confirmAppointment,
  viewLastVisit,
  id,
  appointment,
  appointments,
  available,
  segment,
  blockDate,
  type,
  endSegment,
  occupied,
  index,
  state,
  datesState,
  dayIndex,
  displayableBlocks,
  roomAppointments,
  hoverIndex,
  textResponse,
  startDate,
  roomIndex,
  highlight,
  onClickArgument,
}) {
  return (
    <>
      <div
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick( "" + (appointment.id === undefined ? index + "" + dayIndex + "" + displayableBlocks.staffId : appointment.id))}
        onMouseOver={() => { setHoverIndex( "" + id + appointment.id );}}
        onMouseLeave={() => setHoverIndex("")}
      >
        <DraggableBlock
          appointment={appointment}
          isWeekRange={datesState.isWeekRange}
          displayAll={state.displayAll}
          blocks={roomAppointments.blocks}
          indice={index}
        />
        {hoverIndex === "" + id + appointment.id && (
          <HoverCard 
            appointment={appointment}
            cardClass={getBlockClass(available,appointment,roomAppointments.blocks,index,id,displayableBlocks.staffId,roomAppointments.index,false)} 
            segment={segment}
            endSegment={endSegment}
          />
        )}
      </div>

      <Menu
        open={ state.mouseY !== null && state.appointmentId === "" + appointment.id}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={ state.mouseY !== null && state.mouseX !== null ? { top: state.mouseY, left: state.mouseX, } : undefined}
      >
        <MenuItem
          data-bs-toggle="modal"
          data-bs-target="#modalConfirmationAction"
          onClick={() => {
            setConfirmationAction((confirmationAction) => ({
              ...confirmationAction,
              nameAction: "cancel",
              text: textResponse.cancel,
              response: "default",
              data: appointment,
              info: true,
              infoTittle: `${appointment.firstName} ${appointment.lastName}, ${appointment.sessionTypeName}`,
              infoBody:
                <>
                  <div className="mt-1">
                  <span className="pr-5"><b>Date:</b> {moment(startDate).format("YY-MM-DD")},&nbsp;
                  {moment(appointment.StartDateTime).format("hh:mm A")} - {moment(appointment.endDateTime).format("hh:mm A")}</span>
                  </div>
                  <div className="mt-2">
                  <span className="pr-5"><b>Phone:</b> {appointment.phone}</span>
                  </div>
                  <div className="mt-2">
                  <span className="pr-5 "><b>Email:</b> {appointment.email}</span>
                  </div>
                </>
            }))
          }}
        >
          Cancel
        </MenuItem>
        <MenuItem
          onClick={() => {confirmAppointment(appointment.id);}}
        >
          Confirm
        </MenuItem>
        <MenuItem
          onClick={() => handleSidebarChangeWeek( segment,displayableBlocks.staffId,"editAppointment",blockDate,appointment,id,roomAppointments.index)}
        >
          Edit / Reschedule
        </MenuItem>
        <MenuItem
          onClick={() =>viewLastVisit(appointment)}
        >
          Last Visit
        </MenuItem>
      </Menu>
    </>
  );
}

DefaultBlockW.propTypes = {
  getBlockClass: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  setHoverIndex: PropTypes.func.isRequired,
  handleChangeBlockOnDrop: PropTypes.func.isRequired,
  getBlockDescription: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSidebarChangeWeek: PropTypes.func.isRequired,
  setConfirmationAction: PropTypes.func.isRequired,
  confirmAppointment: PropTypes.func.isRequired,
  viewLastVisit: PropTypes.func.isRequired,
  id: PropTypes.array.isRequired,
  appointment: PropTypes.object.isRequired,
  appointments: PropTypes.array.isRequired,
  available: PropTypes.bool.isRequired,
  segment: PropTypes.string.isRequired,
  blockDate: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  endSegment: PropTypes.string.isRequired,
  occupied: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  datesState: PropTypes.object.isRequired,
  dayIndex: PropTypes.number.isRequired,
  displayableBlocks: PropTypes.object.isRequired,
  roomAppointments: PropTypes.object.isRequired,
  hoverIndex: PropTypes.string.isRequired,
  textResponse: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  roomIndex: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  highlight: PropTypes.bool.isRequired,
  onClickArgument: PropTypes.string,
};

export default DefaultBlockW;