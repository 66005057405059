export { formatDate2string, formatDate2object, transformSites };

function formatDate2string(date) {
	if (typeof date === "string") {
		const ddmmyyyy = /^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/;

		if (ddmmyyyy.test(date)) {
			const dateParts = date.split("-");
			const dd = dateParts[0].padStart(2, "0");
			const mm = dateParts[1].padStart(2, "0");
			const yyyy = dateParts[2];
			const formattedDate = mm + "/" + dd + "/" + yyyy;
			return formattedDate;
		}
	} else if (date instanceof Date) {
		const dd = date.getDate().toString().padStart(2, "0");
		const mm = (date.getMonth() + 1).toString().padStart(2, "0");
		const yyyy = date.getFullYear().toString();
		const formattedDate = mm + "/" + dd + "/" + yyyy;
		return formattedDate;
	}
	return null;
}

function formatDate2object(date) {
	if (typeof date === "string") {
		const ddmmyyyy = /^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/;

		if (ddmmyyyy.test(date)) {
			const dateParts = date.split("-");
			const dd = dateParts[0].padStart(2, "0");
			const mm = dateParts[1].padStart(2, "0");
			const yyyy = dateParts[2];
			const formattedDate = {
				dd: dd,
				mm: mm,
				yyyy: yyyy,
			};
			return formattedDate;
		} else {
			return "error";
		}
	} else if (date instanceof Date) {
		const dd = date.getDate().toString().padStart(2, "0");
		const mm = (date.getMonth() + 1).toString().padStart(2, "0");
		const yyyy = date.getFullYear().toString();
		const formattedDate = {
			dd: dd,
			mm: mm,
			yyyy: yyyy,
		};
		return formattedDate;
	}
	return null;
}

function transformSites({
	sites,
	filterSiteId
  }) {
	const transformedSites = [];
  
	for (const site of sites) {
	  const [siteId, id] = site.site.split('-');
	  if (siteId === filterSiteId) {
		transformedSites.push({
		  siteId: Number(siteId.trim()),
		  id: Number(id.trim()),
		  name: site.name
		});
	  }
  
	}
  
	return transformedSites;
}
  
