// import http from "../http-common";
import {sign, getAlbum, remove} from './aws'

class UploadFilesService {
  remove = async (file, appointmentid) => {

    console.log("remove...");
    console.log(file);
    const response = await remove(file, appointmentid);
    console.log(response);
    return response;
  }  

  upload = async (file, appointmentid, onUploadProgress) => {

    // const formData = new FormData();
    console.log("upload...");
    console.log(file);
    const response = await sign(file, appointmentid);

    console.log(response);
    return response;
    // formData.append("file", file);

    // return http.put(url, file, {
    //   headers: {
    //     "Content-Type": file.type,
    //   },
    //   onUploadProgress,
    // });
  }

  getFiles = async (appointmentid) => {
    return await getAlbum(appointmentid);
  }
}

export default new UploadFilesService();
