import React from "react";
import logo from "../logo.png";
import { Link } from "react-router-dom";
import "../styles/bootstrap-grid.min.css";
import "../styles/global.css";

function MenuDashboard() {
  return (
    <div className="content">
      <div className="container mt-3">
        <div className="row ">
          <div className="col-4 col-md-3 col-lg-2 mx-auto">
            <a className="lb-navbar-brand" href="/">
              <img src={logo} width="100%" alt="Little Bellies Logo"></img>
            </a>
          </div>
        </div>
        <div className="row mt-4 ">
          <div className="col">
            <h4 className="mx-10 lb-font-bold text-center">
              Where do you want to enter?
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="d-grid gap-2 col-4 mx-auto">
            <Link
              className="lb-btn lb-submitButton lb-rounded-0 p-10 lb-no-hover d-none"
              to="/bookinglogin"
              data-cy="btn-call"
            >
              Empathy Center Dashboard
            </Link>
            <Link
              className="lb-btn lb-submitButton lb-rounded-0 p-10 lb-no-hover"
              to="/managementlogin"
              data-cy="btn-solutions"
            >
              Empathy Solutions
            </Link>
            <Link
              className="lb-btn lb-submitButton lb-rounded-0 p-10 lb-no-hover"
              to="/login"
              data-cy="btn-technicians"
            >
              Technicians Dashboard
            </Link>
            <Link
              className="lb-btn lb-submitButton lb-rounded-0 p-10 lb-no-hover"
              to="/directorslogin"
              data-cy="btn-directive"
            >
              Directive Dashboard
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuDashboard;
