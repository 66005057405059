import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { formatDate2string } from "../util/index.js";
import "../styles/global.css";
import "../styles/operational.css";
import OperationalDashboardGraph from "../components/operationalDashboardGraph.js";
import OperationalDashboardTable from "../components/operationalDashBoardTable.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faFileAlt,
  faRedoAlt
} from "@fortawesome/free-solid-svg-icons";

function OperationalDashboard({ appState }) {
  const today = new Date();
  const time = moment(today).format("HH:mm");
  const [operationalData, setOperationalData] = useState([]);
  const [realodDashboard, setRealodDashboard] = useState({action: false});
  const [state, setState] = useState({
    status: "idle",
    message: "",
    table: true,
  });
  const [failed, setFailed] = useState([]);

  const getOperationalData = async (location) => {
    const { siteId, locationId, siteName, token } = location;
    const getMethod = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        siteid: siteId,
        authorization: token,
        locationid: locationId,
      },
    };

    const url = `/api/site/${siteId}/locations/${locationId}/summary?appointmentDate=${formatDate2string(
      today
    )}`;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${url}`,
      getMethod
    );
    if (response.ok) {
      const data = {
        data: await response.json(),
        siteId: siteId,
        locationId: locationId,
        siteName: siteName,
      };
      return Promise.resolve(data);
    } else {
      return Promise.resolve({
        error:
          "Failed to retrieve data from site : " +
          siteName +
          " " +
          siteId +
          " at location: " +
          locationId,
        siteId: siteId,
        locationId: locationId,
        siteName: siteName,
      });
    }
  };

  const getData = async () => {
    setState({
      status: "loading",
      table: true,
    });
    try {
      const dataRequests = appState.directorsAuth.locationsAuth.map((loc) =>
        getOperationalData(loc)
      );
      const data = await Promise.all(dataRequests);
      const goodData = data.filter((item) => {
        return "data" in item;
      });
      const badData = data.filter((item) => {
        return "error" in item;
      });
      setOperationalData(goodData);
      setFailed(badData);
      setState({
        status: "ready",
        table: true,
      });
      setRealodDashboard((realodDashboard) => ({
        ...realodDashboard,
        action:false
      }));
    } catch (e) {
      console.log(e);
      setState({
        status: "error",
        message: e.message,
      });
      setOperationalData([]);
    }
  };


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(realodDashboard.action) getData();
  }, [realodDashboard.action]);

  const handleSwitchButton = (e) => {
    const { name } = e.target;

    const graphIconPath = "M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z";
    const tableIconPath = "M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z";

    const pathArrayProperties = Object.values(e.target);

    if (pathArrayProperties !== undefined) {
      if ("d" in pathArrayProperties[1]) {
        if (pathArrayProperties[1].d === tableIconPath) {
          setState((state) => ({
            ...state,
            table: true,
          }));
        } else if (pathArrayProperties[1].d === graphIconPath) {
          setState((state) => ({
            ...state,
            table: false,
          }));
        }
      } else {
        if (pathArrayProperties[1].children.props.d === tableIconPath) {
          setState((state) => ({
            ...state,
            table: true,
          }));
        } else if (pathArrayProperties[1].children.props.d === graphIconPath) {
          setState((state) => ({
            ...state,
            table: false,
          }));
        }
      }
    }
    if (name === "button-table") {
      setState((state) => ({
        ...state,
        table: true,
      }));
    }
    if (name === "button-graph") {
      setState((state) => ({
        ...state,
        table: false,
      }));
    }
  }

  return (
    <div data-cy="operational-dashboard">
      <div className="container">
        <div className="mt-5 lb-title-daily d-flex justify-content-center">
        <h4>Operational Dashboard</h4>
        </div>
        <div className="mt-3 lb-title-daily d-flex justify-content-end">
          <h5>
            {formatDate2string(today)} {time}
          </h5>
        </div>
        
           <div className="d-flex">
             <div className="col-6 d-flex">
             <div className="lb-nav lb-nav-tabs">
                 <div className="lb-nav-item">
                <button
                  name="button-table"
                  className={state.table ? "lb-nav-link active shadow-sm" : "lb-nav-link shadow"}
                  data-cy="button-table"
                  onClick={(e) => handleSwitchButton(e)}
                >
                  <FontAwesomeIcon className="mt-1" icon={faFileAlt} style={{ fontSize: 25 }} />
                </button>
              </div>

              
              <div className="lb-nav-item">
                <button name="button-graph"
                  className={state.table ? "lb-nav-link shadow" : "lb-nav-link active shadow-sm"}
                  onClick={(e) => handleSwitchButton(e)}
                  data-cy="button-graph"
                >
                  <FontAwesomeIcon className="mt-1" icon={faChartLine} style={{ fontSize: 25 }} />
                </button>
              </div>        
                                  
                 </div>
             </div>
             <div className="justify-content-end d-flex col-6">

                <button name="button-graph"
                  className={"border lb-nav-link active text-white rounded-circle pt-1 p-3 pb-1 m-2 btn-sm"}
                  onClick={(e) => { setRealodDashboard((realodDashboard) => ({...realodDashboard,action:true})) }}
                  data-cy="btn-reload-dashbaoard"
                  style={{ backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff", color: "white" }}>
                    
                  {state.status === "loading" ? 
                  <FontAwesomeIcon spin icon={faRedoAlt} style={{ fontSize: 20 }} />
                  :
                  <FontAwesomeIcon icon={faRedoAlt} style={{ fontSize: 20 }} />
                  }
                </button>
             </div>
         </div>

        <div className="row mt-4">
        {state.status === "loading" && (
            <div className="loading" data-cy="loading-report-message">
              <div className="lb-spinner" />
            </div>
          )}

          {state.status === "error" && (
            <div className="mt-1 mx-auto mb-3 lb-form-submited-error">
              <span data-cy="error-message">{state.message}</span>
            </div>
          )}

          {state.table
            ?
            state.status === "ready" && (
              <OperationalDashboardTable
                operationalData={operationalData}
                state={state}
                realodDashboard={realodDashboard}
              />
            )
            :
            <OperationalDashboardGraph
              operationalData={operationalData}
              failed={failed}
            />
          }

        </div>
      </div>

    </div>
  );
}

OperationalDashboard.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default OperationalDashboard;
