import React from "react";
import { PropTypes } from "prop-types";
import BarChart from "../components/barChart";
import HorizontalBarChart from "../components/horizontalBarChart";
import { Link } from "react-router-dom";

const OperationalDashboardGraph = ({
    operationalData,
    failed,
}) => {

return(

    <div className="row">

    {operationalData.map((location) => {
        const values = {
          Pending:
            location.data.confirmed +
            location.data.booked +
            location.data.arrived,
          CBFF: location.data.cbff,
          "No Show": location.data.noShow,
          Attended: location.data.completed,
        };
        const labels = ["Pending", "CBFF", "No Show", "Attended"];

        const origin = {
          labels: ["Call Center", "Online", "MindBody"],
          values: [
            location.data.origin.callCenter,
            location.data.origin.online,
            location.data.origin.mindBody,
          ],
          colors: ["rgb(255, 205, 86,0.2)", "rgb(75, 192, 192,0.2)"],
          borders: ["rgba(255, 205, 86)", "rgba(75, 192, 192)"],
          chartName: "Appointments Made by",
        };

        return (

          <div
            className="col-12 col-md-4 mb-4"
            key={location.siteId + "-" + location.locationId}
          > 
           <div className="card text-center">
              <div className="card-header lb-title-operational">
                <h4>{location.siteName}</h4>
              </div>
              <div className="card-body">
                <div className="lb-height-bar"> 
                  <BarChart
                    labels={labels}
                    values={values}
                    maxHeight={location.data.totalAppointments}
                  />
                </div>
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="row g-0">
                      <div className="col-8 text-start">
                        <h5>Total Sales</h5>
                      </div>
                      <div className="col-4 text-end">
                        <h5>${location.data.totalSales}</h5>
                      </div>
                      <div className="col-8 text-start">
                        <h6>Total Completed</h6>
                      </div>
                      <div className="col-4 text-end">
                        <h6>{location.data.completed}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="row g-0">
                      <div className="col-8 text-start">
                        <h5>Total Expected Net Sales</h5>
                      </div>
                      <div className="col-4 text-end">
                        <h5>${location.data.totalExpectedSales}</h5>
                      </div>
                      <div className="col-8 text-start">
                        <h6>Total Appointments</h6>
                      </div>
                      <div className="col-4 text-end">
                        <h6>{location.data.totalAppointments}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lb-height-bar-horizontal mt-2">
                  <HorizontalBarChart
                    labels={origin.labels}
                    values={origin.values}
                    colors={origin.colors}
                    borderColors={origin.borders}
                    chartName={origin.chartName}
                  />
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <Link
                  className="lb-nav-link"
                  data-cy="operational-details"
                  to={{
                    pathname: "/operationaldetails",
                    location: location,
                  }}
                >
                  more details
                </Link>
              </div>
            </div>
          </div>
        );
      })}

{failed.length > 0 &&
            failed.map((item) => {
              return (
                <div
                  className="mt-1 mx-auto mb-3 lb-form-submited-error"
                  key={item.siteId + "-" + item.locationId}
                >
                  <span
                    data-cy={
                      "error-message-" + item.siteId + "-" + item.locationId
                    }
                  >
                    {item.error}
                  </span>
                </div>
              );
            })}

</div>


)


};


OperationalDashboardGraph.propTypes = {
    operationalData : PropTypes.array,
    failed : PropTypes.array,
    
  };
  export default OperationalDashboardGraph;
  