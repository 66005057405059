import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import DynamicTable from "../components/dynamicTable";
import { formatAmount } from "../util/formatNumbers";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProjectionReport({ appState }) {

  const todayMinus1 = moment().subtract(1, 'days').format('MM/DD/YYYY');
  const startDate = moment(`${todayMinus1}`, 'MM/DD/YYYY').startOf('month').toString();
  const endDate = moment().subtract(1, 'days').toString();

  const [body, setBody] = useState([]);
  const [startD, setStartD] = useState(startDate);
  const [endD, setEndD] = useState(endDate);
  
  const [state, setState] = useState({
    loading: false,
    response: "default",
  });
  const [reloadData, setReloadData] = useState({
    action: false
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: body.length === 0,
    }));
  }, [body, state.loading, state.response]);

  useEffect(() => {

    if (reloadData.action) {
      getData();
    }
  }, [reloadData.action, body])


  const getDailyClosing = async (location) => {
    const { siteId, locationId, siteName, token } = location;

    const getData = {
      method: "GET",
      headers: {
        "Content-type": "applicationjson; charset=UTF-8",
        siteid: siteId,
        authorization: token,
        locationid: locationId,
      }
    };
    const searchStartDate = moment(startD).format('MM/DD/YYYY');
    const searchEndDate = moment(endD).format('MM/DD/YYYY');

    const request = `${process.env.REACT_APP_API_URL}/api/sites/${siteId}/locations/${locationId}/sales/receipt?startDate=${searchStartDate}&endDate=${searchEndDate}`;
    const response = await fetch(request, getData);

    if (response.ok) {
      const data = {
        data: await response.json(),
        siteId: siteId,
        locationId: locationId,
        siteName: siteName,
      };
      return Promise.resolve(data);
    } else {
      return Promise.resolve({
        error:
          "Failed to retrieve data from site : " +
          siteName +
          " " +
          siteId +
          " at location: " +
          locationId,
        siteId: siteId,
        locationId: locationId,
        siteName: siteName,
      });
    }

  }

  const getData = async () => {
    setState((state) => ({
      ...state,
      response: "loading",
    }));

    try {
      const dataRequests = appState.directorsAuth.locationsAuth.map((loc) =>
        getDailyClosing(loc)
      );
      const data = await Promise.all(dataRequests);

      const goodData = data.filter((item) => {
        return "data" in item;
      });

      const startDate = moment(startD).format('MM/DD/YYYY');
      const endDate = moment(endD).format('MM/DD/YYYY');
      const lastDayOfMonth = moment(`${startDate}`, 'MM/DD/YYYY').endOf('month').format('MM/DD/YYYY');
      const daysBetweenDates = moment(endDate).diff(startDate, 'days');
      const lastDayOfMonthParseDay = parseInt(moment(lastDayOfMonth).format("DD"));

      const newListData = goodData.length !== 0 ? goodData.map((i) => {
        const result = (parseFloat(i.data.salesReceipt) / (daysBetweenDates + 1) * lastDayOfMonthParseDay);
        return { siteName: i.siteName?.split("-")[1], salesReceipt: formatAmount(i.data.salesReceipt), projected: formatAmount(result) };
      }) : [];

      setBody(newListData);
      setReloadData((reloadData) => ({
        ...reloadData,
        action: false
      }));
      setState((state) => ({
        ...state,
        response: "default",
      }));

    } catch (error) {
      setState((state) => ({
        ...state,
        response: "error",
      }));
      setReloadData((reloadData) => ({
        ...reloadData,
        action: false
      }));
      setBody([]);
    }

  }

  const handleDateRangeSearch = () => {
    setReloadData((reloadData) => ({
      ...reloadData,
      action: true
    }));
  };


  return (
    <div className="container mt-4">
      <>
        <h2
          className="text-center lb-title-daily extraclass"
          data-cy="projection-report"
        >
          Projection Report
        </h2>

      <div className="d-flex justify-content-center" style={{marginTop:"8%"}}>
        <div className="col-6 d-flex text-center">

          <div className="col-6 d-block">
            <h5 className="lb-title-daily">Start</h5>
            <DatePicker
            selected={new Date(startD)}
            dateFormat="MM/dd/yyyy"
            onChange={(date) => setStartD(date)}
            className="form-control mr-3"
            />
          </div>
        
          <div className="col-6 d-block">
            <h5 className="lb-title-daily">End</h5>
            <DatePicker
            selected={new Date(endD)}
            dateFormat="MM/dd/yyyy"
            onChange={(date) => setEndD(date)}
            className="form-control ml-3"
            minDate={new Date(startD)}
            />
          </div>

          <div style={{marginTop:"5%"}}>
            <button disabled={reloadData.action || state.loading} 
              data-cy="btn-search-projection-report-data" 
              className="btn border text rounded-pill btn-sm ml-4 pt-2 pb-2"
              onClick={(e) => {handleDateRangeSearch(e)}}
              style={{ color: "white", width: 65, backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff" }}>
                <FontAwesomeIcon icon={faSearch} style={{ fontSize: 20 }} />
            </button>
          </div>
          </div>
      </div>

        <section id="table" className="mt-4">
          <DynamicTable
            fileNamePdf={"reportProjectionReport.pdf"}
            headers={["SITE", "SALES RECEIPT", "PROJECTED"]}
            tfoot={[{ show: false, amount: false }, { show: true, amount: true },{ show: true, amount: true }]}
            rows={body}
            setReloadData={setReloadData}
            reloadData={reloadData}
            titlePdf={"Projection Report"}
            subTitlePdf={`${moment(startD).format('MM/DD/YYYY')} - ${moment(endD).format('MM/DD/YYYY')}`}
          />
        </section>
      </>
    </div>
  );
}

ProjectionReport.propTypes = {
  appState: PropTypes.object.isRequired,
};

export default ProjectionReport;
