
export const formatAmount = (amount) =>{
	const value = parseFloatLocale(amount);
	return value.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const parseFloatLocale = (amount) => {
	const strNum = String(amount); 
	const noComma = strNum.replaceAll(",", "");
	return parseFloat(noComma);
};


export const parseNumberPhoneUS = (phone) => {
	const x = phone.toString().replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
	return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '' );
};

