import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faExclamationTriangle, faSpinner, faTimesCircle, faAngleDoubleLeft, faAngleDoubleRight, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DroppableSidebar from "../components/booking/droppableSidebar";
import DraggableSidebar from "../components/booking/draggableSidebar";
import DnaBlock from "../components/booking/dnaBlock";
import DnaBlockW from "../components/booking/dnaBlockW";
import DefaultBlock from "../components/booking/defaultBlock";
import DefaultBlockW from "../components/booking/defaultBlockW";
import EmptyBlockW from "../components/booking/emptyBlockW";
import EmptyBlock from "../components/booking/emptyBlock";
import { blocks } from "../config/constants.js"
import "../styles/booking.css";
import ConfirmationAction from "../components/modals/confirmationAction";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import VisitsTable from "../components/visitsTable";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import "../styles/modalScroll.css";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Booking dashboard for call center employees to book and manage clients appoointments.
 * @param {object} appState Required object from app state that stores authorization tokens for all locations.
 */

function BookingPage({ appState, setAppState }) {
  const [state, setState] = useState({
    status: "",
    message: "",
    mouseX: null,
    mouseY: null,
    appointmentId: "",
    siteId: "",
    locationId: "",
    staffId: "",
    displayAll: true,
    totalCounter: {
      Booked: 0,
      Confirmed: 0,
      Arrived: 0,
      Completed: 0,
      NoShow: 0,
      total: 0,
      cbff: 0,
    },
    requesting: "IDLE",
    selectedLocation: "",
  });
  const [userAction, setUserAction] = useState({
    info: "",
    date: "",
  });
  const [sidebarState, setSidebarState] = useState({
    status: "IDLE",
    requesting: "IDLE",
    message: "",
    siteId: "",
    locationId: "",
    authorization: "",
    searchTerm: "",
    searchResults: [],
    client: {},
    editedClient: {
      firstName: "",
      lastName: "",
      mobilePhone: "",
      email: "",
    },
    firstName: null,
    lastName: null,
    mobilePhone: null,
    email: null,
    staffId: "",
    service: null,
    serviceName: "",
    block: "",
    blockDate: "",
    appointment: null,
    editedAppointment: null,
    blockId: "",
    dayIndex: "",
    availableBlocks: [],
    createNotes: "",
    statusDisabled: false,
    lastVisit: {
      client: {},
      appointments: [],
    }
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [datesState, setDatesState] = useState({ isWeekRange: false });
  const [rooms, setRooms] = useState([]);
  const [blocksPerRoom, setBlocksPerRoom] = useState([]);
  const [blocksPerWeek, setBlocksPerWeek] = useState([]);
  const [displayableBlocks, setDisplayableBlocks] = useState({});
  const [fetchServices, setFetchServices] = useState(false);
  const [services, setServices] = useState([]);
  const [location, setLocation] = useState({});
  const [hoverIndex, setHoverIndex] = useState("");

  const [confirmationAction, setConfirmationAction] = useState({
    action: false,
    text: "",
    response: "default",
    nameAction: "",
    data: "",
    info: true,
    infoTittle: "",
    infoBody: "",
  });

  const textResponse = {
    error: "Oops, an error has occurred, contact your support team",
    cancel: "Are you sure to cancel appointment?",
    successCancel: "Appointment canceled successfully!",
    reschedule: "Are you sure to reschedule/edit appointment?",
    successReschedule: "Appointment scheduled/edited successfully!"
  };

  const [rebookSidebar, setRebookSidebar] = useState({
    appointment: {},
  });
  const [clientVisits, setClientVisits] = useState({
    status: "IDLE",
    requesting: "IDLE",
    message: "",
    inputValue: "",
    visits: [],
    client: {},
  });
  const classes = useStyles();
  const [sitesInfo, setSitesInfo] = useState([]);


  useEffect(() => {
    const mutableDate = new Date(startDate.toString());
    mutableDate.setDate(mutableDate.getDate() + 6);
    setEndDate(mutableDate);
  }, [startDate]);


  useEffect(() => {
    const dateTime = new Date();
    if(location !== undefined){
    const siteDate = dateTime.toLocaleString('en-US', { timeZone: location.timeZone});
    setCurrentDate(siteDate);
  }
  })

  useEffect(() => {
    if (state.siteId === "") {
      return;
    }
    clearSidebar();
    setBlocksPerWeek([]);
    const params = { value: state.siteId + "|" + state.locationId };
    if (datesState.isWeekRange) {
      handleLocationChangeWeek(params);
    } else {
      console.log("useeffect 1");
      handleLocationChange(params);
    }
  }, [startDate, state.siteId, state.locationId]);

  useEffect(() => {
    clearAppointmentSidebar();
  }, [state.siteId, state.locationId]);

  const handleClick = (appointmentId) => (event) => {
    event.preventDefault();
    if (state.appointmentId !== appointmentId) {
      setState((state) => ({
        ...state,
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        appointmentId: appointmentId,
      }));
    }
    if (state.appointmentId === appointmentId) {
      setState((state) => ({
        ...state,
        mouseX: null,
        mouseY: null,
        appointmentId: "",
      }));
    }
  };

  const consultServices = async (siteId, locationId, token) => {
    try {
      const consultedServices = [];
      const connectionRequest = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: siteId,
          authorization: token,
          locationid: locationId,
        },
      };
      const ultrasoundResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sessionTypes/2`,
        connectionRequest
      );
      const ultrasoundsData = await ultrasoundResponse.json();
      if (ultrasoundResponse.ok) {
        ultrasoundsData.services.forEach((item) => {
          const mutableItem = {
            value: item.sessionTypeId,
            label: item.name,
          };
          consultedServices.push(mutableItem);
        });
      }
      const massageResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sessionTypes/3`,
        connectionRequest
      );
      const massageData = await massageResponse.json();
      if (massageResponse.ok) {
        massageData.services.forEach((item) => {
          const mutableItem = {
            value: item.sessionTypeId,
            label: item.name,
          };
          consultedServices.push(mutableItem);
        });
      }
      const otherResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sessionTypes/6`,
        connectionRequest
      );
      const otherData = await otherResponse.json();
      if (otherResponse.ok) {
        otherData.services.forEach((item) => {
          const mutableItem = {
            value: item.sessionTypeId,
            label: item.name,
          };
          consultedServices.push(mutableItem);
        });
      }
      setServices(consultedServices);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSidebarChange = (
    segment,
    staffId,
    action,
    blockDate,
    appointment,
    blockId
  ) => {
    handleClose();
    if (sidebarState.status === action) {
      clearSidebar();

      setTimeout(() => {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          status: action,
          block: segment,
          staffId: staffId,
          blockDate: moment(blockDate).format("YYYY-MM-DD[T]HH:mm:ss"),
          appointment: appointment,
          editedAppointment: { ...appointment },
          blockId: blockId,
          message: "",
          requesting: "IDLE",
        }));
        if (action === "editAppointment") {
          availableBlocks(staffId);
        }
      }, 500);
    } else {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        status: action,
        block: segment,
        staffId: staffId,
        blockDate: moment(blockDate).format("YYYY-MM-DD[T]HH:mm:ss"),
        appointment: appointment,
        editedAppointment: { ...appointment },
        blockId: blockId,
        message: "",
        requesting: "IDLE",
      }));
      if (action === "editAppointment") {
        availableBlocks(staffId);
      }
    }
  };

  const handleSidebarChangeWeek = (
    segment,
    staffId,
    action,
    blockDate,
    appointment,
    blockId,
    dayIndex
  ) => {
    handleClose();
    if (sidebarState.status === action) {
      clearSidebar();
      setTimeout(() => {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          status: action,
          block: segment,
          staffId: staffId,
          blockDate: moment(blockDate).format("YYYY-MM-DD[T]HH:mm:ss"),
          appointment: appointment,
          editedAppointment: { ...appointment },
          blockId: blockId,
          dayIndex: dayIndex,
          message: "",
        }));
        if (action === "editAppointment") {
          availableBlocksWeek(staffId, dayIndex);
        }
      }, 500);
    } else {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        status: action,
        block: segment,
        staffId: staffId,
        blockDate: moment(blockDate).format("YYYY-MM-DD[T]HH:mm:ss"),
        appointment: appointment,
        editedAppointment: { ...appointment },
        blockId: blockId,
        dayIndex: dayIndex,
        message: "",
      }));
      if (action === "editAppointment") {
        availableBlocksWeek(staffId, dayIndex);
      }
    }
  };

  const handleClose = () => {
    setState((state) => ({
      ...state,
      mouseX: null,
      mouseY: null,
      appointmentId: "",
    }));
  };

  const setDateRange = (range) => {
    if (datesState.isWeekRange === range) {
      return;
    }
    if (state.siteId !== "") {
      setState((state) => ({
        ...state,
        status: "loadingAppointments",
      }));
      setDatesState((datesState) => ({
        ...datesState,
        isWeekRange: range === "week",
      }));
      clearSidebar();
      const params = { value: state.siteId + "|" + state.locationId };
      if (range === "week") {
        handleLocationChangeWeek(params);
      } else {
        console.log("setdaterange");
        handleLocationChange(params);
      }
    } else {
      setDatesState((datesState) => ({
        ...datesState,
        isWeekRange: range === "week",
      }));
    }
  };

  const searchClient = async () => {
    if (sidebarState.requesting === "REQUESTING") {
      return;
    }
    try {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "REQUESTING",
      }));
      const searchClientsRequest = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: state.authorization,
          siteid: state.siteId,
        },
      };
      const searchClientsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/clients/clients?searchText=${sidebarState.searchTerm}`,
        searchClientsRequest
      );
      const searchClientsData = await searchClientsResponse.json();
      if (searchClientsResponse.ok) {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "IDLE",
          searchResults: searchClientsData.clients,
        }));
      } else {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "CLIENT-NO-FOUND",
          searchResults: [],
        }));
      }
    } catch (error) {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "CLIENT-ERROR",
        message: "Client search error: " + JSON.stringify(error),
      }));
    }
  };

  const handleClientSearchChange = (event) => {
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      searchTerm: event.target.value,
    }));
  };

  const clearSidebar = () => {
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      status: "IDLE",
      requesting: "IDLE",
      message: "",
      searchTerm: "",
      searchResults: [],
      client: {},
      editedClient: {
        firstName: "",
        lastName: "",
        mobilePhone: "",
        email: "",
      },
      firstName: null,
      lastName: null,
      mobilePhone: null,
      email: null,
      staffId: "",
      service: null,
      serviceName: "",
      block: "",
      blockDate: "",
      appointment: null,
      editedAppointment: null,
      blockId: "",
      availableBlocks: [],
      createNotes: "",
      statusDisabled: false,
      lastVisit: {
        client: {},
        appointments: [],
      }
    }));
  };

  const handleLocationChangeWeek = async (selectedOption) => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    try {
      setBlocksPerRoom([]);
      const siteId = selectedOption.value.split("|")[0];
      const locationId = selectedOption.value.split("|")[1];
      const authObject = appState.bookingAuth.authorizationTokens.find(
        (item) =>
          item.siteId.toString() === siteId && item.locationId === locationId
      );
      setState((state) => ({
        ...state,
        status:
          state.status === "IDLE" || blocksPerWeek.length === 0
            ? "loadingAppointments"
            : "refreshingAppointments",
        siteId: authObject.siteId,
        locationId: authObject.locationId,
        authorization: authObject.token,
        selectedLocation: selectedOption,
      }));

      if(fetchServices){
        consultServices(
          authObject.siteId,
          authObject.locationId,
          authObject.token
        );
        setFetchServices(false);
      }

      const queryStartDate = moment(startDate).format("MM/DD/YYYY").toString();
      const queryEndDate = moment(startDate).add(6, "days").format("MM/DD/YYYY").toString();

      const blocksRequest = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: authObject.siteId,
          authorization: authObject.token,
          locationid: authObject.locationId,
        },
      };
      const blocksRequestResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sites/${authObject.siteId}/locations/${authObject.locationId}/schedule?startDate=${queryStartDate}&endDate=${queryEndDate}`,
        blocksRequest
      );
      const blocksRequestData = await blocksRequestResponse.json();
      const appointmentsPerRoom = [];
      if (blocksRequestResponse.ok) {
        clearSidebar();
        const rooms = blocksRequestData.schedule.map((room) => {
          const roomReturn = {
            staffId: room.id,
            staffName: room.name,
          };
          return roomReturn;
        });
        setRooms(rooms);

        await blocksRequestData.schedule.reduce(async (memo, room, index) => {
          await memo;
          const appointmentsRequest = {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              siteid: authObject.siteId,
              authorization: authObject.token,
              locationid: authObject.locationId,
            },
          };
          const appointmentsRequestResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/appointments/?appointmentDate=${queryStartDate}&endDate=${queryEndDate}&staffId=${room.id}`,
            appointmentsRequest
          );
          const appointmentsRequestData =
            await appointmentsRequestResponse.json();
          if (appointmentsRequestResponse.ok) {
            const appointmentsPerDay = [];
            const availabilitiesPerDay = [];
            const unavailabilitiesPerDay = [];
            const blocksPerDay = [];
            for (let i = 0; i < 7; i++) {
              appointmentsPerDay.push({
                index: i,
                date: moment(queryStartDate)
                  .add(i, "days")
                  .format("MM/DD/YYYY")
                  .toString(),
                appointments: [],
              });
              blocksPerDay.push({
                index: i,
                date: moment(queryStartDate)
                  .add(i, "days")
                  .format("MM/DD/YYYY")
                  .toString(),
                blocks: [],
              });
              availabilitiesPerDay.push({
                index: i,
                date: moment(queryStartDate)
                  .add(i, "days")
                  .format("MM/DD/YYYY")
                  .toString(),
                blocks: [],
              });
              unavailabilitiesPerDay.push({
                index: i,
                date: moment(queryStartDate)
                  .add(i, "days")
                  .format("MM/DD/YYYY")
                  .toString(),
                blocks: [],
              });
            };
            const totalCounter = {
              Booked: 0,
              Confirmed: 0,
              Arrived: 0,
              Completed: 0,
              NoShow: 0,
              total: 0,
              cbff: 0,
            };
            appointmentsRequestData.forEach((appointment) => {
              const mutableAppointment = { ...appointment };
              mutableAppointment.segment = moment(appointment.StartDateTime).format("hh:mm A").toString();
              const index = appointmentsPerDay.findIndex(
                (item) => item.date === moment(appointment.StartDateTime).format("MM/DD/YYYY").toString()
              );
              appointmentsPerDay[index].appointments.push(mutableAppointment);

              if (mutableAppointment.sessionTypeName.toLowerCase().includes('come back for free') ||
                mutableAppointment.sessionTypeName.toLowerCase().includes('cbff')
              ) {
                totalCounter.cbff++;
              }
              totalCounter[mutableAppointment.status]++;
              totalCounter.total++;
            });
            room.availabilities.forEach((blockSegment) => {
              const startSegment = moment(blockSegment.startDateTime).format("hh:mm A").toString();
              const endSegment = moment(blockSegment.endDateTime).format("hh:mm A").toString();
              const dateSegment = moment(blockSegment.startDateTime).format("MM/DD/YYYY").toString();
              const availabilityInstance = {
                date: dateSegment,
                staffId: room.id,
                staffName: room.name,
                startTime: startSegment,
                endTime: endSegment,
                startDateTime: blockSegment.startDateTime,
                endDateTime: blockSegment.endDateTime,
              };
              const index = availabilitiesPerDay.findIndex(
                (item) => item.date === moment(blockSegment.startDateTime).format("MM/DD/YYYY").toString()
              );
              availabilitiesPerDay[index].blocks.push(availabilityInstance);
            });
            room.unavailabilities.forEach((blockSegment) => {
              const startSegment = moment(blockSegment.StartDateTime).format("hh:mm A").toString();
              const endSegment = moment(blockSegment.EndDateTime).format("hh:mm A").toString();
              const dateSegment = moment(blockSegment.StartDateTime).format("MM/DD/YYYY").toString();
              const unAvailabilityInstance = {
                date: dateSegment,
                staffId: room.id,
                staffName: room.name,
                startTime: startSegment,
                endTime: endSegment,
                startDateTime: blockSegment.StartDateTime,
                endDateTime: blockSegment.EndDateTime,
                description: blockSegment.Description,
              };
              const index = unavailabilitiesPerDay.findIndex(
                (item) => item.date === moment(blockSegment.StartDateTime).format("MM/DD/YYYY").toString()
              );
              unavailabilitiesPerDay[index].blocks.push(unAvailabilityInstance);
            });

            blocksPerDay.forEach((element, index) => {
              const mutableBlocks = [...blocks];
              element.blocks = mutableBlocks.map((block) => {
                const mutableBlock = { ...block };
                const addTwelve = mutableBlock.segment.includes("PM");
                const rawHours = parseInt(mutableBlock.segment.split(" ")[0].split(":")[0]);
                const hours = addTwelve && rawHours !== 12 ? rawHours + 12 : rawHours;
                const minutes = parseInt(mutableBlock.segment.split(" ")[0].split(":")[1]);
                const stringDate = moment(element.date).format("MM/DD/YYYY").toString();
                const startDateTime = moment(stringDate).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD[T]HH:mm:ss");
                const endDateTime = moment(startDateTime).add(30, "minutes").format("YYYY-MM-DD[T]HH:mm:ss");
                mutableBlock.startDateTime = startDateTime;
                mutableBlock.endDateTime = endDateTime;
                const blockDate = moment(stringDate).add(hours, "hours").add(minutes, "minutes").toString();
                mutableBlock.blockDate = blockDate;
                let available = false;
                let description = " ";
                availabilitiesPerDay[index].blocks.forEach(
                  (availabilityBlock) => {
                    available =
                      available +
                      moment(blockDate).isBetween(
                        availabilityBlock.startDateTime,
                        availabilityBlock.endDateTime,
                        undefined,
                        "[)"
                      );
                  }
                );
                unavailabilitiesPerDay[index].blocks.forEach(
                  (unavailabilityBlock) => {
                    available =
                      available *
                      !moment(blockDate).isBetween(
                        unavailabilityBlock.startDateTime,
                        unavailabilityBlock.endDateTime,
                        undefined,
                        "[)"
                      );
                    const something = !moment(blockDate).isBetween(
                      unavailabilityBlock.startDateTime,
                      unavailabilityBlock.endDateTime,
                      undefined,
                      "[)"
                    );
                    description = something
                      ? " "
                      : unavailabilityBlock.description;
                  }
                );
                mutableBlock.available = Boolean(available);
                mutableBlock.description = description;
                mutableBlock.type = room.name.toLowerCase().includes("dna") ? "DNA" : "DEFAULT";
                if(mutableBlock.type === "DNA"){
                  const blockAppointments = appointmentsPerDay[
                    index
                  ].appointments.filter((appointment) =>
                    moment(blockDate).isBetween(
                      appointment.StartDateTime,
                      appointment.endDateTime,
                      undefined,
                      "[)"
                    )
                  );
                  mutableBlock.occupied = blockAppointments.length > 0;
                  for (let i = blockAppointments.length; i < 5; i++) {
                    blockAppointments.push({});
                  }
                  mutableBlock.appointment = {};
                  mutableBlock.appointments = blockAppointments;
                }
                else{
                  const blockAppointment = appointmentsPerDay[
                    index
                  ].appointments.find((appointment) =>
                    moment(blockDate).isBetween(
                      appointment.StartDateTime,
                      appointment.endDateTime,
                      undefined,
                      "[)"
                    )
                  );
                  mutableBlock.appointment = blockAppointment === undefined ? {} : blockAppointment;
                  mutableBlock.appointments = [];
                  mutableBlock.occupied = blockAppointment !== undefined;
                }

                return mutableBlock;
              });
            });

            appointmentsPerRoom.push({
              staffId: room.id,
              name: room.name,
              appointmentsPerDay: appointmentsPerDay,
              availabilitiesPerDay: availabilitiesPerDay,
              unavailabilitiesPerDay: unavailabilitiesPerDay,
              blocksPerDay: blocksPerDay,
              statusCounter: totalCounter,
            });
          } else {
            const appointmentsPerDay = [];
            const availabilitiesPerDay = [];
            const unavailabilitiesPerDay = [];
            const blocksPerDay = [];
            for (let i = 0; i < 7; i++) {
              appointmentsPerDay.push({
                index: i,
                date: moment(queryStartDate)
                  .add(i, "days")
                  .format("MM/DD/YYYY")
                  .toString(),
                appointments: [],
              });
              blocksPerDay.push({
                index: i,
                date: moment(queryStartDate)
                  .add(i, "days")
                  .format("MM/DD/YYYY")
                  .toString(),
                blocks: [],
              });
              availabilitiesPerDay.push({
                index: i,
                date: moment(queryStartDate)
                  .add(i, "days")
                  .format("MM/DD/YYYY")
                  .toString(),
                blocks: [],
              });
              unavailabilitiesPerDay.push({
                index: i,
                date: moment(queryStartDate)
                  .add(i, "days")
                  .format("MM/DD/YYYY")
                  .toString(),
                blocks: [],
              });
            };
            const totalCounter = {
              Booked: 0,
              Confirmed: 0,
              Arrived: 0,
              Completed: 0,
              NoShow: 0,
              total: 0,
              cbff: 0,
            };
            room.availabilities.forEach((blockSegment) => {
              const startSegment = moment(blockSegment.startDateTime).format("hh:mm A").toString();
              const endSegment = moment(blockSegment.endDateTime).format("hh:mm A").toString();
              const dateSegment = moment(blockSegment.startDateTime).format("MM/DD/YYYY").toString();
              const availabilityInstance = {
                date: dateSegment,
                staffId: room.id,
                staffName: room.name,
                startTime: startSegment,
                endTime: endSegment,
                startDateTime: blockSegment.startDateTime,
                endDateTime: blockSegment.endDateTime,
              };
              const index = availabilitiesPerDay.findIndex(
                (item) => item.date === moment(blockSegment.startDateTime).format("MM/DD/YYYY").toString()
              );
              availabilitiesPerDay[index].blocks.push(availabilityInstance);
            });
            room.unavailabilities.forEach((blockSegment) => {
              const startSegment = moment(blockSegment.StartDateTime).format("hh:mm A").toString();
              const endSegment = moment(blockSegment.EndDateTime).format("hh:mm A").toString();
              const dateSegment = moment(blockSegment.StartDateTime).format("MM/DD/YYYY").toString();
              const unAvailabilityInstance = {
                date: dateSegment,
                staffId: room.id,
                staffName: room.name,
                startTime: startSegment,
                endTime: endSegment,
                startDateTime: blockSegment.StartDateTime,
                endDateTime: blockSegment.EndDateTime,
                description: blockSegment.Description,
              };
              const index = unavailabilitiesPerDay.findIndex(
                (item) => item.date === moment(blockSegment.StartDateTime).format("MM/DD/YYYY").toString()
              );
              unavailabilitiesPerDay[index].blocks.push(unAvailabilityInstance);
            });
            blocksPerDay.forEach((element, index) => {
              const mutableBlocks = [...blocks];
              element.blocks = mutableBlocks.map((block) => {
                const mutableBlock = { ...block };
                const addTwelve = mutableBlock.segment.includes("PM");
                const rawHours = parseInt(mutableBlock.segment.split(" ")[0].split(":")[0]);
                const hours = addTwelve && rawHours !== 12 ? rawHours + 12 : rawHours;
                const minutes = parseInt(mutableBlock.segment.split(" ")[0].split(":")[1]);
                const stringDate = moment(element.date).format("MM/DD/YYYY").toString();
                const startDateTime = moment(stringDate).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD[T]HH:mm:ss");
                const endDateTime = moment(startDateTime).add(30, "minutes").format("YYYY-MM-DD[T]HH:mm:ss");
                mutableBlock.startDateTime = startDateTime;
                mutableBlock.endDateTime = endDateTime;
                const blockDate = moment(stringDate).add(hours, "hours").add(minutes, "minutes").toString();
                mutableBlock.blockDate = blockDate;
                let available = false;
                let description = " ";
                availabilitiesPerDay[index].blocks.forEach(
                  (availabilityBlock) => {
                    available =
                      available +
                      moment(blockDate).isBetween(
                        availabilityBlock.startDateTime,
                        availabilityBlock.endDateTime,
                        undefined,
                        "[)"
                      );
                  }
                );
                unavailabilitiesPerDay[index].blocks.forEach(
                  (unavailabilityBlock) => {
                    available =
                      available *
                      !moment(blockDate).isBetween(
                        unavailabilityBlock.startDateTime,
                        unavailabilityBlock.endDateTime,
                        undefined,
                        "[)"
                      );
                    const something = !moment(blockDate).isBetween(
                      unavailabilityBlock.startDateTime,
                      unavailabilityBlock.endDateTime,
                      undefined,
                      "[)"
                    );
                    description = something
                      ? " "
                      : unavailabilityBlock.description;
                  }
                );
                mutableBlock.available = Boolean(available);
                mutableBlock.description = description;

                mutableBlock.appointment = {};
                return mutableBlock;
              });
            });

            appointmentsPerRoom.push({
              staffId: room.id,
              name: room.name,
              appointmentsPerDay: appointmentsPerDay,
              availabilitiesPerDay: availabilitiesPerDay,
              unavailabilitiesPerDay: unavailabilitiesPerDay,
              blocksPerDay: blocksPerDay,
              statusCounter: totalCounter,
            });
          }
        }, undefined);

        setBlocksPerWeek(appointmentsPerRoom);
        setDisplayableBlocks(appointmentsPerRoom[0]);

        setTimeout(() => {
          setState((state) => ({
            ...state,
            status: "displayAppointments",
            displayAll: true,
            staffId: rooms[0].staffId,
          }));
        }, 500);
      } else {
        setState((state) => ({
          ...state,
          status: "no-blocks-found",
          message:
            "Requesting schedule blocks error: " +
            JSON.stringify(blocksRequestData),
        }));
      }
    } catch (error) {
      clearSidebar();
      setState((state) => ({
        ...state,
        status: "on-load-error",
        message:
          "Woops, this is weird. Something went wrong with the connection, please reload the page and try again." +
          JSON.stringify(error.message),
      }));
    }
  };

  const handleLocationChange = async (selectedOption) => {
    // Check if the dashboard is already loading and dismiss the method call if necesary
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    try {
      setBlocksPerWeek([]);
      const siteId = selectedOption.value.split("|")[0];
      const locationId = selectedOption.value.split("|")[1];
      const authObject = appState.bookingAuth.authorizationTokens.find(
        (item) => 
          item.siteId.toString() === siteId && item.locationId === locationId
      );
      setState((state) => ({
        ...state,
        status:
          state.status === "IDLE" || blocksPerRoom.length === 0
            ? "loadingAppointments"
            : "refreshingAppointments",
        siteId: authObject.siteId,
        locationId: authObject.locationId,
        authorization: authObject.token,
        selectedLocation: selectedOption,
      }));
      if(fetchServices){
        consultServices(
          authObject.siteId,
          authObject.locationId,
          authObject.token
        );
        setFetchServices(false);
      }
      const queryStartDate = moment(startDate).format("MM/DD/YYYY").toString();
      
      const fetchSchedule = async (query) => {
        if(query==="appointments"){
          const appointmentsRequest = {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              siteid: authObject.siteId,
              authorization: authObject.token,
              locationid: authObject.locationId,
            },
          };
          const appointmentsRequestResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/appointments/?appointmentDate=${queryStartDate}`,
            appointmentsRequest
          );
          const appointmentsRequestData = await appointmentsRequestResponse.json();
          if(appointmentsRequestResponse.ok){
            return Promise.resolve({
              ok: true,
              appointmentsRequestData,
              query
            });
          }
          else{
            return Promise.resolve({
              ok: false,
              appointmentsRequestData:{},
              query
            });
          }
        }
        else{
          const blocksRequest = {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              siteid: authObject.siteId,
              authorization: authObject.token,
              locationid: authObject.locationId,
            },
          };
          const blocksRequestResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/sites/${authObject.siteId}/locations/${authObject.locationId}/schedule?startDate=${queryStartDate}&endDate=${queryStartDate}`,
            blocksRequest
          );
          const blocksRequestData = await blocksRequestResponse.json();
          if(blocksRequestResponse.ok){
            return Promise.resolve({
              ok: true,
              blocksRequestData,
              query
            });
          }
          else{
            return Promise.resolve({
              ok: false,
              blocksRequestData:{},
              query
            });
          }
        }
      }
      const fetchOptions = ["appointments", "schedule"];
      
      const scheduleAttemps = fetchOptions.map((option) =>
        fetchSchedule(option)
      );
      const scheduleResults = await Promise.all(scheduleAttemps);

      console.log(scheduleResults);

      const appointmentsRequestResponse = scheduleResults.find(
        (item) => item.query === "appointments"
      );
      const appointmentsRequestData = appointmentsRequestResponse.appointmentsRequestData;

      console.log("appointmentsRequestResponse",appointmentsRequestResponse)
      console.log("appointmentsRequestData",appointmentsRequestData)
      
      const blocksRequestResponse = scheduleResults.find(
        (item) => item.query === "schedule"
      );
      const blocksRequestData = blocksRequestResponse.blocksRequestData;

      console.log("blocksRequestResponse",blocksRequestResponse)
      console.log("blocksRequestData",blocksRequestData)

      
      if (appointmentsRequestResponse.ok) {
        clearSidebar();
        
        if (blocksRequestResponse.ok) {
          const availabilityBlocks = [];
          const unavailabilityBlocks = [];
  
          blocksRequestData.schedule.forEach((room) => {
            room.availabilities.forEach((blockSegment) => {
              const startSegment = new Date(blockSegment.startDateTime).toLocaleTimeString("en-US", {hour: "2-digit",minute: "2-digit",});
              const endSegment = new Date(blockSegment.endDateTime).toLocaleTimeString("en-US", {hour: "2-digit",minute: "2-digit",});
              const dateSegment = moment(blockSegment.startDateTime).format("MM/DD/YYYY").toString();
              const availabilityInstance = {
                date: dateSegment,
                staffId: room.id,
                staffName: room.name,
                startTime: startSegment,
                endTime: endSegment,
                startDateTime: blockSegment.startDateTime,
                endDateTime: blockSegment.endDateTime,
              };
              availabilityBlocks.push(availabilityInstance);
            });
            room.unavailabilities.forEach((blockSegment) => {
              
              const startSegment = moment(blockSegment.StartDateTime).format("LT");
              const endSegment = new Date(blockSegment.EndDateTime).toLocaleTimeString("en-US", {hour: "2-digit",minute: "2-digit",});
              const dateSegment = moment(blockSegment.StartDateTime).format("MM/DD/YYYY").toString();
              const unAvailabilityInstance = {
                date: dateSegment,
                staffId: room.id,
                staffName: room.name,
                startTime: startSegment,
                endTime: endSegment,
                startDateTime: blockSegment.StartDateTime,
                endDateTime: blockSegment.EndDateTime,
                description: blockSegment.Description,
              };
              unavailabilityBlocks.push(unAvailabilityInstance);
            });
          });

          console.log("First loop over schedule");
          console.log("availabilityBlocks", availabilityBlocks);
          console.log("unavailabilityBlocks", unavailabilityBlocks);
          console.log("==================================================");
  
          const rooms = blocksRequestData.schedule.map((room) => {
            const roomReturn = {
              staffId: room.id,
              staffName: room.name,
            };
            return roomReturn;
          });
          setRooms(rooms);

          console.log("Second loop over schedule");
          console.log("rooms", rooms);
          console.log("==================================================");
  
          const totalCounter = {
            Booked: 0,
            Confirmed: 0,
            Arrived: 0,
            Completed: 0,
            NoShow: 0,
            total: 0,
            cbff: 0,
          };
          const appointmentsPerRooms = [];
          rooms.forEach((element) => {
            const statusCounter = {
              Booked: 0,
              Confirmed: 0,
              Arrived: 0,
              Completed: 0,
              NoShow: 0,
              total: 0,
              cbff: 0,
            };
            const appointments = [];
            //! This could be changed for a filter instead of running over the entire array of appointment
            //! This could be changed for a filter instead of running over the entire array of appointment
            appointmentsRequestData.forEach((appointment) => {
              if (element.staffId === appointment.staffId) {
                const mutableAppointment = appointment;
                const segment = new Date(
                  mutableAppointment.StartDateTime
                ).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                });
                mutableAppointment.segment = segment;
                appointments.push(mutableAppointment);
  
                statusCounter[mutableAppointment.status]++;
                statusCounter.total++;
                if (mutableAppointment.sessionTypeName.toLowerCase().includes('come back for free') ||
                mutableAppointment.sessionTypeName.toLowerCase().includes('cbff')
                ){
                  statusCounter.cbff++;
                }
                totalCounter[mutableAppointment.status]++;
                totalCounter.total++;
                if (mutableAppointment.sessionTypeName.toLowerCase().includes('come back for free') ||
                mutableAppointment.sessionTypeName.toLowerCase().includes('cbff')
                ){
                  totalCounter.cbff++;
                }
              }
            });
  
            appointmentsPerRooms.push({
              staffId: element.staffId,
              staffName: element.staffName,
              appointments: appointments,
              counter: statusCounter,
            });
          });

          console.log("First loop over rooms");
          console.log("totalCounter", totalCounter);
          console.log("appointmentsPerRooms", appointmentsPerRooms);
          console.log("==================================================");
  
          rooms.forEach((room) => {
            const avBlocks = [];
            availabilityBlocks.forEach((block) => {
              if (room.staffId === block.staffId) {
                avBlocks.push(block);
              }
            });
            const unavBlocks = [];
            unavailabilityBlocks.forEach((block) => {
              if (room.staffId === block.staffId) {
                unavBlocks.push(block);
              }
            });
            const appRoomIndex = appointmentsPerRooms.findIndex(
              (item) => item.staffId === room.staffId
            );
            appointmentsPerRooms[appRoomIndex].availabilities = avBlocks;
            appointmentsPerRooms[appRoomIndex].unavailabilities = unavBlocks;
          });
  
          console.log("Second loop over rooms");
          console.log("appointmentsPerRooms", appointmentsPerRooms);
          console.log("==================================================");

          const blocksPerRoom = appointmentsPerRooms.map((element) => {
            const mutableBlocks = [...blocks];
  
            const blockAppointments = mutableBlocks.map((block) => {
              const mutableBlock = { ...block };
              const addTwelve = mutableBlock.segment.includes("PM"); // "08:00 AM",
              const rawHours = parseInt(mutableBlock.segment.split(" ")[0].split(":")[0]);
              const hours = addTwelve && rawHours !== 12 ? rawHours + 12 : rawHours;
              const minutes = parseInt(mutableBlock.segment.split(" ")[0].split(":")[1]);
              const stringDate = moment(startDate).format("MM/DD/YYYY").toString();
              const startDateTime = moment(stringDate).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD[T]HH:mm:ss");
              const endDateTime = moment(startDateTime).add(30, "minutes").format("YYYY-MM-DD[T]HH:mm:ss");
              mutableBlock.startDateTime = startDateTime;
              mutableBlock.endDateTime = endDateTime;
              const blockDate = moment(stringDate).add(hours, "hours").add(minutes, "minutes").toString();
              mutableBlock.blockDate = blockDate;
              let available = false;
              let description = " ";
              element.availabilities.forEach((availabilityBlock) => {
                available =
                  available +
                  moment(blockDate).isBetween(
                    availabilityBlock.startDateTime,
                    availabilityBlock.endDateTime,
                    undefined,
                    "[)"
                  );
              });
              element.unavailabilities.forEach((unavailabilityBlock) => {
                available =
                  available *
                  !moment(blockDate).isBetween(
                    unavailabilityBlock.startDateTime,
                    unavailabilityBlock.endDateTime,
                    undefined,
                    "[)"
                  );
                const something = !moment(blockDate).isBetween(
                  unavailabilityBlock.startDateTime,
                  unavailabilityBlock.endDateTime,
                  undefined,
                  "[)"
                );
                description = something ? " " : unavailabilityBlock.description;
              });
              mutableBlock.available = Boolean(available);
              mutableBlock.description = description;
              mutableBlock.type = element.staffName.toLowerCase().includes("dna") ? "DNA" : "DEFAULT";
              if(mutableBlock.type === "DNA"){
                const blockAppointments = element.appointments.filter(
                  (appointment) =>
                    moment(blockDate).isBetween(
                      appointment.StartDateTime,
                      appointment.endDateTime,
                      undefined,
                      "[)"
                    )
                );
                mutableBlock.occupied = blockAppointments.length > 0;
                for (let i = blockAppointments.length; i < 5; i++) {
                  blockAppointments.push({});
                }
                mutableBlock.appointment = {};
                mutableBlock.appointments = blockAppointments;
              }
              else{
                const blockAppointment = element.appointments.find(
                  (appointment) =>
                    moment(blockDate).isBetween(
                      appointment.StartDateTime,
                      appointment.endDateTime,
                      undefined,
                      "[)"
                    )
                );
                mutableBlock.appointment = blockAppointment === undefined ? {} : blockAppointment;
                mutableBlock.appointments = [];
                mutableBlock.occupied = blockAppointment !== undefined;
  
              }
              return mutableBlock;
            });
  
            return {
              staffId: element.staffId,
              staffName: element.staffName,
              appointments: element.appointments,
              blocks: blockAppointments,
              statusCounter: element.counter,
            };
          });
  
          setBlocksPerRoom(blocksPerRoom);
          console.log("Blocks Per Room: ", blocksPerRoom);
          setDisplayableBlocks(blocksPerRoom[0]);
          setTimeout(() => {
            setState((state) => ({
              ...state,
              status: "displayAppointments",
              displayAll: true,
              totalCounter: totalCounter,
              staffId: "ALL",
            }));
          }, 100);
        } else {
          setState((state) => ({
            ...state,
            status: "no-blocks-found",
            message:
              "Requesting schedule blocks error: " +
              JSON.stringify(blocksRequestData),
          }));
        }
      } else {
        if (blocksRequestResponse.ok) {
          const availabilityBlocks = [];
          const unavailabilityBlocks = [];
  
          blocksRequestData.schedule.forEach((room) => {
            room.availabilities.forEach((blockSegment) => {
              const startSegment = new Date(
                blockSegment.startDateTime
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              });
              const endSegment = new Date(
                blockSegment.endDateTime
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              });
              const dateSegment = moment(blockSegment.startDateTime)
                .format("MM/DD/YYYY")
                .toString();
              const availabilityInstance = {
                date: dateSegment,
                staffId: room.id,
                staffName: room.name,
                startTime: startSegment,
                endTime: endSegment,
                startDateTime: blockSegment.startDateTime,
                endDateTime: blockSegment.endDateTime,
              };
              availabilityBlocks.push(availabilityInstance);
            });
            room.unavailabilities.forEach((blockSegment) => {
              const startSegment = new Date(
                blockSegment.startDateTime
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              });
              const endSegment = new Date(
                blockSegment.endDateTime
              ).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
              });
              const dateSegment = moment(blockSegment.startDateTime)
                .format("MM/DD/YYYY")
                .toString();
              const unAvailabilityInstance = {
                date: dateSegment,
                staffId: room.id,
                staffName: room.name,
                startTime: startSegment,
                endTime: endSegment,
                startDateTime: blockSegment.StartDateTime,
                endDateTime: blockSegment.EndDateTime,
                description: blockSegment.Description,
              };
              unavailabilityBlocks.push(unAvailabilityInstance);
            });
          });
  
          const rooms = blocksRequestData.schedule.map((room) => {
            const roomReturn = {
              staffId: room.id,
              staffName: room.name,
            };
            return roomReturn;
          });
          setRooms(rooms);
  
          const appointmentsPerRooms = [];
          rooms.forEach((element) => {
            appointmentsPerRooms.push({
              staffId: element.staffId,
              staffName: element.staffName,
              appointments: [],
            });
          });
  
          rooms.forEach((room) => {
            const avBlocks = [];
            availabilityBlocks.forEach((block) => {
              if (room.staffId === block.staffId) {
                avBlocks.push(block);
              }
            });
            const unavBlocks = [];
            unavailabilityBlocks.forEach((block) => {
              if (room.staffId === block.staffId) {
                unavBlocks.push(block);
              }
            });
            const appRoomIndex = appointmentsPerRooms.findIndex(
              (item) => item.staffId === room.staffId
            );
            appointmentsPerRooms[appRoomIndex].availabilities = avBlocks;
            appointmentsPerRooms[appRoomIndex].unavailabilities = unavBlocks;
          });
  
          const totalCounter = {
            Booked: 0,
            Confirmed: 0,
            Arrived: 0,
            Completed: 0,
            NoShow: 0,
            total: 0,
            cbff: 0,
          };
  
          const blocksPerRoom = appointmentsPerRooms.map((element) => {
            const mutableBlocks = [...blocks];
            const blockAppointments = mutableBlocks.map((block) => {
              const mutableBlock = { ...block };
              const addTwelve = mutableBlock.segment.includes("PM"); // "08:00 AM",
              const rawHours = parseInt(
                mutableBlock.segment.split(" ")[0].split(":")[0]
              );
              const hours =
                addTwelve && rawHours !== 12 ? rawHours + 12 : rawHours;
              const minutes = parseInt(
                mutableBlock.segment.split(" ")[0].split(":")[1]
              );
              const stringDate = moment(startDate)
                .format("MM/DD/YYYY")
                .toString();
              const blockDate = moment(stringDate)
                .add(hours, "hours")
                .add(minutes, "minutes")
                .toString();
              mutableBlock.blockDate = blockDate;
              let available = false;
              let description = " ";
              element.availabilities.forEach((availabilityBlock) => {
                available =
                  available +
                  moment(blockDate).isBetween(
                    availabilityBlock.startDateTime,
                    availabilityBlock.endDateTime,
                    undefined,
                    "[)"
                  );
              });
              element.unavailabilities.forEach((unavailabilityBlock) => {
                available =
                  available *
                  !moment(blockDate).isBetween(
                    unavailabilityBlock.startDateTime,
                    unavailabilityBlock.endDateTime,
                    undefined,
                    "[)"
                  );
                const something = !moment(blockDate).isBetween(
                  unavailabilityBlock.startDateTime,
                  unavailabilityBlock.endDateTime,
                  undefined,
                  "[)"
                );
                description = something ? " " : unavailabilityBlock.description;
              });
              mutableBlock.available = Boolean(available);
              mutableBlock.description = description;
              mutableBlock.appointment = {};
              return mutableBlock;
            });
  
            return {
              staffId: element.staffId,
              staffName: element.staffName,
              appointments: element.appointments,
              blocks: blockAppointments,
              statusCounter: totalCounter,
            };
          });
  
          setBlocksPerRoom(blocksPerRoom);
          setDisplayableBlocks(blocksPerRoom[0]);
  
          setTimeout(() => {
            setState((state) => ({
              ...state,
              status: "displayAppointments",
              displayAll: true,
              totalCounter: totalCounter,
              staffId: "ALL",
            }));
          }, 500);
        } else {
          setState((state) => ({
            ...state,
            status: "no-blocks-found",
            message:
              "Requesting schedule blocks error: " +
              JSON.stringify(blocksRequestData),
          }));
        }
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "on-load-error",
        message:
          "Woops, this is weird. Something went wrong with the connection, please reload the page and try again." +
          JSON.stringify(error.message),
      }));
    }
  };

  const handleServiceChange = (selectedOption) => {
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      service: selectedOption.value,
      serviceName: selectedOption.label,
    }));
  };

  const handleEditServiceChange = (selectedOption) => {
    const value = selectedOption.value;
    const label = selectedOption.label;
    const appointmentEdited = sidebarState.editedAppointment;
    appointmentEdited.sessionTypeId = value;
    appointmentEdited.sessionTypeName = label;
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      editedAppointment: appointmentEdited,
    }));
  };

  const handleClientSelect = (index) => {
    const client = sidebarState.searchResults[index];
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      status: "bookAppointment",
      client: client,
    }));
  };

  const handleRoomChange = (staffId) => {
    setState((state) => ({
      ...state,
      status: "loadingAppointments",
    }));
    if (staffId === "ALL") {
      setState((state) => ({
        ...state,
        status: "displayAppointments",
        staffId: staffId,
        displayAll: true,
      }));
      clearSidebar();
    } else {
      const roomIndex = blocksPerRoom.findIndex(
        (item) => item.staffId === staffId
      );
      setDisplayableBlocks(blocksPerRoom[roomIndex]);
      setState((state) => ({
        ...state,
        status: "displayAppointments",
        staffId: staffId,
        displayAll: false,
      }));
      clearSidebar();
    }
  };

  const handleRoomChangeWeek = (staffId) => {
    setState((state) => ({
      ...state,
      status: "loadingAppointments",
    }));
    const roomIndex = blocksPerWeek.findIndex(
      (item) => item.staffId === staffId
    );
    setDisplayableBlocks(blocksPerWeek[roomIndex]);
    setState((state) => ({
      ...state,
      status: "displayAppointments",
      staffId: staffId,
      displayAll: false,
    }));
    clearSidebar();
  };

  const handleCalendarChange = (date) => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    setStartDate(date);
  };


  const handleAppointmentBook = async () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    try {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "REQUESTING",
      }));
      const payload = {
        sessionTypeId: "" + sidebarState.service,
        locationId: parseInt(state.locationId),
        staffId: sidebarState.staffId,
        clientId: sidebarState.client.clientId,
        notes: sidebarState.createNotes,
        startDateTime: sidebarState.blockDate,
      };

      const bookAppointmentRequest = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: state.authorization,
          siteid: state.siteId,
        },
        body: JSON.stringify(payload),
      };
      const bookAppointmentResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/appointments`,
        bookAppointmentRequest
      );
      const bookAppointmentData = await bookAppointmentResponse.json();
      if (bookAppointmentResponse.ok) {
        const notesArray = sidebarState.createNotes.split("\n");
        console.log("notesArray", notesArray);
        let language = "english";
        notesArray.forEach((item) => {
          if (item.toLowerCase().includes("language:")) {
            const splitedItem = item.split(":");
            language = splitedItem[1].toLowerCase();
          }
        });
        console.log("language", language);
        const isSpanish = language.includes('es') || language.includes('spanish') || language.includes('español');
        console.log("isSpanish", isSpanish);
        const mailBody = {
          locationName: location.label,
          web: "www.littlebelliesspa.com/en/home/",
          locationPhone: location.sitePhone,
          locationAddress: location.address,
          howArrive: isSpanish ? location.HTASpanish : location.HTAEnglish,
          appointmentId: bookAppointmentData.Appointment.Id,
          clientName: sidebarState.client.name ?? '',
          clientEmail: sidebarState.client.email ?? '',
          serviceName: sidebarState.serviceName ?? '',
          startDateTime: sidebarState.blockDate,
        }
        const postMail = {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(mailBody),
        };
        const mailResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/book/sendMail`,
          postMail
        );

        if (!mailResponse.ok) {
          console.error(mailResponse);
        }

       
        
        const dateTime = new Date();
        const siteDate = dateTime.toLocaleString('en-US', { timeZone: location?.timeZone});
        const isCbff = sidebarState.serviceName.toLowerCase().includes("come back for free") ||
          sidebarState.serviceName.toLowerCase().includes("cbff");
        const dynamoPayload = {
          id: "" + bookAppointmentData.Appointment.Id,
          sessionTypeId: bookAppointmentData.Appointment.SessionTypeId,
          serviceName: sidebarState.serviceName,
          locationId: "" + bookAppointmentData.Appointment.LocationId,
          staffId: bookAppointmentData.Appointment.StaffId,
          clientId: bookAppointmentData.Appointment.ClientId,
          notes: bookAppointmentData.Appointment.Notes,
          startDateTime: bookAppointmentData.Appointment.StartDateTime,
          status: bookAppointmentData.Appointment.Status,
          firstAppointment: bookAppointmentData.Appointment.FirstAppointment,
          addOns: bookAppointmentData.Appointment.AddOns,
          bookDate: moment(siteDate).format("MM/DD/YYYY"),
          bookTime: moment(siteDate).format("HH:mm"),
          siteId: "" + state.siteId,
          source: "callCenter",
          cbff: isCbff,
          clientName: sidebarState.client.name ?? '',
          userName: userAction.info.userName,
          programId: bookAppointmentData.Appointment.ProgramId,
        };
        const putDynamo = {
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(dynamoPayload),
        };
        // comment
        const dynamoResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/dynamoDB/appointments`,
          putDynamo
        );
        const dynamoData = await dynamoResponse.json();
        if (dynamoResponse.ok) {
          setSidebarState((sidebarState) => ({
            ...sidebarState,
            requesting: "IDLE",
          }));
          clearSidebar();
          const params = { value: state.siteId + "|" + state.locationId };
          if (datesState.isWeekRange) {
            handleLocationChangeWeek(params);
          } else {
            console.log("handleappointmentbook");
            handleLocationChange(params);
          }
        } else {
          setSidebarState((sidebarState) => ({
            ...sidebarState,
            requesting: "BOOK-APPOINTMENT-FAIL",
            message: JSON.stringify(dynamoData),
          }));
        }
      } else {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "BOOK-APPOINTMENT-FAIL",
          message: JSON.stringify(bookAppointmentData),
        }));
      }
    } catch (error) {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "BOOK-APPOINTMENT-ERROR",
        message: "Create appointment error: " + JSON.stringify(error),
      }));
    }
  };

  const triggerModalOnDrop = () => {
    const trigger = document.getElementById('modalOnDrop').click();
    return trigger
  }

  useEffect(() => {

    if (confirmationAction.action && confirmationAction.nameAction === "cancel") {
      cancelAppointment(confirmationAction.data.id, confirmationAction.data.clientId)
    }
    if (confirmationAction.action && confirmationAction.nameAction === "reschedule") {
      handleEditAppointment()
    }
    if (confirmationAction.nameAction === "rescheduleOnDrop") {
      triggerModalOnDrop();
      if (confirmationAction.action) {
        handleEditAppointmentOnDrop(confirmationAction.data)
      }
    }
  }, [confirmationAction, confirmationAction.nameAction,confirmationAction.response])

  useEffect(() => {

    let userName;
    const auth = {
      single: appState.singleAuth,
      directors: appState.directorsAuth,
      booking: appState.bookingAuth
    }
    const searchBySite = `${state.siteId}-${state.locationId}`;

    if (auth.single) {
      if (auth.single.userName !== null) userName = auth.single;
    }
    if (auth.booking.authorizationTokens.length > 0) {
      userName = auth.booking.authorizationTokens.find(i => `${i.siteId}-${i.locationId}` === searchBySite);
    }
    if (auth.directors.authorizationTokens.length > 0) {
      userName = auth.booking.authorizationTokens.find(i => `${i.siteId}-${i.locationId}` === searchBySite);
    }
    setUserAction((userAction) => ({
      ...userAction,
      info: userName
    }));

  }, [state, userAction.info])

  const handleChangeBlockOnDrop = (
    appointmentId,
    startDateTime,
    endDateTime,
    sessionTypeId,
    notes,
    staffId,
    authorization,
    siteId,
    locationId,
    isWeekRange
  ) => {

    const date = moment(startDateTime).format("YY-MM-DD")
    const hourStar = moment(startDateTime).format("hh:mm A")
    const hourEnd = moment(endDateTime).format("hh:mm A")

    setConfirmationAction((confirmationAction) => ({
      ...confirmationAction,
      nameAction: "rescheduleOnDrop",
      response: "default",
      text: <div><span>Are you sure to reschedule the appointment for the date: </span><br />
        <b style={{ fontSize: "20px" }}>{date}, {hourStar} - {hourEnd}</b>?</div>,
      info: false,
      data: {
        startDateTime,
        endDateTime,
        appointmentId,
        staffId,
        authorization,
        siteId,
        locationId,
        isWeekRange,
        sessionTypeId,
        notes,
      }
    }));
  }

  const cancelAppointment = async (appointmentId, clientId) => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    setState((state) => ({
      ...state,
      mouseX: null,
      mouseY: null,
    }));
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      requesting: "REQUESTING",
    }));
    setConfirmationAction((confirmationAction) => ({
      ...confirmationAction,
      nameAction: ""
    }));
    try {
      setConfirmationAction((confirmationAction) => ({
        ...confirmationAction,
        response: "process",
      }))
      const payload = {
        Execute: "Cancel",
      };
      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: state.siteId,
          authorization: state.authorization,
          locationid: state.locationId,
          allowedpermissions: true,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/appointments/${appointmentId}`,
        putMethod
      );
      const data = await response.json();
      if (response.ok) {
        const dynamoPayload = {
          id: "" + appointmentId,
          clientId: clientId,
          userNameCancel: userAction.info.userName,
          cancel: moment(new Date).format("YYYY-MM-DD[T]HH:mm:ss"),
        };
        const putDynamo = {
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(dynamoPayload),
        };
        const dynamoResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/dynamoDB/appointments`,
          putDynamo
        );
        const dynamoData = await dynamoResponse.json();
        if (dynamoResponse.ok) {
          setConfirmationAction((confirmationAction) => ({
            ...confirmationAction,
            action: false,
            response: "success",
            text: textResponse.successCancel,
            data: "",
            nameAction: ""
          }))
  
          clearSidebar();
          setState((state) => ({
            ...state,
            appointmentId: "",
          }));
          const params = { value: state.siteId + "|" + state.locationId };
          if (datesState.isWeekRange) {
            handleLocationChangeWeek(params);
          } else {
            console.log("cancel appointment");
            handleLocationChange(params);
          }
        }
        else{
          setState((state) => ({
            ...state,
            status: "booking-fail",
            message: JSON.stringify(dynamoData),
          }));
        }
        
      } else {
        setState((state) => ({
          ...state,
          status: "booking-fail",
          message: JSON.stringify(data),
        }));

        setConfirmationAction((confirmationAction) => ({
          ...confirmationAction,
          action: false,
          response: "error",
          text: JSON.stringify(data.Error.Message),
          data: "",
          nameAction: ""
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: "Onload page Error: " + JSON.stringify(error.message),
      }));
      setConfirmationAction((confirmationAction) => ({
        ...confirmationAction,
        action: false,
        response: "error",
        text: JSON.stringify(error.message),
        data: "",
        nameAction: ""
      }));
      alert(JSON.stringify(error.message));
      console.error(error);
    }
  };

  const confirmAppointment = async (appointmentId) => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    setState((state) => ({
      ...state,
      mouseX: null,
      mouseY: null,
    }));
    try {
      const payload = {
        Execute: "Confirm",
      };
      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: state.siteId,
          authorization: state.authorization,
          locationid: state.locationId,
          allowedpermissions: true,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/appointments/${appointmentId}`,
        putMethod
      );
      const data = await response.json();
      if (response.ok) {
        setState((state) => ({
          ...state,
          appointmentId: "",
        }));
        clearSidebar();
        const params = { value: state.siteId + "|" + state.locationId };
        if (datesState.isWeekRange) {
          handleLocationChangeWeek(params);
        } else {
          console.log("confirmappointment");
          handleLocationChange(params);
        }
      } else {
        setState((state) => ({
          ...state,
          status: "booking-fail",
          message: JSON.stringify(data),
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: "Cancel appointment error: " + JSON.stringify(error.message),
      }));
    }
  };

  const createClient = async () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    try {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "REQUESTING",
      }));
      const payload = {
        firstName: sidebarState.firstName,
        lastName: sidebarState.lastName,
        mobilePhone: sidebarState.mobilePhone,
        email: sidebarState.email,
      };
      const createClientRequest = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: state.authorization,
          siteid: state.siteId,
        },
        body: JSON.stringify(payload),
      };
      const createClientResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/clients`,
        createClientRequest
      );
      const createClientData = await createClientResponse.json();
      if (createClientResponse.ok) {
        const createdClient = {
          clientId: createClientData.clientId,
          name: createClientData.name,
          phone: createClientData.phone,
          email: createClientData.email,
        };
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          client: createdClient,
          requesting: "IDLE",
          status: "bookAppointment",
        }));
      } else {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "CREATE-CLIENT-FAILED",
          message: JSON.stringify(createClientData),
        }));
      }
    } catch (error) {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "CREATE-CLIENT-ERROR",
        message: "Create client error: " + JSON.stringify(error),
      }));
    }
  };

  const updateClient = async () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    try {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "REQUESTING",
      }));
      const payload = {
        Client: {
          FirstName: sidebarState.editedClient.firstName,
          LastName: sidebarState.editedClient.lastName,
          MobilePhone: sidebarState.editedClient.phone,
          Email: sidebarState.editedClient.email,
          Id: sidebarState.editedClient.clientId,
        },
        SendEmail: true,
        CrossRegionalUpdate: false,
        Test: false,
      };
      const updateClientRequest = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: state.authorization,
          siteid: state.siteId,
        },
        body: JSON.stringify(payload),
      };
      const updateClientResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/clients/${sidebarState.editedClient.clientId}`,
        updateClientRequest
      );
      const updateClientData = await updateClientResponse.json();
      if (updateClientResponse.ok) {
        const updatedClient = {
          clientId: updateClientData.Client.Id,
          name: `${updateClientData.Client.FirstName} ${updateClientData.Client.LastName}`,
          email: updateClientData.Client.Email,
          phone: updateClientData.Client.MobilePhone,
        };
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          client: updatedClient,
          requesting: "IDLE",
          status: "bookAppointment",
          ...(sidebarState.status === "updateClient" && { editedClient: updatedClient }),
          ...(sidebarState.status === "updateClientAppointment" && {
            editedAppointment: {
              ...sidebarState.editedAppointment,
              name: `${updateClientData.Client.FirstName} ${updateClientData.Client.LastName}`,
              email: updateClientData.Client.Email,
              phone: updateClientData.Client.MobilePhone,
            }
          }),
        }));
      } else {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "UPDATE-CLIENT-FAILED",
          message: JSON.stringify(updateClientData),
        }));
      }
    } catch (error) {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "UPDATE-CLIENT-ERROR",
        message: "Update client error: " + JSON.stringify(error),
      }));
    }
  };

  const handleClientFormChange = (event) => {
    event.preventDefault();
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleEditClientFormChange = (event) => {
    event.preventDefault();
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      editedClient: {
        ...sidebarState.editedClient,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const getBlockClass = (available, appointment, blocks, index, id, staffId, roomIndex, highlight) => {
    let returnClass = "";
    let borderBottom = " ";
    let borderTop = " ";
    if (index === 0) {
      if (blocks[index + 1].appointment.id !== undefined) {
        if (blocks[index + 1].appointment.id === appointment.id) {
          borderBottom += " border-bottom-color-";
        }
      }
    }
    if (index > 0 && index < blocks.length - 1) {
      if (blocks[index + 1].appointment.id !== undefined) {
        if (blocks[index + 1].appointment.id === appointment.id) {
          borderBottom += " border-bottom-color-";
        }
      }
      if (blocks[index - 1].appointment.id !== undefined) {
        if (blocks[index - 1].appointment.id === appointment.name) {
          borderTop += " border-top-color-";
        }
      }
    }
    if (index === blocks.length) {
      if (blocks[index - 1].appointment.id !== undefined) {
        if (blocks[index - 1].appointment.id === appointment.id) {
          borderTop += " border-top-color-";
        }
      }
    }

    let borderEmptyBottom = " ";
    let borderEmptyTop = " ";
    if (index === 0) {
      if (
        blocks[index + 1].appointment.id === undefined &&
        blocks[index + 1].available === available
      ) {
        if (blocks[index + 1].description === blocks[index].description) {
          borderEmptyBottom += " border-bottom-color-";
        }
      }
    }
    if (index > 0 && index < blocks.length - 1) {
      if (
        blocks[index + 1].appointment.id === undefined &&
        blocks[index + 1].available === available
      ) {
        if (blocks[index + 1].description === blocks[index].description) {
          borderEmptyBottom += " border-bottom-color-";
        }
      }
      if (
        blocks[index - 1].appointment.id === undefined &&
        blocks[index + 1].available === available
      ) {
        if (blocks[index - 1].description === blocks[index].description) {
          borderEmptyTop += " border-top-color-";
        }
      }
    }
    if (index === blocks.length) {
      if (
        blocks[index - 1].appointment.id === undefined &&
        blocks[index + 1].available === available
      ) {
        if (blocks[index - 1].description === blocks[index].description) {
          borderEmptyTop += " border-top-color-";
        }
      }
    }

    if (appointment.status !== undefined) {
      switch (appointment.status) {
        case "Booked":
          returnClass =
            "list-group-item lb-booked-block" +
            borderBottom +
            "booked " +
            borderTop +
            "booked ";
          break;
        case "Confirmed":
          returnClass =
            "list-group-item lb-confirmed-block" +
            borderBottom +
            "confirmed " +
            borderTop +
            "confirmed ";
          break;
        case "Arrived":
          returnClass =
            "list-group-item lb-arrived-block" +
            borderBottom +
            "arrived " +
            borderTop +
            "arrived ";
          break;
        case "Completed":
          returnClass =
            "list-group-item lb-completed-block" +
            borderBottom +
            "completed " +
            borderTop +
            "completed ";
          break;
        case "NoShow":
          returnClass =
            "list-group-item lb-noshow-block" +
            borderBottom +
            "noshow " +
            borderTop +
            "noshow ";
          break;
      }
    } else {
      returnClass = available
        ? "list-group-item lb-available-block"
        : "list-group-item lb-restricted-block" +
        borderEmptyBottom +
        "restricted " +
        borderEmptyTop +
        "restricted ";
    }
    if (
      id === sidebarState.blockId &&
      staffId === sidebarState.staffId &&
      available &&
      appointment.id === undefined &&
      roomIndex === undefined
    ) {
      returnClass += " selected";
    }
    if (
      id === sidebarState.blockId &&
      staffId === sidebarState.staffId &&
      available &&
      appointment.id === undefined &&
      roomIndex === sidebarState.dayIndex
    ) {
      returnClass += " selected";
    }

    let clientId = null;

    if (sidebarState.appointment !== null) {
      if (sidebarState.appointment.clientId === appointment.clientId) {
        clientId = sidebarState.appointment.clientId;
      }
    }

    if (sidebarState.client.clientId !== undefined) {
      if (sidebarState.client.clientId === appointment.clientId) {
        clientId = sidebarState.client.clientId;
      }
    }

    const addHighlight = clientId !== null && highlight;

    return returnClass + (addHighlight ? " lb-highligthed-block" : "");
  };

  const getBlockClassSidebar = (appointment) => {
    let returnClass = "";

    if (appointment.status !== undefined) {
      switch (appointment.status) {
        case "Booked":
          returnClass = "list-group-item lb-booked-block booked ";
          break;
        case "Confirmed":
          returnClass = "list-group-item lb-confirmed-block confirmed ";
          break;
        case "Arrived":
          returnClass = "list-group-item lb-arrived-block arrived ";
          break;
        case "Completed":
          returnClass = "list-group-item lb-completed-block completed ";
          break;
        case "NoShow":
          returnClass = "list-group-item lb-noshow-block noshow ";
          break;
      }
    }
    return returnClass;
  };

  const getBlockDescription = (available, blocks, index) => {
    let hideDescription = false;

    if (index > 0 && index < blocks.length - 1) {
      if (
        blocks[index - 1].appointment.id === undefined &&
        blocks[index - 1].available === available
      ) {
        if (blocks[index - 1].description === blocks[index].description) {
          hideDescription = true;
        }
      }
    }
    if (index === blocks.length) {
      if (
        blocks[index - 1].appointment.id === undefined &&
        blocks[index - 1].available === available
      ) {
        if (blocks[index - 1].description === blocks[index].description) {
          hideDescription = true;
        }
      }
    }

    const description = hideDescription
      ? "\u00a0"
      : blocks[index].description !== " "
        ? blocks[index].description
        : "Closed";

    return description;
  };

  const availableBlocks = (staffId) => {
    const blocks = blocksPerRoom.find((item) => item.staffId === staffId);
    const mutableBlocks = { ...blocks };
    const availableBlocks = [];
    mutableBlocks.blocks.forEach((item) => {
      if (item.available && item.appointment.id === undefined) {
        availableBlocks.push(item);
      }
    });

    const availableBlocksForSelect = availableBlocks.map((item) => {
      const returnBlock = {
        value: item.id,
        label: item.segment,
      };
      return returnBlock;
    });

    setSidebarState((sidebarState) => ({
      ...sidebarState,
      availableBlocks: availableBlocks,
      availableBlocksForSelect: availableBlocksForSelect,
    }));
  };

  const availableBlocksWeek = (staffId, dayIndex) => {
    const roomIndex = blocksPerWeek.findIndex(
      (item) => item.staffId === staffId
    );
    const blocks = blocksPerWeek[roomIndex].blocksPerDay[dayIndex].blocks;
    const mutableBlocks = [...blocks];
    const availableBlocks = [];
    mutableBlocks.forEach((item) => {
      if (item.available && item.appointment.id === undefined) {
        availableBlocks.push(item);
      }
    });

    const availableBlocksForSelect = availableBlocks.map((item) => {
      const returnBlock = {
        value: item.id,
        label: item.segment,
      };
      return returnBlock;
    });

    setSidebarState((sidebarState) => ({
      ...sidebarState,
      availableBlocks: availableBlocks,
      availableBlocksForSelect: availableBlocksForSelect,
    }));
  };

  const handleRebooking = (selectedOption) => {
    const label = selectedOption.label;
    const apptBlocks = blocksPerRoom.find(
      (block) => block.staffId === sidebarState.staffId
    );
    const block = apptBlocks.blocks.find((item) => item.segment === label);

    const appointmentEdited = sidebarState.editedAppointment;
    appointmentEdited.StartDateTime = block.startDateTime;
    appointmentEdited.endDateTime = block.endDateTime;
    appointmentEdited.segment = label;
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      editedAppointment: appointmentEdited,
    }));
  };

  const handleRebookingWeek = (selectedOption) => {
    const label = selectedOption.label;
    const index = blocksPerWeek.findIndex(
      (block) => block.staffId === sidebarState.staffId
    );
    const apptBlocks =
      blocksPerWeek[index].blocksPerDay[sidebarState.dayIndex].blocks;
    const block = apptBlocks.find((item) => item.segment === label);

    const appointmentEdited = sidebarState.editedAppointment;
    appointmentEdited.StartDateTime = block.startDateTime;
    appointmentEdited.endDateTime = block.endDateTime;
    appointmentEdited.segment = label;
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      editedAppointment: appointmentEdited,
    }));
  };

  const handleEditedNotes = (event) => {
    const appointmentToEdit = sidebarState.editedAppointment;
    appointmentToEdit.notes = event.target.value;

    setSidebarState((sidebarState) => ({
      ...sidebarState,
      editedAppointment: appointmentToEdit,
    }));
  };

  const handleCreateNotes = (event) => {
    setSidebarState((sidebarState) => ({
      ...sidebarState,
      createNotes: event.target.value,
    }));
  };

  const handleEditAppointment = async () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    setConfirmationAction((confirmationAction) => ({
      ...confirmationAction,
      nameAction: ""
    }))
    try {
      setConfirmationAction((confirmationAction) => ({
        ...confirmationAction,
        response: "process",
      }))
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "REQUESTING",
      }));
      const sendFullBody = 
        sidebarState.editedAppointment.StartDateTime !== sidebarState.appointment.StartDateTime
        || sidebarState.appointment.staffId !== sidebarState.editedAppointment.staffId
        || sidebarState.editedAppointment.sessionTypeId !== sidebarState.appointment.sessionTypeId;
      const payload = {
        AppointmentId: sidebarState.editedAppointment.id,
        ...( sendFullBody && { StartDateTime: sidebarState.editedAppointment.StartDateTime }),
        ...( sendFullBody && { EndDateTime: sidebarState.editedAppointment.endDateTime }),
        ...( sendFullBody && { SessionTypeId: sidebarState.editedAppointment.sessionTypeId }),
        Notes: sidebarState.editedAppointment.notes,
      };
      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: state.authorization,
          siteid: state.siteId,
          locationid: state.locationId,
          allowedpermissions: true,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/appointments/${sidebarState.appointment.id}`,
        putMethod
      );
      const data = await response.json();
      if (response.ok) {

        setConfirmationAction((confirmationAction) => ({
          ...confirmationAction,
          action: false,
          response: "success",
          text: textResponse.successReschedule,
          data: "",
          nameAction: ""
        }))

        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "IDLE",
        }));
        clearSidebar();
        const params = { value: state.siteId + "|" + state.locationId };
        if (datesState.isWeekRange) {
          handleLocationChangeWeek(params);
        } else {
          console.log("handleeditappointment");
          handleLocationChange(params);
        }
      } else {
        setConfirmationAction((confirmationAction) => ({
          ...confirmationAction,
          action: false,
          response: "error",
          text: textResponse.error,
          data: "",
          nameAction: "",
        }));
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "EDIT-FAIL",
          message: "Edit appointment failed: " + JSON.stringify(data),
        }));
        clearSidebar();
      }
    } catch (error) {
      setConfirmationAction((confirmationAction) => ({
        ...confirmationAction,
        action: false,
        response: "error",
        text: textResponse.error,
        data: "",
        nameAction: ""
      }));
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "EDIT-ERROR",
        message: "Edit appointment failed: " + JSON.stringify(error),
      }));
    }
  };

  const handleEditAppointmentOnDrop = async (dataParams) => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    setConfirmationAction((confirmationAction) => ({
      ...confirmationAction,
      nameAction: ""
    }))
    try {
      setConfirmationAction((confirmationAction) => ({
        ...confirmationAction,
        response: "process",
      }))
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        status: "IDLE",
        requesting: "REQUESTING-DND",
      }));

      const payload = {
        AppointmentId: dataParams.appointmentId,
        StartDateTime: dataParams.startDateTime,
        EndDateTime: dataParams.endDateTime,
        SessionTypeId: dataParams.sessionTypeId,
        StaffId: dataParams.staffId,
        Notes: dataParams.notes,
      };
      const putMethod = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: dataParams.authorization,
          siteid: dataParams.siteId,
          locationid: dataParams.locationId,
          allowedpermissions: true,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/appointments/${dataParams.appointmentId}`,
        putMethod
      );
      const data = await response.json();
      if (response.ok) {

        setConfirmationAction((confirmationAction) => ({
          ...confirmationAction,
          action: false,
          response: "success",
          text: textResponse.successReschedule,
          data: "",
          nameAction: ""
        }))
        triggerModalOnDrop();

        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "SUCCESS-DND",
        }));
        clearAppointmentSidebar();
        const params = { value: dataParams.siteId + "|" + dataParams.locationId };
        if (datesState.isWeekRange) {
          handleLocationChangeWeek(params);
        } else {
          console.log("handleEditAppointmentOnDrop");
          handleLocationChange(params);
        }
      } else {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          requesting: "EDIT-DND-FAIL",
          message: "Edit appointment failed: " + JSON.stringify(data),
        }));
        setConfirmationAction((confirmationAction) => ({
          ...confirmationAction,
          action: false,
          response: "error",
          text: textResponse.error,
          data: "",
          show: false,
          nameAction: ""
        }));
        triggerModalOnDrop();
      }
    } catch (error) {
      setConfirmationAction((confirmationAction) => ({
        ...confirmationAction,
        action: false,
        response: "error",
        text: textResponse.error,
        data: "",
        nameAction: ""
      }));
      triggerModalOnDrop();
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        requesting: "EDIT-DND-ERROR",
        message: "Edit appointment failed: " + JSON.stringify(error),
      }));
    }
  };

  const handleEditAppointmentOnDropSidebar = (item) => {
    clearSidebar();
    setRebookSidebar((rebookSidebar) => ({
      ...rebookSidebar,
      appointment: item,
    }));
  };

  const clearAppointmentSidebar = () => {
    setRebookSidebar((rebookSidebar) => ({
      ...rebookSidebar,
      appointment: {},
    }));
  };

  const addOneWeek = () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    const mutableDate = new Date(startDate.toString());
    mutableDate.setDate(mutableDate.getDate() + 7);
    setStartDate(mutableDate);
  };
  const addOneDay = () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    const mutableDate = new Date(startDate.toString());
    mutableDate.setDate(mutableDate.getDate() + 1);
    setStartDate(mutableDate);
  };
  const subOneWeek = () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    const mutableDate = new Date(startDate.toString());
    mutableDate.setDate(mutableDate.getDate() - 7);
    setStartDate(mutableDate);
  };
  const subOneDay = () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    const mutableDate = new Date(startDate.toString());
    mutableDate.setDate(mutableDate.getDate() - 1);
    setStartDate(mutableDate);
  };
  const goToToday = () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    const mutableDate = new Date();
    setStartDate(mutableDate);
  };

  const viewLastVisit = async (appointment) => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }

    try {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        status: "IDLE",
        requesting: "REQUESTING-LAST-VISIT",
      }));

      const queryStartDate = moment(startDate).subtract(6, 'months').format("MM/DD/YYYY").toString();

      const getMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: state.authorization,
          siteid: state.siteId,
          locationid: state.locationId,
          allowedpermissions: true,
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/clients/${appointment.clientId}/visits?startDate=${queryStartDate}`,
        getMethod
      );
      const data = await response.json();
      if (response.ok) {
        const appointments = data.visits.filter(appointment => appointment.appointmentStatus === "Completed")

        if (appointments.length > 0) {
          setSidebarState((sidebarState) => ({
            ...sidebarState,
            requesting: "IDLE",
            status: "displayLastVisit",
            lastVisit: {
              client: data.clients[0],
              appointments: appointments,
            }
          }));
        }
        else {
          setSidebarState((sidebarState) => ({
            ...sidebarState,
            status: "IDLE",
            requesting: "ERROR-LAST-VISIT",
            message: "No visits found in the last 6 months",
            lastVisit: {
              client: {},
              appointments: [],
            }
          }));
        }

      } else {
        setSidebarState((sidebarState) => ({
          ...sidebarState,
          status: "IDLE",
          requesting: "ERROR-LAST-VISIT",
          message: "Not Found: " + JSON.stringify(data),
          lastVisit: {
            client: {},
            appointments: [],
          }
        }));
      }
    } catch (error) {
      setSidebarState((sidebarState) => ({
        ...sidebarState,
        status: "IDLE",
        requesting: "ERROR-LAST-VISIT",
        message: "Error: " + JSON.stringify(error),
        lastVisit: {
          client: {},
          appointments: [],
        }
      }));
    }
  }

  const getLocationsInformation = async () => {
    try {
      const getSitesData = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/config/sites`,
        getSitesData
      );

      const data = await response.json();

      if (response.ok) {

        const allSitesItem = data.sites.find(site => site.site === "0-0");
        let sitesArray = []
        if (allSitesItem !== undefined) {
          sitesArray = data.sites.filter(site => site.site !== "0-0");
        }
        else {
          sitesArray = data.sites;
        }
        setSitesInfo(sitesArray);
      } else {
        console.error("error requesting the config sites endpoint");
        console.error(response);
      }
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }

  useEffect(() => {
    getLocationsInformation();
  }, []);

  useEffect(() => {
    if ('label' in appState.bookingAuth.selectedSite) {
      if (
        state.status !== "loadingAppointments" &&
        state.status !== "refreshingAppointments" &&
        sidebarState.requesting !== "REQUESTING" &&
        sidebarState.requesting !== "REQUESTING-DND"
      ) {
        setFetchServices(true);
        setState((state) => ({
          ...state,
          status: "IDLE",
        }));

        setSidebarState((sidebarState) => ({
          ...sidebarState,
          status: "IDLE",
          message: "",
        }));

        setDisplayableBlocks([]);

        setBlocksPerRoom([]);
        // console.log(location);
        const siteLocation = appState.bookingAuth.selectedSite.value.replace("|", "-");
        const siteInfo = sitesInfo.find(site => site.site === siteLocation);
        if (siteInfo) {
          setLocation({
            ...appState.bookingAuth.selectedSite,
            HTAEnglish: siteInfo.HTAEnglish ?? '',
            HTASpanish: siteInfo.HTASpanish ?? '',
            address: siteInfo.address ?? '',
            siteId: siteInfo.site.split("-")[0] ?? '',
            locationId: siteInfo.site.split("-")[1] ?? '',
            sitePhone: siteInfo.phone ?? '',
            timeZone: siteInfo.timeZone ?? '',
          });
        }
        else {
          setLocation(appState.bookingAuth.selectedSite);
        }
      }
      else {
        setAppState((appState) => ({
          ...appState,
          bookingAuth: {
            ...appState.bookingAuth,
            selectedSite: location,
          },
        }));
      }

    }

  }, [appState.bookingAuth.selectedSite]);
  
  useEffect(() => {
    if(location.siteId){
      setFetchServices(true);
      datesState.isWeekRange
        ? handleLocationChangeWeek(appState.bookingAuth.selectedSite)
        : handleLocationChange(appState.bookingAuth.selectedSite);
    }
  }, [location.siteId, location.locationId]);

  const handleSearchClientInput = (event) => {
    setClientVisits((clientVisits) => ({
      ...clientVisits,
      status: "IDLE",
      requesting: "IDLE",
      message: "",
      inputValue: event.target.value,
      visits: [],
    }));
  }

  const handleDialogClose = () => {
    setClientVisits((clientVisits) => ({
      ...clientVisits,
      status: "IDLE",
    }));
  }

  const reloadTable = () => {
    if (
      state.status === "loadingAppointments" ||
      state.status === "refreshingAppointments" ||
      sidebarState.requesting === "REQUESTING" ||
      sidebarState.requesting === "REQUESTING-DND"
    ) {
      return;
    }
    const mutableDate = new Date(startDate.toString());
    setStartDate(mutableDate);
  }

  const searchClientVisits = async (searchTerm) => {
    if (clientVisits.requesting === "REQUESTING") {
      return;
    }
    try {
      setClientVisits((clientVisits) => ({
        ...clientVisits,
        requesting: "REQUESTING",
      }));

      const searchClientsRequest = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: state.authorization,
          siteid: state.siteId,
        },
      };
      const queryStartDate = moment(startDate).subtract(6, "months").format("MM/DD/YYYY").toString();
      const queryEndDate = moment(startDate).add(6, "months").format("MM/DD/YYYY").toString();
      const searchClientsVisitsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/clients/${searchTerm??clientVisits.inputValue}/visits?startDate=${queryStartDate}&endDate=${queryEndDate}`,
        searchClientsRequest
      );

      const searchClientsData = await searchClientsVisitsResponse.json();
      if (searchClientsVisitsResponse.ok) {
        if(searchClientsData.clients[0]){
          setClientVisits((clientVisits) => ({
            ...clientVisits,
            status: "SHOW",
            requesting: "IDLE",
            visits: searchClientsData.visits,
            client: searchClientsData.clients[0]
          }));
        }
        else{
          setClientVisits((clientVisits) => ({
            ...clientVisits,
            requesting: "VISITS-ERROR",
            message: "Visits search error",
          }));
        }
      } else {
        setClientVisits((clientVisits) => ({
          ...clientVisits,
          requesting: "VISITS-NO-FOUND",
          message: "No visits found for this client",
          visits: [],
        }));
      }
    } catch (error) {
      setClientVisits((clientVisits) => ({
        ...clientVisits,
        requesting: "VISITS-ERROR",
        message: "Visits search error: " + JSON.stringify(error),
      }));
    }
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row border border-1 mt-3">

          <ConfirmationAction
            confirmationAction={confirmationAction}
            setConfirmationAction={setConfirmationAction}
          />

          <section id="sidebar" className="col-3 border-end sidebar smallerText" >
            <div className="text-center my-1">
              {moment(currentDate).format("LT Z").toString()}
            </div>
            {datesState.isWeekRange && (
              <div className="d-flex justify-content-center lb-date-picker">
                <DatePicker
                  onSelect={(date) => {
                    if (
                      state.status === "loadingAppointments" ||
                      state.status === "refreshingAppointments" ||
                      sidebarState.requesting === "REQUESTING" ||
                      sidebarState.requesting === "REQUESTING-DND"
                    ) {
                      return;
                    }
                    handleCalendarChange(date);
                  }}
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  inline
                />
              </div>
            )}
            {!datesState.isWeekRange && (
              <div className="d-flex justify-content-center lb-date-picker">
                <DatePicker
                  onSelect={(date) => {
                    if (
                      state.status === "loadingAppointments" ||
                      state.status === "refreshingAppointments" ||
                      sidebarState.requesting === "REQUESTING" ||
                      sidebarState.requesting === "REQUESTING-DND"
                    ) {
                      return;
                    }
                    handleCalendarChange(date);
                  }}
                  selected={startDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  inline
                />
              </div>
            )}
            <DndProvider backend={HTML5Backend}>
              <div>
                <DroppableSidebar
                  updateFunction={handleEditAppointmentOnDropSidebar}
                />
                {rebookSidebar.appointment.status !== undefined &&
                  sidebarState.status === "IDLE" && (
                    <>
                      <div
                        className={
                          getBlockClassSidebar(rebookSidebar.appointment) +
                          "pr-4"
                        }
                      >
                        <button
                          className="btn circleButton btn-link m-0 p-0"
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "1px",
                            height: "100%",
                            color: "black",
                            width: "auto",
                            zIndex: 1000,
                          }}
                          onClick={clearAppointmentSidebar}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                        <DraggableSidebar
                          appointment={rebookSidebar.appointment}
                        />
                      </div>
                    </>
                  )}
              </div>
            </DndProvider>
            {sidebarState.status === "searchOrCreateClient" && (
              <>
                <h5 className="text-center mt-2 mb-2">Book appointment</h5>
                <div className="border border-1 m-3 p-2">
                  <h4 className="h6 mt-2 mb-3">Client search</h4>
                  <div className="input-group input-group-sm mb-4 ">
                    <input
                      type="text"
                      onChange={handleClientSearchChange}
                      className="form-control"
                      placeholder="Search a client"
                    />
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={searchClient}
                    >
                      {sidebarState.requesting === "REQUESTING" && (
                        <FontAwesomeIcon spin icon={faSpinner} />
                      )}
                      {sidebarState.requesting !== "REQUESTING" && (
                        <FontAwesomeIcon icon={faSearch} />
                      )}
                    </button>
                  </div>
                  {sidebarState.searchResults.length > 0 && (
                    <ul className="px-0 smaller-text lb-clients-result-list">
                      {sidebarState.searchResults.map((clientItem, index) => {
                        return (
                          <li
                            key={"block-" + index}
                            className="list-group-item"
                            onClick={() => handleClientSelect(index)}
                          >
                            {clientItem.name} - {clientItem.email} -{" "}
                            {clientItem.phone}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {sidebarState.requesting === "CLIENT-NO-FOUND" && (
                    <div className="d-block text-center p-2 my-1 border border-1">
                      <span> NO CLIENTS FOUND </span>
                    </div>
                  )}
                  {sidebarState.requesting === "CLIENT-ERROR" && (
                    <div className="d-block alert alert-danger">
                      <span> {sidebarState.message} </span>
                    </div>
                  )}

                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={clearSidebar}
                  >
                    BACK
                  </button>
                  <button
                    className="btn btn-link btn-sm"
                    onClick={() => {
                      setSidebarState((sidebarState) => ({
                        ...sidebarState,
                        status: "createClient",
                      }));
                    }}
                  >
                    Or create a new client
                  </button>
                </div>
              </>
            )}
            {(sidebarState.status === "editAppointment" ||
              sidebarState.status === "updateClientAppointment") && (
                <>
                  <h5 className="text-center mt-2 mb-2">Edit appointment</h5>
                  <div className="border border-1 m-3 p-2 smaller-text fw-bold">
                    <div className="d-flex">
                      {sidebarState.editedAppointment.redAlert !== "" &&
                        sidebarState.editedAppointment.redAlert !== undefined &&
                        sidebarState.editedAppointment.redAlert !== null && (
                          <span>
                            <FontAwesomeIcon
                              className="lb-fontAwesome-flag-red"
                              icon={faExclamationTriangle}
                            />
                            &nbsp;
                            {sidebarState.editedAppointment.redAlert}
                          </span>
                        )}
                    </div>
                    <div className="d-flex">
                      {sidebarState.editedAppointment.formFilled !== "" &&
                        sidebarState.editedAppointment.formFilled !== undefined &&
                        sidebarState.editedAppointment.formFilled !== null && (
                          <span>
                            <FontAwesomeIcon
                              className="lb-fontAwesome-flag-yellow"
                              icon={faExclamationTriangle}
                            />
                            &nbsp;
                            {sidebarState.editedAppointment.formFilled}
                          </span>
                        )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>{sidebarState.editedAppointment.name}</span>
                      <span>{sidebarState.editedAppointment.phone}</span>
                    </div>
                    <div className="mt-1">
                      <span>{sidebarState.editedAppointment.email}</span>
                    </div>

                    <div className="d-flex justify-content-between">
                      <span>
                        {sidebarState.editedAppointment.sessionTypeName}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                        className="btn btn-link btn-sm"
                        onClick={() => {
                          setSidebarState((sidebarState) => ({
                            ...sidebarState,
                            status: "updateClientAppointment",
                            statusDisabled: true,
                            editedClient: sidebarState.appointment,
                          }));
                        }}
                      >
                        Edit client
                      </button>
                    </div>

                    {sidebarState.status === "updateClientAppointment" && (
                      <>
                        <div className="border border-1 mt-1 p-0 smaller-text fw-bold lb-available-block">
                          <h5 className="text-center mt-2 mb-2">Update Client</h5>
                          <div className="border border-1 m-1 p-2 smaller-text fw-bold">
                            <input
                              type="text"
                              className="form-control form-control-sm mb-1"
                              name="firstName"
                              value={sidebarState.editedClient.firstName}
                              onChange={handleEditClientFormChange}
                              placeholder="First Name"
                            ></input>
                            <input
                              type="text"
                              className="form-control form-control-sm mb-1"
                              name="lastName"
                              value={sidebarState.editedClient.lastName}
                              onChange={handleEditClientFormChange}
                              placeholder="Last Name"
                            ></input>
                            <input
                              type="phone"
                              className="form-control form-control-sm mb-1"
                              name="phone"
                              value={sidebarState.editedClient.phone}
                              onChange={handleEditClientFormChange}
                              placeholder="Mobile Phone"
                            ></input>
                            <input
                              type="email"
                              className="form-control form-control-sm mb-1"
                              name="email"
                              value={sidebarState.editedClient.email}
                              onChange={handleEditClientFormChange}
                              placeholder="Email"
                            ></input>

                            {sidebarState.requesting === "CREATE-CLIENT-FAIL" && (
                              <div className="d-block alert alert-warning">
                                <span> {sidebarState.message} </span>
                              </div>
                            )}
                            {sidebarState.requesting ===
                              "CREATE-CLIENT-ERROR" && (
                                <div className="d-block alert alert-danger">
                                  <span> {sidebarState.message} </span>
                                </div>
                              )}
                            <div className="d-flex w-100 justify-content-between mt-3">
                              <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => {
                                  setSidebarState((sidebarState) => ({
                                    ...sidebarState,
                                    status: "editAppointment",
                                    statusDisabled: false,
                                  }));
                                }}
                              >
                                CANCEL
                              </button>
                              <button
                                className="btn btn-success btn-sm"
                                onClick={updateClient}
                              >
                                {sidebarState.requesting === "REQUESTING" && (
                                  <FontAwesomeIcon spin icon={faSpinner} />
                                )}
                                {sidebarState.requesting !== "REQUESTING" && (
                                  <>UPDATE</>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="mt-1 mb-2 lb-notes-popover">
                      <textarea
                        name="notes"
                        rows="3"
                        className={
                          sidebarState.statusDisabled === false
                            ? "w-100 form-control smaller-text"
                            : "w-100 form-control smaller-text d-none"
                        }
                        onChange={handleEditedNotes}
                        value={
                          sidebarState.editedAppointment.notes === null
                            ? ""
                            : sidebarState.editedAppointment.notes
                        }
                      />
                    </div>
                    <Select
                      options={sidebarState.availableBlocksForSelect}
                      isSearchable={false}
                      onChange={
                        datesState.isWeekRange
                          ? handleRebookingWeek
                          : handleRebooking
                      }
                      defaultValue={{
                        label: sidebarState.block,
                        value: sidebarState.segmentId,
                      }}
                      className={
                        sidebarState.statusDisabled === false
                          ? "w-100 smaller-text"
                          : "w-100 smaller-text d-none"
                      }
                    />
                    <Select
                      options={services}
                      isSearchable={true}
                      onChange={handleEditServiceChange}
                      defaultValue={{
                        label: sidebarState.editedAppointment.sessionTypeName,
                        value: sidebarState.editedAppointment.sessionTypeId,
                      }}
                      placeholder="Select a service"
                      className={
                        sidebarState.statusDisabled === false
                          ? "w-100 smaller-text"
                          : "w-100 smaller-text d-none"
                      }
                    />
                    {sidebarState.requesting === "EDIT-FAIL" && (
                      <div className="d-block alert alert-warning">
                        <span> {sidebarState.message} </span>
                      </div>
                    )}
                    {sidebarState.requesting === "EDIT-ERROR" && (
                      <div className="d-block alert alert-danger">
                        <span> {sidebarState.message} </span>
                      </div>
                    )}
                    <div className="d-flex w-100 justify-content-between mt-3">
                      <button
                        className={
                          sidebarState.statusDisabled === false
                            ? "btn btn-secondary btn-sm"
                            : "btn btn-secondary btn-sm d-none"
                        }
                        onClick={clearSidebar}
                        disabled={sidebarState.statusDisabled}
                      >
                        BACK
                      </button>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#modalConfirmationAction"
                        className={
                          sidebarState.statusDisabled === false
                            ? "btn btn-success btn-sm"
                            : "btn btn-success btn-sm d-none"
                        }
                        onClick={() => {
                          setConfirmationAction((confirmationAction) => ({
                            ...confirmationAction,
                            nameAction: "reschedule",
                            response: "default",
                            text: textResponse.reschedule,
                            data: sidebarState.appointment,
                            info: true,
                            infoTittle: `${sidebarState.appointment.firstName} ${sidebarState.appointment.lastName}, ${sidebarState.appointment.sessionTypeName}`,
                            infoBody:
                              <>
                                <div className="mt-1">
                                <span className="pr-5"><b>Date:</b> {moment(startDate).format("YY-MM-DD")},&nbsp;
                                {moment(sidebarState.appointment.StartDateTime).format("hh:mm A")} - {moment(sidebarState.appointment.endDateTime).format("hh:mm A")}</span>
                                </div>
                                <div className="mt-2">
                                <span className="pr-5"><b>Phone:</b> {sidebarState.appointment.phone}</span>
                                </div>
                                <div className="mt-2">
                                <span className="pr-5 "><b>Email:</b> {sidebarState.appointment.email}</span>
                                </div>
                              </>
                          }))
                        }}

                        disabled={sidebarState.statusDisabled}
                      >
                        {sidebarState.requesting === "REQUESTING" && (
                          <FontAwesomeIcon spin icon={faSpinner} />
                        )}
                        {sidebarState.requesting !== "REQUESTING" && <>SAVE</>}
                      </button>
                    </div>
                  </div>
                </>
              )}
            {(sidebarState.status === "createClient" ||
              sidebarState.status === "updateClient") && (
                <>
                  <h5 className="text-center mt-2 mb-2">
                    {sidebarState.status === "createClient"
                      ? "Create client"
                      : "Update Client"}
                  </h5>
                  <div className="border border-1 m-3 p-2 smaller-text fw-bold">
                    <input
                      type="text"
                      className="form-control form-control-sm mb-1"
                      name="firstName"
                      value={
                        sidebarState.status === "createClient"
                          ? sidebarState.firstName
                          : sidebarState.editedClient.firstName
                      }
                      onChange={
                        sidebarState.status === "createClient"
                          ? handleClientFormChange
                          : handleEditClientFormChange
                      }
                      placeholder="First Name"
                    ></input>
                    <input
                      type="text"
                      className="form-control form-control-sm mb-1"
                      name="lastName"
                      value={
                        sidebarState.status === "createClient"
                          ? sidebarState.lastName
                          : sidebarState.editedClient.lastName
                      }
                      onChange={
                        sidebarState.status === "createClient"
                          ? handleClientFormChange
                          : handleEditClientFormChange
                      }
                      placeholder="Last Name"
                    ></input>
                    <input
                      type="phone"
                      className="form-control form-control-sm mb-1"
                      name={
                        sidebarState.status === "createClient"
                          ? "mobilePhone"
                          : "phone"
                      }
                      value={
                        sidebarState.status === "createClient"
                          ? sidebarState.mobilePhone
                          : sidebarState.editedClient.phone
                      }
                      onChange={
                        sidebarState.status === "createClient"
                          ? handleClientFormChange
                          : handleEditClientFormChange
                      }
                      placeholder="Mobile Phone"
                    ></input>
                    <input
                      type="email"
                      className="form-control form-control-sm mb-1"
                      name="email"
                      value={
                        sidebarState.status === "createClient"
                          ? sidebarState.email
                          : sidebarState.editedClient.email
                      }
                      onChange={
                        sidebarState.status === "createClient"
                          ? handleClientFormChange
                          : handleEditClientFormChange
                      }
                      placeholder="Email"
                    ></input>

                    {sidebarState.requesting === "CREATE-CLIENT-FAIL" && (
                      <div className="d-block alert alert-warning">
                        <span> {sidebarState.message} </span>
                      </div>
                    )}
                    {sidebarState.requesting === "CREATE-CLIENT-ERROR" && (
                      <div className="d-block alert alert-danger">
                        <span> {sidebarState.message} </span>
                      </div>
                    )}
                    <div className="d-flex w-100 justify-content-between mt-3">
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={clearSidebar}
                      >
                        {sidebarState.status === "createClient"
                          ? "BACK"
                          : "CANCEL"}
                      </button>
                      <button
                        className="btn btn-success btn-sm"
                        onClick={
                          sidebarState.status === "createClient"
                            ? createClient
                            : updateClient
                        }
                      >
                        {sidebarState.requesting === "REQUESTING" && (
                          <FontAwesomeIcon spin icon={faSpinner} />
                        )}
                        {sidebarState.requesting !== "REQUESTING" && (
                          <>
                            {sidebarState.status === "createClient"
                              ? "CREATE"
                              : "UPDATE"}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}
            {sidebarState.status === "bookAppointment" && (
              <>
                <h5 className="text-center mt-2 mb-2">Book appointment</h5>
                <div className="border border-1 m-3 p-2 smaller-text fw-bold">
                  <div className="d-flex justify-content-between">
                    <span>{sidebarState.client.name}</span>
                    <span>{sidebarState.client.phone}</span>
                  </div>
                  <div className="mt-1">
                    <span>{sidebarState.client.email}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h4 className="h6 mt-2 mb-3">
                      Start: {sidebarState.block}
                    </h4>

                    <button
                      className="btn btn-link btn-sm"
                      onClick={() => {
                        setSidebarState((sidebarState) => ({
                          ...sidebarState,
                          status: "updateClient",
                          editedClient: sidebarState.client,
                        }));
                      }}
                    >
                      Edit client
                    </button>
                  </div>
                  <Select
                    options={services}
                    isSearchable={true}
                    onChange={handleServiceChange}
                    placeholder="Select a service"
                    className="w-100 smaller-text"
                  />
                  <div className="mt-1 lb-notes-popover">
                    <textarea
                      name="notes"
                      rows="3"
                      placeholder="Enter the appointment notes here..."
                      className="w-100 form-control smaller-text"
                      onChange={handleCreateNotes}
                      value={
                        sidebarState.createNotes === null
                          ? ""
                          : sidebarState.createNotes
                      }
                    />
                  </div>

                  {sidebarState.requesting === "BOOK-APPOINTMENT-FAIL" && (
                    <div className="d-block alert alert-warning">
                      <span> {sidebarState.message} </span>
                    </div>
                  )}
                  {sidebarState.requesting === "BOOK-APPOINTMENT-ERROR" && (
                    <div className="d-block alert alert-danger">
                      <span> {sidebarState.message} </span>
                    </div>
                  )}

                  <div className="d-flex w-100 justify-content-between mt-3">
                    <button
                      className="btn btn-secondary btn-sm"
                      onClick={clearSidebar}
                    >
                      BACK
                    </button>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={handleAppointmentBook}
                    >
                      {sidebarState.requesting === "REQUESTING" && (
                        <FontAwesomeIcon spin icon={faSpinner} />
                      )}
                      {sidebarState.requesting !== "REQUESTING" && <>BOOK</>}
                    </button>
                  </div>
                </div>
              </>
            )}
            {sidebarState.status === "displayLastVisit" && (
              <div className="d-block mx-4">
                <h5 className="text-left mt-2 mb-2">Last Visit:</h5>
                <div className="px-1">
                  <span><b>Name:</b> {`${sidebarState.lastVisit.client.FirstName} ${sidebarState.lastVisit.client.LastName}`}</span> <br />
                  <span><b>Service:</b> {sidebarState.lastVisit.appointments[0].serviceName}</span> <br />
                  <span><b>Date:</b> {moment(sidebarState.lastVisit.appointments[0].startDateTime).format("DD/MM/YYYY hh:mm").toString()}</span> <br />
                </div>
                <div className="text-center mt-3">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={clearSidebar}
                  >
                    BACK
                  </button>
                </div>
              </div>
            )}
            {sidebarState.requesting === "REQUESTING-DND" && (
              <div className="loading d-flex my-5" data-cy="loading-message">
                <div className="lb-spinner" />
              </div>
            )}
            {sidebarState.requesting === "SUCCESS-DND" && (
              <div className="d-block mx-4 alert alert-success">
                <span> Successful operation </span>
              </div>
            )}
            {sidebarState.requesting === "EDIT-DND-FAIL" && (
              <div className="d-block mx-4 alert alert-warning">
                <span> {sidebarState.message} </span>
              </div>
            )}
            {sidebarState.requesting === "EDIT-DND-ERROR" && (
              <div className="d-block mx-4 alert alert-danger">
                <span> {sidebarState.message} </span>
              </div>
            )}
            {sidebarState.requesting === "REQUESTING-LAST-VISIT" && (
              <div className="loading d-flex my-5" data-cy="loading-message">
                <div className="lb-spinner" />
              </div>
            )}
            {sidebarState.requesting === "ERROR-LAST-VISIT" && (
              <>
                <div className="d-block mx-4 alert alert-danger">
                  <span> {sidebarState.message} </span>
                </div>
                <div className="text-center mt-3">
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={clearSidebar}
                  >
                    BACK
                  </button>
                </div>
              </>
            )}
            {state.status === "displayAppointments" &&
              !datesState.isWeekRange &&
              state.displayAll && (
                <>
                  <div className="row d-flex justify-content-center mt-1">

                    <div className="col-2 d-flex text-center lb-booked-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Booked} Booked</p>
                    </div>

                    <div className="col-3 d-flex lb-confirmed-block text-center user-select-none m-0 border border-1 pr-5" style={{ height: "31px", width: "70px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1">{state.totalCounter.Confirmed} Confirmed</p>
                    </div>

                    <div className="col-2 d-flex lb-arrived-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Arrived} Arrived</p>
                    </div>

                    <div className="col-3 d-flex lb-completed-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px", width: "70px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Completed} Completed</p>
                    </div>

                    <div className="col-2 d-flex lb-noshow-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.NoShow} NoShow</p>
                    </div>

                    <div className="col-2 d-flex lb-available-block user-select-none m-0 border border-1 pr-4" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.total} Total</p>
                    </div>

                    <div className="col-2 d-flex lb-highligthed-block user-select-none m-0 border border-1 pr-4" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.cbff} CBFF</p>
                    </div>

                  </div>
                </>
              )}
            {state.status === "displayAppointments" &&
              !datesState.isWeekRange &&
              !state.displayAll && (
                <>
                  <div className="row d-flex justify-content-center mt-1">

                    <div className="col-2 d-flex text-center lb-booked-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Booked} Booked</p>
                    </div>

                    <div className="col-3 d-flex lb-confirmed-block text-center user-select-none m-0 border border-1 pr-5" style={{ height: "31px", width: "70px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1">{state.totalCounter.Confirmed} Confirmed</p>
                    </div>

                    <div className="col-2 d-flex lb-arrived-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Arrived} Arrived</p>
                    </div>

                    <div className="col-3 d-flex lb-completed-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px", width: "70px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Completed} Completed</p>
                    </div>

                    <div className="col-2 d-flex lb-noshow-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.NoShow} NoShow</p>
                    </div>

                    <div className="col-2 d-flex lb-available-block user-select-none m-0 border border-1 pr-4" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.total} Total</p>
                    </div>

                    <div className="col-2 d-flex lb-highligthed-block user-select-none m-0 border border-1 pr-4" style={{ height: "31px" }}>
                      <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.cbff} CBFF</p>
                    </div>

                  </div>
                </>
              )}
            {state.status === "displayAppointments" && datesState.isWeekRange && (
              <>
                <div className="row d-flex justify-content-center mt-1">

                  <div className="col-2 d-flex text-center lb-booked-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                    <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Booked} Booked</p>
                  </div>

                  <div className="col-3 d-flex lb-confirmed-block text-center user-select-none m-0 border border-1 pr-5" style={{ height: "31px", width: "70px" }}>
                    <p style={{ fontSize: "9px" }} className="mt-1">{state.totalCounter.Confirmed} Confirmed</p>
                  </div>

                  <div className="col-2 d-flex lb-arrived-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                    <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Arrived} Arrived</p>
                  </div>

                  <div className="col-3 d-flex lb-completed-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px", width: "70px" }}>
                    <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.Completed} Completed</p>
                  </div>

                  <div className="col-2 d-flex lb-noshow-block user-select-none m-0 border border-1 pr-5" style={{ height: "31px" }}>
                    <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.NoShow} NoShow</p>
                  </div>

                  <div className="col-2 d-flex lb-available-block user-select-none m-0 border border-1 pr-4" style={{ height: "31px" }}>
                    <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.total} Total</p>
                  </div>

                  <div className="col-2 d-flex lb-highligthed-block user-select-none m-0 border border-1 pr-4" style={{ height: "31px" }}>
                    <p style={{ fontSize: "9px" }} className="mt-1 text-center">{state.totalCounter.cbff} CBFF</p>
                  </div>

                </div>
              </>
            )}
          </section>

          <section id="tableSpace" className="col-9 tableSpace">
            <div id="controls" className="row controls justify-content-around border-bottom">
              <div className="col-3 d-flex">
                <button
                  className="btn btn-link m-0 p-0"
                  style={{color: "black"}}
                  onClick={datesState.isWeekRange ? subOneWeek : subOneDay}
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </button>
                <span className="mx-2 my-auto">
                  {moment(startDate).format("MM/DD/YYYY").toString()}
                  {datesState.isWeekRange && (<> - {moment(endDate).format("MM/DD/YYYY").toString()} </>
                  )}
                </span>
                <button
                  className="btn btn-link m-0 p-0"
                  style={{color: "black"}}
                  onClick={datesState.isWeekRange ? addOneWeek : addOneDay}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
                <button
                  onClick={reloadTable}
                  className="btn btn-outline-secondary mx-1 my-2"
                >
                  <span className="mx-1 my-auto"><FontAwesomeIcon icon={faRedoAlt} /></span>
                </button>
              </div>
              <div className="col-3 d-flex justify-content-between">
                <div className="d-flex mr-3 ">
                  <button
                    className="btn btn-link m-0 p-0"
                    style={{
                      color: "black",
                    }}
                    onClick={datesState.isWeekRange ? subOneWeek : subOneDay}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </button>
                  <button
                    onClick={goToToday}
                    className="btn btn-outline-secondary mx-1 my-2"
                  >
                    <span className="mx-2 my-auto">TODAY</span>
                  </button>
                  <button
                    className="btn btn-link m-0 p-0"
                    style={{
                      color: "black",
                    }}
                    onClick={datesState.isWeekRange ? addOneWeek : addOneDay}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  </button>
                </div>

                <button
                  className={
                    datesState.isWeekRange
                      ? " btn btn-outline-secondary mr-3 my-2"
                      : "btn btn-secondary disabled mr-3 my-2"
                  }
                  onClick={() => {
                    if (
                      state.status === "loadingAppointments" ||
                      state.status === "refreshingAppointments" ||
                      sidebarState.requesting === "REQUESTING" ||
                      sidebarState.requesting === "REQUESTING-DND"
                    ) {
                      return;
                    }
                    setDateRange("day");
                  }}
                >
                  Day
                </button>
                <button
                  className={
                    datesState.isWeekRange
                      ? "btn btn-secondary disabled mr-3 my-2"
                      : " btn btn-outline-secondary mr-3 my-2"
                  }
                  onClick={() => {
                    if (
                      state.status === "loadingAppointments" ||
                      state.status === "refreshingAppointments" ||
                      sidebarState.requesting === "REQUESTING" ||
                      sidebarState.requesting === "REQUESTING-DND"
                    ) {
                      return;
                    }
                    setDateRange("week");
                  }}
                >
                  Week
                </button>
              </div>
              <div className="col-4 my-auto">
                { /* AQUI */}
                { /* AQUI */}
                <div className="input-group input-group-sm">
                  <input
                    type="text"
                    onChange={handleSearchClientInput}
                    className="form-control"
                    placeholder="Search a client"
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => searchClientVisits(null)}
                  >
                    {clientVisits.requesting === "REQUESTING" && (
                      <FontAwesomeIcon spin icon={faSpinner} />
                    )}
                    {clientVisits.requesting !== "REQUESTING" && (
                      <FontAwesomeIcon icon={faSearch} />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div id="appointmentsTable" className="row mt-2 appointmentsTable">
              <div className="col">
                {state.status === "refreshingAppointments" &&
                  !datesState.isWeekRange && (
                    <>
                      <div className="row roomsRow">
                        <div className="col d-flex">
                          <button
                            key={"ALL"}
                            className={
                              state.staffId === "ALL"
                                ? "btn btn-secondary disabled mr-3 my-2"
                                : " btn btn-outline-secondary mr-3 my-2"
                            }
                          >
                            ALL
                          </button>
                          {rooms.map((room) => {
                            return (
                              <button
                                key={room.staffId}
                                className={
                                  state.staffId === room.staffId
                                    ? "btn btn-secondary disabled mr-3 my-2"
                                    : " btn btn-outline-secondary mr-3 my-2"
                                }
                              >
                                {room.staffName}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      {confirmationAction.response !== "success" && (state.status === "refreshingAppointments" && (
                        <div id="overlay" className="">
                          <div className="lb-spinner" />
                        </div>
                      ))}
                      <div className="row tableRow">
                        <div className="lb-w-10 pr-0 pl-1 text-center times-segment">
                          <ul className="list-group">
                            <li className="list-group-item lb-available-block">
                              Name
                            </li>
                            {displayableBlocks.blocks.map(
                              ({ id, segment }, index) => {
                                return (
                                  <li
                                    key={"block-" + id}
                                    className="list-group-item"
                                  >
                                    <b>{segment}</b>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>

                        <div className="lb-w-90 d-flex appointment-segment">
                          {state.displayAll && (
                            <>
                              {blocksPerRoom.map((roomAppointments) => {
                                return (
                                  <div
                                    className={
                                      "lb-w-" +
                                      Math.trunc(100 / blocksPerRoom.length)
                                    }
                                    key={roomAppointments.staffId}
                                  >
                                    <div className="list-group-item lb-available-block">
                                      <span>{roomAppointments.staffName}</span>
                                    </div>
                                    {roomAppointments.blocks.map(
                                      (
                                        {
                                          id,
                                          appointment,
                                          available,
                                          segment,
                                          blockDate,
                                          description,
                                          endSegment,
                                        },
                                        index
                                      ) => {
                                        return (
                                          <div
                                            key={"" + id}
                                            className={getBlockClass(
                                              available,
                                              appointment,
                                              segment,
                                              roomAppointments.blocks,
                                              index,
                                              true,
                                            )}
                                          >
                                            {appointment.sessionTypeName !==
                                              undefined && (
                                                <div className="text-truncate">
                                                  {appointment.name +
                                                    ": " +
                                                    appointment.sessionTypeName}
                                                </div>
                                              )}
                                            {appointment.sessionTypeName ===
                                              undefined && (
                                                <>
                                                  <div>
                                                    {available && <> &nbsp; </>}
                                                    {!available && (
                                                      <>
                                                        {" "}
                                                        {"" +
                                                          getBlockDescription(
                                                            available,
                                                            roomAppointments.blocks,
                                                            index
                                                          )}{" "}
                                                      </>
                                                    )}
                                                  </div>
                                                </>
                                              )}{" "}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          )}
                          {!state.displayAll && (
                            <div className="lb-w-100">
                              <div className="list-group-item lb-available-block">
                                <span>{displayableBlocks.staffName}</span>
                              </div>
                              {displayableBlocks.blocks.map(
                                (
                                  {
                                    id,
                                    appointment,
                                    available,
                                    segment,
                                    blockDate,
                                    description,
                                    endSegment,
                                  },
                                  index
                                ) => {
                                  return (
                                    <div
                                      key={"" + id}
                                      className={getBlockClass(
                                        available,
                                        appointment,
                                        segment,
                                        displayableBlocks.blocks,
                                        index,
                                        true
                                      )}
                                    >
                                      {appointment.sessionTypeName !==
                                        undefined && (
                                          <div className="text-truncate">
                                            {appointment.name +
                                              ": " +
                                              appointment.sessionTypeName}
                                          </div>
                                        )}
                                      {appointment.sessionTypeName ===
                                        undefined && <div> &nbsp; </div>}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )
                }

                {state.status === "refreshingAppointments" &&
                  datesState.isWeekRange && (
                    <>
                      <div className="row roomsRow">
                        <div className="col d-flex">
                          {rooms.map((room) => {
                            return (
                              <button
                                key={room.staffId}
                                className={
                                  state.staffId === room.staffId
                                    ? "btn btn-secondary disabled mr-3 my-2"
                                    : " btn btn-outline-secondary mr-3 my-2"
                                }
                              >
                                {room.staffName}
                              </button>
                            );
                          })}
                        </div>
                      </div>

                      <div className="row tableRow">
                        {confirmationAction.response !== "success" && (state.status === "refreshingAppointments" && (
                          <div id="overlay" className="">
                            <div className="lb-spinner" />
                          </div>
                        ))}

                        <div className="lb-w-10 pr-0 pl-1 text-center times-segment">
                          <ul className="list-group">
                            <li key={"block-" + 0} className="list-group-item">
                              <b>DAY</b>
                            </li>
                            {blocks.map(({ id, segment }, index) => {
                              return (
                                <li
                                  key={"block-" + id}
                                  className="list-group-item"
                                >
                                  <b>{segment}</b>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <>
                          <div className="lb-w-90 d-flex appointment-segment">
                            {state.status === "refreshingAppointments" &&
                              datesState.isWeekRange && (
                                <>
                                  {displayableBlocks.blocksPerDay.map(
                                    (roomAppointments, dayIndex) => {
                                      return (
                                        <div
                                          className="lb-w-14 px-0"
                                          key={roomAppointments.index}
                                        >
                                          <div className="list-group-item">
                                            <b>
                                              {moment(roomAppointments.date)
                                                .format("dddd")
                                                .toString()}
                                            </b>
                                          </div>
                                          {roomAppointments.blocks.map(
                                            (
                                              {
                                                id,
                                                appointment,
                                                available,
                                                segment,
                                                blockDate,
                                                description,
                                                endSegment,
                                              },
                                              index
                                            ) => {
                                              return (
                                                <div
                                                  key={"" + id}
                                                  className={getBlockClass(
                                                    available,
                                                    appointment,
                                                    segment,
                                                    roomAppointments.blocks,
                                                    index,
                                                    true
                                                  )}
                                                >
                                                  {appointment.sessionTypeName !==
                                                    undefined && (
                                                      <div className="text-truncate">
                                                        {appointment.name +
                                                          ": " +
                                                          appointment.sessionTypeName}
                                                      </div>
                                                    )}
                                                  {appointment.sessionTypeName ===
                                                    undefined && (
                                                      <>
                                                        <div>
                                                          {available && (
                                                            <>&nbsp;</>
                                                          )}
                                                          {!available && (
                                                            <>
                                                              {"" +
                                                                getBlockDescription(
                                                                  available,
                                                                  roomAppointments.blocks,
                                                                  index
                                                                )}
                                                            </>
                                                          )}
                                                        </div>
                                                      </>
                                                    )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                          </div>
                        </>
                      </div>
                    </>
                  )
                }

                {state.status === "displayAppointments" &&
                  !datesState.isWeekRange && (
                    <>
                      <div className="row roomsRow">
                        <div className="col d-flex">
                          <button
                            key={"ALL"}
                            className={
                              state.staffId === "ALL"
                                ? "btn btn-secondary disabled mr-3 my-2"
                                : " btn btn-outline-secondary mr-3 my-2"
                            }
                            onClick={() => handleRoomChange("ALL")}
                          >
                            ALL
                          </button>
                          {rooms.map((room) => {
                            return (
                              <button
                                key={room.staffId}
                                className={
                                  state.staffId === room.staffId
                                    ? "btn btn-secondary disabled mr-3 my-2"
                                    : " btn btn-outline-secondary mr-3 my-2"
                                }
                                onClick={() => handleRoomChange(room.staffId)}
                              >
                                {room.staffName}
                              </button>
                            );
                          })}
                        </div>
                      </div>

                      {confirmationAction.response !== "success" && (state.status === "refreshingAppointments" && (
                        <div id="overlay" className="">
                          <div className="lb-spinner" />
                        </div>
                      ))}

                      <div className="row tableRow">
                        <div className="lb-w-10 pr-0 pl-1 text-center times-segment">
                          <ul className="list-group">
                            <li className="list-group-item lb-available-block">
                              Name
                            </li>
                            {displayableBlocks.blocks.map(
                              ({ id, segment }, index) => {
                                return (
                                  <li
                                    key={"block-" + id}
                                    className="list-group-item lb-time-col"
                                  >
                                    <b>{segment}</b>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>

                        <div className="lb-w-90 d-flex appointment-segment">
                          {state.displayAll && (
                            <DndProvider backend={HTML5Backend}>
                              {blocksPerRoom.map((roomAppointments) => {
                                return (
                                  <div className={"lb-w-" + Math.trunc(100 / blocksPerRoom.length)} key={roomAppointments.staffId} >
                                    <div className="list-group-item lb-available-block">
                                      <span>{roomAppointments.staffName}</span>
                                    </div>
                                    {roomAppointments.blocks.map(
                                      ({id, appointment, appointments, available, segment, blockDate, type, endSegment, occupied}, index) => {
                                        return (
                                          <div 
                                            key={"" + id}
                                            className={(occupied && type !== "DNA") ?
                                              getBlockClass(available,appointment,roomAppointments.blocks,index,id,roomAppointments.staffId,undefined,true)
                                              : (occupied && type === "DNA") ? "d-flex ": ""}
                                          >
                                            {!occupied && (
                                              <EmptyBlock
                                                getBlockClass={getBlockClass}
                                                handleClick={handleClick}
                                                setHoverIndex={setHoverIndex}
                                                handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                                getBlockDescription={getBlockDescription}
                                                handleClose={handleClose}
                                                handleSidebarChange={handleSidebarChange}
                                                setConfirmationAction={setConfirmationAction}
                                                confirmAppointment={confirmAppointment}
                                                viewLastVisit={viewLastVisit}
                                                id={id}
                                                appointment={appointment}
                                                appointments={appointments}
                                                available={available}
                                                segment={segment}
                                                blockDate={blockDate}
                                                type={type}
                                                endSegment={endSegment}
                                                occupied={occupied}
                                                index={index}
                                                state={state}
                                                datesState={datesState}
                                                roomAppointments={roomAppointments}
                                                hoverIndex={hoverIndex}
                                                textResponse={textResponse}
                                                startDate={startDate}
                                                roomIndex={undefined}
                                                highlight={true}
                                                onClickArgument={"" +(appointment.id === undefined? id +"" +roomAppointments.staffId: appointment.id)}
                                              />
                                            )}
                                            {(occupied && type === "DNA") && (
                                              <DnaBlock
                                                getBlockClass={getBlockClass}
                                                handleClick={handleClick}
                                                setHoverIndex={setHoverIndex}
                                                handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                                getBlockDescription={getBlockDescription}
                                                handleClose={handleClose}
                                                handleSidebarChange={handleSidebarChange}
                                                setConfirmationAction={setConfirmationAction}
                                                confirmAppointment={confirmAppointment}
                                                viewLastVisit={viewLastVisit}
                                                id={id}
                                                appointment={appointment}
                                                appointments={appointments}
                                                available={available}
                                                segment={segment}
                                                blockDate={blockDate}
                                                type={type}
                                                endSegment={endSegment}
                                                occupied={occupied}
                                                index={index}
                                                state={state}
                                                datesState={datesState}
                                                roomAppointments={roomAppointments}
                                                hoverIndex={hoverIndex}
                                                textResponse={textResponse}
                                                startDate={startDate}
                                                roomIndex={undefined}
                                                highlight={false}
                                                searchClientVisits={searchClientVisits}
                                              />
                                            )}
                                            {(occupied && type !== "DNA") && (
                                              <DefaultBlock
                                                getBlockClass={getBlockClass}
                                                handleClick={handleClick}
                                                setHoverIndex={setHoverIndex}
                                                handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                                getBlockDescription={getBlockDescription}
                                                handleClose={handleClose}
                                                handleSidebarChange={handleSidebarChange}
                                                setConfirmationAction={setConfirmationAction}
                                                confirmAppointment={confirmAppointment}
                                                viewLastVisit={viewLastVisit}
                                                id={id}
                                                appointment={appointment}
                                                appointments={appointments}
                                                available={available}
                                                segment={segment}
                                                blockDate={blockDate}
                                                type={type}
                                                endSegment={endSegment}
                                                occupied={occupied}
                                                index={index}
                                                state={state}
                                                datesState={datesState}
                                                roomAppointments={roomAppointments}
                                                hoverIndex={hoverIndex}
                                                textResponse={textResponse}
                                                startDate={startDate}
                                                roomIndex={undefined}
                                                highlight={false}
                                                onClickArgument={"" +(appointment.id === undefined? id +"" +roomAppointments.staffId: appointment.id)}
                                                searchClientVisits={searchClientVisits}
                                              />
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              })}
                            </DndProvider>
                          )}
                          {!state.displayAll && (
                            <DndProvider backend={HTML5Backend}>
                              <div className="lb-w-100">
                                <div className="list-group-item lb-available-block">
                                  <span>{displayableBlocks.staffName}</span>
                                </div>
                                {displayableBlocks.blocks.map(
                                  ({id,appointment,appointments,available,segment,blockDate,type,description,endSegment,occupied},index) => {
                                    return (
                                      <div 
                                        key={"" + id}
                                        className={(occupied && type !== "DNA") ?
                                          getBlockClass(available,appointment,displayableBlocks.blocks,index,id,displayableBlocks.staffId,undefined,true)
                                          : (occupied && type === "DNA") ? "d-flex ": ""}
                                      >
                                        {!occupied && (
                                          <EmptyBlock
                                            getBlockClass={getBlockClass}
                                            handleClick={handleClick}
                                            setHoverIndex={setHoverIndex}
                                            handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                            getBlockDescription={getBlockDescription}
                                            handleClose={handleClose}
                                            handleSidebarChange={handleSidebarChange}
                                            setConfirmationAction={setConfirmationAction}
                                            confirmAppointment={confirmAppointment}
                                            viewLastVisit={viewLastVisit}
                                            id={id}
                                            appointment={appointment}
                                            appointments={appointments}
                                            available={available}
                                            segment={segment}
                                            blockDate={blockDate}
                                            type={type}
                                            endSegment={endSegment}
                                            occupied={occupied}
                                            index={index}
                                            state={state}
                                            datesState={datesState}
                                            roomAppointments={displayableBlocks}
                                            hoverIndex={hoverIndex}
                                            textResponse={textResponse}
                                            startDate={startDate}
                                            roomIndex={undefined}
                                            highlight={true}
                                            onClickArgument={"" +(appointment.id === undefined? id +"" +displayableBlocks.staffId: appointment.id)}
                                          />
                                        )}
                                        {(occupied && type === "DNA") && (
                                          <DnaBlock
                                            getBlockClass={getBlockClass}
                                            handleClick={handleClick}
                                            setHoverIndex={setHoverIndex}
                                            handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                            getBlockDescription={getBlockDescription}
                                            handleClose={handleClose}
                                            handleSidebarChange={handleSidebarChange}
                                            setConfirmationAction={setConfirmationAction}
                                            confirmAppointment={confirmAppointment}
                                            viewLastVisit={viewLastVisit}
                                            id={id}
                                            appointment={appointment}
                                            appointments={appointments}
                                            available={available}
                                            segment={segment}
                                            blockDate={blockDate}
                                            type={type}
                                            endSegment={endSegment}
                                            occupied={occupied}
                                            index={index}
                                            state={state}
                                            datesState={datesState}
                                            roomAppointments={displayableBlocks}
                                            hoverIndex={hoverIndex}
                                            textResponse={textResponse}
                                            startDate={startDate}
                                            roomIndex={undefined}
                                            highlight={false}
                                            searchClientVisits={searchClientVisits}
                                          />
                                        )}
                                        {(occupied && type !== "DNA") && (
                                          <DefaultBlock
                                            getBlockClass={getBlockClass}
                                            handleClick={handleClick}
                                            setHoverIndex={setHoverIndex}
                                            handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                            getBlockDescription={getBlockDescription}
                                            handleClose={handleClose}
                                            handleSidebarChange={handleSidebarChange}
                                            setConfirmationAction={setConfirmationAction}
                                            confirmAppointment={confirmAppointment}
                                            viewLastVisit={viewLastVisit}
                                            id={id}
                                            appointment={appointment}
                                            appointments={appointments}
                                            available={available}
                                            segment={segment}
                                            blockDate={blockDate}
                                            type={type}
                                            endSegment={endSegment}
                                            occupied={occupied}
                                            index={index}
                                            state={state}
                                            datesState={datesState}
                                            roomAppointments={displayableBlocks}
                                            hoverIndex={hoverIndex}
                                            textResponse={textResponse}
                                            startDate={startDate}
                                            roomIndex={undefined}
                                            highlight={false}
                                            onClickArgument={"" +(appointment.id === undefined? id +"" +displayableBlocks.staffId: appointment.id)}
                                            searchClientVisits={searchClientVisits}
                                          />
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </DndProvider>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                {state.status === "displayAppointments" &&
                  datesState.isWeekRange && (
                    <>
                      <div className="row roomsRow">
                        <div className="col d-flex">
                          {rooms.map((room) => {
                            return (
                              <button
                                key={room.staffId}
                                className={
                                  state.staffId === room.staffId
                                    ? "btn btn-secondary disabled mr-3 my-2"
                                    : " btn btn-outline-secondary mr-3 my-2"
                                }
                                onClick={() =>
                                  handleRoomChangeWeek(room.staffId)
                                }
                              >
                                {room.staffName}
                              </button>
                            );
                          })}
                        </div>
                      </div>

                      <div className="row tableRow">
                        {confirmationAction.response !== "success" && (state.status === "refreshingAppointments" && (
                          <div id="overlay" className="">
                            <div className="lb-spinner" />
                          </div>
                        ))}

                        <div className="lb-w-10 pr-0 pl-1 text-center times-segment">
                          <ul className="list-group">
                            <li key={"block-" + 0} className="list-group-item">
                              <b>DAY</b>
                            </li>
                            {blocks.map(({ id, segment }, index) => {
                              return (
                                <li
                                  key={"block-" + id}
                                  className="list-group-item"
                                >
                                  <b>{segment}</b>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <DndProvider backend={HTML5Backend}>
                          <div className="lb-w-90 d-flex appointment-segment">
                            {(state.status === "displayAppointments" || state.status === "refreshingAppointments") &&
                              datesState.isWeekRange && (
                                <>
                                  {displayableBlocks.blocksPerDay.map(
                                    (roomAppointments, dayIndex) => {
                                      return (
                                        <div
                                          className="lb-w-14 px-0"
                                          key={roomAppointments.index}
                                        >
                                          <div className="list-group-item">
                                            <b>{moment(roomAppointments.date).format("dddd").toString()}</b>
                                          </div>
                                          {roomAppointments.blocks.map(
                                            ({id,appointment,appointments,available,segment,blockDate,type,description,endSegment,occupied},index) => {
                                              return (
                                                <div
                                                  key={"" + id}
                                                  className={(occupied && type !== "DNA") ?
                                                  getBlockClass(available,appointment,roomAppointments.blocks,index,id,displayableBlocks.staffId,roomAppointments.index,true)
                                                  : (occupied && type === "DNA") ? "d-flex ": ""}
                                                >
                                                  {(occupied && type !== "DNA") && (
                                                    <DefaultBlockW
                                                      getBlockClass={getBlockClass}
                                                      handleClick={handleClick}
                                                      setHoverIndex={setHoverIndex}
                                                      handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                                      getBlockDescription={getBlockDescription}
                                                      handleClose={handleClose}
                                                      handleSidebarChangeWeek={handleSidebarChangeWeek}
                                                      setConfirmationAction={setConfirmationAction}
                                                      confirmAppointment={confirmAppointment}
                                                      viewLastVisit={viewLastVisit}
                                                      id={id}
                                                      appointment={appointment}
                                                      appointments={appointments}
                                                      available={available}
                                                      segment={segment}
                                                      blockDate={blockDate}
                                                      type={type}
                                                      endSegment={endSegment}
                                                      occupied={occupied}
                                                      index={index}
                                                      state={state}
                                                      datesState={datesState}
                                                      dayIndex={dayIndex}
                                                      displayableBlocks={displayableBlocks}
                                                      roomAppointments={roomAppointments}
                                                      hoverIndex={hoverIndex}
                                                      textResponse={textResponse}
                                                      startDate={startDate}
                                                      roomIndex={undefined}
                                                      highlight={false}
                                                      onClickArgument={"" +(appointment.id === undefined? id +"" +displayableBlocks.staffId: appointment.id)}
                                                    />
                                                  )}
                                                  {(occupied && type === "DNA") && (
                                                    <DnaBlockW
                                                      getBlockClass={getBlockClass}
                                                      handleClick={handleClick}
                                                      setHoverIndex={setHoverIndex}
                                                      handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                                      getBlockDescription={getBlockDescription}
                                                      handleClose={handleClose}
                                                      handleSidebarChangeWeek={handleSidebarChangeWeek}
                                                      setConfirmationAction={setConfirmationAction}
                                                      confirmAppointment={confirmAppointment}
                                                      viewLastVisit={viewLastVisit}
                                                      id={id}
                                                      appointment={appointment}
                                                      appointments={appointments}
                                                      available={available}
                                                      segment={segment}
                                                      blockDate={blockDate}
                                                      type={type}
                                                      endSegment={endSegment}
                                                      occupied={occupied}
                                                      index={index}
                                                      state={state}
                                                      datesState={datesState}
                                                      dayIndex={dayIndex}
                                                      displayableBlocks={displayableBlocks}
                                                      roomAppointments={roomAppointments}
                                                      hoverIndex={hoverIndex}
                                                      textResponse={textResponse}
                                                      startDate={startDate}
                                                      roomIndex={undefined}
                                                      highlight={false}
                                                      onClickArgument={"" +(appointment.id === undefined? id +"" +displayableBlocks.staffId: appointment.id)}
                                                    />
                                                  )}
                                                  {!occupied && (
                                                    <EmptyBlockW
                                                      getBlockClass={getBlockClass}
                                                      handleClick={handleClick}
                                                      setHoverIndex={setHoverIndex}
                                                      handleChangeBlockOnDrop={handleChangeBlockOnDrop}
                                                      getBlockDescription={getBlockDescription}
                                                      handleClose={handleClose}
                                                      handleSidebarChangeWeek={handleSidebarChangeWeek}
                                                      setConfirmationAction={setConfirmationAction}
                                                      confirmAppointment={confirmAppointment}
                                                      viewLastVisit={viewLastVisit}
                                                      id={id}
                                                      appointment={appointment}
                                                      appointments={appointments}
                                                      available={available}
                                                      segment={segment}
                                                      blockDate={blockDate}
                                                      type={type}
                                                      endSegment={endSegment}
                                                      occupied={occupied}
                                                      index={index}
                                                      state={state}
                                                      datesState={datesState}
                                                      dayIndex={dayIndex}
                                                      displayableBlocks={displayableBlocks}
                                                      roomAppointments={roomAppointments}
                                                      hoverIndex={hoverIndex}
                                                      textResponse={textResponse}
                                                      startDate={startDate}
                                                      roomIndex={undefined}
                                                      highlight={false}
                                                      onClickArgument={"" +(appointment.id === undefined? id +"" +displayableBlocks.staffId: appointment.id)}
                                                    />
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                          </div>
                        </DndProvider>
                      </div>
                    </>
                  )}

                {state.status === "IDLE" && (
                  <h3 className="h1 mt-3 text-center">
                    Please select a location
                  </h3>
                )}

                {state.status === "loadingAppointments" && (
                  <div className="loading mt-5" data-cy="loading-message">
                    <div className="lb-spinner" />
                  </div>
                )}

                {(state.status === "on-load-error" ||
                  state.status === "no-blocks-found") && (
                    <div className="d-block mx-4 alert alert-danger">
                      <span> {state.message} </span>
                    </div>
                  )}
              </div>
            </div>
          </section>

          {clientVisits.status === "SHOW" && (
            <Dialog
              fullScreen
              open={clientVisits.status === "SHOW"}
              onClose={handleDialogClose}
              TransitionComponent={Transition}
            >
              <AppBar className={`${classes.appBar}`}>
                <Toolbar>
                  <Typography variant="h5" className={classes.title}>
                    Visits History
                  </Typography>
                  <IconButton edge="end" color="inherit" onClick={handleDialogClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>


              <VisitsTable visits={clientVisits.visits} client={clientVisits.client} />
            </Dialog>
          )}
        </div>
      </div>
    </div>
  );
}

BookingPage.propTypes = {
  appState: PropTypes.object.isRequired,
  setAppState: PropTypes.func.isRequired,
};
export default BookingPage;
