import React from "react";
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  faCheck,
  faBug,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";

function ConfirmationAction({ confirmationAction, setConfirmationAction }) {

  const handleConfirmationAction = () => {
    setConfirmationAction((confirmationAction) => ({
      ...confirmationAction,
      action: true,
    }));
  }

  const handleCleanData = () => {
    setConfirmationAction((confirmationAction) => ({
      ...confirmationAction,
      action: false,
      data: "",
      infoBody: "",
      infoTittle: "",
      nameAction: "",
      response: "",
      text: "",
      info: false,
    }));
  }
  return (
    <>
      <div className="modal fade" id="modalConfirmationAction" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-body">

              {confirmationAction.info && (
                <div className="accordion accordion-flush mb-3" id="accordionFlushExample">
                  <div className="accordion-item">

                    <p className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <b style={{ fontSize: "19px" }}>{confirmationAction.infoTittle}</b>
                      </button>
                    </p>

                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">

                        {confirmationAction.infoBody}

                      </div>
                    </div>

                  </div>
                </div>
              )}

              <div className="col-12">
                <div className="d-flex align-items-center justify-content-center">
                  <div className={
                    confirmationAction.response === "success" ?
                      "border border-success border-5 rounded-circle p-5" :
                      confirmationAction.response === "error" ?
                        "border border-danger border-5 rounded-circle p-5" :
                        "border border-5 rounded-circle p-5"}>

                    {confirmationAction.response === "default" && (<FontAwesomeIcon icon={faQuestion} style={{ fontSize: "55" }} />)}
                    {confirmationAction.response === "success" && (<FontAwesomeIcon icon={faCheck} style={{ fontSize: "55" }} />)}
                    {confirmationAction.response === "error" && (<FontAwesomeIcon icon={faBug} style={{ fontSize: "55" }} />)}
                    {confirmationAction.response === "process" && (<FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: "55" }} />)}

                  </div>
                </div>
              </div>

              <p className="modal-title mt-3 mb-3 text-center fs-4" id="exampleModalLabel">
                {confirmationAction.text}
              </p>

              <div className="col-12 pb-4 pt-4">
                <div className="d-flex align-items-center justify-content-center">

                  {confirmationAction.response !== "success" && (
                    confirmationAction.response !== "error" && (
                      <>
                        <button type="button"
                          onClick={handleCleanData}
                          className="lb-button-daily-disabled px-5 mr-5" id="btn-close" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        <button type="button" className={
                          (confirmationAction.action || confirmationAction.response === "error" || confirmationAction.response === "success") ?
                            "lb-button-daily-disabled px-5" :
                            "lb-button-daily px-5"
                        }
                          disabled={confirmationAction.action || confirmationAction.response === "error" || confirmationAction.response === "success"}
                          onClick={handleConfirmationAction}
                          data-cy="btn-confirmation-action-yes"
                          >
                          Yes
                        </button>
                      </>
                    ))}

                  {(confirmationAction.response === "success" || confirmationAction.response === "error") && (
                    <button type="button"
                      onClick={handleCleanData}
                      className="lb-button-daily text-center pr-5 pl-5" 
                      id="btn-close" 
                      data-bs-dismiss="modal" 
                      aria-label="Close"
                      data-cy="btn-confirmation-action-ok"
                      >
                      Ok
                      </button>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-white"
        id="modalOnDrop"
        data-bs-toggle="modal"
        data-bs-target="#modalConfirmationAction"
      />
    </>
  )

};

ConfirmationAction.propTypes = {
  confirmationAction: PropTypes.object.isRequired,
  setConfirmationAction: PropTypes.func.isRequired,
};

export default ConfirmationAction;