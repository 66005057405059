import React from "react";

function LogsDashboardPage() {

  return (
    <div className="content">
      <div className="container mt-3">
        <div className="row ">
          <div className="col">
            <iframe
              width="960"
              height="720"
              src="https://us-east-2.quicksight.aws.amazon.com/sn/embed/share/accounts/263333368682/dashboards/045aea6c-69fd-4354-a211-d49c5d013b32"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogsDashboardPage;
