import React from "react";
import PropTypes from "prop-types";
import DroppableBlock from "./droppableBlock";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

function EmptyBlockW({
  getBlockClass,
  handleClick,
  setHoverIndex,
  handleChangeBlockOnDrop,
  getBlockDescription,
  handleClose,
  handleSidebarChangeWeek,
  setConfirmationAction,
  confirmAppointment,
  viewLastVisit,
  id,
  appointment,
  appointments,
  available,
  segment,
  blockDate,
  type,
  endSegment,
  occupied,
  index,
  state,
  datesState,
  dayIndex,
  displayableBlocks,
  roomAppointments,
  hoverIndex,
  textResponse,
  startDate,
  roomIndex,
  highlight,
  onClickArgument,
}) {
  return (
    <>
      <div
        className={getBlockClass(available,appointment,roomAppointments.blocks,index,id,displayableBlocks.staffId,roomAppointments.index,false)}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick( "" + (appointment.id === undefined ? index + "" + dayIndex + "" + displayableBlocks.staffId : appointment.id))}
        onMouseOver={() => { setHoverIndex( "" + id + appointment.id );}}
        onMouseLeave={() => setHoverIndex("")}
      >
        {available && (
          <DroppableBlock
            block={{
              blockDate: blockDate,
              segment: segment,
              staffId: displayableBlocks.staffId,
              authorization: state.authorization,
              siteId: state.siteId,
              locationId: state.locationId,
              isWeekRange: datesState.isWeekRange,
            }}
            updateFunction={handleChangeBlockOnDrop}
          />
        )}
        {!available && (
          <>
            {"" + getBlockDescription(available,roomAppointments.blocks,index)}
          </>
        )}
      </div>
      {available && (
        <Menu
          open={
            state.mouseY !==
            null &&
            state.appointmentId ===
            index +
            "" +
            dayIndex +
            "" +
            displayableBlocks.staffId
          }
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            state.mouseY !==
              null &&
              state.mouseX !==
              null
              ? {
                top: state.mouseY,
                left: state.mouseX,
              }
              : undefined
          }
        >
          <MenuItem
            onClick={() => {
              // clearSidebar();
              handleSidebarChangeWeek(
                segment,
                displayableBlocks.staffId,
                available
                  ? "searchOrCreateClient"
                  : "restricted",
                blockDate,
                null,
                id,
                roomAppointments.index
              );
            }
            }
          >
            Book appointment
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

EmptyBlockW.propTypes = {
  getBlockClass: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  setHoverIndex: PropTypes.func.isRequired,
  handleChangeBlockOnDrop: PropTypes.func.isRequired,
  getBlockDescription: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSidebarChangeWeek: PropTypes.func.isRequired,
  setConfirmationAction: PropTypes.func.isRequired,
  confirmAppointment: PropTypes.func.isRequired,
  viewLastVisit: PropTypes.func.isRequired,
  id: PropTypes.array.isRequired,
  appointment: PropTypes.object.isRequired,
  appointments: PropTypes.array.isRequired,
  available: PropTypes.bool.isRequired,
  segment: PropTypes.string.isRequired,
  blockDate: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  endSegment: PropTypes.string.isRequired,
  occupied: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  datesState: PropTypes.object.isRequired,
  dayIndex: PropTypes.number.isRequired,
  displayableBlocks: PropTypes.object.isRequired,
  roomAppointments: PropTypes.object.isRequired,
  hoverIndex: PropTypes.string.isRequired,
  textResponse: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  roomIndex: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  highlight: PropTypes.bool.isRequired,
  onClickArgument: PropTypes.string,
};

export default EmptyBlockW;