import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DraggableBlock from "./draggableBlock";
import DroppableBlock from "./droppableBlock";
import HoverCard from "./hoverCard";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

function DnaBlock({
  getBlockClass,
  handleClick,
  setHoverIndex,
  handleChangeBlockOnDrop,
  getBlockDescription,
  handleClose,
  handleSidebarChange,
  setConfirmationAction,
  confirmAppointment,
  viewLastVisit,
  id,
  appointment,
  appointments,
  available,
  segment,
  blockDate,
  type,
  endSegment,
  occupied,
  index,
  state,
  datesState,
  roomAppointments,
  hoverIndex,
  textResponse,
  startDate,
  roomIndex,
  highlight,
  searchClientVisits
}) {
  return (
    <>
      {appointments.map((appt)=>{
        return(
          <>
            {appt.sessionTypeName === undefined && (
              <>
              <div
                className={getBlockClass(available,appt,roomAppointments.blocks,index,id,roomAppointments.staffId,roomIndex,highlight)+ " dna-block"}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick("" +(appt.id === undefined ? id +"" +roomAppointments.staffId: appt.id))}
                onMouseOver={() => {setHoverIndex("" + id + appt.id);}}
                onMouseLeave={() =>setHoverIndex("")}
              >
                {available && (
                  <DroppableBlock
                    block={{
                      blockDate: blockDate,
                      segment: segment,
                      staffId: roomAppointments.staffId,
                      authorization: state.authorization,
                      siteId: state.siteId,
                      locationId: state.locationId,
                      isWeekRange: datesState.isWeekRange,
                    }}
                    updateFunction={handleChangeBlockOnDrop}
                  />
                )}
                {!available && (
                  <div className="text-truncate">
                    {`${getBlockDescription(available,roomAppointments.blocks,index)}`}
                  </div>
                )}
              </div>
              {available && (
                <Menu
                  open={state.mouseY !== null &&state.appointmentId ===id +"" +roomAppointments.staffId}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={state.mouseY !== null && state.mouseX !== null ? { top: state.mouseY, left: state.mouseX, } : undefined}
                >
                  <MenuItem
                    onClick={() => {handleSidebarChange(segment,roomAppointments.staffId,available ? "searchOrCreateClient" : "restricted",blockDate,null,id);}}
                  >
                    Book appointment
                  </MenuItem>
                </Menu>
              )}
            </>
            )}
            {appt.sessionTypeName !== undefined && (<>
            <div
              className={getBlockClass(available,appt,roomAppointments.blocks,index,id,roomAppointments.staffId,roomIndex,highlight) + " dna-block"}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick("" +(appt.id === undefined? id +"" +roomAppointments.staffId: appt.id))}
              onMouseOver={() => {setHoverIndex("" + id + appt.id);}}
              onMouseLeave={() =>setHoverIndex("")}
            >
              <DraggableBlock
                appointment={appt}
                isWeekRange={datesState.isWeekRange}
                displayAll={state.displayAll}
                blocks={roomAppointments.blocks}
                indice={index}
                type={type}
              />
              {hoverIndex ==="" + id + appt.id && (
                <HoverCard 
                  appointment={appt}
                  cardClass={getBlockClass(available,appt,roomAppointments.blocks,index,id,roomAppointments.staffId,roomIndex,highlight)} 
                  segment={segment}
                  endSegment={endSegment}
                />
              )}
            </div>

            <Menu
              open={state.mouseY !== null && state.appointmentId === "" + appt.id}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={state.mouseY !== null &&state.mouseX !== null? {top: state.mouseY,left: state.mouseX,}: undefined}
            >
              <MenuItem
                data-bs-toggle="modal"
                data-bs-target="#modalConfirmationAction"
                onClick={() => {
                  setConfirmationAction((confirmationAction) => ({
                    ...confirmationAction,
                    nameAction: "cancel",
                    text: textResponse.cancel,
                    data: appt,
                    response: "default",
                    info: true,
                    infoTittle: `${appt.firstName} ${appt.lastName}, ${appt.sessionTypeName}`,
                    infoBody:<>
                      <div className="mt-1">
                      <span className="pr-5"><b>Date:</b> {moment(startDate).format("YY-MM-DD")},&nbsp;
                      {moment(appt.StartDateTime).format("hh:mm A")} - {moment(appt.endDateTime).format("hh:mm A")}</span>
                      </div>
                      <div className="mt-2">
                      <span className="pr-5"><b>Phone:</b> {appt.phone}</span>
                      </div>
                      <div className="mt-2">
                      <span className="pr-5 "><b>Email:</b> {appt.email}</span>
                      </div>
                    </>
                  }))
                }}
              >
                Cancel
              </MenuItem>
              <MenuItem onClick={() => {confirmAppointment(appt.id);}}>
                Confirm
              </MenuItem>
              <MenuItem onClick={() => handleSidebarChange(segment,roomAppointments.staffId,"editAppointment",blockDate,appt,id)}>
                Edit / Reschedule
              </MenuItem>
              <MenuItem onClick={() => viewLastVisit(appt)}>
                Last Visit
              </MenuItem>
              <MenuItem
                onClick={() => {searchClientVisits(appt.clientId); handleClose()}}
              >
                Visits History
              </MenuItem>
            </Menu></>)}
          </>
        )
      })}
    </>
  );
}

DnaBlock.propTypes = {
  getBlockClass: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  setHoverIndex: PropTypes.func.isRequired,
  handleChangeBlockOnDrop: PropTypes.func.isRequired,
  getBlockDescription: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSidebarChange: PropTypes.func.isRequired,
  setConfirmationAction: PropTypes.func.isRequired,
  confirmAppointment: PropTypes.func.isRequired,
  viewLastVisit: PropTypes.func.isRequired,
  id: PropTypes.array.isRequired,
  appointment: PropTypes.object.isRequired,
  appointments: PropTypes.array.isRequired,
  available: PropTypes.bool.isRequired,
  segment: PropTypes.string.isRequired,
  blockDate: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  endSegment: PropTypes.string.isRequired,
  occupied: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  datesState: PropTypes.object.isRequired,
  roomAppointments: PropTypes.object.isRequired,
  hoverIndex: PropTypes.string.isRequired,
  textResponse: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  roomIndex: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  highlight: PropTypes.bool.isRequired,
  searchClientVisits: PropTypes.func.isRequired,
};

export default DnaBlock;
