import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import "../styles/bootstrap-grid.min.css";
import "../styles/global.css";
import "../styles/formScroll.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormUltraSound from "../components/formUltraSound";
import FormMassage from "../components/formMassage";
import FormDNA from "../components/formDNA";
import RequestOtpCode from "../components/requestOtpCode";
import VerifyOtpCode from "../components/verifyOtpCode";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "../App.css";
// import moment from "moment";
import logo from "../logo.png";
import publicIp from "public-ip";
import SignatureCanvas from "react-signature-canvas";
import { sitesList } from "../config/constants.js";
import GoGreenModal from "../components/modals/goGreenModal";

/**
 * Form page, clients are supposed to fill this form before this appointment can be completed
 * this component may recieve a state with the bypass2fa attribute depending on the way to
 * arrive to this page, this particular case is when the user is a logged in person that used
 * the "form dashboard" to get to this page.
 * @component
 */
function FormPage() {
  const params = useParams();
  const location = useLocation();

  const spanishWaiverContent = (
    <>
      <div>
        Little Bellies no es un proveedor de atención médica. Little Bellies
        entiende la importancia de una atención médica prenatal adecuada tanto
        para la futura madre como para el feto. Por lo tanto, para proporcionar
        a nuestros clientes una imagen de ultrasonido de &quot;recuerdo&quot;
        significativa, Little Bellies exige que yo: (i) certifique sinceramente
        que estoy bajo el cuidado de un médico u otro proveedor de atención
        médica para mi embarazo y que no estoy obteniendo este ultrasonido como
        reemplazo o en lugar de la atención médica prenatal estándar, y (ii)
        notificará a mi médico o proveedor de atención médica actual con
        respecto al ultrasonido de recuerdo que recibí de Pancitas.
        <br />
        Little Bellies me solicita que presente a Little Bellies un acuse de
        recibo de este aviso, firmado por mi médico o proveedor de salud, antes
        de que Little Bellies realice el ultrasonido de recuerdo. En el caso de
        que no pueda notificar a mi médico o proveedor de salud antes de la
        realización del ultrasonido de recuerdo, asumo la responsabilidad
        exclusiva de notificarlo tan pronto como sea práctico después de la
        realización del ultrasonido de recuerdo.
        <br />
        Como condición adicional para recibir los servicios de ultrasonido de
        Little Bellies, por la presente reconozco, comprendo y acepto las
        siguientes declaraciones.
        <br />
        <ul>
          <li>
            Este ultrasonido: (i) es un procedimiento electivo no médico que
            solicité voluntariamente y (ii) no pretende reemplazar el
            ultrasonido de diagnóstico o cualquier otra prueba o tratamiento que
            haya sido recomendado por mi médico. o proveedor de salud.
          </li>
          <li>
            Debido a su naturaleza optativa, no médica, este ultrasonido
            generalmente no está cubierto por el seguro. Por lo tanto, se
            requiere el pago por adelantado.
          </li>
          <li>
            El técnico que realiza el ultrasonido, aunque está calificado para
            brindar dichos servicios de ultrasonido, no es un médico, una
            enfermera o un proveedor de atención médica y no puede interpretar,
            diagnosticar condiciones médicas u ofrecer consejos médicos con
            respecto a las imágenes producidas.
            <b>
              {" "}
              Este ultrasonido no será leído ni interpretado por ningún médico,
              enfermera u otro proveedor de atención médica en ningún momento.
            </b>
          </li>
          <li>
            Este ultrasonido de recuerdo está diseñado para proporcionar
            imágenes mejoradas con el fin de ver el movimiento fetal en el
            útero. El técnico no hará ningún intento de garantizar un
            ultrasonido o un bienestar fetal que incluya una inclusión médica.
          </li>
          <li>
            Entiendo que soy responsable de contactar a mi propio proveedor de
            atención médica si tengo alguna pregunta relacionada con este
            ultrasonido de recuerdo o cualquier otro aspecto de mi embarazo.
          </li>
          <li>
            {" "}
            Entiendo que la calidad del ultrasonido de recuerdo y el DVD, u
            otros medios audiovisuales, dependen de muchos factores que
            incluyen: el contenido de tejido corporal, la etapa de desarrollo y
            la posición fetal.
          </li>
          <li>
            Entiendo que Pancitas no garantiza la calidad del DVD u otros medios
            audiovisuales, ni la capacidad de visualizar ninguna característica
            del feto.
          </li>
          <li>
            Entiendo que la publicación, presentación o distribución de
            cualquier video tomado durante la sesión de ultrasonido, no
            proporcionado por Pancitas,
            <b> está estrictamente prohibido. </b>
          </li>
          <li>
            Entiendo que todas las imágenes y videoclips tomados durante mi
            sesión pueden ser utilizados con fines promocionales por Pancitas.
          </li>
          <li>
            Acepto recibir encuestas por mensajes de texto y / o correo
            electrónico de Pancitas para mejorar el servicio proporcionado.
          </li>
          <li>
            Acepto recibir las imágenes y videos de mi sesión en mi correo
            electrónico y teléfono inteligente.
          </li>
          <li>Como lo demuestra mi firma a continuación,</li>
        </ul>
        Entiendo que los factores que están más allá del control de Little
        Bellies también pueden afectar la capacidad para determinar con
        precisión el género del feto, y que Little Bellies no puede proporcionar
        ninguna garantía o garantía en cuanto a la exactitud de dicha
        determinación.
        <br />
        Además, entiendo y acepto el riesgo de que, si bien se cree que el
        ultrasonido no tiene efectos perjudiciales para la madre o el feto,
        investigaciones futuras u otra información pueden revelar efectos
        dañinos o adversos que actualmente se desconocen. <br />
        EN CONSIDERACIÓN DE LOS SERVICIOS PRESTADOS, ACEPTO LIBERAR A LITTLE
        BELLIES, SUS AGENTES, AFILIADOS, MIEMBROS, ADMINISTRADORES Y EMPLEADOS
        DE CUALQUIER Y TODAS LAS RECLAMACIONES O CAUSAS DE ACCIONES POR
        LESIONES, ARMAS, DAÑOS O OTRAS RESPONSABILIDADES QUE SEAN DETENIDOS EN
        EL ÁREA DE PARTE, EL PACIENTE, EL ÓRGENERO, EL ÓRGENERO, EL ÓRGENERO DE
        LOS ESTADOS UNIDOS HAN RESULTADO DE ESTO, HACER ULTIMASISMO, INCLUYENDO
        PERO NO LIMITADO A, EL FALLO DE UN ULTRASONIDO DE LITTLE BELLIES PARA
        DETERMINAR EXACTAMENTE EL GÉNERO FETAL O DE OTRA CARACTERÍSTICA. <br />
        “He leído cuidadosamente este documento y al firmar en la parte
        inferior, reconozco que entiendo completamente y acepto su contenido.”
      </div>
    </>
  );

  const englishWaiverContent = (
    <>
      <div>
        Little Bellies is not a health care provider. Little Bellies understands
        the importance of proper prenatal medical care for both the expectant
        mother and the fetus. Therefore, in order to provide our clients with a
        meaningful “keepsake” ultrasound image, Little Bellies requires that I:
        (i) truthfully certify that I am under the care of a physician or other
        health care provider for my pregnancy and that I am not obtaining this
        ultrasound as a replacement for, or in lieu of, standard prenatal
        medical care, and (ii) notify my current physician or health care
        provider regarding the keepsake ultrasound I receive from Little
        Bellies.
        <br />
        Little Bellies requests that I present to Little Bellies an
        acknowledgement of receipt of this notice, signed by my physician or
        health provider, prior to Little Bellies’ performance of the keepsake
        ultrasound. In the event I am unable to notify my physician or health
        provider prior to performance of the keepsake ultrasound, I assume sole
        responsibility for notifying him or her as soon as practical following
        performance of the keepsake ultrasound.
        <br />
        As further condition to my receiving ultrasound services from Little
        Bellies, I hereby acknowledge, understand and agree to the following
        statements.
        <br />
        <ul>
          <li>
            This ultrasound: (i) is an elective non-medical procedure that I
            have voluntarily requested and (ii) is not intended to take the
            place of a diagnostic ultrasound or any other test or treatment that
            has been or may be recommended by my physician or healthcare
            provider.
          </li>
          <li>
            Because of its elective, non-medical nature, this ultrasound is
            generally not covered by insurance. Therefore, advance payment is
            required.
          </li>
          <li>
            The technician who performs the ultrasound, while qualified to
            provide such ultrasound services, is not a doctor, nurse, or
            healthcare provider and cannot interpret, diagnose medical
            conditions from, or otherwise offer medical advice regarding the
            images produced.
            <b>
              {" "}
              This ultrasound will not be read or interpreted by any physician,
              nurse or other healthcare provider at any time.
            </b>
          </li>
          <li>
            This keepsake ultrasound is intended to provide enhanced images for
            the purpose of my viewing fetal movement in utero. The technician
            will make no attempt to guarantee a medically inclusive ultrasound
            or fetal well being.
          </li>
          <li>
            I understand that I am responsible for contacting my own healthcare
            provider if I have any questions concerning this keepsake ultrasound
            or any other aspect of my pregnancy.
          </li>
          <li>
            {" "}
            I understand that the quality of the keepsake ultrasound and the
            DVD, or other audio visual media, depends upon many factors
            including: body tissue content, developmental stage and fetal
            position.
          </li>
          <li>
            I understand that Little Bellies does not guarantee the quality of
            the DVD, or other audio visual media, or the ability to visualize
            any characteristics of the fetus.
          </li>
          <li>
            I understand that publication, presentation or distribution of any
            video taken during the ultrasound session, not provided by Little
            Bellies, <b> is strictly prohibited. </b>
          </li>
          <li>
            I understand that all the images and video clips taken during my
            session can be used for promotional purposes by Little Bellies.
          </li>
          <li>
            I agree to receive E-mail and SMS from Little Bellies, as well as
            surveys, promotional offers and marketing.
          </li>
          <li>
            I accept to receive the images and videos of my session in my E-mail
            and Smartphone.
          </li>
          <li>As evidenced by my signature below,</li>
        </ul>
        I understand that factors beyond Little Bellies’ control may also affect
        the ability to accurately determine the gender of the fetus, and that
        Little Bellies can provide no warranty or guaranty as to the accuracy of
        any such determination. <br />
        I further understand and accept the risk that, while ultrasound is
        believed to have no harmful effect on the mother or the fetus, future
        research or other information may disclose harmful or adverse effects
        that are presently unknown. <br />
        IN CONSIDERATION OF THE SERVICES RENDERED, I AGREE TO RELEASE LITTLE
        BELLIES, ITS AGENTS, AFFILIATES, MEMBERS, MANAGERS AND EMPLOYEES FROM
        ANY AND ALL CLAIMS OR CAUSES OF ACTIONS FOR INJURY, HARM, DAMAGE, OR
        OTHER LIABILITY WHICH RESULTS FROM, OR ARE ALLEGED TO HAVE RESULTED
        FROM, THIS KEEPSAKE ULTRASOUND, INCLUDING BUT NOT LIMITED TO, THE
        FAILURE OF A LITTLE BELLIES ULTRASOUND TO ACCURATELY DETERMINE FETAL
        GENDER OR OTHER CHARACTERISTICS OR ANOMOLIES AND ANY DAMAGES OR INJURIES
        RESULTING FROM ULTRASOUND WHICH ARE NOT NOW KNOWN TO OCCUR. <br />
        “I have carefully read this document and by signing at the bottom,
        acknowledge that I fully understand and agree to its contents.”
      </div>
    </>
  );

  const englishWaiverContentDNA = (
    <>
      <div>
        Little Bellies is not a health care provider. Little Bellies recognizes
        the significance of appropriate prenatal medical care for both the
        expectant mother and the fetus. To provide our clients with a meaningful
        DNA gender test, Little Bellies requires that I: (i) truthfully certify
        that I am under the care of a physician or other health care provider
        for my pregnancy and that I am not obtaining this test as a replacement
        for, or in lieu of, standard prenatal medical care, and (ii) notify my
        current physician or health care provider regarding the DNA gender test
        I receive from Little Bellies.
        <br />
        Little Bellies requests that I present to Little Bellies an
        acknowledgement of receipt of this notice, signed by my physician or
        health provider, prior to Little Bellies’ performance of the DNA gender
        test. In the event I am unable to notify my physician or health provider
        prior to performance of the DNA gender test, I assume sole
        responsibility for notifying him or her as soon as practical following
        performance of the DNA gender test.
        <br />
        As a further condition to my receiving DNA gender test services from
        Little Bellies, I hereby acknowledge, understand and agree to the
        following statements.
        <br />
        <ul>
          <li>
            This test: (i) is an elective non-medical procedure that I have
            voluntarily requested and (ii) is not intended to take the place of
            a diagnostic DNA test or any other test or treatment that has been
            or may be recommended by my physician or healthcare provider.
          </li>
          <li>
            Because of its elective, non-medical nature, this test is generally
            not covered by insurance. Therefore, advance payment is required.
          </li>
          <li>
            The qualified person who performs the test, while qualified to
            provide such services, is not a doctor, nurse, or healthcare
            provider and cannot interpret, diagnose medical conditions from, or
            otherwise offer medical advice regarding the results produced.
            <b>
              This test will not be read or interpreted by any physician, nurse
              or other healthcare provider at any time.
            </b>
          </li>
          <li>
            This DNA gender test is intended to provide a prediction of the
            baby&apos;s gender. The technician will make no attempt to guarantee
            a medically inclusive test or fetal well being.
          </li>
          <li>
            I understand that I am responsible for contacting my own healthcare
            provider if I have any questions concerning this DNA gender test or
            any other aspect of my pregnancy.
          </li>
          <li>
            I understand that the accuracy of the DNA gender test results
            depends upon various factors including: gestational age, sample
            collection, and fetal DNA concentration. I understand that Little
            Bellies does not guarantee the accuracy of the test or the ability
            to predict the gender of the fetus.
          </li>
          <li>
            I understand that publication, presentation or distribution of any
            results from the DNA gender test, not provided by Little Bellies, is
            strictly prohibited.
          </li>
          <li>
            I understand that all the images and video clips taken during my
            session can be used for promotional purposes by Little Bellies.
          </li>
          <li>
            I agree to receive E-mail and SMS from Little Bellies, as well as
            surveys, promotional offers, and marketing.
          </li>
          <li>
            I accept to receive the results of my test in my E-mail and
            Smartphone.
          </li>
          <li>
            The DNA Gender Test will be analyzed by a third-party laboratory
            company. Little Bellies is not responsible for any errors in the
            results and is released from any liability arising from the test
            analysis or the results provided by the third-party laboratory. I
            understand that any discrepancies or concerns about the test results
            should be addressed directly with the laboratory or my healthcare
            provider.
          </li>
          <li>
            I acknowledge and understand that the blood extraction necessary for
            the DNA Gender Test will be carried out by a qualified phlebotomist.
            I agree that Little Bellies is not responsible or liable for any
            error, injury, or harm that may occur during or as a result of this
            procedure. By signing this waiver, I voluntarily release and forever
            discharge Little Bellies, its agents, affiliates, members, managers,
            and employees from any and all claims, lawsuits, damages, or causes
            of action, present or future, whether known or unknown, for any
            harm, injury, or damages that may occur due to the blood extraction.
          </li>
          <li>As evidenced by my signature below,</li>
        </ul>
        I understand that factors beyond Little Bellies’ control may also affect
        the ability to accurately predict the gender of the fetus, and that
        Little Bellies can provide no warranty or guaranty as to the accuracy of
        any such prediction. <br />
        I further understand and accept the risk that, while DNA gender testing
        is believed to have no harmful effect on the mother or the fetus, future
        research or other information may disclose harmful or adverse effects
        that are presently unknown.
        <br />
        IN CONSIDERATION OF THE SERVICES RENDERED, I AGREE TO RELEASE LITTLE
        BELLIES, ITS AGENTS, AFFILIATES, MEMBERS, MANAGERS AND EMPLOYEES FROM
        ANY AND ALL CLAIMS OR CAUSES OF ACTIONS FOR INJURY, HARM, DAMAGE, OR
        OTHER LIABILITY WHICH RESULTS FROM, OR ARE ALLEGED TO HAVE RESULTED
        FROM, THIS DNA GENDER TEST, INCLUDING BUT NOT LIMITED TO, THE FAILURE OF
        A LITTLE BELLIES TEST TO ACCURATELY DETERMINE FETAL GENDER OR OTHER
        CHARACTERISTICS OR ANOMALIES AND ANY DAMAGES OR INJURIES RESULTING FROM
        THE TEST WHICH ARE NOT NOW KNOWN TO OCCUR. <br />
        “I have carefully read this document and by signing at the bottom,
        acknowledge that I fully understand and agree to its contents.”
      </div>
    </>
  );

  const spanishWaiverContentDNA = (
    <>
      <div>
        Little Bellies no es un proveedor de atención médica. Little Bellies
        reconoce la importancia de la atención médica prenatal adecuada tanto
        para la futura madre como para el feto. Para proporcionar a nuestros
        clientes una prueba de género de ADN significativa, Little Bellies
        requiere que: (i) certifique sinceramente que estoy bajo el cuidado de
        un médico u otro proveedor de atención médica para mi embarazo y que no
        estoy obteniendo esta prueba como reemplazo de, o en lugar de, la
        atención médica prenatal estándar, y (ii) notifique a mi médico o
        proveedor de atención médica actual sobre la prueba de género de ADN que
        recibo de Little Bellies.
        <br />
        Little Bellies solicita que presente a Little Bellies un acuse de recibo
        de este aviso, firmado por mi médico o proveedor de salud, antes de que
        Little Bellies realice la prueba de género de ADN. En el caso de que no
        pueda notificar a mi médico o proveedor de salud antes de la realización
        de la prueba de género de ADN, asumo la responsabilidad exclusiva de
        notificarle tan pronto como sea posible después de la realización de la
        prueba de género de ADN.
        <br />
        Como condición adicional para recibir servicios de pruebas de género de
        ADN de Little Bellies, por la presente reconozco, entiendo y acepto las
        siguientes declaraciones.
        <br />
        <ul>
          <li>
            Esta prueba: (i) es un procedimiento electivo no médico que he
            solicitado voluntariamente y (ii) no está destinado a reemplazar una
            prueba de ADN de diagnóstico o cualquier otra prueba o tratamiento
            que haya sido o pueda ser recomendado por mi médico o proveedor de
            atención médica.
          </li>
          <li>
            Debido a su naturaleza electiva, no médica, esta prueba generalmente
            no está cubierta por el seguro. Por lo tanto, se requiere el pago
            por adelantado.
          </li>
          <li>
            La persona calificada que realiza la prueba, aunque está calificada
            para proporcionar dichos servicios, no es un médico, enfermera o
            proveedor de atención médica y no puede interpretar, diagnosticar
            afecciones médicas u ofrecer asesoramiento médico con respecto a los
            resultados producidos.
            <b>
              Esta prueba no será leída ni interpretada por ningún médico,
              enfermera u otro proveedor de atención médica en ningún momento.
            </b>
          </li>
          <li>
            Esta prueba de ADN de género está destinada a proporcionar una
            predicción del sexo del bebé. El técnico no hará ningún intento de
            garantizar una prueba médicamente inclusiva o el bienestar fetal.
          </li>
          <li>
            Entiendo que soy responsable de contactar a mi propio proveedor de
            atención médica si tengo alguna pregunta sobre esta prueba de ADN de
            género o cualquier otro aspecto de mi embarazo.
          </li>
          <li>
            Entiendo que la precisión de los resultados de la prueba de género
            de ADN depende de varios factores, entre ellos: edad gestacional,
            recolección de muestras y concentración de ADN fetal. Entiendo que
            Little Bellies no garantiza la precisión de la prueba o la capacidad
            de predecir el sexo del feto.
          </li>
          <li>
            Entiendo que la publicación, presentación o distribución de
            cualquier resultado de la prueba de género de ADN, no proporcionada
            por Little Bellies, está estrictamente prohibida.
          </li>
          <li>
            Entiendo que todas las imágenes y videoclips tomados durante mi
            sesión pueden ser utilizados con fines promocionales por Little
            Bellies
          </li>
          <li>
            Acepto recibir correos electrónicos y SMS de Little Bellies, así
            como encuestas, ofertas promocionales y marketing.
          </li>
          <li>
            Acepto recibir los resultados de mi prueba en mi E-mail y
            Smartphone.
          </li>
          <li>
            La prueba de género de ADN será analizada por una empresa de
            laboratorio externa. Little Bellies no es responsable de ningún
            error en los resultados y se libera de cualquier responsabilidad
            derivada del análisis de prueba o los resultados proporcionados por
            el laboratorio de terceros. Entiendo que cualquier discrepancia o
            inquietud sobre los resultados de la prueba debe abordarse
            directamente con el laboratorio o mi proveedor de atención médica.
          </li>
          <li>
            Reconozco y entiendo que la extracción de sangre necesaria para la
            prueba de género de ADN será llevada a cabo por un flebotomista
            calificado. Estoy de acuerdo en que Little Bellies no es responsable
            de ningún error, lesión o daño que pueda ocurrir durante o como
            resultado de este procedimiento. Al firmar esta renuncia, libero
            voluntariamente y libero para siempre a Little Bellies, sus agentes,
            afiliados, miembros, gerentes y empleados de todos y cada uno de los
            reclamos, demandas, daños o causas de acción, presentes o futuros,
            ya sean conocidos o desconocidos, por cualquier daño, lesión o daño
            que pueda ocurrir debido a la extracción de sangre.
          </li>
          <li>Como lo demuestra mi firma a continuación,</li>
        </ul>
        Entiendo que los factores más allá del control de Little Bellies también
        pueden afectar la capacidad de predecir con precisión el género del
        feto, y que Little Bellies no puede proporcionar ninguna garantía o
        garantía en cuanto a la precisión de dicha predicción
        <br />
        Además, entiendo y acepto el riesgo de que, si bien se cree que las
        pruebas de ADN de género no tienen ningún efecto dañino en la madre o el
        feto, las investigaciones futuras u otra información pueden revelar
        efectos dañinos o adversos que actualmente se desconocen.
        <br />
        EN CONSIDERACIÓN DE LOS SERVICIOS PRESTADOS, ACEPTO LIBERAR A LITTLE
        BELLIES, SUS AGENTES, AFILIADOS, MIEMBROS, GERENTES Y EMPLEADOS DE TODOS
        Y CADA UNO DE LOS RECLAMOS O CAUSAS DE ACCIONES POR LESIONES, DAÑOS,
        DAÑOS U OTRA RESPONSABILIDAD QUE RESULTE DE, O SUPUESTAMENTE HAYA
        RESULTADO DE, ESTA PRUEBA DE GÉNERO DE ADN, INCLUYENDO PERO NO LIMITADO
        A, EL FRACASO DE UNA PRUEBA DE VIENTRES PEQUEÑOS PARA DETERMINAR CON
        PRECISIÓN EL SEXO FETAL U OTRAS CARACTERÍSTICAS O ANOMALÍAS Y CUALQUIER
        DAÑO O LESIONES RESULTANTES DE LA PRUEBA QUE AHORA NO SE SABE QUE
        OCURREN.
        <br />
        “He leído cuidadosamente este documento y, al firmar en la parte
        inferior, reconozco que entiendo completamente y estoy de acuerdo con su
        contenido”.
      </div>
    </>
  );

  const [state, setState] = useState({
    status: "loading",
    message: "",
    siteId: "",
    siteName: "",
    checkStatus: "",
    formStatus: "",
    validate: "default",
    requestOtpStatus: "",
    checkOtpStatus: "",
    clientName: "",
    appoimentDate: "",
    appointmentId: "",
    currentDate: "",
    otpTarget: null,
    otpToken: "",
    regenerateToken: "",
    bypass2fa: false,
    programId: 0,
  });
  const [msgData, setMsgData] = useState({
    firstName: "",
    lastName: "",
    fullName: "",
    appointmentDate: "",
    serviceRequired: "",
    initials: "",
    siteName: "",
  });
  const [formData, setFormData] = useState({
    client_id: "",
    firstName: "",
    lastName: "",
    spouse_partner: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zip_code: "",
    phone_home: "",
    phone_work: "",
    phone_cel: "",
    due_date: "",
    birth_date: "",
    phisician_name: "",
    phisician_phone: "",
    pregnancy_problem_explain: "",
    ultrasounds: "",
    massages: "",
    last_ultrasound_date: "",
    last_massage_date: "",

    normal_results_explain: "",
    problems_massages_explain: "",
    about_us: "null",
    pregnancy_problem: "",
    doctor_facilities: "",
    normal_results: "",
    problems_massages: "",

    terms_checkbox: false,
    waiver_checkbox: false,
    terms_conditions_checkbox: false,
    email: "",
    locationId: "",
    firstAppointment: "",
    ipv4: "",
    signatureDate: "",
    missingData: "",
    fullData: "",
    signatureImage: "",
    initialsImage: "",
  });
  const [formSubmited, setFormSubmited] = useState({
    status: "IDLE",
    message: "",
    required: "not-ready",
    inputRequiredClass: false,
    attempt: false,
  });
  const [modalsState, setModalsState] = useState({
    termsCheckboxModal: "hidden",
    waiverCheckboxModal: "hidden",
    initialsModal: "hidden",
    termsAndConditionsCheckboxModal: "hidden",
    initialsImage: "",
    waiverSignatureModal: "hidden",
    termsAndConditionsImage: "",
    medicalConditions: "",
    waiverSignatureImage: "",
    termsButtonClass: "lb-btn my-auto text-lb-btn-submit lb-submitButton",
    waiverButtonClass:
      "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
    initialsButtonClass:
      "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
    termsAndConditionsSignatureClass:
      "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
    waiverSignatureButtonClass:
      "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
    termsButtonDisabled: false,
    waiverButtonDisabled: true,
    initialsButtonDisabled: true,
    waiverSignatureButtonDisabled: true,
    termsAndConditionsSignatureButtonDisabled: true,
    termsLanguage: "english",
    waiverLanguage: "english",
    termsAndConditionsLenguage: "english",
    goGreenModal: "visible",
  });
  const [search, setSearch] = useState({
    searchTerm: "",
    locationTerm: "",
    facilities: [],
  });
  const initialsRef = useRef({});
  const waiverSignatureRef = useRef({});
  // const requiredFields

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  /**
   * On page load it stores the current local date, the clients ip address, the it checks
   * if the appointment's id is on the URL params.
   * If this is the case it will request the client's data, then it checks if the
   * YellowAlert attribute of this client is the same value of the appointment's id,
   * this meaning that the form has already been filled, if this is the case the page
   * will display a message about it. If the form hasn't been filled it checks if the
   * skip2fa attribute is pressent it sets the page into the "form" step, if the
   * attribute is not present then it will set the page into the "requestOtpCode" step.
   * If the appointment's id is not on the URL params, it will check if the site param
   * is on the URL params, if this is the case, it will set the page into the "check"
   * step, if not it will set the app into the "facility" step.
   */
  useEffect(() => {
    const currentDate = new Date();
    const getIp = async () => {
      const ipAdress = await publicIp.v4();
      setFormData((formData) => ({
        ...formData,
        ipv4: ipAdress,
      }));
    };
    getIp();

    if (params.appointmentId !== undefined) {
      const getClient = async () => {
        const ipAdress = await publicIp.v4();
        try {
          const getMethod = {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              siteid: params.siteId,
            },
          };
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/appointments/${params.appointmentId}`,
            getMethod
          );
          const appointmentData = await response.json();
          if (response.ok) {
            const apptDate = new Date(appointmentData.StartDateTime);
            const site = sitesList.find(
              (site) => site.siteId === params.siteId
            );
            const siteName = site.name;

            setState((state) => ({
              ...state,
              programId: appointmentData.ProgramId,
            }));

            setMsgData((msgData) => ({
              ...msgData,
              firstName: appointmentData.Client.FirstName,
              lastName: appointmentData.Client.LastName,
              fullName:
                appointmentData.Client.FirstName +
                " " +
                appointmentData.Client.LastName,
              appointmentDate: apptDate.toDateString(),
              serviceRequired: appointmentData.Service.Name,
              initials:
                appointmentData.Client.FirstName[0] +
                " " +
                appointmentData.Client.LastName[0],
              siteName: siteName,
            }));
            const missingData =
              appointmentData.Client.FirstName === null ||
              appointmentData.Client.FirstName === "" ||
              appointmentData.Client.LastName === null ||
              appointmentData.Client.LastName === "" ||
              appointmentData.Client.AddressLine1 === null ||
              appointmentData.Client.AddressLine1 === "" ||
              appointmentData.Client.City === null ||
              appointmentData.Client.City === "" ||
              appointmentData.Client.State === null ||
              appointmentData.Client.State === "" ||
              appointmentData.Client.PostalCode === null ||
              appointmentData.Client.PostalCode === "" ||
              appointmentData.Client.MobilePhone === null ||
              appointmentData.Client.MobilePhone === "" ||
              appointmentData.Client.BirthDate === null ||
              appointmentData.Client.BirthDate === "" ||
              appointmentData.Client.Email === null ||
              appointmentData.Client.Email === "";

            const fullData =
              appointmentData.FirstAppointment === false &&
              missingData === false;

            let numberFormat = "";
            try {
              const x = appointmentData.Client.MobilePhone.substr(0, 10)
                .toString()
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
              numberFormat = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
            } catch (error) {
              console.error(error);
              numberFormat = "";
            }

            setFormData((formData) => ({
              ...formData,
              client_id:
                appointmentData.ClientId === null
                  ? ""
                  : appointmentData.ClientId,
              firstName:
                appointmentData.Client.FirstName === null
                  ? ""
                  : appointmentData.Client.FirstName,
              lastName:
                appointmentData.Client.LastName === null
                  ? ""
                  : appointmentData.Client.LastName,
              address_line_1:
                appointmentData.Client.AddressLine1 === null
                  ? ""
                  : appointmentData.Client.AddressLine1,
              address_line_2:
                appointmentData.Client.AddressLine2 === null
                  ? ""
                  : appointmentData.Client.AddressLine2,
              city:
                appointmentData.Client.City === null
                  ? ""
                  : appointmentData.Client.City,
              state:
                appointmentData.Client.State === null
                  ? ""
                  : appointmentData.Client.State,
              zip_code:
                appointmentData.Client.PostalCode === null
                  ? ""
                  : appointmentData.Client.PostalCode,
              phone_home:
                appointmentData.Client.HomePhone === null
                  ? ""
                  : appointmentData.Client.HomePhone,
              phone_work:
                appointmentData.Client.WorkPhone === null
                  ? ""
                  : appointmentData.Client.WorkPhone,
              phone_cel:
                appointmentData.Client.MobilePhone === null ? "" : numberFormat,
              birth_date:
                appointmentData.Client.BirthDate === null
                  ? ""
                  : appointmentData.Client.BirthDate.substr(0, 10),
              email:
                appointmentData.Client.Email === null
                  ? ""
                  : appointmentData.Client.Email,
              locationId: appointmentData.LocationId,
              firstAppointment: appointmentData.FirstAppointment,
              terms_checkbox: false,
              terms_conditions_checkbox: false,
              ipv4: ipAdress,
              missingData: missingData,
              fullData: fullData,
            }));
            if (
              appointmentData.Client.YellowAlert !==
              "" + appointmentData.Id
            ) {
              let bypass2fa = false;

              if (location.state !== undefined) {
                bypass2fa = location.state.skip2fa;
              }
              setState((state) => ({
                ...state,
                siteId: params.siteId,
                appointmentId: params.appointmentId,
                status: bypass2fa === true ? "form" : "requestOtpCode",
                currentDate: currentDate,
                bypass2fa,
              }));
            } else {
              let bypass2fa = false;
              if (location.state !== undefined) {
                bypass2fa = location.state.skip2fa;
              }
              setState((state) => ({
                ...state,
                status: "form-filled-already",
                bypass2fa,
                message:
                  "You have already filled this form. You can now close this page.",
              }));
            }
          } else {
            setState((state) => ({
              ...state,
              status: "no-data-found",
              message: JSON.stringify(appointmentData),
            }));
          }
        } catch (error) {
          setState((state) => ({
            ...state,
            status: "error",
            message: JSON.stringify(error.message),
          }));
        }
      };
      getClient();
    } else {
      const results = sitesList;

      const paramSite = results.find((item) => item.siteId === params.siteId);
      if (paramSite !== undefined) {
        setState((state) => ({
          ...state,
          status: "check",
          siteId: paramSite.siteId,
          siteName: paramSite.name,
          currentDate: currentDate,
        }));
        setMsgData((msgData) => ({
          ...msgData,
          siteName: paramSite.name,
        }));
      } else {
        setState((state) => ({
          ...state,
          status: "facility",
          siteId: "",
          siteName: "",
          currentDate: currentDate,
        }));
      }
    }
  }, [params]);
  // }, [location.state, params]);

  /**
   * When the search term inside the search state changes it will filter the results
   * displayed into the facility list in the "facility" state of the page
   */
  useEffect(() => {
    const results = sitesList.filter((facility) =>
      facility.name.toLowerCase().includes(search.searchTerm.toLowerCase())
    );
    setSearch((search) => ({
      ...search,
      facilities: results,
    }));
  }, [search.searchTerm]);

  /**
   * When the formData state changes it will check if all the information required
   * to allow the form send is present to change its "required" attribute to ready
   * and this activates the submit button
   */
  //  yes useEffect(() [formData]

  /**
   * Sets the terms and conditions and waiver checkboxes to true when the
   * client has already signed or puts its initials in the respective fields
   */
  // yes useEffect(() modalsState

  /**
   * Handles the inputs changes, stores the value of the input in the respective
   * formData state attribute
   * @param {object} event Event object of the input on change action
   */
  // yes handleChange

  /**
   * Handles the checkbox or links click, this method set the respective modal
   * visible for displaying the terms and conditions or waiver content according
   * to what element the client just clicked
   * @param {object} event Event object of the button click action
   */
  // yes handleCheckbox

  /**
   * Handles the modal overlay or close button click, this method set the
   * respective modal hidden according to what element the client just clicked
   * @param {object} event Event object of the overlay or button click action
   */
  // yes modal
  const closeModal = (event) => {
    const name = event.target.id;
    if (name === "termsOverlay" || name === "closeTermsModal") {
      setModalsState((modalsState) => ({
        ...modalsState,
        termsCheckboxModal: "hidden",
      }));
    }

    if (name === "initialsOverlay" || name === "closeInitialsModal") {
      setModalsState((modalsState) => ({
        ...modalsState,
        initialsModal: "hidden",
      }));
    }

    if (
      name === "termsSignatureOverlay" ||
      name === "closeTermsSignatureModal"
    ) {
      setModalsState((modalsState) => ({
        ...modalsState,
        termsSignatureModal: "hidden",
      }));
    }

    if (name === "waiverOverlay" || name === "closeWaiverModal") {
      setModalsState((modalsState) => ({
        ...modalsState,
        waiverCheckboxModal: "hidden",
      }));
    }

    if (
      name === "waiverSignatureOverlay" ||
      name === "closeWaiverSignatureModal"
    ) {
      setModalsState((modalsState) => ({
        ...modalsState,
        waiverSignatureModal: "hidden",
      }));
    }

    if (
      name === "goGreenModalOverlay" ||
      name === "closeGoGreenModal" ||
      name === "goGreenModalCloseButton"
    ) {
      setModalsState((modalsState) => ({
        ...modalsState,
        goGreenModal: "hidden",
      }));
    }
  };

  /**
   * Calculates if the client has scrolled to the bottom of the temrms and
   * conditions content, if this is the case it will activate the proceed button
   * @param {object} event Event object of the scrolling element
   */
  // yes
  const handleTermsScroll = (event) => {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      setModalsState((modalsState) => ({
        ...modalsState,
        termsButtonClass: "lb-btn my-auto text-lb-btn-submit lb-submitButton",
        termsButtonDisabled: false,
      }));
    }
  };

  /**
   * Calculates if the client has scrolled to the bottom of the waiver content,
   * if this is the case it will activate the proceed button
   * @param {object} event Event object of the scrolling element
   */
  // yes modal
  const handleWaiverScroll = (event) => {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight + 120) {
      setModalsState((modalsState) => ({
        ...modalsState,
        waiverButtonClass: "lb-btn my-auto text-lb-btn-submit lb-submitButton",
        waiverButtonDisabled: false,
      }));
    }
  };

  /**
   * Handles the modal proceed button, it sets the modal's next step, and if is
   * the case it will also stores the signature or initials into the corresponding
   * state and then close the modal
   * @param {object} event Event object of the button click action
   */
  // yes modal
  const handleModalSubmit = async (event) => {
    event.preventDefault();
    const name = event.target.name;

    const initialSignatureName = `initials-${state.siteId}-${state.appointmentId}.png`;
    const waiverSignatureName = `signature-${state.siteId}-${state.appointmentId}.png`;

    if (name === "termsButton") {
      setModalsState((modalsState) => ({
        ...modalsState,
        termsCheckboxModal: "hidden",
        initialsModal: "visible",
        initialsButtonClass:
          "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
        initialsButtonDisabled: true,
      }));
    }
    if (name === "initialsButton") {
      const initialsImagePNG = await urltoFile(
        initialsRef.current.getTrimmedCanvas().toDataURL("image/png"),
        initialSignatureName,
        "image/png"
      );

      setModalsState((modalsState) => ({
        ...modalsState,
        initialsModal: "hidden",
        initialsImage: initialsImagePNG,
      }));
    }
    if (name === "waiverButton") {
      setModalsState((modalsState) => ({
        ...modalsState,
        waiverCheckboxModal: "hidden",
        waiverSignatureModal: "visible",
        waiverSignatureButtonClass:
          "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
        waiverSignatureButtonDisabled: true,
      }));
    }
    if (name === "waiverSignatureButton") {
      const signatureImagePNG = await urltoFile(
        waiverSignatureRef.current.getTrimmedCanvas().toDataURL("image/png"),
        waiverSignatureName,
        "image/png"
      );

      setModalsState((modalsState) => ({
        ...modalsState,
        waiverSignatureModal: "hidden",
        waiverSignatureImage: signatureImagePNG,
      }));
    }
  };

  /**
   * Submits the clients data, in case there is a missing field it scrolls back to that field,
   * api calls are, in this order, first the upload pdf request, after that update client's data
   * on DynamoDB, and finally updates client's data in MindBody.
   * @param {object} event Event object of the button click action
   */
  // yes handleSubmit

  /**
   * Checks if the inputs value is a valid phone or valid email and if not it will set the
   * validate attribute to the corresponding value
   * @param {object} event Event object of input that just blured
   */
  const handleOnBlur = (event) => {
    const value = event.target.value;
    let cleanValue = "";
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(value)) {
      cleanValue = value.replace(/[-.() ]/g, "");
      setState({
        ...state,
        validate: true,
        emailOrPhone: cleanValue,
      });
    } else {
      if (emailRegex.test(value)) {
        cleanValue = value;
        setState({
          ...state,
          validate: true,
          emailOrPhone: cleanValue,
        });
      } else {
        if (value === "") {
          setState({
            ...state,
            validate: "default",
            emailOrPhone: "",
          });
        } else {
          setState({
            ...state,
            validate: false,
            emailOrPhone: cleanValue,
          });
        }
      }
    }
  };

  /**
   * Handles the check appointment button click, it checks if the client has an
   * appointment and if this is the case and the user hasn't filled the form already
   * it will set the page to the "requestOtp" step, if the appointment exist but the
   * form has already been filled it will display the filled form message, if the
   * client has no appointment or the client doesn't exist it will display the
   * corresponding message
   * @param {object} event Event object of the button click action
   */
  const handleOnClickCheck = async (event) => {
    event.preventDefault();
    if (state.validate) {
      setState((state) => ({
        ...state,
        checkStatus: "validating",
      }));
      try {
        const date = new Date();
        const formatedDate =
          date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
        const getMethod = {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            siteid: state.siteId,
            startdate: formatedDate,
          },
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/clients/${state.emailOrPhone}/validate`,
          getMethod
        );
        const data = await response.json();
        const { clients = [], appoimnets = [] } = data;

        if (response.ok) {
          setState((state) => ({
            ...state,
            clientName: `${clients[0].FirstName} ${clients[0].LastName}`,
            appoimentDate: appoimnets[0].StartDateTime,
            appointmentId: appoimnets[0].Id,
            programId: appoimnets[0].ProgramId,
          }));

          const ipAdress = await publicIp.v4();
          try {
            const appointmentGetMethod = {
              method: "GET",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                siteid: state.siteId,
              },
            };
            const appointmentResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/api/appointments/${appoimnets[0].Id}`,
              appointmentGetMethod
            );
            const appointmentData = await appointmentResponse.json();

            if (appointmentResponse.ok) {
              const apptDate = new Date(appointmentData.StartDateTime);
              setMsgData((msgData) => ({
                ...msgData,
                firstName: appointmentData.Client.FirstName,
                lastName: appointmentData.Client.LastName,
                fullName:
                  appointmentData.Client.FirstName +
                  " " +
                  appointmentData.Client.LastName,
                appointmentDate: apptDate.toDateString(),
                serviceRequired: appointmentData.Service.Name,
                initials:
                  appointmentData.Client.FirstName[0] +
                  " " +
                  appointmentData.Client.LastName[0],
                siteName: state.siteName,
              }));

              const missingData =
                appointmentData.Client.FirstName === null ||
                appointmentData.Client.FirstName === "" ||
                appointmentData.Client.LastName === null ||
                appointmentData.Client.LastName === "" ||
                appointmentData.Client.AddressLine1 === null ||
                appointmentData.Client.AddressLine1 === "" ||
                appointmentData.Client.City === null ||
                appointmentData.Client.City === "" ||
                appointmentData.Client.State === null ||
                appointmentData.Client.State === "" ||
                appointmentData.Client.PostalCode === null ||
                appointmentData.Client.PostalCode === "" ||
                appointmentData.Client.MobilePhone === null ||
                appointmentData.Client.MobilePhone === "" ||
                appointmentData.Client.BirthDate === null ||
                appointmentData.Client.BirthDate === "" ||
                appointmentData.Client.Email === null ||
                appointmentData.Client.Email === "";

              const fullData =
                appointmentData.FirstAppointment === false &&
                missingData === false;
              let numberFormat = "";
              try {
                const x = appointmentData.Client.MobilePhone.substr(0, 10)
                  .toString()
                  .replace(/\D/g, "")
                  .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                numberFormat = !x[2]
                  ? x[1]
                  : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
              } catch (error) {
                console.error(error);
                numberFormat = "";
              }

              setFormData((formData) => ({
                ...formData,
                client_id:
                  appointmentData.ClientId === null
                    ? ""
                    : appointmentData.ClientId,
                firstName:
                  appointmentData.Client.FirstName === null
                    ? ""
                    : appointmentData.Client.FirstName,
                lastName:
                  appointmentData.Client.LastName === null
                    ? ""
                    : appointmentData.Client.LastName,
                address_line_1:
                  appointmentData.Client.AddressLine1 === null
                    ? ""
                    : appointmentData.Client.AddressLine1,
                address_line_2:
                  appointmentData.Client.AddressLine2 === null
                    ? ""
                    : appointmentData.Client.AddressLine2,
                city:
                  appointmentData.Client.City === null
                    ? ""
                    : appointmentData.Client.City,
                state:
                  appointmentData.Client.State === null
                    ? ""
                    : appointmentData.Client.State,
                zip_code:
                  appointmentData.Client.PostalCode === null
                    ? ""
                    : appointmentData.Client.PostalCode,
                phone_home:
                  appointmentData.Client.HomePhone === null
                    ? ""
                    : appointmentData.Client.HomePhone,
                phone_work:
                  appointmentData.Client.WorkPhone === null
                    ? ""
                    : appointmentData.Client.WorkPhone,
                phone_cel:
                  appointmentData.Client.MobilePhone === null
                    ? ""
                    : numberFormat,
                birth_date:
                  appointmentData.Client.BirthDate === null
                    ? ""
                    : appointmentData.Client.BirthDate.substr(0, 10),
                email:
                  appointmentData.Client.Email === null
                    ? ""
                    : appointmentData.Client.Email,
                locationId: appointmentData.LocationId,
                firstAppointment: appointmentData.FirstAppointment,
                terms_checkbox: false,
                ipv4: ipAdress,
                missingData: missingData,
                fullData: fullData,
              }));
              if (
                appointmentData.Client.YellowAlert !==
                "" + appointmentData.Id
              ) {
                setState((state) => ({
                  ...state,
                  status: "requestOtpCode",
                }));
              } else {
                setState((state) => ({
                  ...state,
                  status: "form-filled-already",
                  message:
                    "You have already filled this form. You can now close this page.",
                }));
              }
            } else {
              setState((state) => ({
                ...state,
                checkStatus: "failed",
                message: JSON.stringify(appointmentData),
              }));
            }
          } catch (error) {
            setState((state) => ({
              ...state,
              checkStatus: "error",
              message: JSON.stringify(error.message),
            }));
          }
        } else {
          setState((state) => ({
            ...state,
            checkStatus: "failed",
            message: JSON.stringify(data),
          }));
        }
      } catch (error) {
        setState((state) => ({
          ...state,
          checkStatus: "error",
          message: "Error: " + JSON.stringify(error.message),
        }));
      }
    }
  };

  /**
   * Handles the search term change and stores the value inside the search state
   * for the results to be filtered
   * @param {object} event Event object of the changing input
   */
  const handleTermChange = (event) => {
    setSearch((search) => ({
      ...search,
      searchTerm: event.target.value,
    }));
  };

  /**
   * Stores the information of the selected facility for further use on the page
   * @param {object} event Event object of the button click action
   * @param {string} id String value of the Facility's Id
   * @param {string} name String value of the Facility's Name
   */
  const handleClick = (id, name) => async (event) => {
    event.preventDefault();
    setMsgData((msgData) => ({
      ...msgData,
      siteName: name,
    }));
    setState((state) => ({
      ...state,
      status: "check",
      siteId: id,
      siteName: name,
    }));
  };

  /**
   * Stores the language in wich the user wants to read the terms and conditions
   * or waiver
   * @param {object} event Event object of the button click action
   * @param {string} language String value corresponding to the language
   */
  // yes
  const handleLanguageChange = (language) => (event) => {
    setModalsState((modalsState) => ({
      ...modalsState,
      [event.target.name]: language,
    }));
  };

  /**
   * Validates the length of the initials drawn into the signature pad, if the
   * initials has more of 20 points it will activate the proceed button
   */
  // yes
  const validateInitials = () => {
    let signatureLength = 0;

    initialsRef.current.toData().forEach((element) => {
      signatureLength += element.length;
    });

    setModalsState((modalsState) => ({
      ...modalsState,
      initialsButtonClass:
        signatureLength > 20
          ? "lb-btn my-auto text-lb-btn-submit lb-submitButton"
          : "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
      initialsButtonDisabled: !signatureLength > 20,
    }));
  };

  /**
   * Validates the length of the signature drawn into the signature pad, if the
   * signature has more of 20 points it will activate the proceed button
   */
  // yes
  const validateSignature = () => {
    let signatureLength = 0;

    waiverSignatureRef.current.toData().forEach((element) => {
      signatureLength += element.length;
    });

    setModalsState((modalsState) => ({
      ...modalsState,
      waiverSignatureButtonClass:
        signatureLength > 20
          ? "lb-btn my-auto text-lb-btn-submit lb-submitButton"
          : "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
      waiverSignatureButtonDisabled: signatureLength <= 20,
    }));
  };

  const isDNA = state.programId > 3;
  return (
    <div className="content lb-form-content">
      {state.status === "loading" && (
        <div className="loading" data-cy="loading-message">
          <div className="lb-spinner" />
        </div>
      )}
      {state.status === "error" && (
        <div>
          <div className="container mt-3">
            <div className="row ">
              <div className="col-4 col-md-3 col-lg-2 mx-auto">
                <nav className="lb-navbar">
                  <nav className="lb-navbar">
                    <Link className="lb-navbar-brand" to="/">
                      <img
                        src={logo}
                        width="100%"
                        alt="Little Bellies Logo"
                      ></img>
                    </Link>
                  </nav>
                </nav>
              </div>
            </div>
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="col">
                <h1 className="lb-text-center">Error</h1>
                <h2 className="lb-text-center">
                  Seems like there has been an error
                </h2>
                <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                  <span>{state.message}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.status === "form-filled-already" && (
        <div>
          <div className="container mt-3">
            <div className="row ">
              <div className="col-4 col-md-3 col-lg-2 mx-auto">
                <nav className="lb-navbar">
                  <nav className="lb-navbar">
                    <Link className="lb-navbar-brand" to="/">
                      <img
                        src={logo}
                        width="100%"
                        alt="Little Bellies Logo"
                      ></img>
                    </Link>
                  </nav>
                </nav>
              </div>
            </div>
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="col">
                <h1 className="lb-text-center">Whoops</h1>
                <h2 className="lb-text-center">
                  Seems like this form has already been filled
                </h2>
                <div
                  className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-warning"
                  data-cy="filledFormMessage"
                >
                  <span>{state.message}</span>
                </div>
              </div>
            </div>
            {state.bypass2fa === true && (
              <div className="row">
                <div className="col lb-text-center">
                  <Link
                    to={`/forms`}
                    className="lb-btn lb-submitButton lb-no-hover lb-rounded-0 mx-3 mt-4 mb-2"
                  >
                    GO BACK
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {state.status === "no-data-found" && (
        <div>
          <div className="container mt-3">
            <div className="row ">
              <div className="col-4 col-md-3 col-lg-2 mx-auto">
                <nav className="lb-navbar">
                  <nav className="lb-navbar">
                    <Link className="lb-navbar-brand" to="/">
                      <img
                        src={logo}
                        width="100%"
                        alt="Little Bellies Logo"
                      ></img>
                    </Link>
                  </nav>
                </nav>
              </div>
            </div>
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="col">
                <h1 className="lb-text-center">Nothing found</h1>
                <h2 className="lb-text-center">Seems like nothing was found</h2>
                <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                  <span>{state.message}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.status === "facility" && (
        <>
          <div className="container pt-4">
            <div className="row ">
              <div className="col-5 col-md-3 col-lg-2 mx-auto">
                <Link className="lb-navbar-brand" to="/">
                  <img src={logo} width="100%" alt="Little Bellies Logo"></img>
                </Link>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-4 ">
              <div className="col">
                <h4 className="mx-5 lb-font-bold">Select the facility</h4>
              </div>
            </div>
            <div className="row px-5">
              <div className="col lb-text-center">
                <div className="lb-form-group">
                  <input
                    data-cy="search-bar"
                    type="text"
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    placeholder="Search"
                    onChange={handleTermChange}
                    value={search.searchTerm}
                  />
                </div>
              </div>
            </div>
            <hr className="mb-3"></hr>
            {search.facilities.map((facility) => {
              return (
                <div key={facility.siteId}>
                  <div className="row px-2 px-md-5">
                    <div className="col col-md-2 d-none d-md-block lb-text-center my-auto">
                      <img
                        src={logo}
                        width="55%"
                        alt="Little Bellies Logo"
                      ></img>
                    </div>
                    <div className="col-7 col-md-8 my-auto">
                      <h5 className="my-0 px-1 lb-font-bold">
                        {facility.name}
                      </h5>
                    </div>
                    <div className="col-4 col-md-2 lb-text-center my-auto">
                      <button
                        className="lb-btn lb-submitButton lb-rounded-0 py-2 px-3"
                        onClick={handleClick(facility.siteId, facility.name)}
                        data-cy={"select-" + facility.siteId}
                      >
                        SELECT
                      </button>
                    </div>
                  </div>
                  <hr className="mb-3"></hr>
                </div>
              );
            })}
          </div>
        </>
      )}
      {state.status === "check" && (
        <>
          <div className="lb-container-vcenter container mt-5">
            <img src={logo} alt="little bellies" width="125" />
            <h1 className="lb-text-title lb-text-center">
              Welcome to {msgData.siteName}
            </h1>
            <div>
              <span className="lb-container-left">
                Please enter your email or phone number to begin filling out
                your form
              </span>
              <input
                placeholder="email or phone number"
                className={
                  state.validate
                    ? "lb-input-complete lb-email-input"
                    : "lb-input-validated-error"
                }
                onBlur={handleOnBlur}
                data-cy="mailOrPhoneInput"
              />
            </div>
            {!state.validate && (
              <span className="lb-validation-error-message">
                Invalid email or phone number, please enter a valid email or
                phone number
              </span>
            )}
            <br />
            <button
              className="lb-button-primary lb-cta-lb-start-form"
              onClick={handleOnClickCheck}
              data-cy="cta-submit-check"
            >
              {state.checkStatus === "validating" ? (
                <div>
                  <FontAwesomeIcon spin icon={faSpinner} /> Loading...
                </div>
              ) : (
                "Check Appoiment"
              )}
            </button>

            {state.checkStatus === "failed" && (
              <span className="lb-container-center">
                <div className="lb-form-submited-warning mt-2">
                  <strong>Sorry</strong> your form is not available at this
                  moment. <br />
                  <span>{state.message}</span>
                </div>
              </span>
            )}
            {state.checkStatus === "error" && (
              <span className="lb-container-center">
                <div className="lb-form-submited-warning mt-2">
                  Whoops an error has occurred. <br />
                  <span>{state.message}</span>
                </div>
              </span>
            )}
          </div>
        </>
      )}
      {state.status === "requestOtpCode" && (
        <>
          <RequestOtpCode
            msgData={msgData}
            formData={formData}
            setState={setState}
            state={state}
          />
        </>
      )}
      {state.status === "verifyOtpCode" && (
        <>
          <VerifyOtpCode
            msgData={msgData}
            state={state}
            setState={setState}
            formData={formData}
          />
        </>
      )}

      {state.status === "form" && (
        <div>
          {modalsState.goGreenModal === "visible" && (
            <GoGreenModal closeModal={closeModal} />
          )}
          <div className="container mt-3">
            <div className="row ">
              <div className="col-5 col-md-3 col-lg-2 mx-auto">
                <Link className="lb-navbar-brand" to="/">
                  <img src={logo} width="100%" alt="Little Bellies Logo"></img>
                </Link>
              </div>
            </div>
          </div>
          <div className="container mt-2">
            <div className="row ">
              <div className={state.bypass2fa ? "col-6" : "col"}>
                <p>
                  <span>
                    Welcome{" "}
                    <strong className="lb-greenText">
                      {msgData.fullName}{" "}
                    </strong>
                    to Little Bellies {msgData.siteName}
                  </span>
                  <br />
                  <span>
                    You have an apointment for{" "}
                    <strong>{msgData.appointmentDate}</strong>
                  </span>
                </p>
                <p>
                  <span>Your required services are:</span>
                  <br />
                  <strong className="lb-greenText">
                    {msgData.serviceRequired}
                  </strong>
                </p>
                <p>Please fill the following form:</p>
              </div>
              {state.bypass2fa && (
                <div className="col-6 lb-text-right">
                  <Link
                    to={`/forms`}
                    className="lb-btn lb-submitButton lb-no-hover lb-rounded-0 mx-3 mt-4 mb-2"
                  >
                    GO BACK
                  </Link>
                </div>
              )}
            </div>
          </div>

          {state.programId === 3 ? (
            <FormMassage
              state={state}
              formData={formData}
              formSubmited={formSubmited}
              setFormSubmited={setFormSubmited}
              setModalsState={setModalsState}
              modalsState={modalsState}
              setFormData={setFormData}
              msgData={msgData}
            />
          ) : isDNA ? (
            <FormDNA
              state={state}
              formData={formData}
              formSubmited={formSubmited}
              setFormSubmited={setFormSubmited}
              setModalsState={setModalsState}
              modalsState={modalsState}
              setFormData={setFormData}
              msgData={msgData}
            />
          ) : (
            <FormUltraSound
              state={state}
              formData={formData}
              formSubmited={formSubmited}
              setFormSubmited={setFormSubmited}
              setModalsState={setModalsState}
              modalsState={modalsState}
              setFormData={setFormData}
              msgData={msgData}
            />
          )}
          {modalsState.termsCheckboxModal === "visible" && (
            <div
              className="lb-modal-overlay"
              onClick={closeModal}
              id="termsOverlay"
            >
              <button
                type="button"
                id="closeTermsModal"
                onClick={closeModal}
                className="lb-close-button lb-text-center"
              >
                &times;
              </button>
              <div className="lb-modal">
                <div
                  className="lb-modal-body py-3 px-4 text-justify"
                  onScroll={handleTermsScroll}
                  name="termsModal"
                  data-cy="termsModal"
                >
                  <div className="row px-2 pb-2">
                    <div className="col col-md-8">
                      <h3>Little Bellies Terms of services.</h3>
                    </div>
                    <div className="col col-md-4 d-flex">
                      <button
                        name="termsLanguage"
                        className="lb-btn a mx-1"
                        onClick={handleLanguageChange("english")}
                      >
                        ENGLISH
                      </button>
                      <button
                        name="termsLanguage"
                        className="lb-btn a mx-1"
                        onClick={handleLanguageChange("spanish")}
                      >
                        ESPAÑOL
                      </button>
                    </div>
                  </div>

                  {modalsState.termsLanguage === "english" && (
                    <>
                      <p className="px-3 pt-3">
                        I am receiving ongoing prenatal care. <br />
                        <br />
                        I have undergone a medical diagnostic ultrasound
                        prescribed by my OB provider in regard to this
                        pregnancy. <br />
                        <br />I understand that my OB provider ultimately will
                        confirm my due date, screen for fetal abnormalities
                        and/or any issues/concerns related to my pregnancy.{" "}
                        <br />
                        <br /> Little Bellies is not a medical provider and will
                        not do any of the foregoing. <br />
                        <br />I understand that this ultrasound may not last
                        more than 25 minutes and will focus on my pelvic area.{" "}
                        <br />
                        <br />
                        I understand that this is a limited non-medical
                        ultrasound and does not replace any diagnostic
                        ultrasound ordered by my OB provider. <br />
                        <br />
                        I understand that there is a possibility that the wrong
                        gender may be assigned to my baby. <br />
                        <br />
                      </p>
                      <div>
                        <p
                          className="mt-1"
                          style={{
                            textAlign: "justify",
                          }}
                        >
                          I verify the accuracy of the information above. I
                          authorize Little Bellies to disclose medical
                          information to my healthcare provider if necessary. I
                          agree that I am financially responsible for charges
                          related to this ultrasound.
                        </p>
                      </div>
                    </>
                  )}
                  {modalsState.termsLanguage === "spanish" && (
                    <>
                      <p className="px-3 pt-3">
                        Estoy recibiendo atención prenatal en curso. <br />
                        <br />
                        Me sometí a un ultrasonido de diagnóstico médico
                        recetado por mi proveedor de OB en lo que respecta a
                        este embarazo. <br />
                        <br />
                        Entiendo que mi proveedor de obstetricia finalmente
                        confirmará mi fecha de parto, la detección de anomalías
                        fetales y / o cualquier problema / preocupación
                        relacionado con mi embarazo. <br />
                        <br /> Pancitas no es un proveedor médico y no hará nada
                        de lo anterior. <br />
                        <br />
                        Entiendo que este ultrasonido no puede durar más de 25
                        minutos y se enfocará en mi área pélvica. <br />
                        <br />
                        Entiendo que este es un ultrasonido no médico limitado y
                        no reemplaza ningún ultrasonido de diagnóstico
                        solicitado por mi proveedor de OB. <br />
                        <br />
                        Entiendo que existe la posibilidad de que se asigne el
                        género equivocado a mi bebé. <br />
                        <br />
                      </p>
                      <div>
                        <p
                          className="mt-1"
                          style={{
                            textAlign: "justify",
                          }}
                        >
                          Verifico la exactitud de la información anterior.
                          Autorizo a Little Bellies a divulgar información
                          médica a mi proveedor de atención médica si es
                          necesario. Acepto que soy financieramente responsable
                          de los cargos relacionados con este ultrasonido.
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="lb-modal-footer lb-text-center ">
                  <button
                    className={modalsState.termsButtonClass}
                    onClick={handleModalSubmit}
                    disabled={modalsState.termsButtonDisabled}
                    name="termsButton"
                    data-cy="termsButton"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          )}

          {modalsState.initialsModal === "visible" && (
            <div
              className="lb-modal-overlay"
              onClick={closeModal}
              id="initialsOverlay"
            >
              <button
                type="button"
                id="closeInitialsModal"
                onClick={closeModal}
                className="lb-close-button lb-text-center"
              >
                &times;
              </button>
              <div className="lb-modal">
                <div
                  className="lb-modal-body px-4 text-justify"
                  name="termsInitialsModal"
                  data-cy="termsInitialsModal"
                >
                  <h3 className="pt-4 mx-3">Please sign your initials here.</h3>
                  <SignatureCanvas
                    canvasProps={{
                      className: "lb-sigCanvas mx-auto initialsCanvas",
                    }}
                    ref={initialsRef}
                    onEnd={validateInitials}
                  />
                </div>
                <div className="lb-modal-footer lb-text-center ">
                  <button
                    className={modalsState.initialsButtonClass}
                    onClick={handleModalSubmit}
                    disabled={modalsState.initialsButtonDisabled}
                    name="initialsButton"
                    data-cy="initialsButton"
                  >
                    Sign
                  </button>
                </div>
              </div>
            </div>
          )}

          {modalsState.waiverCheckboxModal === "visible" && (
            <div
              className="lb-modal-overlay"
              onClick={closeModal}
              id="waiverOverlay"
            >
              <button
                type="button"
                id="closeWaiverModal"
                onClick={closeModal}
                className="lb-close-button lb-text-center"
              >
                &times;
              </button>
              <div className="lb-modal ">
                <div
                  className="container lb-modal-body py-3 px-4 "
                  onScroll={handleWaiverScroll}
                  name="waiverModal"
                  data-cy="waiverModal"
                >
                  <div className="row p-2">
                    <div className="col col-md-8">
                      <h3>Waiver Terms of services.</h3>
                    </div>
                    <div className="col col-md-4 d-flex">
                      <button
                        name="waiverLanguage"
                        className="lb-btn a mx-1"
                        onClick={handleLanguageChange("english")}
                      >
                        ENGLISH
                      </button>
                      <button
                        name="waiverLanguage"
                        className="lb-btn a mx-1"
                        onClick={handleLanguageChange("spanish")}
                      >
                        ESPAÑOL
                      </button>
                    </div>
                  </div>

                  {isDNA
                    ? modalsState.waiverLanguage === "spanish"
                      ? spanishWaiverContentDNA
                      : englishWaiverContentDNA
                    : modalsState.waiverLanguage === "spanish"
                    ? spanishWaiverContent
                    : englishWaiverContent}
                </div>
                <div className="lb-modal-footer lb-text-center ">
                  <button
                    className={modalsState.waiverButtonClass}
                    onClick={handleModalSubmit}
                    disabled={modalsState.waiverButtonDisabled}
                    name="waiverButton"
                    data-cy="waiverButton"
                  >
                    Accept
                  </button>
                </div>
              </div>
            </div>
          )}

          {modalsState.waiverSignatureModal === "visible" && (
            <div
              className="lb-modal-overlay"
              onClick={closeModal}
              id="waiverSignatureOverlay"
            >
              <button
                type="button"
                id="closeWaiverSignatureModal"
                onClick={closeModal}
                className="lb-close-button lb-text-center"
              >
                &times;
              </button>
              <div className="lb-modal">
                <div
                  className="lb-modal-body px-4 text-justify"
                  name="termsInitialsModal"
                  data-cy="termsInitialsModal"
                >
                  <h3 className="pt-4 mx-3">Please sign here.</h3>
                  <SignatureCanvas
                    canvasProps={{
                      className: "lb-sigCanvas mx-auto",
                    }}
                    ref={waiverSignatureRef}
                    onEnd={validateSignature}
                  />
                </div>
                <div className="lb-modal-footer lb-text-center ">
                  <button
                    className={modalsState.waiverSignatureButtonClass}
                    onClick={handleModalSubmit}
                    disabled={modalsState.waiverSignatureButtonDisabled}
                    name="waiverSignatureButton"
                    data-cy="waiverSignatureButton"
                  >
                    Sign
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FormPage;
