import React, { useEffect, useState } from "react";
import "../styles/global.css";
import { QRCode } from "react-qr-svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useParams, Redirect } from "react-router-dom";
import moment from "moment";
import logo from "../logo.png";
import { PropTypes } from "prop-types";
import { sitesList } from "../config/constants.js"

function Home({ appState }) {
  const [state, setstate] = useState({
    emailOrPhone: null,
    status: "IDLE",
    validate: "default",
    error: null,
    clientName: null,
    appoimentDate: null,
    appointmentId: "",
    fillForm: "",
  });
  const [authState, setAuthState] = useState({
    status: "",
    siteId: "",
    siteName: "",
  });
  const [search, setSearch] = useState({
    searchTerm: "",
    locationTerm: "",
    facilities: [],
  });

  const dateAppoiment = moment(state.appoimentDate).calendar();
  const isPast = moment() > moment(state.appoimentDate);
  const params = useParams();

  useEffect(() => {
    const results = sitesList;

    const paramSite = results.find((item) => item.siteId === params.siteId);
    if (paramSite !== undefined) {
      setAuthState((authState) => ({
        ...authState,
        status: "checkinstep",
        siteId: paramSite.siteId,
        siteName: paramSite.name,
      }));
    } else {
      if (appState.nav.siteId !== null) {
        const site = results.find((item) => item.siteId === appState.nav.siteId);
        setAuthState((authState) => ({
          ...authState,
          status: "checkinstep",
          siteId: site.siteId,
          siteName: site.name,
        }));
      } else {
        setAuthState((authState) => ({
          ...authState,
          status: "facilityStep",
          siteId: "",
          siteName: "",
        }));
      }
    }
  }, [appState.nav.siteId, params.siteId]);

  useEffect(() => {
    const results = sitesList.filter((facility) =>
      facility.name.toLowerCase().includes(search.searchTerm.toLowerCase())
    );
    setSearch((search) => ({
      ...search,
      facilities: results,
    }));
  }, [search.searchTerm]);

  const handleTermChange = (event) => {
    setSearch((search) => ({
      ...search,
      searchTerm: event.target.value,
    }));
  };

  const handleClick = (id, name) => async (event) => {
    event.preventDefault();
    setAuthState((authState) => ({
      ...authState,
      status: "checkinstep",
      siteId: id,
      siteName: name,
    }));
  };

  const handleOnBlur = (event) => {
    const value = event.target.value;
    let cleanValue = "";
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(value)) {
      cleanValue = value.replace(/[-.() ]/g, "");
      setstate({
        ...state,
        validate: true,
        emailOrPhone: cleanValue,
      });
    } else {
      if (emailRegex.test(value.replace(" ", ""))) {
        setstate({
          ...state,
          validate: true,
          emailOrPhone: value.replace(" ", ""),
        });
      } else {
        setstate({
          ...state,
          validate: false,
          emailOrPhone: cleanValue,
        });
      }
    }
  };

  const handleOnClickCheck = async (event) => {
    event.preventDefault();
    if (state.validate) {
      setstate({ ...state, status: "REQUESTING_EMAIL_VALITADION" });
      try {
        const getMethod = {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            siteid: authState.siteId,
          },
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/clients/${state.emailOrPhone}/validate`,
          getMethod
        );
        const data = await response.json();
        const { clients = [], appoimnets = [] } = data;

        if (response.ok) {
          if (
            appoimnets[0].Status !== "Arrived" &&
            appoimnets[0].Status !== "Completed"
          ) {
            setstate({
              ...state,
              clientName: `${clients[0].FirstName} ${clients[0].LastName}`,
              appoimentDate: appoimnets[0].StartDateTime,
              appointmentId: appoimnets[0].Id,
              fillForm: clients[0].YellowAlert !== "" + appoimnets[0].Id,
            });
            try {
              const putPayload = {
                Execute: "arrive",
              };
              const putMethod = {
                method: "PUT",
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  siteid: authState.siteId,
                  allowedpermissions: false,
                },
                body: JSON.stringify(putPayload),
              };
              const putResponse = await fetch(
                `${process.env.REACT_APP_API_URL}/api/appointments/${appoimnets[0].Id}`,
                putMethod
              );
              const putData = await putResponse.json();
              if (putResponse.ok) {
                const dynamoPayload = {
                  appointmentId: parseInt(appoimnets[0].Id),
                  locationId: parseInt(appoimnets[0].LocationId),
                  clientName: `${clients[0].FirstName} ${clients[0].LastName}`,
                  appointmentDate: appoimnets[0].StartDateTime,
                  staffId: "" + appoimnets[0].StaffId,
                  arrived: moment(new Date()).toString(),
                  clientId: appoimnets[0].ClientId,
                };
                try {
                  const putDynamo = {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                    },
                    body: JSON.stringify(dynamoPayload),
                  };
                  const dynamoResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/dynamoDB/sites/${authState.siteId}/sales`,
                    putDynamo
                  );
                  const dynamoData = await dynamoResponse.json();
                  if (dynamoResponse.ok) {
                    setstate({
                      ...state,
                      status: "SUCCESS_REQUESTING_EMAIL_CONFIRMATION",
                      clientName: `${clients[0].FirstName} ${clients[0].LastName}`,
                      appoimentDate: appoimnets[0].StartDateTime,
                      appointmentId: appoimnets[0].Id,
                      fillForm:
                        clients[0].YellowAlert !== "" + appoimnets[0].Id,
                    });
                  } else {
                    setstate({
                      ...state,
                      status: "ERROR_REQUESTING_EMAIL_CONFIRMATION",
                      error:
                        "Dynamo Response Error: " + JSON.stringify(dynamoData),
                    });
                  }
                } catch (error) {
                  setstate({
                    ...state,
                    status: "ERROR_REQUESTING_EMAIL_CONFIRMATION",
                    error: "Dynamo Request Error: " + JSON.stringify(error),
                  });
                }
              } else {
                setstate({
                  ...state,
                  status: "ERROR_REQUESTING_EMAIL_CONFIRMATION",
                  error: "Arrive Response Error: " + JSON.stringify(putData),
                });
              }
            } catch (error) {
              setstate({
                ...state,
                status: "ERROR_REQUESTING_EMAIL_CONFIRMATION",
                error: "Arrive Request Error: " + JSON.stringify(error),
              });
            }
            // todo: crear estados para cuando el cliente no existe y para cuando el cliente existe y el appoiment no
          } else {
            if (appoimnets[0].Status === "Completed") {
              setstate({
                ...state,
                status: "APPOINTMENT_ALREADY_ARRIVED",
                error: "Appointment has already been completed.",
                fillForm: false,
              });
            }
            if (appoimnets[0].Status === "Arrived") {
              setstate({
                ...state,
                appointmentId: appoimnets[0].Id,
                fillForm: clients[0].YellowAlert !== "" + appoimnets[0].Id,
                status: "APPOINTMENT_ALREADY_ARRIVED",
                error: "Appointment has already been arrived.",
              });
            }
          }
        } else {
          setstate({
            ...state,
            status: "FAILD_REQUESTING_EMAIL_CONFIRMATION",
            error: "Validate Response Error: " + JSON.stringify(data),
          });
        }
      } catch (error) {
        setstate({
          ...state,
          status: "FAILD_REQUESTING_EMAIL_CONFIRMATION",
          error: "Validate Request Error: " + JSON.stringify(error.message),
        });
      }
    }
  };

  return (
    <main className="pb-4">
      {authState.status === "facilityStep" && (
        <>
          <div className="container">
            <div className="row ">
              <div className="col-4 col-md-3 col-lg-2 mx-auto">
                <nav className="lb-navbar">
                  <a className="lb-navbar-brand" href="/">
                    <img
                      src={logo}
                      width="100%"
                      alt="Little Bellies Logo"
                    ></img>
                  </a>
                </nav>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-4 ">
              <div className="col">
                <h4 className="mx-5 lb-font-bold">Select the facility</h4>
              </div>
            </div>
            <div className="row px-5">
              <div className="col lb-text-center">
                <div className="lb-form-group">
                  <input
                    data-cy="search-bar"
                    type="text"
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    placeholder="Search"
                    onChange={handleTermChange}
                    value={search.searchTerm}
                  />
                </div>
              </div>
            </div>
            <hr className="mb-3"></hr>
            {search.facilities.map((facility) => {
              return (
                <div key={facility.siteId}>
                  <div className="row px-2 px-md-5">
                    <div className="col col-md-2 d-none d-md-block lb-text-center my-auto">
                      <img
                        src={logo}
                        width="55%"
                        alt="Little Bellies Logo"
                      ></img>
                    </div>
                    <div className="col-7 col-md-8 my-auto">
                      <h5 className="my-0 px-1 lb-font-bold">
                        {facility.name}
                      </h5>
                    </div>
                    <div className="col-4 col-md-2 lb-text-center my-auto">
                      <button
                        className="lb-btn lb-submitButton lb-rounded-0 py-2 px-3"
                        onClick={handleClick(facility.siteId, facility.name)}
                        data-cy={"select-" + facility.siteId}
                      >
                        SELECT
                      </button>
                    </div>
                  </div>
                  <hr className="mb-3"></hr>
                </div>
              );
            })}
          </div>
        </>
      )}

      {authState.status === "checkinstep" && (
        <>
          <div className="lb-divcontainer ">
            <div className="lb-container-vcenter lb-start-form">
              <img src={logo} alt="little bellies" width="125" />
              <h1 className="lb-text-title lb-text-center">
                Welcome to {authState.siteName}
              </h1>
              <div>
                <span className="lb-container-left">
                  Please enter your email or phone number to start the check-in
                  process
                </span>
                <input
                  placeholder="email or phone number"
                  className={
                    state.validate
                      ? "lb-input-complete lb-email-input"
                      : "lb-input-validated-error"
                  }
                  onBlur={handleOnBlur}
                  data-cy="mailOrPhoneInput"
                />
              </div>
              {!state.validate && (
                <span className="lb-validation-error-message">
                  Invalid email or phone number, please enter a valid email or
                  phone number
                </span>
              )}
              <br />
              <button
                className="lb-button-primary lb-cta-lb-start-form"
                onClick={handleOnClickCheck}
                data-cy="cta-submit"
              >
                {state.status === "REQUESTING_EMAIL_VALITADION" ? (
                  <div>
                    <FontAwesomeIcon spin icon={faSpinner} /> Loading...
                  </div>
                ) : (
                  "Check Appointment"
                )}
              </button>

              {state.status === "SUCCESS_REQUESTING_EMAIL_CONFIRMATION" && (
                <span className="lb-container-vcenter" data-cy="successMessage">
                  <div className="lb-alert-secondary mt-2">
                    Welcome {state.clientName}
                  </div>
                  <div className="lb-alert-secondary">
                    {isPast
                      ? "Your last appointment was " + dateAppoiment
                      : "Your next appointment is " + dateAppoiment}
                  </div>
                  {!state.fillForm && (
                    <div className="lb-form-submited-success lb-small-margin lb-text-center">
                      Thank you for submitting the check-in, your information is
                      now being processed. Please take a seat until your turn is
                      called.
                    </div>
                  )}
                </span>
              )}

              {(state.status === "ERROR_REQUESTING_EMAIL_CONFIRMATION" ||
                state.status === "APPOINTMENT_ALREADY_ARRIVED") && (
                <span className="lb-container-center" data-cy="errorMessage">
                  <div className="lb-form-submited-error mt-2">
                    <span>{state.error}</span>
                  </div>
                </span>
              )}

              {state.status === "FAILD_REQUESTING_EMAIL_CONFIRMATION" && (
                <span className="lb-container-center" data-cy="failMessage">
                  <div className="lb-form-submited-warning mt-2">
                    <strong>Sorry</strong> you do not have appoiments today.
                    <br />
                    {state.error}
                  </div>
                </span>
              )}

              {state.fillForm && (
                <>
                  <div className="lb-form-submited-success lb-small-margin lb-text-center">
                    To proceed to the check-in process, we have sent an email
                    <br /> with the URL or you can{" "}
                    <Redirect
                      to={{
                        pathname: `/appointments/${authState.siteId}/${state.appointmentId}`,
                        state: {
                          skip2fa: false,
                        },
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="container  d-none d-md-block" data-cy="qr-code">
            <div className="row">
              <div className="col lb-text-center mb-3 mb-md-5">
                <p className="">Or do it yourself on your device.</p>
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  value={
                    process.env.REACT_APP_PUBLIC_URL + "/" + authState.siteId
                  }
                  className="lb-qrcode"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  );
}

Home.propTypes = {
  appState: PropTypes.object.isRequired,
};

export default Home;
