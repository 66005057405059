import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography, Button, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import UploadService from "../services/upload-files.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "../styles/global.css";
const REGION = "us-east-2";
const albumBucketName = "littlebellies-landing.storage";
const href = "https://s3." + REGION + ".amazonaws.com/";
const bucketUrl = href + albumBucketName + "/";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.deleteS3 = this.deleteS3.bind(this);
    this.state = {
      currentFile: [],
      previewImage: [],
      progress: 0,

      message: "",
      isError: false,
      imageInfos: [],

      uploadStatus: "IDLE",
    };
  }

  componentDidMount() {
    UploadService.getFiles(this.props.appointmentid).then((response) => {
      this.setState({
        imageInfos: response.Contents,
      });
      this.props.updateImages(response.Contents);
    });
  }

  selectFile(event) {
    const files = event.target.files;
    let currentFile = this.state.currentFile;
    let previewImage = this.state.previewImage;
    for (let i = 0; i < files.length; i++) {
      currentFile = [...currentFile, files[i]];
      previewImage = [...previewImage, URL.createObjectURL(files[i])];
    }
    this.setState({
      currentFile,
      previewImage,
      progress: 0,
      message: "",
    });
  }

  delete(index) {
    const currentFile = this.state.currentFile;
    const previewImage = this.state.previewImage;

    previewImage.splice(index, 1);
    currentFile.splice(index, 1);
    this.setState({
      currentFile,
      previewImage,
    });
  }

  deleteS3(file) {
    UploadService.remove(file, this.props.appointmentid)
      .then((response) => {
        this.setState({
          message: response.message,
          isError: false,
        });
        return UploadService.getFiles(this.props.appointmentid);
      })
      .then((files) => {
        this.setState({
          imageInfos: files.Contents,
        });

        this.props.updateImages(files.Contents);
      })
      .catch((err) => {
        this.setState({
          progress: 0,
          message: "Could not remove the image! " + err.message,
          currentFile: undefined,
          isError: true,
        });
      });
  }

  upload() {
    const currentFile = this.state.currentFile;
    this.setState({
      progress: 0,
      uploadStatus: "uploading",
    });
    currentFile.forEach((file) => {
      UploadService.upload(file, this.props.appointmentid, (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      })
        .then((response) => {
          this.setState({
            message: response.message,
            isError: false,
          });
          return UploadService.getFiles(this.props.appointmentid);
        })
        .then((files) => {
          this.setState({
            imageInfos: files.Contents,
            uploadStatus: "success",
          });

          this.props.updateImages(files.Contents);
        })
        .catch((err) => {
          this.setState({
            progress: 0,
            message: "Could not upload the image! " + err.message,
            currentFile: undefined,
            isError: true,
            uploadStatus: "error",
          });
        });
    });
  }

  _renderListItim(image, index) {
    if (image.Key.slice(-1) === "/") return null;
    return (
      <div className="col-12 col-md-6 col-lg-4 mb-4 lb-text-center" key={index}>
        <img
          src={bucketUrl + encodeURIComponent(image.Key)}
          alt={image.Key}
          className="preview my20"
        />
        <Button
          key={"deleteS3" + index}
          className="lb-btn-upload lb-btn-delete"
          onClick={() => this.deleteS3(image.Key)}
        >
          &times;
        </Button>
        <a href={bucketUrl + encodeURIComponent(image.Key)}>{image.Key}</a>
      </div>
    );
  }

  render() {
    const {
      currentFile,
      previewImage,
      progress,
      message,
      imageInfos,
      isError,
    } = this.state;
    return (
      <>
        <div className="row mt-3">
          <div className="col px-4">
            <div className="mg20">
              <div className="row mb-3">
                <div className="col d-flex justify-content-between">
                  <label htmlFor="lb-btn-upload">
                    <input
                      id="lb-btn-upload"
                      name="lb-btn-upload"
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={this.selectFile}
                      multiple
                    />
                    <Button
                      className="lb-btn-upload-img"
                      variant="outlined"
                      component="span"
                    >
                      Choose Image
                    </Button>
                  </label>
                  <div className="file-name">
                    {currentFile
                      ? currentFile.map((file) => file.name + ":")
                      : null}
                  </div>
                </div>
              </div>

              {currentFile && (
                <Box className="my20 d-none" display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={progress}
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >{`${progress}%`}</Typography>
                  </Box>
                </Box>
              )}

              {previewImage && !imageInfos && (
                <>
                  <div className="row">
                    <div className="col">
                      <Typography variant="h6" className="list-header">
                        Images to be uploaded
                      </Typography>
                    </div>
                  </div>
                  <div className="row">
                    {previewImage.map((item, index) => (
                      <div
                        className="col-12 col-md-6 col-lg-4 mb-4 lb-text-center"
                        key={"div" + index}
                      >
                        <img
                          key={index}
                          className="preview my20"
                          src={item}
                          alt=""
                        />
                        <Button
                          key={"delte" + index}
                          className="lb-btn-upload lb-btn-delete"
                          onClick={() => this.delete(index)}
                        >
                          &times;
                        </Button>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {message && (
                <Typography
                  variant="subtitle2"
                  className={`upload-message ${isError ? "error" : ""}`}
                >
                  {message}
                </Typography>
              )}

              {imageInfos && (
                <>
                  <div className="row">
                    <div className="col">
                      <Typography variant="h6" className="list-header">
                        Uploaded images
                      </Typography>
                    </div>
                  </div>
                  <div className="row list-group">
                    {imageInfos &&
                      imageInfos.map((image, index) =>
                        this._renderListItim(image, index)
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row px-0 mt-3 mb-5">
          <div className="col no-gutters px-0 lb-text-center">
            {this.props.statusVideo && (
              <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-success">
                <span>{this.props.statusVideo}</span>
              </div>
            )}
            {previewImage && !imageInfos && (
              <Button
                className="lb-btn-upload-img text-lb-numberButton"
                disabled={!currentFile}
                onClick={this.upload}
              >
                {this.state.uploadStatus === "uploading" ? (
                  <div className="text-lb-numberButton mx-1 my-1">
                    <FontAwesomeIcon spin icon={faSpinner} /> Uploading...
                  </div>
                ) : (
                  <div className="text-lb-numberButton mx-1 my-1">Upload</div>
                )}
              </Button>
            )}
            <button
              type="button"
              className={
                this.props.imgCount < this.props.minImageCount
                  ? "lb-btn lb-submitButtonDisabled lb-rounded-0 mx-3"
                  : "lb-btn lb-submitButton lb-rounded-0 mx-3"
              }
              disabled={
                this.props.isSending ||
                this.props.imgCount < this.props.minImageCount
              }
              onClick={this.props.sendRequest}
              data-cy="lb-btn-send"
            >
              <div className="text-lb-numberButton mx-1 my-1">
                {this.props.isSending ? (
                  <span>
                    <FontAwesomeIcon spin icon={faSpinner} /> CREATING...
                  </span>
                ) : (
                  "CREATE VIDEO"
                )}
              </div>
            </button>
          </div>
        </div>
      </>
    );
  }
}

UploadImages.propTypes = {
  appointmentid: PropTypes.string,
  isSending: PropTypes.bool,
  imgCount: PropTypes.number,
  minImageCount: PropTypes.number,
  statusVideo: PropTypes.string,
  updateImages: PropTypes.func,
  sendRequest: PropTypes.func,
  innerRef: PropTypes.object,
};
export default React.forwardRef((props, ref) => (
  <UploadImages innerRef={ref} {...props} />
));
