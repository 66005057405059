import PropTypes from 'prop-types';
import SignatureCanvas from "react-signature-canvas";
import React, {useRef } from "react";

const ModalFormMassage = ({
    modalsState,
    state,
    setModalsState,
    setFormData,
    formData
}) =>{


const conditionsAndTermsSignatureRef = useRef({});


const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

  const validateSignatureConditionsAndTerms = () => {
    let signatureLength = 0;

    conditionsAndTermsSignatureRef.current.toData().forEach((element) => {
      signatureLength += element.length;
    });

    setModalsState((modalsState) => ({
      ...modalsState,
      termsAndConditionsSignatureClass:
        signatureLength > 20
          ? "lb-btn my-auto text-lb-btn-submit lb-submitButton"
          : "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
         termsAndConditionsSignatureButtonDisabled: signatureLength <= 20,
    }));
  };

  const handleLanguageChange = (language) => (event) => {
    setModalsState((modalsState) => ({
      ...modalsState,
      [event.target.name]: language,
    }));
  };

  
  const closeModal = (event) => {
    const name = event.target.id;
  
    if (name === "termsAndConditionOverlay" || name === "closeTermsAndConditionModal") {
      setModalsState((modalsState) => ({
        ...modalsState,
        termsAndConditionsCheckboxModal: "hidden",
      }));
    }
  
  };

const handleMedicalConditions = async (e) => {
const {value} = e.target;

setModalsState((modalsState) => ({
...modalsState,
medicalConditions: value,
}));

};

  const handleModalSubmit = async (event) => {
    event.preventDefault();
    const name = event.target.name;     
    
    const termsAndConditionsSignatureName = `signature-${state.siteId}-${state.appointmentId}.png`
    
    if (name === "termsAndConditionsSignatureButton") {
        const signatureImagePNG = await urltoFile(conditionsAndTermsSignatureRef.current .getTrimmedCanvas()
        .toDataURL("image/png"), termsAndConditionsSignatureName,'image/png');

      setModalsState((modalsState) => ({
        ...modalsState,
        termsAndConditionsCheckboxModal: "hidden",
        termsAndConditionsSignatureClass:
          "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
          termsAndConditionsSignatureButtonDisabled: true,
          termsAndConditionsImage: signatureImagePNG
      }));

    }
  };


return (

    <div
    className="lb-modal-overlay"
    onClick={closeModal}
    id="termsAndConditionOverlay"
  >
    <button
      type="button"
      id="closeTermsAndConditionModal"
      onClick={closeModal}
      className="lb-close-button lb-text-center"
    >
      &times;
    </button>
     <div className="lb-modal ">
      <div
        className="container lb-modal-body py-3 px-4 "
        name="conditionsModal" 
        data-cy="conditionsModal"
      >  
        <div className="row p-2">
          <div className="col col-md-8">
            {modalsState.termsAndConditionsLenguage === "english" 
            ? <h3>Prenatal Massage.</h3> 
            : <h3>Masaje Prenatal.</h3>
            }
          </div>
          <div className="col col-md-4 d-flex">
            <button
              name="termsAndConditionsLenguage"
              className="lb-btn a mx-1"
              onClick={handleLanguageChange("english")}
            >
              ENGLISH
            </button>
            <button
              name="termsAndConditionsLenguage"
              className="lb-btn a mx-1"
              onClick={handleLanguageChange("spanish")}
            >
              ESPAÑOL
            </button>
          </div>
        </div>

        {modalsState.termsAndConditionsLenguage === "english" && (

          <>
            <div>
            <p style={{textAlign:'justify',color:"black"}}>I understand that massage therapy during pregnancy,
            can help with muscle and tension relaxation as well as stress relief. &nbsp;&nbsp;By signing below, 
            I understand that massage therapy is not an alternative to medical/prenatal 
            case and that I am under the care of a physician for this pregnancy. &nbsp;&nbsp;Below is a list
            of pregnancy complications. &nbsp;&nbsp; By signing below, I verify that I do not have 
            any  of  the  following  complications  or  risk  factors  that  are  conditions  
            contraindicated  to having a prenatal massage.</p>
            <ul className="navbar-nav">
            <li className="ml-4">1. First trimester (up to 13 weeks gestation); </li>
            <li className="ml-4">2. Pre‐term labor/possible miscarriage: discharge of blood, amniotic bag ruptured,
            <strong>Contractions;</strong></li>
            <li className="ml-4">3. Pre‐eclampsia: unusually high weight gain, protein in urine, high blood pressure</li>
            <li className="ml-4">4. Eclampsia (Toxemia): severe water retention, headaches, back pain, vomiting, visual
            <strong className='ml-1'>disturbances</strong></li>
            <li className="ml-4">5. Gestational Diabetes: abnormal appetite/thirst, sugar in urine</li>
            <li className="ml-4">6. Deep Vein Thrombosis: pain, redness, or swelling isolated to one leg</li>
            </ul>
            <br/>
            <p style={{textAlign:'justify',color:"black"}}>I have had the opportunity to ask the massage therapist any questions I may have about
            pregnancy massage and I have discussed this with my physician or other health care
            practitioner.&nbsp;&nbsp;I will immediately let my massage therapist know of any pain or discomfort 
            so that pressure and strokes can be adjusted to my level of comfort.</p>
            </div>

            <div className="mt-3 lb-form-group">
            <label style={{textAlign:'justify'}}>
            <strong>I affirm that I do not currently have any of the above conditions except as listed here:</strong>
            </label>
            <textarea
              className="lb-w-100 lb-border-0 lb-inputBackground"
              onChange={(e) => handleMedicalConditions(e)}
              value={modalsState.medicalConditions}
              rows="3"
              type="text"
              name="medical_condition"
              data-cy="medical_condition"
            />
          </div>

          <div>
        <p className='mt-3' style={{textAlign:'justify',color:"black"}}>I know that massage can be harmful in some circumstances; I am voluntarily seeking a 
         prenatal  massage  and  fully  assume  responsibility  for  receiving  massage  therapy  at 
         Pancitas and release and discharge the licensed massage therapist and Pancitas from 
         any and all claims, liabilities, damages, actions arising from or related to massage therapy 
         received. &nbsp;&nbsp;I  fully  and  truthfully  answered  the  questions  above  and  will  tell  the  massage 
         therapist promptly if anything changes.</p>
          </div>

          <div>
          <p className='mt-4' style={{textAlign:'justify'}}> I verify the accuracy of the information above. I authorize Little Bellies to disclose 
          information to my healthcare provider if necessary. I agree that I am financially 
          responsible for charges related to this massage.</p>
          </div>
          </>
        )}
        {modalsState.termsAndConditionsLenguage === "spanish" && (
          <>
            <div>
            <p style={{textAlign:'justify',color:"black"}}>Yo entiendo que la terapia de masaje durante el embarazo puede ayudar a relajar los músculos
            y la tensión, así como a aliviar el estrés. Al firmar a continuación, 
            entiendo que la terapia de masaje no es una alternativa al caso médico/prenatal
            y que estoy bajo el cuidado de un médico durante este embarazo. &nbsp;&nbsp;
            A continuación se muestra una lista de complicaciones del embarazo. &nbsp;&nbsp; Al firmar a continuación, 
            verifico que no tengo ninguna de las siguientes complicaciones o factores de 
            riesgo que son condiciones contraindicadas para recibir un masaje prenatal.</p>
            <ul className="navbar-nav">
            <li className="ml-4">1. Primer trimestre (hasta 13 semanas de gestación);</li>
            <li className="ml-4">2. Trabajo de parto prematuro/posible aborto espontáneo: secreción de sangre, bolsa amniótica rota, <strong>Contracciones;</strong></li>
            <li className="ml-4">3. Preeclampsia: aumento de peso inusualmente alto, proteína en la orina, presión arterial alta</li>
            <li className="ml-4">4. Eclampsia (Toxemia): retención severa de agua, dolores de cabeza, dolor de espalda, vómitos, <strong>trastornos</strong> visuales</li>
            <li className="ml-4">5. Diabetes gestacional: apetito anormal/sed, azúcar en la orina</li>
            <li className="ml-4">6. Trombosis venosa profunda: dolor, enrojecimiento o hinchazón aislada en una pierna</li>
            </ul>
            <br/>
            <p style={{textAlign:'justify',color:"black"}}>He tenido la oportunidad de hacerle al masajista todas las preguntas
             que pueda tener sobre el masaje durante el embarazo y lo he discutido con mi médico u otro
              profesional de la salud. &nbsp;&nbsp; Inmediatamente informaré a mi masajista sobre cualquier dolor 
            o molestia para que la presión y las caricias puedan ajustarse a mi nivel de comodidad.</p>
            </div>

            <div className="mt-3 lb-form-group">
            <label style={{textAlign:'justify'}}>
            <strong>Yo afirmo que actualmente no tengo ninguna de las condiciones anteriores, excepto las que se enumeran aquí:</strong>
            </label>
            <textarea
              className="lb-w-100 lb-border-0 lb-inputBackground"
              onChange={(e) => handleMedicalConditions(e)}
              value={modalsState.medicalConditions}
              rows="3"
              type="text"
              name="medical_condition"
              data-cy="medical_condition"
            />
          </div>

          <div>
        <p className='mt-3' style={{textAlign:'justify',color:"black"}}>Yo sé que el masaje puede ser dañino en algunas circunstancias; Estoy buscando voluntariamente un masaje prenatal
         y asumo completamente la responsabilidad de recibir terapia de masaje en Pancitas y libero
          al terapeuta de masaje con licencia y a Pancitas de cualquier reclamo, responsabilidad, 
          daño, acción que surja de o esté relacionado con la terapia de masaje recibida.  &nbsp;&nbsp;
          Respondí completa y sinceramente las preguntas anteriores y le informaré al masajista de inmediato
           si algo cambia.</p>
          </div>

          <div>
          <p className='mt-4' style={{textAlign:'justify'}}> Verifico la exactitud de la información anterior. Autorizo a Little Bellies a divulgar
           información a mi proveedor de atención médica si es necesario. Acepto que soy financieramente
           responsable de los cargos relacionados con este masaje.</p>
          </div>

          </>
        )}
        <div
        className="lb-modal-body text-justify mt-4 col-12"
        name="termsInitialsModal"
        data-cy="termsInitialsModal">

        <h3 className="pt-3 mx-3">Please sign here.</h3>

        <SignatureCanvas
          canvasProps={{
            className: "lb-sigCanvas mx-auto",
          }}
          ref={conditionsAndTermsSignatureRef}
          onEnd={validateSignatureConditionsAndTerms}
        />
            </div>
             </div>  
       
      <div className="lb-modal-footer lb-text-center ">
        <button
          className={modalsState.termsAndConditionsSignatureClass}
          onClick={handleModalSubmit}
          disabled={modalsState.termsAndConditionsSignatureButtonDisabled}
          name="termsAndConditionsSignatureButton"
          data-cy="termsAndConditionsSignatureButton"
        >
          Accept
        </button>
      </div>

   </div>
  </div>





)



};


ModalFormMassage.propTypes = {
    modalsState: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setModalsState: PropTypes.func.isRequired,
    setFormData: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
  };
  export default ModalFormMassage;
  