import React, { useState } from "react";
import BarChart from "../components/barChart";
import HorizontalBarChart from "../components/horizontalBarChart";
import { useLocation } from "react-router-dom";
import { formatDate2string } from "../util/index.js";
import distinctColors from "distinct-colors";
import moment from "moment";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import "../styles/operational.css";
import PolarAreaChart from "../components/polarAreaChart";

function OperationalDetails() {
  const today = new Date();
  const time = moment(today).format("HH:mm");
  const locationParams = useLocation();
  const location = locationParams.location;

  const detailsData = {
    origin: location.data.origin,
    appointmentsAttended: location.data.services,
  };

  const status = {
    labels: ["Pending", "CBFF", "No Show", "Attended"],
    values: {
      Pending: location.data.confirmed + location.data.booked,
      CBFF: location.data.cbff,
      "No Show": location.data.noShow,
      Attended: location.data.completed,
    },
  };

  const returningTotal = location.data.returning - location.data.cbff;

  const retvsnew = {
    labels: ["New Client", "Returning Costumer"],
    values: [location.data.new.toString(), returningTotal.toString()],
    colors: ["rgb(153, 102, 255, 0.2)", "rgb(201, 203, 207, 0.2)"],
    borders: ["rgba(153, 102, 255)", "rgba(201, 203, 207)"],
    chartName: "Returning Costumer vs New Client",
  };

  const [anchor, setAnchor] = useState(null);
  const openPopover = (event) => {
    setAnchor(event.currentTarget);
  };

  const [anchorClient, setAnchorClient] = useState(null);
  const openPopoverClient = (event) => {
    setAnchorClient(event.currentTarget);
  };

  const cbffGrantedTotal =
    location.data.cbffGranted === 0 || location.data.totalAppointments === 0
      ? 0
      : (location.data.cbffGranted / location.data.totalAppointments) * 100;

  function roundWithEpsilon(numbeToRound) {
    return Math.round((numbeToRound + Number.EPSILON) * 100) / 100;
  }

  return (
    <div data-cy="operational-details-page">
      <div className="container pb-5">
        <div className="mt-5 lb-title-daily d-flex justify-content-center">
          <h4>Operational Dashboard Details</h4>
        </div>
        <div className="mt-3 lb-title-daily d-flex justify-content-end">
          <h5>
            {formatDate2string(today)} {time}
          </h5>
        </div>
        <div className="mt-5 lb-title-daily d-flex justify-content-start">
          <h5>{location.siteName}</h5>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-md-8 lb-height-bar-details">
            <BarChart
              labels={status.labels}
              values={status.values}
              maxHeight={location.data.totalAppointments}
            />
          </div>
          <div className="col-12 col-md-4 my-auto">
            <div className="card mt-3">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-9 text-start">
                    <h5>Total Sales</h5>
                  </div>
                  <div className="col-3 text-end">
                    <h5>${location.data.totalSales}</h5>
                  </div>
                  <div className="col-9 text-start">
                    <h6>Total Completed</h6>
                  </div>
                  <div className="col-3 text-end">
                    <h6>{location.data.completed}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-9 text-start">
                    <h5>No show Total </h5>
                  </div>
                  <div className="col-3 text-end">
                    <h5>${location.data.totalNoShow}</h5>
                  </div>
                  <div className="col-9 text-start">
                    <h6>No show</h6>
                  </div>
                  <div className="col-3 text-end">
                    <h6>{location.data.noShow}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-9 text-start">
                    <h5>Total Expected Net Sales</h5>
                  </div>
                  <div className="col-3 text-end">
                    <h5>${location.data.totalExpectedSales}</h5>
                  </div>
                  <div className="col-9 text-start">
                    <h6>Total Appointments</h6>
                  </div>
                  <div className="col-3 text-end">
                    <h6>{location.data.totalAppointments}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="card mt-3">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-8 col-md-8 text-start">
                    <h5 className="lb-title-daily">Patient Results Sent</h5>
                  </div>
                  <div className="col-4 col-md-4 text-end">
                    <h5 className="lb-title-daily">
                      {location.data.dicomOk} sent /
                      {location.data.completedUltrasound}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-6">
            <div className="card mt-3">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-7 col-md-6 text-start">
                    <h5>CBFF&apos;s Granted</h5>
                  </div>
                  <div className="col-3 col-md-4 text-end">
                    <h5>{roundWithEpsilon(cbffGrantedTotal)} %</h5>
                  </div>
                  <div className="col-2 col-md-2 text-end">
                    <h5>
                      {location.data.cbffGranted}/{location.data.completed}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <div className="row g-0">
                  <div className="col-7 col-md-9 text-start">
                    <h5>Total Tips</h5>
                  </div>
                  <div className="col-5 col-md-3 text-end">
                    <h5>${location.data.totalTip}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-10 col-md-11">
                    <h5>Appointments Made by</h5>
                  </div>
                  <div className="col-2 col-md-1">
                    <button
                      className="lb-btn-info"
                      type="button"
                      onClick={openPopover}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-info-circle"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#ffffff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="9" />
                        <line x1="12" y1="8" x2="12.01" y2="8" />
                        <polyline points="11 12 12 12 12 16 13 16" />
                      </svg>
                    </button>
                    <Popover
                      open={Boolean(anchor)}
                      anchorEl={anchor}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      elevation={4}
                      onClose={() => setAnchor(null)}
                    >
                      <Typography
                        variant="body2"
                        align="justify"
                        className="p-2"
                      >
                        Number of appointments that have been scheduled by call
                        center vs online, CBFF are excluded
                      </Typography>
                    </Popover>
                  </div>
                </div>
                <div className="row g-0 mt-3">
                  <div className="col-12 text-start">
                    <h5>Call Center: {detailsData.origin.callCenter} </h5>
                  </div>
                  <div className="col-12 text-start mt-2">
                    <h5>Online: {detailsData.origin.online} </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-10 col-md-11">
                    <h5>Returning Costumer vs New Client</h5>
                  </div>
                  <div className="col-2 col-md-1">
                    <button
                      className="lb-btn-info"
                      type="button"
                      onClick={openPopoverClient}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-info-circle"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#ffffff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="9" />
                        <line x1="12" y1="8" x2="12.01" y2="8" />
                        <polyline points="11 12 12 12 12 16 13 16" />
                      </svg>
                    </button>
                    <Popover
                      open={Boolean(anchorClient)}
                      anchorEl={anchorClient}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      elevation={4}
                      onClose={() => setAnchorClient(null)}
                    >
                      <Typography
                        variant="body2"
                        align="justify"
                        className="p-2"
                      >
                        Number of new customers and returning customers,
                        excluding cbff
                      </Typography>
                    </Popover>
                  </div>
                </div>
                <HorizontalBarChart
                  labels={retvsnew.labels}
                  values={retvsnew.values}
                  colors={retvsnew.colors}
                  borderColors={retvsnew.borders}
                  chartName={retvsnew.chartName}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="lb-title-daily d-flex justify-content-center">
            <h5>Appointments</h5>
          </div>
          <div className=" mt-3 lb-title-daily d-flex justify-content-start">
            <h6>Services</h6>
          </div>
          <div className="row">
            {detailsData.appointmentsAttended.map((service) => {
              const labels = [
                "Total " + service.name + " Scheduled",
                "Attended ",
                "No Show",
                "Cbff Granted",
              ];
              const values = [
                service.count,
                service.completed,
                service.noShow,
                service.cbffGranted,
              ];

              const basicColors = distinctColors({
                count: values.length,
                hueMin: 270,
                hueMax: 330,
                chromaMin: 0,
                chromaMax: 100,
                lightMin: 40,
                lightMax: 100,
              });
              const colors = basicColors.map((color) => {
                return color.hex() + "7F";
              });

              const borders = basicColors.map((color) => {
                return color.brighten(0.2).hex();
              });

              const totalSales = service.price * service.completed;
              const totalExpected = service.price * service.count;

              return (
                <div className="col-12 col-md-6" key={service.name}>
                  <div className="card mt-3">
                    <div className="card-body">
                      <h5>{service.name}</h5>
                      <div className="lb-height-polar mx-auto">
                        <PolarAreaChart
                          labels={labels}
                          values={values}
                          colors={colors}
                          borderColors={borders}
                          chartName={service.name}
                        />
                      </div>
                      <div className="row mt-2">
                        <div className="col-5 text-center">
                          <h6>Total Sales: ${totalSales}</h6>
                        </div>
                        <div className="col-7 text-center">
                          <h6>Total Expected Sales: ${totalExpected}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OperationalDetails;
