import React from 'react'
import { ItemTypes } from './ItemTypes'
import { useDrop } from 'react-dnd'
import { PropTypes } from "prop-types";
import moment from "moment";

function DroppableBlock({ block, updateFunction }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.APPOINTMENT,
    drop: (item, monitor) => {
      if((item.status === "Booked" || item.status === "Confirmed" || item.status === "NoShow")){
        updateFunction(item.id, moment(block.blockDate).format("YYYY-MM-DD[T]HH:mm:ss").toString(), moment(block.blockDate).add(30, "minutes").format("YYYY-MM-DD[T]HH:mm:ss").toString(), item.sessionTypeId, item.notes, block.staffId, block.authorization, block.siteId, block.locationId, block.isWeekRange) 
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    > &nbsp;
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        ></div>
      )}
    </div>
  );
}

DroppableBlock.propTypes = {
  block: PropTypes.object,
  updateFunction: PropTypes.func,
};
export default DroppableBlock