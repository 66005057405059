import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import DatePicker from "react-datepicker";
import { formatAmount } from "../util/formatNumbers.js";
import { formatDate2string, formatDate2object } from "../util/index.js";
import "../styles/global.css";
import "../styles/dailyCloseout.css";

function DailyCloseout({ appState, setAppState }) {
  const today = new Date();
  const [state, setState] = useState({
    status: "idle",
    message: "",
    date: new Date().toISOString().substr(0, 10),
  });
  const [locations, setLocations] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState({});
  const [location, setLocation] = useState({});

  const updateSiteName = () => {
    let siteName;
    const sites = appState.directorsAuth.authorizationTokens;
    for (let index = 0; index < sites.length; index++) {
      if (sites[index].siteId.toString() === appState.nav.siteId) {
        siteName = sites[index].siteName;
        break;
      }
    }
    setAppState((appState) => ({
      ...appState,
      nav: {
        ...appState.nav,
        siteName: siteName,
      },
    }));
  };
  useEffect(() => {
    getLocations();
    updateSiteName();
  }, [appState.nav.siteId]);

  const handleLocationChange = (event) => {
    const name = event.target.options[event.target.selectedIndex].text;
    setLocation({
      id: event.target.value,
      name: name,
    });
  };

  const formatQuantity = (amount) => {
    return parseFloat(amount).toLocaleString("en-US", {
      maximumFractionDigits: 0,
    });
  };

  const getLocations = async () => {
    try {
      const getMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: appState.nav.siteId,
          authorization: appState.singleAuth.token,
        },
      };
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/locations`,
        getMethod
      );

      if (getResponse.ok && getResponse.status === 200) {
        const siteLocations = await getResponse.json();

        if (siteLocations.length > 1) {
          siteLocations.push({
            name: "All Locations",
            id: 0,
          });
        }
        setLocations(siteLocations);
        setLocation(siteLocations[0]);
      } else {
        setLocations([]);
      }
    } catch (error) {
      setLocations([]);
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const getData = async () => {
    const addTotals = (dailyData) => {
      let receipt = 0;
      let tax = 0;
      let returns = 0;
      let tip = 0;
      let total = 0;

      dailyData.byPayment.cashEquivalent.forEach((item) => {
        receipt += parseFloat(item.receipt);
        tax += parseFloat(item.tax);
        returns += parseFloat(item.returns);
        tip += parseFloat(item.tip);
        total += parseFloat(item.total);
        item.receipt = formatAmount(parseFloat(item.receipt));
        item.tax = formatAmount(parseFloat(item.tax));
        item.returns = formatAmount(parseFloat(item.returns));
        item.tip = formatAmount(parseFloat(item.tip));
        item.total = formatAmount(parseFloat(item.total));
      });

      dailyData.cashEquivalentSum = {
        receipt: receipt,
        tax: tax,
        returns: returns,
        tip: tip,
        total: total,
      };

      receipt = 0;
      tax = 0;
      returns = 0;
      tip = 0;
      total = 0;

      dailyData.byPayment.nonCashEquivalent.forEach((item) => {
        receipt += parseFloat(item.receipt);
        tax += parseFloat(item.tax);
        returns += parseFloat(item.returns);
        tip += parseFloat(item.tip);
        total += parseFloat(item.total);
        item.receipt = formatAmount(parseFloat(item.receipt));
        item.tax = formatAmount(parseFloat(item.tax));
        item.returns = formatAmount(parseFloat(item.returns));
        item.tip = formatAmount(parseFloat(item.tip));
        item.total = formatAmount(parseFloat(item.total));
      });

      dailyData.nonCashEquivalentSum = {
        receipt: receipt,
        tax: tax,
        returns: returns,
        tip: tip,
        total: total,
      };
      dailyData.totalReceipts = {
        receipt: formatAmount(
          dailyData.cashEquivalentSum.receipt +
            dailyData.nonCashEquivalentSum.receipt
        ),
        tax: formatAmount(
          dailyData.cashEquivalentSum.tax + dailyData.nonCashEquivalentSum.tax
        ),
        returns: formatAmount(
          dailyData.cashEquivalentSum.returns +
            dailyData.nonCashEquivalentSum.returns
        ),
        tip: formatAmount(
          dailyData.cashEquivalentSum.tip + dailyData.nonCashEquivalentSum.tip
        ),
        total: formatAmount(
          dailyData.cashEquivalentSum.total +
            dailyData.nonCashEquivalentSum.total
        ),
      };

      let sales = 0;
      tax = 0;
      let quantity = 0;
      returns = 0;
      let returnsqty = 0;
      total = 0;

      dailyData.byCategory.serviceCategory.forEach((service) => {
        sales += parseFloat(service.sales);
        tax += parseFloat(service.tax);
        quantity += parseInt(service.quantity);
        returns += parseFloat(service.returns);
        returnsqty += parseInt(service.returnsqty);
        total += parseFloat(service.total);
        service.sales = formatAmount(parseFloat(service.sales));
        service.tax = formatAmount(parseFloat(service.tax));
        service.quantity = formatQuantity(parseInt(service.quantity));
        service.returns = formatAmount(parseFloat(service.returns));
        service.returnsqty = formatQuantity(parseInt(service.returnsqty));
        service.total = formatAmount(parseFloat(service.total));
      });

      dailyData.serviceCategorySum = {
        sales: sales,
        tax: tax,
        quantity: quantity,
        returns: returns,
        returnsqty: returnsqty,
        total: total,
      };

      sales = 0;
      tax = 0;
      quantity = 0;
      returns = 0;
      returnsqty = 0;
      total = 0;

      dailyData.byCategory.productCategory.forEach((service) => {
        sales += parseFloat(service.sales);
        tax += parseFloat(service.tax);
        quantity += parseInt(service.quantity);
        returns += parseFloat(service.returns);
        returnsqty += parseInt(service.returnsqty);
        total += parseFloat(service.total);
        service.sales = formatAmount(parseFloat(service.sales));
        service.tax = formatAmount(parseFloat(service.tax));
        service.quantity = formatQuantity(parseInt(service.quantity));
        service.returns = formatAmount(parseFloat(service.returns));
        service.returnsqty = formatQuantity(parseInt(service.returnsqty));
        service.total = formatAmount(parseFloat(service.total));
      });

      dailyData.serviceCategoryProductSum = {
        sales: sales,
        tax: tax,
        quantity: quantity,
        returns: returns,
        returnsqty: returnsqty,
        total: total,
      };

      dailyData.totalAccruedSales = {
        sales:
          dailyData.serviceCategorySum.sales +
          dailyData.serviceCategoryProductSum.sales,
        tax:
          dailyData.serviceCategorySum.tax +
          dailyData.serviceCategoryProductSum.tax,
        quantity:
          dailyData.serviceCategorySum.quantity +
          dailyData.serviceCategoryProductSum.quantity,
        returns:
          dailyData.serviceCategorySum.returns +
          dailyData.serviceCategoryProductSum.returns,
        returnsqty:
          dailyData.serviceCategorySum.returnsqty +
          dailyData.serviceCategoryProductSum.returnsqty,
        total:
          dailyData.serviceCategorySum.total +
          dailyData.serviceCategoryProductSum.total,
      };

      sales = 0;
      tax = 0;
      quantity = 0;
      returns = 0;
      returnsqty = 0;
      total = 0;

      dailyData.byCategory.giftCard.forEach((service) => {
        sales += parseFloat(service.sales);
        tax += parseFloat(service.tax);
        quantity += parseInt(service.quantity);
        returns += parseFloat(service.returns);
        returnsqty += parseInt(service.returnsqty);
        total += parseFloat(service.total);
        service.sales = formatAmount(parseFloat(service.sales));
        service.tax = formatAmount(parseFloat(service.tax));
        service.quantity = formatQuantity(parseInt(service.quantity));
        service.returns = formatAmount(parseFloat(service.returns));
        service.returnsqty = formatQuantity(parseInt(service.returnsqty));
        service.total = formatAmount(parseFloat(service.total));
      });

      dailyData.serviceCategorygiftCardSum = {
        sales: sales,
        tax: tax,
        quantity: quantity,
        returns: returns,
        returnsqty: returnsqty,
        total: total,
      };

      dailyData.totalPaymentsSum = {
        sales: formatAmount(
          dailyData.totalAccruedSales.sales +
            dailyData.serviceCategorygiftCardSum.sales
        ),
        tax: formatAmount(
          dailyData.totalAccruedSales.tax +
            dailyData.serviceCategorygiftCardSum.tax
        ),
        quantity: formatAmount(
          dailyData.totalAccruedSales.quantity +
            dailyData.serviceCategorygiftCardSum.quantity
        ),
        returns: formatAmount(
          dailyData.totalAccruedSales.returns +
            dailyData.serviceCategorygiftCardSum.returns
        ),
        returnsqty: formatQuantity(
          dailyData.totalAccruedSales.returnsqty +
            dailyData.serviceCategorygiftCardSum.returnsqty
        ),
        total: formatAmount(
          dailyData.totalAccruedSales.total +
            dailyData.serviceCategorygiftCardSum.total
        ),
      };

      dailyData.totalAccruedSales = {
        sales: formatAmount(
          dailyData.serviceCategorySum.sales +
            dailyData.serviceCategoryProductSum.sales
        ),
        tax: formatAmount(
          dailyData.serviceCategorySum.tax +
            dailyData.serviceCategoryProductSum.tax
        ),
        quantity: formatQuantity(
          dailyData.serviceCategorySum.quantity +
            dailyData.serviceCategoryProductSum.quantity
        ),
        returns: formatAmount(
          dailyData.serviceCategorySum.returns +
            dailyData.serviceCategoryProductSum.returns
        ),
        returnsqty: formatQuantity(
          dailyData.serviceCategorySum.returnsqty +
            dailyData.serviceCategoryProductSum.returnsqty
        ),
        total: formatAmount(
          dailyData.serviceCategorySum.total +
            dailyData.serviceCategoryProductSum.total
        ),
      };

      dailyData.cashEquivalentSum = {
        receipt: formatAmount(dailyData.cashEquivalentSum.receipt),
        tax: formatAmount(dailyData.cashEquivalentSum.tax),
        returns: formatAmount(dailyData.cashEquivalentSum.returns),
        tip: formatAmount(dailyData.cashEquivalentSum.tip),
        total: formatAmount(dailyData.cashEquivalentSum.total),
      };

      dailyData.nonCashEquivalentSum = {
        receipt: formatAmount(dailyData.nonCashEquivalentSum.receipt),
        tax: formatAmount(dailyData.nonCashEquivalentSum.tax),
        returns: formatAmount(dailyData.nonCashEquivalentSum.returns),
        tip: formatAmount(dailyData.nonCashEquivalentSum.tip),
        total: formatAmount(dailyData.nonCashEquivalentSum.total),
      };

      dailyData.serviceCategorySum = {
        sales: formatAmount(dailyData.serviceCategorySum.sales),
        tax: formatAmount(dailyData.serviceCategorySum.tax),
        quantity: formatQuantity(dailyData.serviceCategorySum.quantity),
        returns: formatAmount(dailyData.serviceCategorySum.returns),
        returnsqty: formatQuantity(dailyData.serviceCategorySum.returnsqty),
        total: formatAmount(dailyData.serviceCategorySum.total),
      };
      dailyData.serviceCategoryProductSum = {
        sales: formatAmount(dailyData.serviceCategoryProductSum.sales),
        tax: formatAmount(dailyData.serviceCategoryProductSum.tax),
        quantity: formatQuantity(dailyData.serviceCategoryProductSum.quantity),
        returns: formatAmount(dailyData.serviceCategoryProductSum.returns),
        returnsqty: formatQuantity(
          dailyData.serviceCategoryProductSum.returnsqty
        ),
        total: formatAmount(dailyData.serviceCategoryProductSum.total),
      };

      dailyData.serviceCategorygiftCardSum = {
        sales: formatAmount(dailyData.serviceCategorygiftCardSum.sales),
        tax: formatAmount(dailyData.serviceCategorygiftCardSum.tax),
        quantity: formatQuantity(dailyData.serviceCategorygiftCardSum.quantity),
        returns: formatAmount(dailyData.serviceCategorygiftCardSum.returns),
        returnsqty: formatQuantity(
          dailyData.serviceCategorygiftCardSum.returnsqty
        ),
        total: formatAmount(dailyData.serviceCategorygiftCardSum.total),
      };
    };
    setState({
      status: "loading",
    });
    try {
      const getData = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: appState.nav.siteId,
          authorization: appState.singleAuth.token,
        },
      };
      const request = `api/site/${
        appState.nav.siteId
      }/sales/dailyCloseOut?startDate=${formatDate2string(
        startDate
      )}&endDate=${formatDate2string(endDate)}&locationId=${location.id}`;

      const getResponseData = await fetch(
        `${process.env.REACT_APP_API_URL}/${request}`,
        getData
      );

      if (getResponseData.ok && getResponseData.status === 200) {
        const dailyData = await getResponseData.json();
        addTotals(dailyData);
        setData(dailyData);
        setState((state) => ({
          ...state,
          status: "ready",
        }));
      } else {
        setData({});
      }
    } catch (error) {
      setData({});
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    const qDate = formatDate2object(new Date());
    const name =
      "DailyCloseout-" +
      appState.nav.siteId +
      "-" +
      appState.nav.locationId +
      "-" +
      qDate.mm +
      "" +
      qDate.dd +
      "" +
      qDate.yyyy +
      ".pdf";
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const postPDF = async () => {
    try {
      const infoPDF = { ...data };
      infoPDF.locations = [appState.nav.siteName + " - " + location.name];
      infoPDF.startDate = formatDate2string(startDate);
      infoPDF.endDate = formatDate2string(endDate);

      const postMethod = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(infoPDF),
      };
      const responseInfo = await fetch(
        `${process.env.REACT_APP_API_URL}/api/pdf/sales/dailyCloseOutPdf`,
        postMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
        if ("url" in info) {
          downloadFile(info.url);
        }
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  return (
    <div data-cy="daily-closeout">
      <div className="container">
        <div className="mt-5 lb-title-daily d-flex justify-content-center">
          <h4>Daily Closeout</h4>
        </div>
        <div className="mt-3 lb-title-daily d-flex justify-content-end">
          <h5>{formatDate2string(today)}</h5>
        </div>
        <div className="row mt-4 d-flex justify-content-center">
          <div className="col-12 col-md-3">
            <label>Locations</label>
          </div>
          <div className="col-12 col-md-3 pl-5">
            <label>Start Date</label>
          </div>
          <div className="col-12 col-md-3">
            <label>End Date</label>
          </div>
        </div>
        <div className="row  d-flex justify-content-center">
          <div className="col-12 col-md-3">
            <select
              data-cy="location-select"
              className="form-select"
              value={location.id}
              onChange={handleLocationChange}
            >
              {locations.map((option) => (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-3 text-center" data-cy="start-date">
            <DatePicker
              className="lb-datepicker-closeout"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              value={startDate}
            />
          </div>
          <div className="col-12 col-md-3" data-cy="end-date">
            <DatePicker
              className="lb-datepicker-closeout"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={startDate}
              minDate={startDate}
              value={startDate}
            />
          </div>
          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-4 text-center">
              <button
                className={"lb-button-daily"}
                data-cy="btn-search-daily-closeout"
                onClick={getData}
              >
                Consult Report.
              </button>
            </div>
          </div>
          {state.status === "loading" && (
            <div className="loading" data-cy="loading-report-message">
              <div className="lb-spinner" />
            </div>
          )}
          {(state.status === "error" ||
            appState.nav.status === "logOutError") && (
            <div className="container" data-cy="error">
              <div className="row">
                <div className="col">
                  <h1 className="lb-text-center">Error</h1>
                  <h2 className="lb-text-center">
                    Seems like there has been an error
                  </h2>
                  <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                    <span>
                      {appState.nav.status === "logOutError"
                        ? appState.nav.message
                        : state.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          section="tables mt-4"
          className="row mt-4 d-flex justify-content-center"
        >
          {(state.status === "ready" || state.status === "success") && (
            <>
              <h6 className="lb-title-daily">Sales by Payment Type</h6>
              <div className="col-12 table-responsive">
                <table
                  className="table table-borderless table-sm"
                  data-cy="payment-table"
                >
                  <thead className="lb-thead-lila">
                    <tr>
                      <th scope="col" className="text-center">
                        Name
                      </th>
                      <th scope="col" className="text-center">
                        Receipt
                      </th>
                      <th scope="col" className="text-center">
                        Tax
                      </th>
                      <th scope="col" className="text-center">
                        Returns
                      </th>
                      <th scope="col" className="text-center">
                        Tip
                      </th>
                      <th scope="col" className="text-center">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="col">Payment Type</th>
                    </tr>
                    {data.byPayment.cashEquivalent.map((paymentType) => {
                      return (
                        <tr key={paymentType.name}>
                          <td>{paymentType.name}</td>
                          <td className="text-end">${paymentType.receipt}</td>
                          <td className="text-end">${paymentType.tax}</td>
                          <td className="text-end lb-red-text">
                            ${paymentType.returns}
                          </td>
                          <td className="text-end">${paymentType.tip}</td>
                          <td className="text-end">${paymentType.total}</td>
                        </tr>
                      );
                    })}
                    <tr className="border-top border-bottom border-dark">
                      <th scope="row">Cash Equivalent Receipts</th>
                      <td className="text-end fw-bold">
                        ${data.cashEquivalentSum.receipt}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.cashEquivalentSum.tax}
                      </td>
                      <td className="text-end fw-bold lb-red-text">
                        ${data.cashEquivalentSum.returns}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.cashEquivalentSum.tip}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.cashEquivalentSum.total}
                      </td>
                    </tr>
                    {data.byPayment.nonCashEquivalent.map((paymentTypeNon) => {
                      return (
                        <tr key={paymentTypeNon.name}>
                          <td>{paymentTypeNon.name}</td>
                          <td className="text-end">
                            ${paymentTypeNon.receipt}
                          </td>
                          <td className="text-end">${paymentTypeNon.tax}</td>
                          <td className="text-end lb-red-text">
                            ${paymentTypeNon.returns}
                          </td>
                          <td className="text-end">${paymentTypeNon.tip}</td>
                          <td className="text-end">${paymentTypeNon.total}</td>
                        </tr>
                      );
                    })}
                    <tr className="border-top border-dark">
                      <th scope="row">Non-Cash Equivalent Receipts</th>
                      <td className="text-end fw-bold">
                        ${data.nonCashEquivalentSum.receipt}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.nonCashEquivalentSum.tax}
                      </td>
                      <td className="text-end fw-bold lb-red-text">
                        ${data.nonCashEquivalentSum.returns}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.nonCashEquivalentSum.tip}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.nonCashEquivalentSum.total}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot className="border-top border-bottom border-dark">
                    <tr>
                      <th scope="row">Total Receipts</th>
                      <td className="text-end fw-bold">
                        ${data.totalReceipts.receipt}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.totalReceipts.tax}
                      </td>
                      <td className="text-end fw-bold lb-red-text">
                        ${data.totalReceipts.returns}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.totalReceipts.tip}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.totalReceipts.total}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <h6 className="lb-title-daily mt-4">Sales by Category</h6>
              <div className="col-12 table-responsive">
                <table
                  className="table table-borderless table-sm"
                  data-cy="categories-table"
                >
                  <thead className="lb-thead-lila">
                    <tr>
                      <th scope="col" className="text-center">
                        Name
                      </th>
                      <th scope="col" className="text-center">
                        Sales
                      </th>
                      <th scope="col" className="text-center">
                        Tax
                      </th>
                      <th scope="col" className="text-center">
                        Qty
                      </th>
                      <th scope="col" className="text-center">
                        Returns
                      </th>
                      <th scope="col" className="text-center">
                        Qty
                      </th>
                      <th scope="col" className="text-center">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="col">Service Categories</th>
                    </tr>
                    {data.byCategory.serviceCategory.map((category) => {
                      return (
                        <tr key={category.name}>
                          <td>{category.name}</td>
                          <td className="text-end">${category.sales}</td>
                          <td className="text-end">${category.tax}</td>
                          <td className="text-end">{category.quantity}</td>
                          <td className="text-end lb-red-text">
                            ${category.returns}
                          </td>
                          <td className="text-end">{category.returnsqty}</td>
                          <td className="text-end">${category.total}</td>
                        </tr>
                      );
                    })}

                    <tr className="border-top border-bottom border-dark">
                      <th scope="row">Service Subtotals</th>
                      <td className="text-end fw-bold">
                        ${data.serviceCategorySum.sales}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.serviceCategorySum.tax}
                      </td>
                      <td className="text-end fw-bold">
                        {data.serviceCategorySum.quantity}
                      </td>
                      <td className="text-end fw-bold lb-red-text">
                        ${data.serviceCategorySum.returns}
                      </td>
                      <td className="text-end fw-bold">
                        {data.serviceCategorySum.returnsqty}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.serviceCategorySum.total}
                      </td>
                    </tr>
                    <tr>
                      <td className="lb-td-height"></td>
                    </tr>
                    <tr>
                      <th scope="col">Product Categories</th>
                    </tr>
                    {data.byCategory.productCategory.map((categoryProduct) => {
                      return (
                        <tr key={categoryProduct.name}>
                          <td>{categoryProduct.name}</td>
                          <td className="text-end">${categoryProduct.sales}</td>
                          <td className="text-end">${categoryProduct.tax}</td>
                          <td className="text-end">
                            {categoryProduct.quantity}
                          </td>
                          <td className="text-end lb-red-text">
                            ${categoryProduct.returns}
                          </td>
                          <td className="text-end">
                            {categoryProduct.returnsqty}
                          </td>
                          <td className="text-end">${categoryProduct.total}</td>
                        </tr>
                      );
                    })}
                    <tr className="border-top border-bottom border-dark">
                      <th scope="row">Products Subtotals</th>
                      <td className="text-end fw-bold">
                        ${data.serviceCategoryProductSum.sales}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.serviceCategoryProductSum.tax}
                      </td>
                      <td className="text-end fw-bold">
                        {data.serviceCategoryProductSum.quantity}
                      </td>
                      <td className="text-end fw-bold lb-red-text">
                        ${data.serviceCategoryProductSum.returns}
                      </td>
                      <td className="text-end fw-bold">
                        {data.serviceCategoryProductSum.returnsqty}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.serviceCategoryProductSum.total}
                      </td>
                    </tr>
                    <tr>
                      <td className="lb-td-height"></td>
                    </tr>
                    <tr className="border-top border-bottom border-dark">
                      <th scope="row">Total Accrued Sales</th>
                      <td className="text-end fw-bold">
                        ${data.totalAccruedSales.sales}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.totalAccruedSales.tax}
                      </td>
                      <td className="text-end fw-bold">
                        {data.totalAccruedSales.quantity}
                      </td>
                      <td className="text-end fw-bold lb-red-text">
                        ${data.totalAccruedSales.returns}
                      </td>
                      <td className="text-end fw-bold">
                        {data.totalAccruedSales.returnsqty}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.totalAccruedSales.total}
                      </td>
                    </tr>
                    <tr>
                      <td className="lb-td-height"></td>
                    </tr>
                    <tr>
                      <th scope="col">Prepaid giftcard</th>
                    </tr>
                    {data.byCategory.giftCard.map((categoryGiftcard) => {
                      return (
                        <tr key={categoryGiftcard.name}>
                          <td>{categoryGiftcard.name}</td>
                          <td className="text-end">
                            ${categoryGiftcard.sales}
                          </td>
                          <td className="text-end">${categoryGiftcard.tax}</td>
                          <td className="text-end">
                            {categoryGiftcard.quantity}
                          </td>
                          <td className="text-end lb-red-text">
                            ${categoryGiftcard.returns}
                          </td>
                          <td className="text-end">
                            {categoryGiftcard.returnsqty}
                          </td>
                          <td className="text-end">
                            ${categoryGiftcard.total}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="border-top border-bottom border-dark">
                      <th scope="row">Total Payments on Account</th>
                      <td className="text-end fw-bold">
                        ${data.serviceCategorygiftCardSum.sales}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.serviceCategorygiftCardSum.tax}
                      </td>
                      <td className="text-end fw-bold">
                        {data.serviceCategorygiftCardSum.quantity}
                      </td>
                      <td className="text-end fw-bold lb-red-text">
                        ${data.serviceCategorygiftCardSum.returns}
                      </td>
                      <td className="text-end fw-bold">
                        {data.serviceCategorygiftCardSum.returnsqty}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.serviceCategorygiftCardSum.total}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot className="border-top border-bottom border-dark">
                    <tr>
                      <th scope="row">Total Payments</th>
                      <td className="text-end  fw-bold">
                        ${data.totalPaymentsSum.sales}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.totalPaymentsSum.tax}
                      </td>
                      <td className="text-end fw-bold">
                        {data.totalPaymentsSum.quantity}
                      </td>
                      <td className="text-end fw-bold lb-red-text">
                        ${data.totalPaymentsSum.returns}
                      </td>
                      <td className="text-end fw-bold">
                        {data.totalPaymentsSum.returnsqty}
                      </td>
                      <td className="text-end fw-bold">
                        ${data.totalPaymentsSum.total}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="col-4 text-center mb-4">
                <button
                  className={
                    state.status === "ready"
                      ? "lb-button-daily"
                      : "lb-button-daily-disabled "
                  }
                  data-cy="btn-download-pdf"
                  onClick={postPDF}
                  disabled={state.status === "success"}
                >
                  Download PDF
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

DailyCloseout.propTypes = {
  appState: PropTypes.object.isRequired,
  setAppState: PropTypes.func.isRequired,
};

export default DailyCloseout;
