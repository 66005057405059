import React, { useState, useEffect, Suspense } from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDownload, faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { formatAmount } from "../util/formatNumbers.js";
import PdfTable from "./pdfs/historicalReportPDF.js";
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from "moment";
 
const OperationalDashboardTable = ({
    operationalData,
    state,
    realodDashboard,
}) => {
    const table = {header: {fontSize:"16px"}, body: {fontSize:"17px"}}
    const reportDate = moment(new Date).format("MM/DD/YY hh:mm:ss A");
    const [sitesData, setSitesData] = useState(operationalData);
    const [btnPdf, setBtnPdf] = useState(false);
    const [sortTable, setSortTable] = useState({
        sort: true,
        sortBySites: true,
        sortBySales: true,
    });
    const [totals, setTotals] = useState({
        online: 0,
        callCenter: 0,
        mindBody: 0,
        appointmentsBooked: 0,
        appointments: 0,
        sales: 0,
        cbffGranted: 0,
        expectedSales: 0,
        completed: 0,
        noShow: 0,
        pending: 0,
        cbff: 0,
        dicomOk: 0,
    });
    const [viewTableColumn, setViewTableColumn] = useState({
        madeBy: true,
        appointments: false,
        sales: false,
    });
    const [selectRows, setSelectRows] = useState(false);
    const [buttonSelectRows, setButtonSelectRows] = useState({
        select: false,
        viewAllRows: false,
        disabled: true,
    });

    const handleSortTableBySites = () => {
        setSortTable((sortTable) => ({
            ...sortTable,
            sortBySites: !sortTable.sortBySites
        }));
        if (sortTable.sortBySites) {
            sitesData.sort((a, b) => {
                if (a.siteName < b.siteName) return 1;
                if (a.siteName > b.siteName) return -1;
                return 0;
            });
        } else {
            sitesData.sort((a, b) => {
                if (a.siteName > b.siteName) return 1;
                if (a.siteName < b.siteName) return -1;
                return 0;
            });
        }
    }
    const handleSortTableBySales = () => {
        setSortTable((sortTable) => ({
            ...sortTable,
            sortBySales: !sortTable.sortBySales
        }));
        if (sortTable.sortBySales) {
            sitesData.sort((a, b) => {
                if (a.data.totalSales > b.data.totalSales) return 1;
                if (a.data.totalSales < b.data.totalSales) return -1;
                return 0;
            });
        } else {
            sitesData.sort((a, b) => {
                if (a.data.totalSales < b.data.totalSales) return 1;
                if (a.data.totalSales > b.data.totalSales) return -1;
                return 0;
            });
        }
    }
    const handleViewTableColumn = (e) => {
        const { name } = e.target;
        switch (name) {
            case "madeBy":
                setViewTableColumn((viewTableColumn) => ({
                    ...viewTableColumn,
                    madeBy: !viewTableColumn.madeBy,
                }));
                break;
            case "sales":
                setViewTableColumn((viewTableColumn) => ({
                    ...viewTableColumn,
                    sales: !viewTableColumn.sales,
                }));
                break;
            case "appointments":
                setViewTableColumn((viewTableColumn) => ({
                    ...viewTableColumn,
                    appointments: !viewTableColumn.appointments,
                }));
                break;
            default:
                break;
        }
    }
    const handleViewSelectedRows = (e) => {
        const selectedSitesRows = sitesData.filter((i) => { return i.checked === true });
        if (selectedSitesRows.length !== 0) {
            setSitesData(selectedSitesRows)
            setButtonSelectRows((buttonSelectRows) => ({
                ...buttonSelectRows,
                select: true,
                viewAllRows: true,
            }));
        }
    }
    const handleSitesSelectedByRows = (e, row) => {
        const name = "checked";
        const newList = [...sitesData];
        newList[row][name] = !newList[row][name];
        setSitesData(newList);
        const selectedSitesRows = sitesData.filter((i) => { return i.checked === true });

        if (selectedSitesRows.length !== 0) {
            setButtonSelectRows((buttonSelectRows) => ({
                ...buttonSelectRows,
                disabled: false,
            }));
        } else {
            setButtonSelectRows((buttonSelectRows) => ({
                ...buttonSelectRows,
                disabled: true,
            }));
        }
    }
    const handleViewAllRows = () => {
        setButtonSelectRows((buttonSelectRows) => ({
            ...buttonSelectRows,
            select: false,
            viewAllRows: false,
            disabled: true
        }));
        const newSitesData = [];
        operationalData.forEach(element => {
            newSitesData.push({
                data: element.data,
                locationId: element.locationId,
                siteId: element.siteId,
                siteName: element.siteName,
                checked: false,
            })
        });
        setSitesData(newSitesData);
    }
    useEffect(() => {
        const newSitesData = [];

        sitesData.forEach(element => {
            newSitesData.push({
                data: element.data,
                locationId: element.locationId,
                siteId: element.siteId,
                siteName: element.siteName,
                checked: false,
            });
        });

        setSitesData(newSitesData);
    }, [state.table, realodDashboard.action]);


    useEffect(() => {
        let totalSales = 0;
        let totalOnline = 0;
        let totalCallCenter = 0;
        let totalMindBody = 0;
        let totalColumnAppointmentBooked = 0;
        let totalAppointments = 0;
        let totalAppointmentsBooked = 0;
        let totalCbffGranted = 0;
        let totalExpectedSales = 0;
        let totalCompleted = 0;
        let totalnoShow = 0;
        let totalColumnPending = 0;
        let totalCbff = 0;
        let dicomOk = 0;

        sitesData.forEach(i => {
            totalOnline += i.data.origin.online;
            totalCallCenter += i.data.origin.callCenter;
            totalMindBody += i.data.origin.mindBody;
            totalAppointments += i.data.totalAppointments;
            totalSales += i.data.totalSales;
            totalExpectedSales += i.data.totalExpectedSales;
            totalCompleted += i.data.completed;
            totalnoShow += i.data.noShow;
            totalColumnPending += i.data.confirmed + i.data.booked + i.data.arrived;
            totalCbff += i.data.cbff;
            totalColumnAppointmentBooked = i.data.origin.callCenter + i.data.origin.online +
                (i.data.origin.mindBody ? i.data.origin.mindBody : 0);
            totalAppointmentsBooked += totalColumnAppointmentBooked;
            totalCbffGranted += i.data.cbffGranted;
            dicomOk += i.data.dicomOk;
        });

        setTotals((totals) => ({
            ...totals,
            online: totalOnline,
            callCenter: totalCallCenter,
            mindBody: isNaN(totalMindBody) ? 0 : totalMindBody,
            appointmentsBooked: totalAppointmentsBooked,
            appointments: totalAppointments,
            sales: totalSales,
            cbffGranted: isNaN(totalCbffGranted) ? 0 : totalCbffGranted,
            expectedSales: totalExpectedSales,
            completed: totalCompleted,
            noShow: totalnoShow,
            pending: totalColumnPending,
            cbff: totalCbff,
            dicomOk: dicomOk
        }));
    }, [sitesData, totals.sales])

    useEffect(() => {
        if (!viewTableColumn.appointments && !viewTableColumn.sales) {
            setViewTableColumn((viewTableColumn) => ({
                ...viewTableColumn,
                madeBy: true
            }));
        }
    }, [viewTableColumn.appointments, viewTableColumn.madeBy, viewTableColumn.sales]);

    useEffect(() => {
        const clickedButtonTrigger = document.getElementById('btn-create-pdf');
        if (clickedButtonTrigger) {
            setTimeout(() => {
                clickedButtonTrigger.click();
                clickedButtonTrigger.addEventListener('mousedown', () => { });
            }, 500);
        }
    }, [btnPdf]);
    let totalColumnAppointmentBooked = 0;

    return (
        <div className="container">
            <div className="d-flex mt-4">
                <div className="col-6 d-flex">
                    <div>
                        {!btnPdf ?
                            <button disabled={btnPdf} data-cy="btn-create-pdf" onClick={(e) => setBtnPdf(!btnPdf)} className="btn pt-2 pb-2 border text rounded-pill m-2 btn-sm"
                                style={{ color: "white", width: 65, backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff" }}
                            ><FontAwesomeIcon icon={faDownload} style={{ fontSize: 20 }} /></button>
                            :
                            <Suspense>
                                <PDFDownloadLink document={
                                    <PdfTable
                                        sitesData={sitesData}
                                        viewTableColumn={viewTableColumn}
                                        reportDate={reportDate}
                                        totals={totals}
                                    />
                                } fileName={`reportHistorical.pdf`}>
                                    {({ loading }) => (loading
                                        ?
                                        <button
                                            className="btn  border text rounded-pill pt-2 pb-2 m-2 btn-sm"
                                            style={{ color: "white", width: 65, backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff" }}>
                                            <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: 20 }} />
                                        </button>
                                        :
                                        <button
                                            data-cy="btn-create-pdf" id="btn-create-pdf"
                                            onClick={(e) => setBtnPdf(!btnPdf)}
                                            className="btn border pt-2 pb-2 text rounded-pill m-2 btn-sm"
                                            style={{ color: "white", width: 65, backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff" }}>
                                            <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: 20 }} />
                                        </button>
                                    )}
                                </PDFDownloadLink>
                            </Suspense>
                        }
                    </div>
                </div>
                <div className="justify-content-end d-flex col-6">
                    <button
                        className="btn border rounded-pill m-2" data-cy="button-made-by" name="madeBy"
                        onClick={(e) => handleViewTableColumn(e)}
                        style={viewTableColumn.madeBy ? { backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff", width: 100, color: "white" } : { color: "black", backgroundColor: "white", width: 100 }}>
                        Made By
                    </button>
                    <button
                        className="btn border rounded-pill m-2" data-cy="button-sales" name="sales"
                        onClick={(e) => handleViewTableColumn(e)}
                        style={viewTableColumn.sales ? { backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff", width: 65, color: "white" } : { color: "black", backgroundColor: "white", width: 65 }}>
                        Sales
                    </button>
                    <button
                        className="btn border rounded-pill m-2" data-cy="button-appointments" name="appointments"
                        onClick={(e) => handleViewTableColumn(e)}
                        style={viewTableColumn.appointments ? { backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff", width: 150, color: "white" } : { color: "black", backgroundColor: "white", width: 150 }}>
                        Appointments
                    </button>
                </div>
            </div>

            {buttonSelectRows.viewAllRows && (
                <button className="btn text border rounded-pill m-2"
                    style={{ backgroundColor: "#c2608e", color: "white", borderColor: "#c2608e #c2608e #fff" }}
                    data-cy="button-view-all-rows"
                    onClick={handleViewAllRows}>
                    <FontAwesomeIcon className="mt-1" icon={faEye} style={{ fontSize: 20 }} /> &nbsp;&nbsp;View All
                </button>)}

            <table className="table-responsive table table-striped table-sm mt-4 shadow">
                <thead className="lb-thead-lila">
                    <tr>
                        <th scope="col" className="text-center" >
                            <FontAwesomeIcon name="selectRows" className={selectRows ? "mt-1 text-white" : "mt-1 text-dark"}
                                style={{ cursor: 'pointer' }} icon={faCheck} onClick={(e) => { setSelectRows(!selectRows) }}
                                data-cy="button-select-rows"
                            />
                        </th>
                        <th scope="col" className="text-center">
                        <span style={{ cursor: 'pointer', fontSize:"12px" }}
                                className={sortTable.sortBySites ? "text-white" : "text-dark"}
                                onClick={handleSortTableBySites}
                                data-cy="button-sort-table-sites"
                            >
                                <span className="text-white" style={{fontSize:"17px"}} >SITE</span>
                                &nbsp;&nbsp;{sortTable.sortBySites ? "▲" : "▼"}
                            </span>
                        </th>
                        {viewTableColumn.madeBy && (
                            <>
                                <th scope="col" className="text-center" style={table.header}>
                                    ONLINE
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    EMPATHY CENTER
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    MIND BODY
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    APPOINTMENTS BOOKED
                                </th>
                            </>
                        )}
                        {viewTableColumn.appointments && (
                            <>
                                <th scope="col" className="text-center" style={table.header}>
                                    DICOM
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    ATTENDED
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    NO SHOW
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    PENDING
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    CBFF
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    CBFF GRANTED
                                </th>
                                <th scope="col" className="text-center" style={table.header}>
                                    APPOINTMENTS
                                </th>
                            </>
                        )}
                        {viewTableColumn.sales && (
                            <>
                                <th scope="col" className="text-center" style={table.header}>
                                    EXPECTED SALES
                                </th>
                                <th scope="col" className="text-center">
                                    <span style={{ cursor: 'pointer', fontSize:"12px" }}
                                        className={sortTable.sortBySales ? "text-dark" : "text-white"}
                                        onClick={handleSortTableBySales}
                                        data-cy="button-sort-table-sales"
                                    >
                                        <span className="text-white" style={{ fontSize:"15px" }}>SALES</span>
                                        &nbsp;&nbsp;{sortTable.sortBySales ? "▲" : "▼"}
                                    </span>
                                </th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {sitesData.map((location, i) => {

                        totalColumnAppointmentBooked = location.data.origin.callCenter + location.data.origin.online +
                            (location.data.origin.mindBody ? location.data.origin.mindBody : 0);

                        return (
                            <tr key={i}
                                onClick={(e) => { selectRows && (handleSitesSelectedByRows(e, i)) }}
                                style={selectRows ? { cursor: 'pointer' } : { color: "black" }}
                                data-cy={`button-clicked-table-row-${i}`}>

                                <td className="text-end">
                                    {selectRows ?
                                        <input type="checkbox" className="lb-form-check-input form-check-input"
                                            checked={location.checked} readOnly />
                                        : <FontAwesomeIcon className="mt-1" icon={faEye} />
                                    }
                                </td>

                                <td className="text-center" style={table.body}>
                                    {location.siteName?.split("-")[1]} 
                                </td>
                                {viewTableColumn.madeBy && (
                                    <>
                                        <td className="text-center" style={table.body}>
                                            <p>{location.data.origin.online}</p>
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {location.data.origin.callCenter}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {location.data.origin.mindBody}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {totalColumnAppointmentBooked}
                                        </td>
                                    </>
                                )}
                                {viewTableColumn.appointments && (
                                    <>
                                        <td className="text-center" style={table.body}>
                                            {location.data.dicomOk}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {location.data.completed}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {location.data.noShow}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {location.data.confirmed + location.data.booked + location.data.arrived}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {location.data.cbff}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {location.data.cbffGranted}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            {location.data.totalAppointments}
                                        </td>
                                    </>
                                )}
                                {viewTableColumn.sales && (
                                    <>
                                        <td className="text-center" style={table.body}>
                                            ${formatAmount(location.data.totalExpectedSales)}
                                        </td>
                                        <td className="text-center" style={table.body}>
                                            ${formatAmount(location.data.totalSales)}
                                        </td>
                                    </>
                                )}
                            </tr>)
                    })}
                </tbody>
                <tfoot className=" border-bottom border-dark">
                    <tr>
                        <td className="text-center" />
                        <td className="text-center" />
                        {viewTableColumn.madeBy && (
                            <>
                                <td className="text-center" style={table.body}>
                                    {totals.online}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.callCenter}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.mindBody}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.appointmentsBooked}
                                </td>
                            </>
                        )}
                        {viewTableColumn.appointments && (
                            <>
                                <td className="text-center" style={table.body}>
                                    {totals.dicomOk}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.completed}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.noShow}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.pending}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.cbff}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.cbffGranted}
                                </td>
                                <td className="text-center" style={table.body}>
                                    {totals.appointments}
                                </td>
                            </>
                        )}
                        {viewTableColumn.sales && (
                            <>
                                <td className="text-center" style={table.body}>
                                    ${formatAmount(totals.expectedSales)}
                                </td>
                                <td className="text-center" style={table.body}>
                                    ${formatAmount(totals.sales)}
                                </td>
                            </>
                        )}
                    </tr>
                </tfoot>
            </table>
            {selectRows && (
                <div className="text-center">
                    <button
                        className="btn pl-4 pr-4" disabled={buttonSelectRows.disabled}
                        style={{ backgroundColor: "#c2608e", color: "white", borderColor: "#c2608e #c2608e #fff" }}
                        data-cy="button-selected-rows" onClick={(e) => handleViewSelectedRows(e)}
                    > Select
                    </button>
                </div>)}
            <br /><br />
        </div>
    )
};
OperationalDashboardTable.propTypes = {
    operationalData: PropTypes.array,
    state: PropTypes.object,
    realodDashboard: PropTypes.object,
};
export default OperationalDashboardTable;
