import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import DatePicker from "react-datepicker";
import distinctColors from "distinct-colors";
import { formatDate2string } from "../util/index.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import "../styles/global.css";
import PieChart from "../components/pieChart";
import "react-datepicker/dist/react-datepicker.css";

function ConsultTechnicians({ appState }) {
  const [staffDetails, setStaffDetails] = useState([]);
  const [palette, setPalette] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [locations, setLocations] = useState([]);
  const [selection, setSelection] = useState({});
  const [grouping, setGrouping] = useState("Simple");
  const [search, setSearch] = useState("");
  const groupType = ["Simple", "Detailed"];
  const [emailStatus, setEmailStatus] = useState({
    clicked: false,
  });
  const [alertMessage, setAlertMessage] = useState({
    visible: false,
    message: "",
    success: false,
  });
  const [state, setState] = useState({
    status: "ready",
  });

  const filterHelper = (query, value) => {
    try {
      const sSquery = new RegExp(query.trim().toLowerCase(), "g");
      const sValue = value.toLowerCase();
      return sSquery.test(sValue);
    } catch (error) {
      return true;
    }
  };

  const updateLocation = (loc) => {
    loc = loc.split("-");
    const siteId = loc[0];
    const locationId = loc[1];

    setSelection((selection) => ({
      ...selection,
      siteId: siteId,
      locationId: locationId,
    }));

    setEmailStatus({ clicked: false });
  };
  const handleLocationChange = (event) => {
    updateLocation(event.target.value);
  };

  const handleGroupingChange = (event) => {
    setGrouping(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const getServiceName = (detailedName) => {
    if (grouping === "Simple") {
      const parts = detailedName.split("-");
      const shortName = parts[0].trim();
      return shortName;
    } else {
      return detailedName;
    }
  };

  const serviceCompareCount = (a, b) => {
    try {
      if (a.count < b.count) {
        return -1;
      } else if (a.count > b.count) {
        return 1;
      } else {
        return 0;
      }
    } catch (e) {
      return 0;
    }
  };
  const serviceCompareName = (a, b) => {
    try {
      return a.serviceName.localeCompare(b.serviceName);
    } catch (e) {
      return 0;
    }
  };

  const sortTechnician =
    ({ name, criteria }) =>
    () => {
      const sortedStaff = staffDetails.map((staff) => {
        if (staff.staffName === name) {
          if (criteria === "quantity") {
            staff.services.sort(serviceCompareCount).reverse();
          } else if (criteria === "service") {
            staff.services.sort(serviceCompareName);
          }
        }
        return staff;
      });
      setStaffDetails(sortedStaff);
    };

  useEffect(() => {
    const getLocations = async () => {
      try {
        const getMethod = {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            siteid: appState.nav.siteId,
            authorization: appState.singleAuth.token,
          },
        };
        const getResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/sites/all/locations/`,
          getMethod
        );

        if (getResponse.ok && getResponse.status === 200) {
          const locationData = await getResponse.json();
          updateLocation(locationData.siteslist[0].id);
          setLocations(locationData.siteslist);
        } else {
          setLocations([]);
        }
      } catch (error) {
        setLocations([]);
      }
    };

    getLocations();
  }, [appState.nav.siteId, appState.singleAuth.token]);

  const getColorPalette = (data) => {
    const staffOnly = data.appointments.filter(
      (app) => "serviceName" in app && app.serviceName
    );
    const colorPalette = [];
    const services = [
      ...new Set(staffOnly.map((app) => getServiceName(app.serviceName))),
    ];
    const colors = distinctColors({
      count: services.length,
      hueMin: 270,
      hueMax: 330,
      chromaMin: 0,
      chromaMax: 100,
      lightMin: 40,
      lightMax: 100,
    });
    services.forEach((service, index) => {
      const color = {
        serviceName: service,
        color: colors[index].hex(),
        border: colors[index].brighten(0.2).hex(),
      };
      colorPalette.push(color);
    });
    return colorPalette;
  };

  const getStaff = (data) => {
    const staffOnly = data.appointments.filter(
      (app) =>
        "serviceName" in app &&
        app.serviceName &&
        "staffName" in app &&
        app.staffName
    );
    const staff = [...new Set(staffOnly.map((app) => app.staffName))];
    staff.unshift("All appointments");
    staff.push("Unkown Staff");
    return staff;
  };

  const getGroupedData = (staff, data) => {
    const staffData = [];
    let memberData;
    staff.forEach((member) => {
      if (member === "Unkown Staff") {
        memberData = data.appointments.filter(
          (app) =>
            "serviceName" in app &&
            app.serviceName &&
            (!("staffName" in app) || app.staffName === "")
        );
      } else if (member === "All appointments") {
        memberData = data.appointments.filter(
          (app) => "serviceName" in app && app.serviceName
        );
      } else {
        memberData = data.appointments.filter(
          (app) =>
            "serviceName" in app &&
            app.serviceName &&
            "staffName" in app &&
            app.staffName === member
        );
      }

      const services = [
        ...new Set(memberData.map((app) => getServiceName(app.serviceName))),
      ];
      const memberServices = [];
      services.forEach((service) => {
        const count = memberData.filter(
          (app) => getServiceName(app.serviceName) === service
        ).length;
        const serviceCount = { serviceName: service, count: count };
        memberServices.push(serviceCount);
      });
      memberServices.sort(serviceCompareCount).reverse();
      const cbff = memberData.filter(
        (app) => "cbff" in app && app.cbff !== "nA"
      ).length;

      const cbffServed = memberData.filter(
        (app) => app.serviceName === "Come back for free"
      ).length;
      const count = memberData.length;
      const memberDetails = {
        staffName: member,
        services: memberServices,
        cbff: cbff,
        count: count,
        cbffServed: cbffServed,
      };
      staffData.push(memberDetails);
    });
    return staffData;
  };

  const getData = async () => {
    setState({ status: "loading" });
    try {
      const ReportGetMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };

      const request = `api/dynamoDB/sites/${selection.siteId}/locations/${
        selection.locationId
      }/sales?sortDate=${formatDate2string(
        startDate
      )}&sortDateTo=${formatDate2string(endDate)}`;
      const reportResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/${request}`,
        ReportGetMethod
      );

      if (reportResponse.ok) {
        const data = await reportResponse.json();
        const staff = getStaff(data);
        const palette = getColorPalette(data);
        setPalette(palette);
        const staffData = getGroupedData(staff, data);
        setStaffDetails(staffData);
        setAlertMessage({ visile: false });
      } else {
        setStaffDetails([]);
        setAlertMessage({
          visible: true,
          message: "No appointments were found",
          success: false,
        });
      }
    } catch (error) {
      setStaffDetails([]);
      setAlertMessage({
        visible: true,
        message: "No appointments were found" + JSON.stringify(error),
        success: false,
      });
    }
    setState({ status: "ready" });
  };

  const getMehodPDF = async (siteId, locationId) => {
    try {
      const ReportGetMethodPDF = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };

      const requestPDF = `api/pdf/dynamoDB/sales?sortDate=${formatDate2string(
        startDate
      )}&sortDateTo=${formatDate2string(endDate)}`;
      const reportResponsePDF = await fetch(
        `${process.env.REACT_APP_API_URL}/${requestPDF}`,
        ReportGetMethodPDF
      );

      if (reportResponsePDF.ok) {
        const reportDataPDF = await reportResponsePDF.json();
        setEmailStatus({
          clicked: true,
        });
        setAlertMessage({
          visible: true,
          message: reportDataPDF.message,
          success: true,
        });
      } else {
        setAlertMessage({
          visible: true,
          message: "The PDF couldn't be send ",
          success: false,
        });
      }
    } catch (error) {
      setEmailStatus({
        clicked: true,
      });
      setAlertMessage({
        visible: true,
        message: "The PDF couldn't be send: " + JSON.stringify(error),
        success: false,
      });
    }
  };
  useEffect(() => {
    setEmailStatus({ clicked: false });
    setAlertMessage({
      visible: false,
      message: "",
      success: false,
    });
    setSearch("");
    setStaffDetails([]);
  }, [startDate, endDate, selection]);

  useEffect(() => {
    if (staffDetails.length > 0) {
      getData();
    }
  }, [grouping]);

  const handlePDFButton = () => {
    setState({ status: "loading" });
    getMehodPDF(selection.siteId, selection.locationId);
    setState({ status: "ready" });
  };

  return (
    <div>
      <div className="container">
        <div className="mt-5 lb-title-daily d-flex justify-content-center">
          <h4>Consult Technicians</h4>
        </div>
        <div className="row mt-4 justify-content-center">
          <div className="col-4"></div>
          <div className="col-2">
            <label>Start Date</label>
          </div>
          <div className="col-2">
            <label>End Date</label>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-4">
            <select
              data-cy="location-select"
              className="form-select"
              value={selection.siteId + "-" + selection.locationId}
              onChange={handleLocationChange}
            >
              {locations.map((option) => (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-2 " data-cy="start-date">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              value={startDate}
            />
          </div>
          <div className="col-2" data-cy="end-date">
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={startDate}
              minDate={startDate}
              value={startDate}
            />
          </div>
          <div className="col-2">
            <select
              data-cy="grouping-select"
              className="form-select"
              value={grouping}
              onChange={handleGroupingChange}
            >
              {groupType.map((option) => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-4 text-center">
            <button
              className={"lb-button-daily"}
              data-cy="btn-search-technicians"
              onClick={getData}
            >
              Consult technicians
            </button>
          </div>

          <div className="col-4 text-center">
            <button
              className={
                !emailStatus.clicked
                  ? "lb-button-daily"
                  : "lb-button-daily-disabled"
              }
              data-cy="btn-create-pdf"
              disabled={emailStatus.clicked}
              onClick={handlePDFButton}
            >
              Create and Send PDF
            </button>
          </div>

          {alertMessage.visible && (
            <div
              data-cy="alert-message"
              className={
                alertMessage.success
                  ? "alert alert-success mt-5 col-6 text-center"
                  : "alert alert-danger mt-5 col-6 text-center"
              }
              role="alert"
            >
              {alertMessage.message}
            </div>
          )}
        </div>
      </div>
      {state.status === "loading" && (
        <div className="loading" data-cy="loading-report-message">
          <div className="lb-spinner" />
        </div>
      )}
      {staffDetails.length > 0 && (
        <div className="container mt-5">
          <div className="row m-4 d-flex justify-content-center">
            <div className="col-8 text-center">
              <input
                data-cy="search-bar"
                type="text"
                className="lb-form-control lb-border-0 lb-inputBackground"
                placeholder="Search"
                onChange={handleSearchChange}
                value={search}
              />
            </div>
          </div>
          <div className="row">
            {staffDetails
              .filter(
                (technician) =>
                  filterHelper(search, technician.staffName) &&
                  technician.services.length > 0
              )
              .map((technician) => {
                const technicianKey = technician.staffName
                  .normalize("NFD")
                  .replace(/[^a-zA-Z0-9]/g, "");

                const labels = technician.services.map(
                  (service) => service.serviceName
                );
                const values = technician.services.map(
                  (service) => service.count
                );
                const colors = labels.map(
                  (service) =>
                    palette
                      .filter((color) => color.serviceName === service)
                      .map((color) => color.color)[0]
                );
                const borders = labels.map(
                  (service) =>
                    palette
                      .filter((color) => color.serviceName === service)
                      .map((color) => color.border)[0]
                );

                return (
                  <div
                    className="row"
                    key={technicianKey}
                    data-cy={technicianKey}
                  >
                    <h6 className="text-center lb-title-daily">
                      {technician.staffName}
                    </h6>
                    <div className="col-6 my-4">
                      <PieChart
                        labels={labels}
                        values={values}
                        colors={colors}
                        borders={borders}
                      />
                    </div>
                    <div className="col-6 my-4">
                      <table className="table table-striped table-sm table-responsive">
                        <thead className="lb-thead-lila">
                          <tr>
                            <th>
                              <button
                                className="btn"
                                onClick={sortTechnician({
                                  name: technician.staffName,
                                  criteria: "service",
                                })}
                                data-cy={technicianKey + "-service-sort"}
                              >
                                Service <FontAwesomeIcon icon={faSortDown} />
                              </button>
                            </th>
                            <th>
                              <button
                                className="btn"
                                onClick={sortTechnician({
                                  name: technician.staffName,
                                  criteria: "quantity",
                                })}
                                data-cy={technicianKey + "-quantity-sort"}
                              >
                                Quantity <FontAwesomeIcon icon={faSortDown} />
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {labels.map((label, index) => {
                            return (
                              <tr key={label}>
                                <td>{label}</td>
                                <td className="text-end">{values[index]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td className="lb-title-consult">
                              Service Appointments
                            </td>
                            <td
                              className="text-end"
                              data-cy={technicianKey + "-serv-appts"}
                            >
                              {technician.count - technician.cbffServed}
                            </td>
                          </tr>
                          <tr>
                            <td className="lb-title-consult">
                              CBFF Appointments
                            </td>
                            <td
                              className="text-end"
                              data-cy={technicianKey + "-cbff-appts"}
                            >
                              {technician.cbffServed}
                            </td>
                          </tr>
                          <tr>
                            <td className="lb-title-consult">
                              Total Appointments
                            </td>
                            <td
                              className="text-end"
                              data-cy={technicianKey + "-appts"}
                            >
                              {technician.count}
                            </td>
                          </tr>
                          <tr>
                            <td className="lb-title-consult">CBFF provided</td>
                            <td
                              className="text-end"
                              data-cy={technicianKey + "-cbff"}
                            >
                              {technician.cbff}
                            </td>
                          </tr>

                          <tr>
                            <td className="lb-title-consult">CBFF ratio</td>
                            <td className="text-end">
                              {(
                                (technician.cbff * 100) /
                                technician.count
                              ).toFixed(2)}
                              %
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

ConsultTechnicians.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default ConsultTechnicians;
