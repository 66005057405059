import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ModalFormMassage from "./modals/modalFormMassage";
import {parseNumberPhoneUS } from "../util/formatNumbers.js";

const FormMassage = ({
  state,
  formData,
  formSubmited,
  setFormSubmited,
  setModalsState,
  modalsState,
  setFormData,
  msgData,
}) => {

  const requiredFields = {
    firstName: useRef(null),
    lastName: useRef(null),
    email: useRef(null),
    address_line_1: useRef(null),
    city: useRef(null),
    state: useRef(null),
    zip_code: useRef(null),
    phone_cel: useRef(null),
    birth_date: useRef(null),
    doctor_facilities: useRef(null),
    pregnancy_problem: useRef(null),
    massages: useRef(null),
    about_us: useRef(null),
    terms_conditions_checkbox: useRef(null),
  };


  useEffect(() => {
    const filledFirstAppointmentData = ((formData.firstName !== null &&
      formData.firstName !== "" &&
      formData.lastName !== null &&
      formData.lastName !== "" &&
      formData.email !== null &&
      formData.email !== "" &&
      formData.address_line_1 !== null &&
      formData.address_line_1 !== "" &&
      formData.city !== null &&
      formData.city !== "" &&
      formData.state !== null &&
      formData.state !== "" &&
      formData.zip_code !== null &&
      formData.zip_code !== "" &&
      formData.phone_cel !== null &&
      formData.phone_cel !== "" &&
      formData.phone_cel.length === 14 &&
      formData.birth_date !== null &&
      formData.birth_date !== "" &&
      formData.birth_date < moment(state.currentDate.toISOString()).format("YYYY-MM-DD") &&
      formData.doctor_facilities !== null && 
      formData.doctor_facilities !== "" && 
      formData.pregnancy_problem !== null && 
      formData.pregnancy_problem !== "" && 
      formData.massages !== null &&  
      formData.massages !== "" && 
      formData.about_us !== null &&  
      formData.about_us !== "" && 
      formData.about_us !== "null" && 
      formData.terms_conditions_checkbox) ||
      formData.fullData) &&
      formData.terms_conditions_checkbox;

    if (filledFirstAppointmentData) {
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        required: "ready",
      }));
    } else {
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        required: "not-ready",
      }));
    }
  }, [formData]);


  const handleCheckbox = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const id = event.target.id;

    if (name === "terms_conditions_checkbox" || id === "terms_and_conditions_link") {
      setModalsState((modalsState) => ({
        ...modalsState,
        termsAndConditionsCheckboxModal: "visible",
        termsAndConditionsSignatureClass:
          "lb-btn my-auto text-lb-btn-submit lb-submitButtonDisabled",
        termsAndConditionsSignatureButtonDisabled: true,
      }));
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  
    if(name === "phone_cel"){
  
    const phoneNumber = parseNumberPhoneUS(value);
  
       setFormData((formData) => ({
        ...formData,
        [name]: phoneNumber,
      }));

    } else {

      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));

    }

  };

  useEffect(() => {
    if (modalsState.termsAndConditionsImage !== "") {
      setFormData((formData) => ({
        ...formData,
        terms_conditions_checkbox: true,
      }));
    }
  }, [modalsState]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formSubmited.status === "sending") {
      return;
    }

    const fields = [
      "firstName",
      "lastName",
      "email",
      "address_line_1",
      "city",
      "state",
      "zip_code",
      "phone_cel",
      "birth_date",
      "doctor_facilities",
      "pregnancy_problem",
      "massages",
      "about_us",
      "terms_conditions_checkbox",
    ];

    const firstRef = fields.map((name) => {

      let validFiel = false;

      if (name === "phone_cel" && formData[name].length === 14) {
        validFiel = true;
      }

      else if (name === "firstName" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "lastName" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "email" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "address_line_1" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "city" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "state" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "zip_code" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "birth_date" && formData[name] !== "" && formData[name] < moment(state.currentDate.toISOString()).format("YYYY-MM-DD")) {
        validFiel = true;
      }

      else if (name === "doctor_facilities" && formData[name]) {
        validFiel = true;
      }

      else if (name === "pregnancy_problem" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "massages" && formData[name] !== "") {
        validFiel = true;
      }

      else if (name === "about_us" && formData[name] !== "" && formData[name] !== "null") {
        validFiel = true;
      }
      else if (name === "terms_conditions_checkbox" && formData[name]) {
        validFiel = true;
      } else {
        return name;
      }
      return validFiel;

    });

    const badValue = firstRef.find((i) => i !== true);

    if (!formData.fullData) {
      if (badValue !== undefined) {
        requiredFields[badValue].current.scrollIntoView();
      }
    }


    if (formSubmited.required !== "ready") {
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        inputRequiredClass: "lb-form-validated-error",
        attempt: true,
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        signatureDate: moment(new Date()).toString(),
      }));
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        status: "sending",
        inputRequiredClass: "",
        attempt: false,
      }));
      let pdfData = {};
      try {

        const formDataPdf = new FormData();

        const birthDate = new Date(formData.birth_date.concat("T00:00:00"));
        const appointmentDate = new Date(msgData.appointmentDate);

        let massageDate = "";
        if (formData.last_massage_date !== "") {
          const lastMassageDate = new Date(
            formData.last_massage_date.concat("T00:00:00")
          );
          massageDate =
            lastMassageDate.getMonth() +
            1 +
            " / " +
            lastMassageDate.getDate() +
            " / " +
            lastMassageDate.getFullYear();
        }

        let pdfPayload = {};

        const firstAppointment = formData.firstAppointment || formData.missingData;
        if (firstAppointment) {
          pdfPayload = {
            clientId: "" + formData.client_id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            signatureFile: modalsState.termsAndConditionsImage,
            medicalConditions: modalsState.medicalConditions,
            email: formData.email,
            addressLine1: formData.address_line_1,
            addressLine2: formData.address_line_2,
            birthDate: birthDate.getMonth() + 1 + " / " + birthDate.getDate() + " / " + birthDate.getFullYear(),
            city: formData.city,
            state: formData.state,
            postalCode: formData.zip_code,
            mobilePhone: formData.phone_cel.replace(/[^0-9.]+/g, ''),
            referredBy: formData.about_us === "null" ? "" : formData.about_us,
            spousePartner: formData.spouse_partner,
            phisicianName: formData.phisician_name,
            phisicianPhone: formData.phisician_phone,
            pregnancyProblem: formData.pregnancy_problem,
            pregnancyProblemExplain: formData.pregnancy_problem_explain,
            massages: formData.massages,
            lastMassageDate: massageDate,
            problemsMassages: formData.problems_massages,
            problemsMassagesExplain: formData.problems_massages_explain,
            doctorFacilities: formData.doctor_facilities,
            siteId: state.siteId,
            locationId: formData.locationId,
            signatureDate: moment(new Date()).toString(),
            ipAddress: formData.ipv4,
            firstAppointment: formData.firstAppointment || formData.missingData,
            appointmentId: state.appointmentId,
            appointmentDate: appointmentDate.getMonth() + 1 + " / " + appointmentDate.getDate() + " / " + appointmentDate.getFullYear(),
            programId: state.programId
          };
        }
        else {
          pdfPayload = {
            clientId: "" + formData.client_id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            signatureFile: modalsState.termsAndConditionsImage,
            medicalConditions: modalsState.medicalConditions,
            siteId: state.siteId,
            locationId: formData.locationId,
            signatureDate: moment(new Date()).toString(),
            ipAddress: formData.ipv4,
            firstAppointment: firstAppointment,
            appointmentId: state.appointmentId,
            appointmentDate: appointmentDate.getMonth() + 1 + " / " + appointmentDate.getDate() + " / " + appointmentDate.getFullYear(),
            programId: state.programId
          };
        }
        const keys = Object.keys(pdfPayload);

        keys.forEach((key, index) => {
          formDataPdf.append(`${key}`, pdfPayload[key]);
        });

        const pdfPutMethod = {
          method: "PUT",
          headers: {
            siteid: state.siteId,
          },
          body: formDataPdf,
        };
        const pdfResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/uploadPdf/signature`,
          pdfPutMethod
        );

        pdfData = await pdfResponse.json();

        if (pdfResponse.ok) {
          const bucketOk = pdfData.Aws.Key === pdfData.Aws.key && pdfData.Aws.Key !== undefined;
          let mindBodyOk = false;
          if ((pdfData.MindBody.Error !== undefined && pdfData.MindBody.Error.Message === "File already exists.") || (pdfData.MindBody.FileName !== undefined && pdfData.MindBody.FileName !== "")) {
            mindBodyOk = true;
          }

          if (bucketOk && mindBodyOk) {
            try {
              const clientPayload = {
                id: "" + formData.client_id,
                firstName: formData.firstName,
                lastName: formData.lastName,
                name: formData.firstName + " " + formData.lastName,
                email: formData.email,
                addressLine1: formData.address_line_1,
                addressLine2: formData.address_line_2,
                birthDate: formData.birth_date.concat("T00:00:00"),
                city: formData.city,
                state: formData.state,
                postalCode: formData.zip_code,
                mobilePhone: formData.phone_cel.replace(/[^0-9.]+/g, ''),
                homePhone: formData.phone_home,
                workPhone: formData.phone_work,
                referredBy:
                  formData.about_us === "null" ? "" : formData.about_us,
                spousePartner: formData.spouse_partner,
                phisicianName: formData.phisician_name,
                phisicianPhone: formData.phisician_phone,
                pregnancyProblem: formData.pregnancy_problem,
                pregnancyProblemExplain: formData.pregnancy_problem_explain,
                massages: formData.massages,
                lastMassageDate:
                  formData.last_massage_date !== ""
                    ? formData.last_massage_date.concat("T00:00:00")
                    : "",
                    problemsMassages: formData.problems_massages,
                    problemsMassagesExplain: formData.problems_massages_explain,
                doctorFacilities: formData.doctor_facilities,
                siteId: state.siteId,
                locationId: formData.locationId,
              };
              const clientPutMethod = {
                method: "PUT",
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  siteid: state.siteId,
                },
                body: JSON.stringify(clientPayload),
              };
              const clientResponse = await fetch(
                `${process.env.REACT_APP_API_URL}/api/dynamoDB/clients`,
                clientPutMethod
              );
              const clientData = await clientResponse.json();
              if (clientResponse.ok) {
                try {
                  const payload = {
                    Client: {
                      FirstName: formData.firstName,
                      LastName: formData.lastName,
                      AddressLine1: formData.address_line_1,
                      AddressLine2: formData.address_line_2,
                      BirthDate: formData.birth_date.concat("T00:00:00"),
                      City: formData.city,
                      State: formData.state,
                      PostalCode: formData.zip_code,
                      MobilePhone: formData.phone_cel.replace(/[^0-9.]+/g, ''),
                      ReferredBy:
                        formData.about_us === "null" ? "" : formData.about_us,
                      Email: formData.email,
                      Id: formData.client_id,
                      YellowAlert: "" + state.appointmentId,
                    },
                    SendEmail: true,
                    CrossRegionalUpdate: false,
                    Test: false,
                  };
                  const putMethod = {
                    method: "PUT",
                    headers: {
                      "Content-type": "application/json; charset=UTF-8",
                      siteid: state.siteId,
                    },
                    body: JSON.stringify(payload),
                  };
                  const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/clients/${formData.client_id}`,
                    putMethod
                  );

                  const data = await response.json();
                  if (response.ok) {
                    setFormSubmited((formSubmited) => ({
                      ...formSubmited,
                      status: "success",
                    }));
                  } else {
                    setFormSubmited((formSubmited) => ({
                      ...formSubmited,
                      status: "responseError",
                      message: JSON.stringify(data),
                    }));
                  }
                } catch (error) {
                  setFormSubmited((formSubmited) => ({
                    ...formSubmited,
                    status: "requestError",
                    message: JSON.stringify(error.message),
                  }));
                }
              } else {
                setFormSubmited((formSubmited) => ({
                  ...formSubmited,
                  status: "responseError",
                  message: JSON.stringify(clientData),
                }));
              }
            } catch (error) {
              setFormSubmited((formSubmited) => ({
                ...formSubmited,
                status: "requestError",
                message: JSON.stringify(error.message),
              }));
            }
          } else {
            setFormSubmited((formSubmited) => ({
              ...formSubmited,
              status: "pdfError",
              message:
                "There was a problem creating the mindbody pdf, please try again",
            }));
          }
        } else {
          setFormSubmited((formSubmited) => ({
            ...formSubmited,
            status: "requestError",
            message: JSON.stringify(pdfData),
          }));
        }
      } catch (error) {
        console.log(error)
        if (pdfData.Aws !== undefined && pdfData.MindBody !== undefined) {
          setFormSubmited((formSubmited) => ({
            ...formSubmited,
            status: "requestError",
            message: JSON.stringify(error.message),
          }));
        } else {
          setFormSubmited((formSubmited) => ({
            ...formSubmited,
            status: "pdfError",
            message:
              "There was a problem creating the mindbody pdf, please try again",
          }));
        }
      }
    }
  };

  return (
    <div>

      {modalsState.termsAndConditionsCheckboxModal === "visible" && (

        <ModalFormMassage
          modalsState={modalsState}
          state={state}
          setModalsState={setModalsState}
          setFormData={setFormData}
          formData={formData}
        />
      )}

      <form>
        <div id="formContainer" className="container">
          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                <div className="col">
                  <div
                    className="lb-form-group"
                    ref={requiredFields.firstName}
                  >
                    <label>
                      First name / Nombre{" "}
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <input
                      type="text"
                      className={
                        !formSubmited.attempt
                          ? "lb-form-control lb-border-0 lb-inputBackground"
                          : formData.firstName !== ""
                            ? "lb-form-control lb-border-0 lb-inputBackground"
                            : "lb-form-control " +
                            formSubmited.inputRequiredClass
                      }
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      data-cy="firstName"
                    />
                  </div>
                </div>
              </div>
            )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                <div className="col">
                  <div
                    className="lb-form-group"
                    ref={requiredFields.lastName}
                  >
                    <label>
                      Last name / Apellido{" "}
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <input
                      type="text"
                      className={
                        !formSubmited.attempt
                          ? "lb-form-control lb-border-0 lb-inputBackground"
                          : formData.lastName !== ""
                            ? "lb-form-control lb-border-0 lb-inputBackground"
                            : "lb-form-control " +
                            formSubmited.inputRequiredClass
                      }
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      data-cy="lastName"
                    />
                  </div>
                </div>
              </div>
            )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                <div className="col">
                  <div className="lb-form-group" ref={requiredFields.email}>
                    <label>
                      Email / Correo electronico{" "}
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <input
                      type="text"
                      className={
                        !formSubmited.attempt
                          ? "lb-form-control lb-border-0 lb-inputBackground"
                          : formData.email !== ""
                            ? "lb-form-control lb-border-0 lb-inputBackground"
                            : "lb-form-control " +
                            formSubmited.inputRequiredClass
                      }
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      data-cy="email"
                    />
                  </div>
                </div>
              </div>
            )}

          {formData.firstAppointment && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group" id="spousePartner">
                  <label>Spouse - Partner Name / Esposo - Conyugue:</label>
                  <input
                    type="text"
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    name="spouse_partner"
                    value={formData.spouse_partner}
                    onChange={handleChange}
                    data-cy="spouse_partner"
                  />
                </div>
              </div>
            </div>
          )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                <div className="col">
                  <div
                    className="lb-form-group"
                    ref={requiredFields.address_line_1}
                  >
                    <label>
                      Address line 1 / Dirección línea 1{" "}
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <input
                      type="text"
                      className={
                        !formSubmited.attempt
                          ? "lb-form-control lb-border-0 lb-inputBackground"
                          : formData.address_line_1 !== ""
                            ? "lb-form-control lb-border-0 lb-inputBackground"
                            : "lb-form-control " +
                            formSubmited.inputRequiredClass
                      }
                      name="address_line_1"
                      value={formData.address_line_1}
                      onChange={handleChange}
                      data-cy="address_line_1"
                    />
                  </div>
                </div>
              </div>
            )}

          {formData.firstAppointment && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group">
                  <label>Address line 2 / Dirección línea 2</label>
                  <input
                    type="text"
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    name="address_line_2"
                    value={formData.address_line_2}
                    onChange={handleChange}
                    data-cy="address_line_2"
                  />
                </div>
              </div>
            </div>
          )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                {(formData.firstAppointment ||
                  (!formData.firstAppointment && formData.missingData)) && (
                    <div className="col">
                      <div className="lb-form-group" ref={requiredFields.city}>
                        <label>
                          City / Ciudad
                          <span className="lb-requiredLabel"> * </span>
                        </label>
                        <input
                          type="text"
                          className={
                            !formSubmited.attempt
                              ? "lb-form-control lb-border-0 lb-inputBackground"
                              : formData.city !== ""
                                ? "lb-form-control lb-border-0 lb-inputBackground"
                                : "lb-form-control " +
                                formSubmited.inputRequiredClass
                          }
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          data-cy="city"
                        />
                      </div>
                    </div>
                  )}
                {(formData.firstAppointment ||
                  (!formData.firstAppointment && formData.missingData)) && (
                    <div className="col">
                      <div className="lb-form-group" ref={requiredFields.state}>
                        <label>
                          State / Estado
                          <span className="lb-requiredLabel"> * </span>
                        </label>
                        <input
                          type="text"
                          className={
                            !formSubmited.attempt
                              ? "lb-form-control lb-border-0 lb-inputBackground"
                              : formData.state !== ""
                                ? "lb-form-control lb-border-0 lb-inputBackground"
                                : "lb-form-control " +
                                formSubmited.inputRequiredClass
                          }
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          data-cy="state"
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                {(formData.firstAppointment ||
                  (!formData.firstAppointment && formData.missingData)) && (
                    <div className="col-6">
                      <div
                        className="lb-form-group"
                        ref={requiredFields.zip_code}
                      >
                        <label>
                          Zip Code / Codigo postal
                          <span className="lb-requiredLabel"> * </span>
                        </label>
                        <input
                          type="text"
                          className={
                            !formSubmited.attempt
                              ? "lb-form-control lb-border-0 lb-inputBackground"
                              : formData.zip_code !== ""
                                ? "lb-form-control lb-border-0 lb-inputBackground"
                                : "lb-form-control " +
                                formSubmited.inputRequiredClass
                          }
                          name="zip_code"
                          value={formData.zip_code}
                          onChange={handleChange}
                          data-cy="zip_code"
                        />
                      </div>
                    </div>
                  )}
                {(formData.firstAppointment ||
                  (!formData.firstAppointment && formData.missingData)) && (
                    <div className="col-6">
                      <div
                        className="lb-form-group"
                        ref={requiredFields.phone_cel}
                      >
                        <label>
                          Phone - Cel / Teléfono de celular
                          <span className="lb-requiredLabel"> * </span>
                        </label>
                        <div className="d-flex">
                          <input
                            type="text"
                            className={
                              "lb-form-control lb-border-0 lb-inputBackground col-3 col-sm-2"
                            }
                            value={'+1'}
                            readOnly
                          />&nbsp;
                          <input
                            type="text"
                            className={
                              !formSubmited.attempt
                                ? "lb-form-control lb-border-0 lb-inputBackground"
                                : formData.phone_cel.length === 14
                                  ? "lb-form-control lb-border-0 lb-inputBackground"
                                  : "lb-form-control " +
                                  formSubmited.inputRequiredClass
                            }
                            name="phone_cel"
                            value={formData.phone_cel}
                            onChange={handleChange}
                            data-cy="phone_cel"
                            maxLength={14}
                          />
                        </div>

                      </div>
                    </div>
                  )}
              </div>
            )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                {(formData.firstAppointment ||
                  (!formData.firstAppointment && formData.missingData)) && (
                    <div className="col-12">
                      <div
                        className="lb-form-group"
                        ref={requiredFields.birth_date}
                      >
                        <label>
                          Your Date of Birth / Su Fecha de nacimiento
                          <span className="lb-requiredLabel"> * </span>
                        </label>
                        <input
                          type="date"
                          className={
                            !formSubmited.attempt
                              ? "lb-form-control lb-border-0 lb-inputBackground"
                              : formData.birth_date !== "" &&
                                formData.birth_date < moment(state.currentDate.toISOString()).format("YYYY-MM-DD")
                                ? "lb-form-control lb-border-0 lb-inputBackground"
                                : "lb-form-control " +
                                formSubmited.inputRequiredClass
                          }
                          name="birth_date"
                          value={formData.birth_date}
                          onChange={handleChange}
                          data-cy="birth_date"
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}

          {formData.firstAppointment && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group">
                  <label>Phisician Name / Médico</label>
                  <input
                    type="text"
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    name="phisician_name"
                    onChange={handleChange}
                    data-cy="phisician_name"
                  />
                </div>
              </div>
            </div>
          )}

          {formData.firstAppointment && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group">
                  <label>Phisician Phone / Teléfono de medico</label>
                  <input
                    type="text"
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    name="phisician_phone"
                    value={formData.phisician_phone}
                    onChange={handleChange}
                    data-cy="phisician_phone"
                  />
                </div>
              </div>
            </div>
          )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                <div className="col">
                  <div
                    className="lb-form-group"
                    ref={requiredFields.doctor_facilities}
                  >
                    <label
                      className={
                        formSubmited.attempt &&
                          formData.doctor_facilities === ""
                          ? "lb-form-check-label-error"
                          : ""
                      }
                    >
                      Have you informed your doctor of your visit to our
                      facilities? / ¿Ha informado a su médico de su visita a
                      nuestras instalaciones?
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <br />
                    <div
                      className={
                        formSubmited.attempt &&
                          formData.doctor_facilities === ""
                          ? "lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom lb-checkbox-error"
                          : "lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom "
                      }
                    >
                      <label className="lb-form-check-label mr-2">Yes</label>
                      <input
                        type="radio"
                        name="doctor_facilities"
                        value="yes"
                        className="lb-form-check-input"
                        onChange={handleChange}
                        data-cy="doctor_facilities_yes"
                      />
                    </div>
                    <div
                      className={
                        formSubmited.attempt &&
                          formData.doctor_facilities === ""
                          ? "lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom lb-checkbox-error"
                          : "lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom "
                      }
                    >
                      <label className="lb-form-check-label mr-2">No</label>
                      <input
                        type="radio"
                        name="doctor_facilities"
                        value="no"
                        className="lb-form-check-input"
                        onChange={handleChange}
                        data-cy="doctor_facilities_no"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                <div className="col">
                  <div
                    className="lb-form-group"
                    ref={requiredFields.pregnancy_problem}
                  >
                    <label
                      className={
                        formSubmited.attempt &&
                          formData.pregnancy_problem === ""
                          ? "lb-form-check-label-error"
                          : ""
                      }
                    >
                      Have you had any problem with your current pregnacy? /
                      ¿Has tenido algún problema con tu embarazo actual?{" "}
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <br />
                    <div
                      className={
                        formSubmited.attempt &&
                          formData.pregnancy_problem === ""
                          ? "lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom lb-checkbox-error"
                          : "lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom "
                      }
                    >
                      <label className="lb-form-check-label mr-2">Yes</label>
                      <input
                        type="radio"
                        name="pregnancy_problem"
                        value="yes"
                        className="lb-form-check-input"
                        onChange={handleChange}
                        data-cy="pregnancy_problem_yes"
                      />
                    </div>
                    <div
                      className={
                        formSubmited.attempt &&
                          formData.pregnancy_problem === ""
                          ? "lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom lb-checkbox-error"
                          : "lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom "
                      }
                    >
                      <label className="lb-form-check-label mr-2">No</label>
                      <input
                        type="radio"
                        name="pregnancy_problem"
                        value="no"
                        className="lb-form-check-input"
                        onChange={handleChange}
                        data-cy="pregnancy_problem_no"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

          {formData.firstAppointment && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group">
                  <label>
                    If Yes, please explain / En caso afirmativo, sírvase
                    explicar:
                  </label>
                  <input
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    name="pregnancy_problem_explain"
                    value={formData.pregnancy_problem_explain}
                    onChange={handleChange}
                    data-cy="pregnancy_problem_explain"
                  />
                </div>
              </div>
            </div>
          )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                <div className="col">
                  <div
                    className="lb-form-group"
                    ref={requiredFields.massages}
                  >
                    <label>
                      How many massages have you had with this current pregnancy?
                      / ¿Cuantos masajes has tenido con este embarazo actual?
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <input
                      type="text"
                      className={
                        !formSubmited.attempt
                          ? "lb-form-control lb-border-0 lb-inputBackground"
                          : formData.massages !== ""
                            ? "lb-form-control lb-border-0 lb-inputBackground"
                            : "lb-form-control " +
                            formSubmited.inputRequiredClass
                      }
                      name="massages"
                      value={formData.massages}
                      onChange={handleChange}
                      data-cy="massages"
                    />
                  </div>
                </div>
              </div>
            )}

          {formData.firstAppointment && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group">
                  <label>
                    When was your last massage? / ¿Cuándo fue tu último masaje?
                  </label>
                  <input
                    type="date"
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    name="last_massage_date"
                    value={formData.last_massage_date}
                    onChange={handleChange}
                    data-cy="last_massage_date"
                  />
                </div>
              </div>
            </div>
          )}

          {formData.firstAppointment && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group">
                  <label className=".lb-text-muted">
                    Were there any problems? / ¿Hubo algún problema?
                  </label>
                  <br />
                  <div className="lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom">
                    <label className="lb-form-check-label mr-2">Yes</label>
                    <input
                      type="radio"
                      name="problems_massages"
                      value="yes"
                      className="lb-form-check-input"
                      onChange={handleChange}
                      data-cy="problems_massages_yes"
                    />
                  </div>
                  
                  <div className="lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom">
                    <label className="lb-form-check-label mr-2">No</label>
                    <input
                      type="radio"
                      name="problems_massages"
                      value="no"
                      className="lb-form-check-input"
                      onChange={handleChange}
                      data-cy="problems_massages_no"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {formData.firstAppointment && (
            <div className="row">
              <div className="col">
                <div className="lb-form-group">
                  <label>
                    If problems, please explain / Si hay problemas, por favor explique
                  </label>
                  <input
                    className="lb-form-control lb-border-0 lb-inputBackground"
                    name="problems_massages_explain"
                    value={formData.problems_massages_explain}
                    onChange={handleChange}
                    data-cy="problems_massages_explain"
                  />
                </div>
              </div>
            </div>
          )}

          {(formData.firstAppointment ||
            (!formData.firstAppointment && formData.missingData)) && (
              <div className="row">
                <div className="col">
                  <div
                    className="lb-form-group"
                    ref={requiredFields.about_us}
                  >
                    <label>
                      How did your hear about us? / ¿Como supiste de nosotros?
                      <span className="lb-requiredLabel"> * </span>
                    </label>
                    <select
                      className={
                        !formSubmited.attempt
                          ? "lb-form-control lb-border-0 lb-inputBackground"
                          : formData.about_us !== "null"
                            ? "lb-form-control lb-border-0 lb-inputBackground"
                            : "lb-form-control " +
                            formSubmited.inputRequiredClass
                      }
                      defaultValue="null"
                      name="about_us"
                      id="tipoDestinos"
                      onChange={handleChange}
                      data-cy="about_us"
                    >
                      <option value="null">Select an option</option>
                      <option value="TV">TV</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Flyers">Flyers</option>
                      <option value="Yelp">Yelp</option>
                      <option value="Friend">Friend</option>
                      <option value="Google">Google</option>
                      <option value="Baby Store">Baby Store</option>
                      <option value="Online Search">Embarazadas.com</option>
                      <option value="Doctors Office">Doctors Office</option>
                    </select>
                  </div>
                </div>
              </div>
            )}

          <div className="row justify-content-around lb-text-center">
             <div className="col">
              <a href="#" id="terms_and_conditions_link" onClick={handleCheckbox}>
                Terms and conditions
              </a>
            </div>
          </div>
          <br />
          <div className="row mt-3 mb-3">
            <div className="col">
              <div
                ref={requiredFields.terms_conditions_checkbox}
                className="lb-form-check lb-text-muted"
              >
                <input
                  type="checkbox"
                  className={
                    !formSubmited.attempt
                      ? "lb-form-check-input"
                      : formData.terms_conditions_checkbox
                        ? "lb-form-check-input"
                        : "lb-form-check-input " +
                        formSubmited.inputRequiredClass
                  }
                  onChange={handleCheckbox}
                  name="terms_conditions_checkbox"
                  id="terms_conditions_checkbox"
                  data-cy="terms_conditions_checkbox"
                  checked={formData.terms_conditions_checkbox}
                />
                <label
                  htmlFor="terms_conditions_checkbox"
                  className={
                    !formSubmited.attempt
                      ? "lb-form-check-label"
                      : formData.terms_conditions_checkbox
                        ? "lb-form-check-label"
                        : "lb-form-check-label-error"
                  }
                >
                  I agree with the terms of use
                  <span className="lb-requiredLabel"> * </span>
                </label>
              </div>
            </div>
          </div>

          {formSubmited.status === "success" && (
            <div
              className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-success"
              data-cy="formSubmitOk"
            >
              <span className="">
                Thank you for submitting the form, your information is now
                being processed. You can now close this page.
              </span>
            </div>
          )}
          {formSubmited.status === "responseError" && (
            <div className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error">
              <span className="">
                {formSubmited.message === ""
                  ? "Woops, this is weird. Something went wrong with the connection, please reload the page and try again."
                  : formSubmited.message}
              </span>
            </div>
          )}
          {formSubmited.status === "pdfError" && (
            <div
              className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error"
              data-cy="pdfErrorMsg"
            >
              <span className="">{formSubmited.message}</span>
            </div>
          )}
          {formSubmited.status === "requestError" && (
            <div className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error">
              <span className="">
                Woops, this is weird. Something went wrong with the form,
                please reload the page and try filling it again.{" "}
                {formSubmited.message}
              </span>
            </div>
          )}
          {formSubmited.required !== "ready" && formSubmited.attempt && (
            <div className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error">
              <span className="">
                Please fill all the required fields before submitting
              </span>
            </div>
          )}

          <br />
          <div className="row px-0">
            <div className="col no-gutters px-0 lb-text-center">
              {formSubmited.status !== "success" && (
                <button
                  type="submit"
                  className={
                    formSubmited.required === "ready"
                      ? "lb-btn lb-submitButton p-3 pb-4 lb-w-100 no-gutters text-lb-btn-submit mb-2"
                      : "lb-btn lb-submitButtonDisabled p-3 pb-4 lb-w-100 no-gutters text-lb-btn-submit mb-2"
                  }
                  onClick={handleSubmit}
                  data-cy="cta-submit-massages"
                >
                  {formSubmited.status === "sending" ? (
                    <div>
                      <FontAwesomeIcon spin icon={faSpinner} /> Loading...
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              )}
              {formSubmited.status === "success" &&
                state.bypass2fa === true && (
                  <Link
                    to={`/forms`}
                    className="lb-btn lb-submitButton lb-no-hover p-3 pb-4 lb-w-100 no-gutters text-lb-btn-submit mb-2"
                    data-cy="back-to-dashboard-submit"
                  >
                    GO BACK
                  </Link>
                )}
            </div>
          </div>
        </div>
      </form>
    </div>
  )
};

FormMassage.propTypes = {
  state: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  formSubmited: PropTypes.object.isRequired,
  setFormSubmited: PropTypes.func.isRequired,
  setModalsState: PropTypes.func.isRequired,
  modalsState: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  msgData: PropTypes.object.isRequired,
};
export default FormMassage;
