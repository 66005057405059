export const sitesList = [
  { siteId: "490100", name: "Little Bellies Columbus" },
  { siteId: "549974", name: "Little Bellies - Houston" },
  { siteId: "557418", name: "Little Bellies - Miami" },
  { siteId: "743289", name: "Little Bellies Oklahoma" },
  { siteId: "758692", name: "Little Bellies - Jacksonville" },
  { siteId: "795028", name: "Little Bellies Cleveland" },
  { siteId: "902886", name: "Little Bellies Tulsa" },
  { siteId: "826493", name: "Little Bellies - West Palm Beach" },
  { siteId: "888809", name: "Little Bellies - Dallas" },
  { siteId: "782522", name: "Little Bellies - San Antonio" },
  { siteId: "491521", name: "Little Bellies - Atlanta" },
  { siteId: "5721159", name: "Little Bellies - Tampa" },
  { siteId: "5721382", name: "Little Bellies - Orlando" },
  { siteId: "5722368", name: "Little Bellies - Austin" },
  { siteId: "5721162", name: "Little Bellies - Denver" },
  { siteId: "5723785", name: "Little Bellies - Los Angeles" },
  { siteId: "572377", name: "Little Bellies - Sacramento" },
  { siteId: "5725645", name: "Little Bellies - Raleigh" },
  { siteId: "5726405", name: "Little Bellies - Chicago" },
  { siteId: "5729682", name: "Little Bellies - Minneapolis" },
  { siteId: "5729354", name: "Little Bellies - Washington" },
  { siteId: "5731081", name: "Little Bellies - Phoenix" },
  { siteId: "5739497", name: "Little Bellies - Fort Worth" },
  { siteId: "5739704", name: "Little Bellies - Charlotte" },
];

export const singleMenuOptions = [
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Appointments",
    link: "/appointments",
    dataCy: "appointments-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Remake-Video",
    link: "/remakevideo",
    dataCy: "remakeVideo-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Forms",
    link: "/forms",
    dataCy: "forms-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "FAQ",
    link: "/faq",
    dataCy: "faq-nav",
  },
];

export const bookingMenuOptions = [
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Booking",
    link: "/booking",
    dataCy: "booking-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Visits",
    link: "/visits",
    dataCy: "visits-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "FAQ",
    link: "/faq",
    dataCy: "faq-nav",
  },
];

export const directorsMenuOptions = [
  {
    type: "dropdown",
    label: "Technicians Options",
    link: "#",
    dataCy: "dropdown-nav",
    items: [
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Appointments",
        link: "/appointments",
        dataCy: "appointments-nav",
      },
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Remake-Video",
        link: "/remakevideo",
        dataCy: "remakeVideo-nav",
      },
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Forms",
        link: "/forms",
        dataCy: "forms-nav",
      },
    ],
  },
  {
    type: "dropdown",
    label: "Reports",
    link: "#",
    dataCy: "dropdown-reports-nav",
    items: [
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Daily Report",
        link: "/dailyreport",
        dataCy: "daily-report-nav",
      },
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Review Report",
        link: "/reviewdailyreport",
        dataCy: "review-daily-report-nav",
      },
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Projection Report",
        link: "/projectionreport",
        dataCy: "projection-report-nav",
      },
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Business Snapshot",
        link: "/snapshots",
        dataCy: "consult-technicians-nav",
      },
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Daily Closeout",
        link: "/dailycloseout",
        dataCy: "daily-closeout-nav",
      },
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Incomplete Booking",
        link: "/incompleteappointments",
        dataCy: "incomplete-appointments-nav",
      },
    ],
  },

  {
    type: "dropdown",
    label: "Content Manager",
    link: "#",
    dataCy: "dropdown-nav-cm",
    items: [
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Reviews",
        link: "/contentmanagereviews",
        dataCy: "contentmanagereviews-nav",
      },
      {
        type: "single",
        restricted: false,
        users: null,
        label: "Services",
        link: "/contentmanagerservices",
        dataCy: "contentmanagerservices-nav",
      },
    ],
  },

  {
    type: "single",
    restricted: false,
    users: null,
    label: "Operational Dashboard",
    link: "/operationaldashboard",
    dataCy: "operational-dashboard-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Sites Configuration",
    link: "/config",
    dataCy: "config-nav",
  },
  {
    type: "single",
    restricted: false,
    users: ["Saul Pesate", "pancitasspa@gmail.com"],
    label: "Link Configuration",
    link: "/editreviewlinks",
    dataCy: "editlink-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Logs",
    link: "/logs",
    dataCy: "logs-nav",
  },
];

export const managementMenuOptions = [
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Appointments",
    link: "/appointments",
    dataCy: "appointments-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Daily Report",
    link: "/dailyreport",
    dataCy: "daily-report-nav",
  },
  {
    type: "single",
    restricted: false,
    users: null,
    label: "Review Report",
    link: "/reviewdailyreport",
    dataCy: "review-daily-report-nav",
  },
];

export const questions = [
  {
    categoryName: "General",
    id: 0,
    items: [
      {
        id: 0,
        question:
          "Something happened and there's an error notification, what does it means?",
        answer:
          "The error notification in all pages comes with an error message, if you get an error notification please take a screenshot or a picture and report this to your supervisor, the code will help to determine the origin of the error itself.",
      },
      {
        id: 1,
        question:
          "I get an error indicating Denied access and that i don't have permissions to perform an action, what's that about?",
        answer:
          "If you get an error indicating that you can't perform an action you should contact your supervisor and ask for the respective permissions, after a manual upgrade of your user you will be good to go.",
      },
    ],
  },
  {
    categoryName: "Checkout",
    id: 1,
    items: [
      {
        id: 0,
        question:
          "How do the cash and credit buttons work on the checkout page?",
        answer:
          "Well if you select just one it will add the total ammount of the purchase to it, if you edit that value and then select the other payment method it will add the remaining ammount to that payment method.",
      },
      {
        id: 1,
        question:
          "The checkout button appears to be disabled, what's going on?",
        answer:
          "If the button is disabled it means that either the ammount doesn't match the total price or that the staff member was not selected, please make sure this two things are correctly set.",
      },
      {
        id: 3,
        question: "I can't type more in the discount box, what's going on?",
        answer:
          "You are trying to enter a discount above what's permited, you should see it on the error message at the top",
      },
    ],
  },
  {
    categoryName: "Appointments dashboard",
    id: 2,
    items: [
      {
        id: 0,
        question:
          "In my appointments board i get a 'not found' notification. What's happening?",
        answer:
          "Either the location doesn't have any appointments left for the day or is too early to display the appointments list for the next day.",
      },
      {
        id: 1,
        question:
          "I accidentaly marked an appointment as arrived, what should i do?",
        answer:
          "If this happens you should contact your supervisor and inform what happened and the appointment will be manually returned to the previous state.",
      },
      {
        id: 2,
        question: "What does the form indicator means?",
        answer:
          "The form indicator in the appointments board shows if a client has filled the form and agreed to little bellies terms and conditions.",
      },
    ],
  },
  {
    categoryName: "Appointments Notes Edit",
    id: 3,
    items: [
      {
        id: 0,
        question:
          "I can't assign a come back for free on an appointment, what should i do?",
        answer:
          "If you are editing the notes on an appointment and the cbff field isn't there it means that the service doesn't have an automatic come back for free, if there is the need to give that service please contact your supervisor in order to manually give the client it's come back for free.",
      },
      {
        id: 1,
        question:
          "I can't assign a come back for free on an appointment, what should i do?",
        answer:
          "If you are editing the notes on an appointment and a message appears pointing out that the client has already two consecutives come back for free appointments, you should notify your supervisor about the need to give a third one and it will be manually give the client it's come back for free.",
      },
      {
        id: 2,
        question:
          "In the edit notes page the button seems to be disabled, why is that?",
        answer:
          "If a button on the page appears to be disabled it means that there is a mandatory field that needs to be filled, check if there is an empty field marked as mandatory (*) and make sure to fill it with the respective info.",
      },
    ],
  },
  {
    categoryName: "Video Experience",
    id: 4,
    items: [
      {
        id: 0,
        question:
          "If i have to remake a video experience, how would i know what service did it had on the appointment?",
        answer:
          "Don't worry when you search for the user's appointments to remake the video experience, the appointments board will tell you the details about the date of the appointment and the service.",
      },
      {
        id: 1,
        question:
          "I updated an image and then realise it was not the image i intented, what do i do?",
        answer:
          "No problem there, just look for the image and click on the 'x' sign on the corner, that will remove that image from the upload queue and there you go, problem solved.",
      },
      {
        id: 2,
        question:
          "A client requested the video experience to be sented to a different email address and not the one it has registered on mindBody, how do i do that?",
        answer:
          "In the edit notes page you can see a field that has a label 'Send photos to' it has the mindbody email by default, but you can edit that value into any other email the client provides.",
      },
    ],
  },
  {
    categoryName: "2FA and Form",
    id: 5,
    items: [
      {
        id: 0,
        question:
          "A client requested a validation code to be sent to the wrong destination, what should she/he do?",
        answer:
          "A new verification code can be requested in just 3 minutes, so she/he need to wait 3 minutes and request a new code in the correct destination.",
      },
      {
        id: 1,
        question:
          "The client says the submit button on the form is disabled, why is that?",
        answer:
          "The submit button is disabled on the form until the user fills all the mandatory fields with valid values, and agrees to the terms and put down his signature and/or initials.",
      },
      {
        id: 2,
        question: "Where can the clients recieve the authorization code?",
        answer:
          "An authorization code can be sent to the MindBody email address or mobile phone.",
      },
    ],
  },
  {
    categoryName: "Daily Report",
    id: 6,
    items: [
      {
        id: 0,
        question:
          "Why is the save and create PDF button disabled after pressing it?",
        answer:
          "This means that you have completed the process of entering the data necessary for the daily closing and decided to send them to generate the pdf, therefore the button is deactivated because you have already completed the process and you should not continue pressing.",
      },
      {
        id: 1,
        question:
          "Can I send the Daily Report from one location to multiple emails?",
        answer:
          "Yes, they must be registered in the foldersConfig file in the location, in the email item indicate the emails separated by commas (,). Example “email”: [“email1@gmail.com”, ”email2@gmail.com”, ”email3@domain.com”,].",
      },
      {
        id: 2,
        question: "How can I add another employee to record their information?",
        answer:
          "When you press the purple word 'add', all the fields will appear below to add another employee with their corresponding information, in addition to enabling the 'delete' option in case you want to delete everything about that employee.",
      },
      {
        id: 3,
        question:
          "How can I know if the information uploaded created the pdf and was sent by email?",
        answer:
          "After pressing the save and create PDF button, a success message will appear later.",
      },
      {
        id: 4,
        question: "How can I add more reasons?",
        answer:
          "By clicking on the purple words 'add reason' another field will appear to add a new reason and its value, in addition to a 'delete' option will now appear in case you add by accident or if you change your mind and no longer you want to add it.",
      },
      {
        id: 5,
        question: "How are the Withdrawals calculated?",
        answer:
          "Withdrawals is the sum of the values of each reason, as you add or remove reasons with their respective values, the Withdrawals field will be affected.",
      },
      {
        id: 6,
        question: "How is Ending Cash calculated?",
        answer:
          "Ending Cash will be equal to Opening Cash + Cash Payments - Withdrawals.",
      },
      {
        id: 7,
        question: "What email will the Daily Report be sent to?",
        answer:
          "To the email indicated in the configuration file (foldersconfig) of each location.",
      },
      {
        id: 8,
        question: "What happens if I press the cancel button?",
        answer:
          "This will show you a warning if you want to delete the data you had uploaded, if you press that if the data of the entered form will be deleted and you can fill it all again, if you want to only keep your data and return to the screen select no.",
      },
    ],
  },
];

export const adminQuestionsOnly = [
  {
    categoryName: "Review Report",
    id: 7,
    items: [
      {
        id: 0,
        question: "Why can I update some parts of the report and not others?",
        answer:
          "Review report is intented to view old reports and make minor updates on them, for security, only a small portion can be changed to correct mistakes",
      },
    ],
  },
  {
    categoryName: "Business Snapshot",
    id: 8,
    items: [
      {
        id: 0,
        question: "What does the Simple/Detailed button does?",
        answer:
          "It shows the two modes of viewing the report, on simple mode, services are grouped by its nature and ignores its price, on detailed mode, services are separated by their prices",
      },
      {
        id: 1,
        question: "Can I see the report for only one day?",
        answer:
          "Yes, you can see the snapshot for only one day if you choose the same date for the start and end date",
      },
      {
        id: 2,
        question:
          "I see that the services that have more appointments are listed first, can I change that?",
        answer:
          "Yes, you can sort the tables by clicking on Service or Quantiy at the head of the table",
      },
      {
        id: 3,
        question: "How does the Search bar works?",
        answer:
          "You can type the name of a technician and it will update the reports in real time by matching what you type",
      },
      {
        id: 4,
        question: "What are the fields at the bottom of every table?",
        answer:
          "Service appointments is the total of paid appoinments served, CBFF Appointments is the total of Come Back for Free appointments served, Total appointments is the sum of those two, CBFF provided is the amount of come back for free coupons that the technician served, CBFF ratio is the ratio of CBFF provided against the total of appointments served",
      },
      {
        id: 5,
        question: "What happens when I click create and Send PDF?",
        answer:
          "A detailed PDF covering all locations and all services for the date range is send to the emails on the configuration file",
      },
    ],
  },
  {
    categoryName: "Configuration Files",
    id: 9,
    items: [
      {
        id: 0,
        question: "Where do I find the configuration file?",
        answer:
          "In Little Bellies Dropbox ... it's a txt file called foldersConfig.",
      },
      {
        id: 1,
        question: "How to add a new location to the configuration file?",
        answer: `Open the foldersConfig file located at the bottom before the closing bracket (]) you must copy and paste the content between braces {} including the comma and fill in the data for instance: { "site": "newsite-newlocation", "folder": "/ foldername /", "email": ["email1@domain.com",] } newsite = site code to create; newlocation = location code; folder = short name of the new location; email = email to send the location reports `,
      },
    ],
  },
];

export const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const blocks = [
  {
    id: 1,
    segment: "08:30 AM",
    endSegment: "09:00 AM",
    appointment: {},
  },
  {
    id: 2,
    segment: "09:00 AM",
    endSegment: "09:30 AM",
    appointment: {},
  },
  {
    id: 3,
    segment: "09:30 AM",
    endSegment: "10:00 AM",
    appointment: {},
  },
  {
    id: 4,
    segment: "10:00 AM",
    endSegment: "10:30 AM",
    appointment: {},
  },
  {
    id: 5,
    segment: "10:30 AM",
    endSegment: "11:00 AM",
    appointment: {},
  },
  {
    id: 6,
    segment: "11:00 AM",
    endSegment: "11:30 AM",
    appointment: {},
  },
  {
    id: 7,
    segment: "11:30 AM",
    endSegment: "12:00 PM",
    appointment: {},
  },
  {
    id: 8,
    segment: "12:00 PM",
    endSegment: "12:30 PM",
    appointment: {},
  },
  {
    id: 9,
    segment: "12:30 PM",
    endSegment: "01:00 PM",
    appointment: {},
  },
  {
    id: 10,
    segment: "01:00 PM",
    endSegment: "01:30 PM",
    appointment: {},
  },
  {
    id: 11,
    segment: "01:30 PM",
    endSegment: "02:00 PM",
    appointment: {},
  },
  {
    id: 12,
    segment: "02:00 PM",
    endSegment: "02:30 PM",
    appointment: {},
  },
  {
    id: 13,
    segment: "02:30 PM",
    endSegment: "03:00 PM",
    appointment: {},
  },
  {
    id: 14,
    segment: "03:00 PM",
    endSegment: "03:30 PM",
    appointment: {},
  },
  {
    id: 15,
    segment: "03:30 PM",
    endSegment: "04:00 PM",
    appointment: {},
  },
  {
    id: 16,
    segment: "04:00 PM",
    endSegment: "04:30 PM",
    appointment: {},
  },
  {
    id: 17,
    segment: "04:30 PM",
    endSegment: "05:00 PM",
    appointment: {},
  },
  {
    id: 18,
    segment: "05:00 PM",
    endSegment: "05:30 PM",
    appointment: {},
  },
  {
    id: 19,
    segment: "05:30 PM",
    endSegment: "06:00 PM",
    appointment: {},
  },
  {
    id: 20,
    segment: "06:00 PM",
    endSegment: "06:30 PM",
    appointment: {},
  },
  {
    id: 21,
    segment: "06:30 PM",
    endSegment: "07:00 PM",
    appointment: {},
  },
  {
    id: 22,
    segment: "07:00 PM",
    endSegment: "07:30 PM",
    appointment: {},
  },
  {
    id: 23,
    segment: "07:30 PM",
    endSegment: "08:00 PM",
    appointment: {},
  },
];

export const timeZones = [
  {
    value: "Hawaiian Standard Time",
    abbr: "HST",
    offset: -10,
    isdst: false,
    text: "(UTC-10:00) Hawaii",
    utc: [
      "Etc/GMT+10",
      "Pacific/Honolulu",
      "Pacific/Johnston",
      "Pacific/Rarotonga",
      "Pacific/Tahiti",
    ],
  },

  {
    value: "Pacific Daylight Time",
    abbr: "PDT",
    offset: -7,
    isdst: true,
    text: "(UTC-07:00) Pacific Time (US & Canada)",
    utc: ["America/Los_Angeles", "America/Tijuana", "America/Vancouver"],
  },
  {
    value: "Pacific Standard Time",
    abbr: "PST",
    offset: -8,
    isdst: false,
    text: "(UTC-08:00) Pacific Time (US & Canada)",
    utc: [
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "PST8PDT",
    ],
  },
  {
    value: "US Mountain Standard Time",
    abbr: "UMST",
    offset: -7,
    isdst: false,
    text: "(UTC-07:00) Arizona",
    utc: [
      "America/Creston",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "America/Whitehorse",
      "Etc/GMT+7",
    ],
  },
  {
    value: "Mountain Standard Time",
    abbr: "MDT",
    offset: -6,
    isdst: true,
    text: "(UTC-07:00) Mountain Time (US & Canada)",
    utc: [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT",
    ],
  },
  {
    value: "Central Standard Time",
    abbr: "CDT",
    offset: -5,
    isdst: true,
    text: "(UTC-06:00) Central Time (US & Canada)",
    utc: [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT",
    ],
  },
  {
    value: "Eastern Standard Time",
    abbr: "EST",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Eastern Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
  },
  {
    value: "Eastern Daylight Time",
    abbr: "EDT",
    offset: -4,
    isdst: true,
    text: "(UTC-04:00) Eastern Daylight Time (US & Canada)",
    utc: [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
  },
  {
    value: "US Eastern Standard Time",
    abbr: "UEDT",
    offset: -5,
    isdst: false,
    text: "(UTC-05:00) Indiana (East)",
    utc: [
      "America/Indiana/Marengo",
      "America/Indiana/Vevay",
      "America/Indianapolis",
    ],
  },
  {
    value: "UTC-02",
    abbr: "U",
    offset: -2,
    isdst: false,
    text: "(UTC-02:00) Coordinated Universal Time-02",
    utc: ["America/Noronha", "Atlantic/South_Georgia", "Etc/GMT+2"],
  },
];
