import React, { useState, useEffect } from "react";
import "../styles/bootstrap-grid.min.css";
import "../styles/global.css";
import { PropTypes } from "prop-types";
import { timeZones } from "../config/constants.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";

function ConfigSetup({ appState }) {
  const [siteList, setSiteList] = useState([]);
  const [siteToken, setSiteToken] = useState(null);
  const initDatosSite = {
    site: "",
    location: "",
    name: "",
    folder: "",
    email: [],
    phone: "",
    zipwhipSession: "",
    bitlyForm: "",
    inboxId: "",
    creatorId: "",
    timeZone: "",
    address: "",
    storeName: "",
    gMapLink: "",
    HTAEnglish: "",
    HTASpanish: "",
    isdst: "",
    reviewLinkOriginal: "",
    reviewLinkSent: "",
    chatbotId: "",
  };
  const [datosSite, setDatosSite] = useState(initDatosSite);
  const [allTimeZones, setAllTimeZones] = useState(null);
  const [showSelect, setShowSelect] = useState(true);
  const initState = {
    status: "loading",
    message: "",
    validateEmail: true,
    validatePhone: true,
    validateFolder: true,
    updateStatus: "default",
    updateServiceStatus: "default",
    updateServiceMessage: "",
    updateMessage: "",
    requestAction: "update",
    selectValueDefault: "#",
    selectLabelDefault: "Select a Site",
    selectedValueTimeZone: "#",
    selectedLabelTimeZone: "Select a Time Zone",
    selectedUtcTimeZone: "",
    selectedIdtstTimeZone: "",
    address: "",
    storeName: "",
    gMapLink: "",
    HTAEnglish: "",
    HTASpanish: "",
    reviewLinkOriginal: "",
    selectedSite: {},
  };
  const [state, setState] = useState(initState);

  useEffect(() => {
    getTimeZone();
    getConfig();
  }, []);

  const handleSave = (event) => {
    event.preventDefault();
    if (
      datosSite.site.trim() !== "" &&
      datosSite.location.trim() !== "" &&
      datosSite.name.trim() !== "" &&
      datosSite.folder.trim() !== "" &&
      datosSite.email !== "" &&
      datosSite.zipwhipSession.trim() !== "" &&
      datosSite.bitlyForm.trim() !== "" &&
      datosSite.inboxId.trim() !== "" &&
      datosSite.creatorId.trim() !== "" &&
      datosSite.phone.trim() !== "" &&
      state.selectedValueTimeZone !== "" &&
      state.selectedValueTimeZone !== "#" &&
      datosSite.address.trim() !== "" &&
      datosSite.storeName.trim() !== "" &&
      datosSite.gMapLink.trim() !== "" &&
      datosSite.HTAEnglish.trim() !== "" &&
      datosSite.HTASpanish.trim() !== "" &&
      datosSite.chatbotId.trim() !== "" &&
      datosSite.reviewLinkOriginal.trim() !== ""
    ) {
      if (
        state.validateEmail === true &&
        state.validateFolder === true &&
        state.validatePhone === true
      ) {
        setState({
          ...state,
          // pendiente
          // updateMessage: "",
          // updateStatus: "default",
        });
        updateConfig();
      } else {
        setState((state) => ({
          ...state,
          updateStatus: "validateError",
          updateMessage:
            "Debe corregir los errores en los campos Folder y/o Email y/o Phone",
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        updateStatus: "emptyError",
        updateMessage: "Debe llenar los campos obligatorios",
      }));
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setDatosSite((datosSite) => ({
      ...datosSite,
      [name]: value,
    }));
  };

  const handleValidateFolder = (event) => {
    if (event.target.value !== "") {
      const value = event.target.value;

      if (value.substring(0, 1) !== "/" || value.slice(-1) !== "/") {
        setState({
          ...state,
          validateFolder: false,
        });
      } else {
        setState({
          ...state,
          validateFolder: true,
        });
      }
    }
  };

  const handleOnBlur = (event) => {
    if (event.target.value !== "") {
      const emailSinEspacios = event.target.value.replace(/ /g, "");

      const emails = emailSinEspacios.split(",");

      const badEmail = emails.filter((value) => {
        if (value === "") return false;
        const emailRegex = /^\S+@\S+\.\S+$/;
        return !emailRegex.test(value);
      });
      if (badEmail.length === 0) {
        setState({
          ...state,
          validateEmail: true,
        });
      } else {
        setState({
          ...state,
          validateEmail: false,
        });
      }
    }
  };

  const handlePhoneOnBlur = (event) => {
    const value = event.target.value;
    // let cleanValue = "";
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(value)) {
      setState({
        ...state,
        validatePhone: true,
      });
    } else {
      if (value === "") {
        setState({
          ...state,
          validatePhone: true,
        });
      } else {
        setState({
          ...state,
          validatePhone: false,
        });
      }
    }
  };

  const handleTimeZoneChange = (event) => {
    const value = event.target.value;

    const foundTimeZone = allTimeZones.find(
      (element) => element.value === value
    );

    if (foundTimeZone && foundTimeZone.value) {
      setState((state) => ({
        ...state,
        selectedValueTimeZone: foundTimeZone.value,
        selectedLabelTimeZone: foundTimeZone.label,
        selectedUtcTimeZone: foundTimeZone.utc,
        selectedIdtstTimeZone: foundTimeZone.isdst,
      }));
    } else {
      setState((state) => ({
        ...state,
        selectedValueTimeZone: "#",
        selectedLabelTimeZone: "Select a Time Zone",
        selectedUtcTimeZone: "",
        selectedIdtstTimeZone: "",
      }));
    }
    getTimeZone();
  };

  const clearForm = () => {
    setDatosSite({
      site: "",
      location: "",
      name: "",
      folder: "",
      email: [],
      phone: "",
      zipwhipSession: "",
      bitlyForm: "",
      inboxId: "",
      creatorId: "",
      timeZone: "",
      address: "",
      storeName: "",
      gMapLink: "",
      HTAEnglish: "",
      HTASpanish: "",
      reviewLinkOriginal: "",
      chatbotId: "",
    });
    setState({
      ...state,
      requestAction: !showSelect ? "update" : "create",
      updateMessage: "",
      updateStatus: "default",
      selectedValueTimeZone: "#",
      selectedLabelTimeZone: "Select a Time Zone",
      selectedUtcTimeZone: "",
      selectedIdtstTimeZone: "",
      selectValueDefault: "#",
      selectLabelDefault: "Select a Site",
    });
    setShowSelect((value) => !value);
    getTimeZone();
  };

  const updateConfig = async () => {
    if (state.updateStatus === "loading") {
      return;
    }

    setState((state) => ({
      ...state,
      updateStatus: "loading",
    }));

    try {
      const payload = {
        site: datosSite.site + "-" + datosSite.location,
        name: datosSite.name,
        folder: datosSite.folder,
        phone: datosSite.phone,
        email: datosSite.email,
        zipwhipSession: datosSite.zipwhipSession,
        bitlyForm: datosSite.bitlyForm,
        inboxId: datosSite.inboxId,
        creatorId: datosSite.creatorId,
        timeZoneValue: state.selectedValueTimeZone,
        timeZone: state.selectedUtcTimeZone,
        isdst: state.selectedIdtstTimeZone,
        address: datosSite.address,
        storeName: datosSite.storeName,
        gMapLink: datosSite.gMapLink,
        HTAEnglish: datosSite.HTAEnglish,
        HTASpanish: datosSite.HTASpanish,
        reviewLinkOriginal: datosSite.reviewLinkOriginal,
        chatbotId: datosSite.chatbotId,
        reviewLinkSent:
          state.requestAction === "update"
            ? datosSite.reviewLinkSent
            : datosSite.reviewLinkOriginal,
      };

      const updateSitesQuery = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/config/sites`,
        updateSitesQuery
      );

      const updateSiteResponse = await response.json();

      if (response.ok) {
        setDatosSite({ ...initDatosSite });

        setState((state) => ({
          ...state,
          updateStatus: "success",
          updateMessage:
            state.requestAction === "update"
              ? "Site updated successfully!"
              : "Site created successfully!",
          selectValueDefault: "#",
          selectLabelDefault: "Select a Site",
          selectedValueTimeZone: "#",
          selectedLabelTimeZone: "Select a Time Zone",
          selectedIdtstTimeZone: "",
          selectedUtcTimeZone: "",
          validateEmail: true,
          validatePhone: true,
          validateFolder: true,
        }));
      } else {
        setState((state) => ({
          ...state,
          updateStatus: "requestError",
          updateMessage: JSON.stringify(updateSiteResponse),
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        updateStatus: "error",
        updateMessage: "Error: " + JSON.stringify(error.message),
      }));
    }

    setState((state) => ({
      ...state,
      status: "ready",
    }));
  };

  const getTimeZone = () => {
    const timeZone = timeZones.map((item) => {
      const times = {
        value: item.value,
        label: item.text,
        isdst: item.isdst,
        utc: item.utc[0],
      };
      return times;
    });
    setAllTimeZones(timeZone);
  };

  const getConfig = async (siteSelected) => {
    try {
      const getSitesQuery = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/config/sites`,
        getSitesQuery
      );

      const datosSiteResponse = await response.json();

      if (response.ok) {

        const filterSites = datosSiteResponse.sites.filter((i) => { return i.site !== "0-0" });
        setSiteList(filterSites);

        setState((state) => ({
          ...state,
          status: "ready",
        }));
      } else {
        setState((state) => ({
          ...state,
          status: "no-data-found",
          message: JSON.stringify(datosSiteResponse),
          selectValueDefault: siteSelected,
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: "Onload page Error: " + JSON.stringify(error.message),
        selectValueDefault: siteSelected,
      }));
    }
    return null;
  };

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.value === "#") {
      setDatosSite({
        site: "",
        location: "",
        name: "",
        folder: "",
        email: [],
        phone: "",
        zipwhipSession: "",
        bitlyForm: "",
        inboxId: "",
        creatorId: "",
        timeZone: "",
        reviewLinkSent: "",
        isdst: "",
        gMapLink: "",
        storeName: "",
        HTAEnglish: "",
        HTASpanish: "",
        address: "",
        reviewLinkOriginal: "",
        timeZoneValue: "",
        chatbotId: "",
      });

      setState({
        ...state,
        requestAction: !showSelect ? "update" : "create",
        updateMessage: "",
        updateStatus: "default",
        updateServiceMessage: "",
        updateServiceStatus: "default",
        selectedValueTimeZone: "#",
        selectedLabelTimeZone: "Select a Time Zone",
        selectedUtcTimeZone: "",
        selectedIdtstTimeZone: "",
        selectValueDefault: "#",
        selectLabelDefault: "Select a Site",
        HTAEnglish: "",
        HTASpanish: "",
        address: "",
        reviewLinkOriginal: "",
      });
      getTimeZone();
      return;
    }

    getTimeZone();
    const found = siteList.find(
      (element) => element.site === event.target.value
    );
    setDatosSite({
      ...initDatosSite,
      ...found,
      site: found.site.split("-")[0].trim(),
      location: found.site.split("-")[1].trim(),
      timeZone: found.timeZoneValue,
    });
    setState((state) => ({
      ...state,
      selectValueDefault: event.target.value,
      requestAction: showSelect ? "update" : "create",
    }));
    if (found && found.timeZoneValue) {
      const foundTimeZone = allTimeZones.find(
        (element) => element.value === found.timeZoneValue
      );
      if (foundTimeZone && foundTimeZone.value) {
        setState((state) => ({
          ...state,
          selectedValueTimeZone: foundTimeZone.value,
          selectedLabelTimeZone: foundTimeZone.label,
          selectedUtcTimeZone: foundTimeZone.utc,
          selectedIdtstTimeZone: foundTimeZone.isdst,
        }));
      } else {
        setState((state) => ({
          ...state,
          selectedValueTimeZone: "#",
          selectedLabelTimeZone: "Select a Time Zone",
          selectedUtcTimeZone: "",
          selectedIdtstTimeZone: "",
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        selectedValueTimeZone: "#",
        selectedLabelTimeZone: "Select a Time Zone",
        selectedUtcTimeZone: "",
        selectedIdtstTimeZone: "",
      }));
    }
  };


  useEffect(() => {

    const getToken = appState.directorsAuth.authorizationTokens.find(
      (i) => i.siteId.toString() === datosSite.site
    );

    if (getToken !== undefined) {
      setSiteToken(getToken.auth.accesssToken);
    } else {
      setSiteToken(null);
    }


  }, [datosSite]);


  const handleUpdateService = async () => {
    try {

      setState((state) => ({
        ...state,
        updateServiceStatus: "processing",
      }));

      const postServices = {
        method: "POST",
        headers: {
          siteId: `${datosSite.site}`,
          authorization: siteToken,
        },
      };

      const responsePost = await fetch(
        `${process.env.REACT_APP_API_URL}/api/program/sessionTypes`,
        postServices
      );

      if (responsePost.ok) {
        setState((state) => ({
          ...state,
          updateServiceStatus: "success",
          updateServiceMessage: "Services updated successfully!",
        }));

        setTimeout(() => {
          setState((state) => ({
            ...state,
            updateServiceStatus: "default",
            updateServiceMessage: "",
          }));
        }, 2000);

      }

    } catch (error) {

      setState((state) => ({
        ...state,
        updateServiceStatus: "error",
        updateServiceMessage: "Error: " + JSON.stringify(error.message),
      }));


    }
  }

  return (
    <div className="content pb-4">
      {state.status === "loading" && (
        <div className="loading" data-cy="loading-message">
          <div className="lb-spinner" />
        </div>
      )}

      {state.status === "ready" && (
        <div className="container mt-4 col-sm-10">
          <div className="container mt-4 mb-5">
            <h2 className="text-center lb-title-daily" data-cy="daily-report">
              Setup
            </h2>
          </div>

          <section id="data-setup" className="mt-4">
            <div>
              <div>
                <div className="row">
                  <div className="col-12 col-md-8 mb-3 mt-1">
                    {showSelect === true && siteList && (
                      <select
                        className="form-select"
                        data-cy="select-site"
                        onChange={handleChange}
                        value={state.selectValueDefault}
                      >
                        <option value="#">Select a Site</option>
                        {siteList.map((site, i) => {
                          return (
                            <option value={site.site} key={site.site}>
                              {site.name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label htmlFor="btn-create">
                    </label>
                    <button
                      className="lb-button-daily col-5"
                      data-cy="btn-create"
                      onClick={clearForm}
                    >
                      {showSelect === true ? "Create" : "Cancel"}
                    </button>
                    <button
                      className={siteToken === null || state.updateServiceStatus === "processing"
                        ? "lb-button-daily-disabled col-6 ml-3" :
                        state.updateServiceStatus === "success" ?
                          "lb-button-success col-6 ml-3"
                          : "lb-button-daily col-6 ml-3"}
                      data-cy="btn-update-service"
                      onClick={handleUpdateService}
                      disabled={siteToken === null ||
                        state.updateServiceStatus === "processing" ||
                        state.updateServiceStatus === "success"}
                    >
                      {state.updateServiceStatus === "processing" ?
                        <FontAwesomeIcon spin icon={faSpinner} />
                        : state.updateServiceStatus === "success" ?
                          <FontAwesomeIcon icon={faCheck} /> : "Update Service"}

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="data-setup" className="mt-4">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-12 col-md-8 mb-3">
                      <label htmlFor="site">
                        <strong>Site</strong>
                      </label>
                      <span className="lb-requiredLabel"> * </span>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="site"
                        data-cy="input-site"
                        name="site"
                        value={datosSite.site}
                        onChange={handleInputChange}
                        disabled={state.requestAction === "update"}
                      ></input>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                      <label htmlFor="location">
                        <strong>Location</strong>
                      </label>
                      <span className="lb-requiredLabel"> * </span>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="location"
                        data-cy="input-location"
                        name="location"
                        value={datosSite.location}
                        onChange={handleInputChange}
                        disabled={state.requestAction === "update"}
                      ></input>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="name">
                        <strong>Name</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="name"
                        data-cy="input-name"
                        name="name"
                        value={datosSite.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="folder">
                        <strong>Folder</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        type="text"
                        className={
                          state.validateFolder
                            ? "form-control"
                            : "form-control is-invalid"
                        }
                        onBlur={handleValidateFolder}
                        aria-describedby="folder"
                        data-cy="input-folder"
                        name="folder"
                        value={datosSite.folder}
                        onChange={handleInputChange}
                        maxLength={30}
                      />
                      {!state.validateFolder && (
                        <span className="lb-validation-error-message">
                          Invalid folder, remember to contain slash at the
                          beginning and end. Please enter a valid folder
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 mb-3">
                      <label htmlFor="email">
                        <strong>Email</strong>
                      </label>
                      <span className="lb-requiredLabel"> * </span>
                      <textarea
                        className={
                          state.validateEmail
                            ? "form-control"
                            : "form-control is-invalid"
                        }
                        onBlur={handleOnBlur}
                        rows="4"
                        value={datosSite.email}
                        data-cy="input-email"
                        name="email"
                        onChange={handleInputChange}
                      />

                      {!state.validateEmail && (
                        <span className="lb-validation-error-message">
                          Invalid email, please enter a valid email
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="phone">
                        <strong>Phone</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        type="text"
                        onBlur={handlePhoneOnBlur}
                        className="form-control"
                        aria-describedby="phone"
                        data-cy="input-phone"
                        name="phone"
                        value={datosSite.phone}
                        onChange={handleInputChange}
                      />

                      {!state.validatePhone && (
                        <span className="lb-validation-error-message">
                          Invalid phone, please enter a valid phone
                        </span>
                      )}
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="zipwhipSession">
                        <strong>ZipwhipSession</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="zip"
                        data-cy="input-zip"
                        name="zipwhipSession"
                        value={datosSite.zipwhipSession}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="bitlyForm">
                        <strong>BitlyForm</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="bitly"
                        data-cy="input-bitly"
                        name="bitlyForm"
                        value={datosSite.bitlyForm}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="reviewLinkOriginal">
                        <strong>Bitly review link</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="reviewLinkOriginal"
                        data-cy="input-reviewLinkOriginal"
                        name="reviewLinkOriginal"
                        value={datosSite.reviewLinkOriginal}
                        onChange={handleInputChange}
                        disabled={state.requestAction === "update"}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-3 mb-3">
                      <label htmlFor="inboxId">
                        <strong>Inbox Id</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="inboxId"
                        data-cy="input-inboxId"
                        name="inboxId"
                        value={datosSite.inboxId}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 col-md-3 mb-3">
                      <label htmlFor="creatorId">
                        <strong>Creator Id</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="creatorId"
                        data-cy="input-creatorId"
                        name="creatorId"
                        value={datosSite.creatorId}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="timeZone">
                        <strong>Time Zone</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <select
                        className="form-select form-select-sm"
                        data-cy="select-timeZone"
                        onChange={handleTimeZoneChange}
                        value={state.selectedValueTimeZone}
                      >
                        <option value="#">Select a Time Zone</option>
                        {allTimeZones.map((time, i) => {
                          return (
                            <option value={time.value} key={time.value}>
                              {time.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 mb-3">
                      <label htmlFor="address">
                        <strong>Address</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="address"
                        data-cy="input-address"
                        name="address"
                        value={datosSite.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="storeName">
                        <strong>Store Name</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        className="form-control"
                        value={datosSite.storeName}
                        data-cy="input-storeName"
                        name="storeName"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="gMapLink">
                        <strong>Google Maps Link</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        className="form-control"
                        value={datosSite.gMapLink}
                        data-cy="input-gMapLink"
                        name="gMapLink"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 col-md-6 mb-3">
                      <label htmlFor="chatbotId">
                        <strong>Chatbot Id</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <input
                        className="form-control"
                        value={datosSite.chatbotId}
                        data-cy="input-chatbotId"
                        name="chatbotId"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="HTAEnglish">
                        <strong>How to arrive (English)</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <textarea
                        className="form-control"
                        rows="2"
                        value={datosSite.HTAEnglish}
                        data-cy="input-HTAEnglish"
                        name="HTAEnglish"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label htmlFor="HTASpanish">
                        <strong>How to arrive (Spanish)</strong>
                        <span className="lb-requiredLabel"> * </span>
                      </label>
                      <textarea
                        className="form-control"
                        rows="2"
                        value={datosSite.HTASpanish}
                        data-cy="input-HTASpanish"
                        name="HTASpanish"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section id="buttonsdown">
            <div className="lb-text-center mt-5">
              {state.updateStatus === "success" && (
                <div
                  data-cy="msg-success"
                  className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-success"
                >
                  <span className="">{state.updateMessage}</span>
                </div>
              )}
              {state.updateServiceStatus === "success" && (
                <div
                  data-cy="msg-success-update-service"
                  className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-success"
                >
                  <span className="">{state.updateServiceMessage}</span>
                </div>
              )}
              {state.updateServiceStatus === "error" && (
                <div
                  data-cy="msg-error-update-service"
                  className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error"
                >
                  <span className="">{state.updateServiceMessage}</span>
                </div>
              )}
              {(state.updateStatus === "requestError" ||
                state.updateStatus === "emptyError" ||
                state.updateStatus === "validateError" ||
                state.updateStatus === "error") && (
                  <div
                    data-cy="msg-error"
                    className="mt-1 mb-3 lb-text-center lb-w-60 mx-auto lb-form-submited-error"
                  >
                    <span className="">{state.updateMessage}</span>
                  </div>
                )}
              <button
                onClick={handleSave}
                data-cy="btn-save"
                className="lb-button-daily mr-1"
              >
                <span>
                  {state.requestAction === "update"
                    ? "Update Site"
                    : "Create Site"}
                </span>
              </button>
            </div>
          </section>
        </div>
      )}

      {state.status === "error" && (
        <div className="container" data-cy="error">
          <div className="row">
            <div className="col">
              <h1 className="lb-text-center">Error</h1>
              <h2 className="lb-text-center">
                Seems like there has been an error
              </h2>
              <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                <span>{state.message}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.status === "no-data-found" && (
        <div className="container" data-cy="not-found-message">
          <div className="row">
            <div className="col">
              <h1 className="lb-text-center">Nothing found</h1>
              <h2 className="lb-text-center">Seems like nothing was found</h2>
              <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                <span>{state.message}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ConfigSetup.propTypes = {
  appState: PropTypes.object.isRequired,
};

export default ConfigSetup;
