import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";

const ManagementDashboard = () => {
  return (
    <div className="row mt-4">
      <div className="col-md-12 text-center" data-cy="redirection-management">
        <img src={logo} width="10%" alt="Little Bellies Logo"></img>
        <h3 className="h3 mt-4">Welcome back!!!</h3>
        <h3 className="h4">Where would you like to go?</h3>
        <Link
          to={`/appointments`}
          className="btn border rounded-pill m-2 w-auto"
          data-cy="button-made-by"
          style={{
            backgroundColor: "#c2608e",
            borderColor: "#c2608e #c2608e #fff",
            width: 100,
            color: "white",
          }}
        >
          Appointments
        </Link>
        <Link
          to={`/dailyreport`}
          className="btn border rounded-pill m-2 w-auto"
          data-cy="button-made-by"
          style={{
            backgroundColor: "#c2608e",
            borderColor: "#c2608e #c2608e #fff",
            width: 100,
            color: "white",
          }}
        >
          Daily Report
        </Link>
      </div>
    </div>
  );
};

export default ManagementDashboard;
