import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

function ApproveDailyReport({ appState, setAppState }) {
  const [state, setState] = useState({
    status: "ready",
    message: "",
    siteId: "",
    locationId: "",
    locationName: "",
    date: new Date(moment().subtract(1,"days").toString()),
    disableButton: true,
    approved : false,
  });

  const [sites, setSites] = useState([]);

  const handleDateChange = (date) => {
    setState((state) => ({
      ...state,
      date: date,
      disableButton : true,
    }));
  };

  const handleSiteChange = (site) => {
    let foundedSite = {};
    
    // sites[0].options.find((i) => i.value === site.value) === undefined ? foundedSite = sites[1].options.find((i) => i.value === site.value) : foundedSite = sites[0].options.find((i) => i.value === site.value)

    if(sites[1].options.find((i) => i.value === site.value) === undefined){

      foundedSite = sites[0].options.find((i) => i.value === site.value);

      setState((state) => ({
        ...state,
        siteId: site.value.split("-")[0],
        locationId: site.value.split("-")[1],
        locationName: site.label,
        disableButton: foundedSite === undefined,
        approved : true
      }));

    }else{

      foundedSite = sites[1].options.find((i) => i.value === site.value)

      setState((state) => ({
        ...state,
        siteId: site.value.split("-")[0],
        locationId: site.value.split("-")[1],
        locationName: site.label,
        disableButton: foundedSite === undefined,
        approved : false
      }));


    }


  };

  useEffect(() => {
    getSites();
  }, [state.date]);

  const getSites = async () => {
    try {
      setState((state) => ({
        ...state,
        status: "loading",
      }));

      const getSitesQuery = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/config/sites`,
        getSitesQuery
      );
      const data = await response.json();
      if (response.ok) {
        const completedSitesQuery = {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };

        const qDate = moment(state.date).format("MM/DD/YYYY").toString();

        const completedSitesResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/dailyReport/completed?sortDate=${qDate}`,
          completedSitesQuery
        );
        const completedData = await completedSitesResponse.json();
        if (completedSitesResponse.ok) {

          const sitesOptions = data.sites.map((site) => {
            const siteObject = {
              value: site.site,
              label: site.name,
            };
            return siteObject;
          });

          sitesOptions.sort(function (a, b) {
            if (a.label > b.label) {
              return 1;
            }
            if (a.label < b.label) {
              return -1;
            }
            return 0;
          });

          let completedOptions = [];
          let approvedOptions = [];

          if (completedData.sites.length > 0) {
            completedOptions = completedData.sites.map((site) => {
              const siteId = site.site;
              const siteIndex = sitesOptions.findIndex(
                (site) => site.value === siteId
              );
              let siteObject = {};
              if (siteIndex !== -1) {
                siteObject = sitesOptions[siteIndex];
                sitesOptions.splice(siteIndex, 1);
              } else {
                console.log("not found");
              }
              return siteObject;
            });

            completedOptions.sort(function (a, b) {
              if (a.label > b.label) {
                return 1;
              }
              if (a.label < b.label) {
                return -1;
              }
              return 0;
            });


             approvedOptions = completedData.sites.map((i) => {

                  let approved = {};
  
                   const sitios = completedOptions.find((c) => c.value === i.site)
  
                    if(sitios !== undefined){

                      if(sitios.value === i.site && i.approved){
                       
                        const foundSitedex = completedOptions.findIndex((c) => c.value === sitios.value);
                        approved = {value: sitios.value, label: sitios.label};
                        completedOptions.splice(foundSitedex,1);

                      }
                    }

                    if(completedOptions.length === 0){

                      completedOptions.push(
                        {
                          value: "No-data",
                          label: "No completed reports found",
                          isDisabled: true,
                        });

                    }

                    return approved;
  
                 }).filter((sitesApproved) => {
  
                  return Object.entries(sitesApproved).length !== 0;
    
                 }).sort((a,b) => {
                  if(a.label > b.label)
                  { return 1 }
                  if(a.label < b.label)
                  { return -1}
                  return 0;
                  });
                              

          }else {

          approvedOptions = [
            {
              value: "No-data",
              label: "No approved reports found",
              isDisabled: true,
            },
          ];
          completedOptions = [
            {
              value: "No-data",
              label: "No completed reports found",
              isDisabled: true,
            },
          ];
        }

          const displayableSites = [
            {
              label: "Approved",
              options: approvedOptions,
            },
            {
              label: "Completed",
              options: completedOptions,
            },
            {
              label: "Pending",
              options: sitesOptions,
            },
          ];

          setSites(displayableSites);
          setState((state) => ({
            ...state,
            status: "ready",
          }));
        } else {
          console.log("Fallo completados");
        }
      } else {
        console.log("Fallo general");
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
      setSites([]);
    }
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const selectStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: 14,
      };
    },
  };

  const groupTextStyles = {
    color: "#AE678C",
    fontSize: 16,
    textTransform: "capitalize",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span style={groupTextStyles}>{data.label}</span>
    </div>
  );

  return (
    <div className="content">
      {state.status === "loading" && (
        <div className="loading" data-cy="loading-report-message">
          <div className="lb-spinner" />
        </div>
      )}
      {state.status !== "loading" && (
        <div className="container">
          <div className="container mt-3">
            <div className="row mb-3">
              <div className="col text-center">
                <h3 className="lb-title-daily " data-cy="daily-report-review">
                  Review Daily Report.
                </h3>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-4 my-3">
                <Select
                  options={sites}
                  className="dropdown w-100 mb-3"
                  formatGroupLabel={formatGroupLabel}
                  styles={selectStyles}
                  data-cy="review-selector"
                  id="review-selector"
                  instanceId="review-selector3"
                  isSearchable={false}
                  onChange={(site) => {
                    handleSiteChange(site);
                  }}
                />
              </div>
              <div className="col-3 my-3">
                <DatePicker
                  selected={state.date}
                  dateFormat="MM/dd/yyyy"
                  onChange={(date) => handleDateChange(date)}
                  className="form-control"
                />
              </div>
              <div className="col-2 my-3">
                {!state.disableButton && (
                  <Link
                    data-cy="daily-report-go"
                    className={
                      state.disableButton
                        ? "btn lb-btn-review-search-disabled"
                        : "btn lb-btn-review-search"
                    }
                    to={{
                      pathname: `/dailyreport/${moment(state.date)
                        .format("MM-DD-YYYY")
                        .toString()}`,
                      state: {
                        siteId: state.siteId,
                        locationId: state.locationId,
                        locationName: state.locationName, 
                        approved : state.approved,
                        date: moment(state.date)
                          .format("MM/DD/YYYY")
                          .toString(),
                      },
                    }}
                    disabled={state.disableButton}
                  >
                    Review Report
                  </Link>
                )}
                {state.disableButton && (
                  <button
                    className={
                      state.disableButton
                        ? "btn lb-btn-review-search-disabled"
                        : "btn lb-btn-review-search"
                    }
                    data-cy="disabled-report"
                    disabled={state.disableButton}
                  >
                    Review Report
                  </button>
                )}
              </div>
            </div>
          </div>

          {(state.status === "error" ||
            appState.nav.status === "logOutError") && (
            <div className="container" data-cy="error">
              <div className="row">
                <div className="col">
                  <h1 className="lb-text-center">Error</h1>
                  <h2 className="lb-text-center">
                    Seems like there has been an error
                  </h2>
                  <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                    <span>
                      {appState.nav.status === "logOutError"
                        ? appState.nav.message
                        : state.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {state.status === "no-data-found" && (
            <div className="container" data-cy="not-found-message">
              <div className="row">
                <div className="col">
                  <h3 className="lb-text-center">No report found</h3>
                  <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                    <span>{state.message}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
ApproveDailyReport.propTypes = {
  appState: PropTypes.object.isRequired,
  setAppState: PropTypes.func.isRequired,
};
export default ApproveDailyReport;
