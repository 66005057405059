import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  faEye,
  faEyeSlash,
  faPen,
  faTrashAlt,
  faPaperclip,
  faUpload,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/dailyReport.css";

function ContentManagerReviews({ appState }) {
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [reviewsData, setReviews] = useState([]);
  const [fetchData, setFetchData] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const [formData, setFormData] = useState({
    name: "",
    abstract: "",
    service: "Ultrasounds",
    photo: null,
  });
  const [state, setState] = useState({
    status: "idle",
    message: "",
  });
  console.log(state);
  const handleModalClose = (e) => {
    if (e.target.id === "reviewModal") {
      setShow(false);
    }
  };

  const handleModalOpen = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleModalCloseDelete = (e) => {
    if (e.target.id === "deleteModal") {
      setShowDelete(false);
    }
  };

  const handleModalOpenDelete = () => {
    setShowDelete(true);
  };

  const closeModalDelete = () => {
    setShowDelete(false);
  };

  const modalOpenForReview =
    ({ uniqueId }) =>
    () => {
      const review = reviewsData.find((review) => review.uniqueId === uniqueId);

      setFormData({
        uniqueId,
        name: review.name,
        abstract: review.abstract,
        service: review.service,
        photo: null,
        image1: review.image1,
      });
      handleModalOpen();
    };

  const modalOpenForDelete =
    ({ uniqueId }) =>
    () => {
      setDeleteId(uniqueId);
      handleModalOpenDelete();
    };

  const handleDelete = async (event) => {
    event.preventDefault();

    deleteReview({ uniqueId: deleteId });
    closeModalDelete();
  };

  const handleChange = ({ name, value }) => {
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const getReviews = async () => {
    setState((state) => ({
      ...state,
      status: "loading",
    }));
    try {
      const getMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/content/review`,
        getMethod
      );

      if (getResponse.ok && getResponse.status === 200) {
        const reviewData = await getResponse.json();
        const reviews = reviewData.reviews.map((review) => {
          return { ...review, visible: review.visible === "true" };
        });
        setReviews(
          reviews.filter((review) => review.siteId === appState.nav.siteId)
        );
      } else {
        setReviews([]);
      }
    } catch (error) {
      setReviews([]);
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const deleteReview = async ({ uniqueId }) => {
    try {
      const postMethod = {
        method: "DELETE",
      };
      const responseInfo = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/api/content/review/${uniqueId.trim()}`,
        postMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setFetchData(!fetchData);
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const postVisible = async ({ uniqueId, visible }) => {
    try {
      const data = new FormData();
      data.append("visible", visible);

      const postMethod = {
        method: "PUT",
        body: data,
      };

      const responseInfo = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/api/content/review/${uniqueId.trim()}`,
        postMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setFetchData((val) => !val);
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const postForm = async () => {
    try {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("abstract", formData.abstract);
      if (formData.photo)
        data.append("photo", formData.photo, formData?.photo?.name);
      data.append("siteId", appState.nav.siteId);
      data.append("service", formData.service);
      data.append("visible", "true");

      const postMethod = {
        method: "POST",
        body: data,
      };

      const responseInfo = await fetch(
        `${process.env.REACT_APP_API_URL}/api/content/review`,
        postMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setFetchData((val) => !val);
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const putForm = async ({ uniqueId }) => {
    try {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("abstract", formData.abstract);
      if (formData.photo)
        data.append("photo", formData.photo, formData?.photo?.name);
      data.append("siteId", appState.nav.siteId);
      data.append("service", formData.service);

      const putMethod = {
        method: "PUT",
        body: data,
      };

      const responseInfo = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/api/content/review/${uniqueId.trim()}`,
        putMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setFetchData((val) => !val);
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  useEffect(() => {
    getReviews();
  }, [appState.nav.siteId, fetchData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.uniqueId) {
      putForm({ uniqueId: formData.uniqueId });
    } else {
      postForm();
    }

    closeModal();
  };

  const handleNewReview = () => {
    setFormData({
      name: "",
      abstract: "",
      service: "Ultrasounds",
      photo: null,
    });
    handleModalOpen();
  };

  return (
    <div className="container" data-cy="cm-review">
      <div className="my-5 d-flex justify-content-center">
        <h4 className="lb-title-daily">Reviews Content Manager</h4>
      </div>
      <table className="table mt-5">
        <colgroup>
          <col style={{ width: "15%" }} />
          <col style={{ width: "60%" }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">
              <h5 className="lb-not-bold">List of reviews</h5>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {reviewsData.map((item) => (
            <tr key={item.uniqueId}>
              <th scope="row">{item.name}</th>
              <td>{item.abstract}</td>
              <td>
                <button
                  className="lb-button-faq"
                  onClick={() =>
                    postVisible({
                      uniqueId: item.uniqueId,
                      visible: !item.visible,
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={item.visible ? faEye : faEyeSlash}
                    style={{ fontSize: 20 }}
                  />
                </button>
              </td>
              <td>
                <button
                  className="lb-button-faq"
                  type="button"
                  onClick={modalOpenForReview({ uniqueId: item.uniqueId })}
                >
                  <FontAwesomeIcon icon={faPen} style={{ fontSize: 20 }} />
                </button>
              </td>
              <td>
                <button
                  className="lb-button-faq"
                  onClick={modalOpenForDelete({ uniqueId: item.uniqueId })}
                >
                  <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: 20 }} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-3 d-flex justify-content-end">
        <button
          className="lb-button-daily px-5"
          type="button"
          onClick={handleNewReview}
        >
          Create
        </button>
      </div>

      <div hidden={!show}>
        <div
          className="modal-background"
          id="reviewModal"
          onClick={handleModalClose}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header mx-auto lb-divider-hide">
                <h5
                  className="modal-title lb-title-daily"
                  id="reviewModalLabel"
                >
                  Edit review content
                </h5>
              </div>
              <div className="modal-body">
                <div className="mx-auto mb-3">
                  <label htmlFor="service-category-select d-block">
                    Please select a service category
                  </label>
                  <select
                    className="form-select d-block"
                    aria-label="service category select"
                    value={formData.service}
                    onChange={(e) =>
                      handleChange({
                        name: "service",
                        value: e.target.value,
                      })
                    }
                  >
                    <option value="Ultrasounds">Ultrasounds</option>
                    <option value="DNA">DNA</option>
                    <option value="Massages">Massages</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="nameInput" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nameInput"
                    placeholder="John Doe"
                    value={formData.name}
                    onChange={(e) =>
                      handleChange({
                        name: "name",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <div className="ml-1">
                    Photo{" "}
                    {formData.image1 && (
                      <a href={formData.image1}>View photo</a>
                    )}
                  </div>
                  <label
                    htmlFor="lbFile1"
                    className="lb-file-label d-flex justify-content-between"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        style={{ fontSize: 15, marginRight: 10 }}
                      />
                      {formData.photo?.name
                        ? formData.photo.name
                        : formData.image1
                        ? "photo.png"
                        : "No file selected"}
                    </span>
                    <span className="lb-add-file">
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ fontSize: 10, marginRight: 10 }}
                      />
                      select file
                    </span>
                  </label>
                  <input
                    type="file"
                    id="lbFile1"
                    hidden
                    onChange={(e) =>
                      handleChange({
                        name: "photo",
                        value: e.target.files[0],
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="abstractInput" className="form-label">
                    Abstract
                  </label>
                  <textarea
                    className="form-control"
                    id="abstractInput"
                    rows="3"
                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tincidunt elementum mi et vestibulum."
                    value={formData.abstract}
                    onChange={(e) =>
                      handleChange({
                        name: "abstract",
                        value: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between lb-divider-hide">
                <button
                  type="button"
                  className="btn btn-outline-secondary lb-w-btn"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="lb-button-daily lb-w-btn py-2"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div hidden={!showDelete}>
        <div
          className="modal-background"
          id="deleteModal"
          onClick={handleModalCloseDelete}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header mx-auto lb-divider-hide">
                <h5 className="modal-title lb-title-daily">Delete Review</h5>
              </div>
              <div className="modal-body mx-auto">
                <div className="d-flex justify-content-center mb-2">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ fontSize: 20, color: "#c2608e" }}
                  />
                </div>
                <p>Are you sure about deleting this review?</p>
              </div>
              <div className="modal-footer d-flex justify-content-between ">
                <button
                  type="button"
                  className="btn btn-outline-secondary lb-w-btn"
                  data-bs-dismiss="modal"
                  onClick={closeModalDelete}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="lb-button-daily lb-w-btn py-2"
                  onClick={handleDelete}
                >
                  Yes, delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ContentManagerReviews.propTypes = {
  appState: PropTypes.object.isRequired,
};

export default ContentManagerReviews;
