import React from "react";
import PropTypes from 'prop-types'

function GoGreenModal ({closeModal}) {
    return(
      <div
        className="lb-modal-overlay"
        onClick={closeModal}
        id="goGreenModalOverlay"
      >
        <button
          type="button"
          id="closeGoGreenModal"
          onClick={closeModal}
          className="lb-close-button lb-text-center"
        >
          &times;
        </button>
        <div className="lb-gogreen-modal">
          <div
            className="lb-gogreen-modal-body pt-5 px-4"
            name="goGreenModal"
            data-cy="goGreenModal"
          >
            <div className="container">
              <div className="row">
                <div className="col">
                  <span className="fw-bold">
                    Hi everyone!!! How are you? <br />
                    <br />
                    HELP US GO GREEN ♻️, no more paper 📝 <br />
                    <br />
                    We’ve been trying for a few months now to go green ♻️and
                    we’ve come a long way but we need your help to go all the
                    way. <br />
                    <br />
                    Please no more paper!❌ <br />
                    <br />
                    Please fill all the information that the system requires for
                    your check in <br />
                    <br />
                    ♻️THINK GREEN, THINK CLEAN ♻️
                  </span>
                </div>
              </div>
              
              <div className="row my-4">
                <div className="col text-center">
                    <button
                    className="lb-btn text-lb-btn-submit lb-inverse-submitButton"
                    onClick={closeModal}
                    name="goGreenModalCloseButton"
                    id="goGreenModalCloseButton"
                    data-cy="goGreenModalCloseButton"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

GoGreenModal.propTypes = {
  closeModal: PropTypes.func
};

export default GoGreenModal;