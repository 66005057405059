import React, { useState, useEffect } from "react";
import "../styles/global.css";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function EditReviewLinksPage({ appState }) {
  const initState = {
    loading: true,
    requestStatus: "IDLE",
    status: "loading",
    message: "",
  };
  const [state, setState] = useState(initState);
  const [sitesData, setSitesData] = useState([]);

  const getSitesData = async () => {
    try {
      const getSitesData = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/config/sites`,
        getSitesData
      );

      const data = await response.json();

      if (response.ok) {
        const allSitesItem = data.sites.find(site => site.site === "0-0");
        let sitesArray = []
        if(allSitesItem !== undefined){
          sitesArray = data.sites.filter(site => site.site !== "0-0");
        }
        else{
          sitesArray = data.sites;
        }
        setSitesData(sitesArray);
        setState({
          ...state,
          loading: false,
          requestStatus: "OK",
          status: "ready",
          message: "",
        });
      } else {
        setState({
          ...state,
          loading: false,
          requestStatus: "FAIL",
          status: "ready",
          message: JSON.stringify(data),
        });
      }
    } catch (error) {
      setState({
        ...state,
        loading: false,
        requestStatus: "ERROR",
        status: "ready",
        message: JSON.stringify(error.message),
      });
    }
    console.log(sitesData);
  };

  useEffect(() => {
    getSitesData();
  }, []);

  const handleSelectChange = (e) => {
    const siteId = e.target.name;
    const value = e.target.value;

    const mutableSitesData = sitesData;

    const findedIndex = mutableSitesData.findIndex(
      (site) => site.site === siteId
    );

    if (findedIndex !== -1) {
      mutableSitesData[findedIndex].reviewLinkSent = value;
      setSitesData([...mutableSitesData]);
    }
  };

  const updateAllSites = async () => {
    const singleUpdate = async (siteObject) => {
      const payload = { ...siteObject };
      const updateSiteRequestConfig = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/config/sites`,
        updateSiteRequestConfig
      );

      if (response.ok) {
        return Promise.resolve({
          siteName: siteObject.storeName,
          success: response.ok,
        });
      } else {
        return Promise.resolve({
          siteName: siteObject.storeName,
          success: response.ok,
        });
      }
    };

    const updateAttemps = sitesData.map((site) => singleUpdate({ ...site }));

    const updateResults = await Promise.all(updateAttemps);

    return Promise.resolve(updateResults);
  };

  const handleUpdateClick = async (e) => {
    try {
      setState({
        ...state,
        requestStatus: "REQUESTING",
      });

      const results = await updateAllSites();
      const failedAttempts = results.filter((elem) => elem.success === false);

      if (failedAttempts.length > 0) {
        let sitesList = "";
        failedAttempts.forEach((el) => {
          sitesList += el.siteName + ", ";
        });
        setState({
          ...state,
          requestStatus: "UPDATE-FAIL",
          message: "Failed to update: " + sitesList,
        });
      } else {
        setState({
          ...state,
          requestStatus: "UPDATE-OK",
          message: "",
        });
      }
    } catch (error) {
      setState({
        ...state,
        requestStatus: "UPDATE-ERROR",
        message: "ERROR: " + JSON.stringify(error),
      });
    }
  };

  return (
    <div className="container">
      {state.loading === true && (
        <div className="loading" data-cy="loading-message">
          <div className="lb-spinner" />
        </div>
      )}

      {state.loading === false && (
        <div className="row">
          <div className="col text-center mb-5">
            {state.requestStatus === "FAIL" && (
              <div className="d-block alert alert-warning">
                <span> {state.message} </span>
              </div>
            )}
            {state.requestStatus === "ERROR" && (
              <div className="d-block alert alert-danger">
                <span> {state.message} </span>
              </div>
            )}
            {state.status === "ready" && (
              <>
                <div className="table-responsive lb-w-80 mx-auto">
                  <table className="table table-fit reviewLinksTable table-borderless">
                    <thead>
                      <tr>
                        <th>Site Name</th>
                        <th>Site Link</th>
                        <th>Target Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sitesData.map((site) => {
                        return (
                          <tr key={site.site} id={site.site}>
                            <td>{site.name}</td>
                            <td>
                              <a
                                href={site.reviewLinkOriginal}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {site.reviewLinkOriginal}
                              </a>
                            </td>
                            <td>
                              <form>
                              <select
                                name={site.site}
                                value={site.reviewLinkSent}
                                onChange={(e) => handleSelectChange(e)}
                                className="lb-w-100 form-select form-select-sm"
                                data-cy={`select-${site.site}`}
                              >
                                {sitesData.map((siteSelect, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={siteSelect.reviewLinkOriginal}
                                      className="lb-w-100"
                                    >
                                      {siteSelect.storeName}
                                    </option>
                                  );
                                })}
                              </select>
                              </form>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {state.requestStatus === "UPDATE-OK" && (
                  <div className="w-50 mx-auto">
                    <div className="d-block alert alert-success" data-cy="update-success-message">
                      <span> All sites were successfully updated </span>
                    </div>
                  </div>
                )}
                {(state.requestStatus === "UPDATE-FAIL" ||
                  state.requestStatus === "UPDATE-ERROR") && (
                  <div className="w-50 mx-auto">
                    <div className="d-block alert alert-danger" data-cy="update-fail-message">
                      <span> {state.message} </span>
                    </div>
                  </div>
                )}
                <button
                  onClick={handleUpdateClick}
                  data-cy="btn-save"
                  className="lb-button-daily mt-2"
                >
                  <span>
                    {state.requestStatus === "REQUESTING" ? (
                      <>
                        <FontAwesomeIcon spin icon={faSpinner} /> Loading...
                      </>
                    ) : (
                      "Update Sites"
                    )}
                  </span>
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

EditReviewLinksPage.propTypes = {
  appState: PropTypes.object.isRequired,
};

export default EditReviewLinksPage;
