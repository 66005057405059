import "../styles/bootstrap-grid.min.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/global.css";
import "../App.css";
import { PropTypes } from "prop-types";

function VideoRemakePage({ appState }) {
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [state, setState] = useState({
    status: "ready",
    message: "",
    searchTerm: "",
    clientName: "",
    clientPhone: "",
    clientEmail: "",
    date: new Date().toISOString().substr(0, 10),
  });

  const getAppointmentList = async (event) => {
    event.preventDefault();
    setState((state) => ({
      ...state,
      status: "loading",
    }));
    try {
      const getMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: appState.nav.siteId,
          authorization: appState.singleAuth.token,
        },
      };

      const splittedDate = state.date.split("-");
      const formatedDate =
        splittedDate[1] + "/" + splittedDate[2] + "/" + splittedDate[0];
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/clients/${state.searchTerm}/visits?startDate=${formatedDate}`,
        getMethod
      );
      const data = await response.json();
      if (response.ok) {
        const appointmentsData = data.visits.map((item) => {
          const mutableItem = item;
          const date = mutableItem.StartDateTime.split("T")[0].split("-");
          mutableItem.formatedDate =
            date[1] + " / " + date[2] + " / " + date[0];
          return mutableItem;
        });

        setAppointmentsData(appointmentsData);

        setState((state) => ({
          ...state,
          status: "ready",
          clientName:
            data.clients[0].FirstName + " " + data.clients[0].LastName,
          clientPhone: data.clients[0].MobilePhone,
          clientEmail: data.clients[0].Email,
        }));
      } else {
        setState((state) => ({
          ...state,
          status: "no-data-found",
          message: JSON.stringify(data),
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: "Onload page Error: " + JSON.stringify(error.message),
      }));
    }
  };

  const handleSearchTermChange = (event) => {
    setState((state) => ({
      ...state,
      searchTerm: event.target.value,
    }));
  };

  const handleDateChange = (event) => {
    setState((state) => ({
      ...state,
      date: event.target.value,
    }));
  };

  return (
    <div className="content">
      <div className="container">
        <div className="container mt-3">
          <div className="row">
            <div className="col-6 lb-text-center">
              <div className="lb-form-group lb-form-inline">
                <input
                  data-cy="search-client-appointments"
                  type="text"
                  value={state.searchTerm}
                  onChange={handleSearchTermChange}
                  className="lb-form-control lb-w-100 px-2 lb-border-0 lb-inputBackground"
                  placeholder="Search appointments by client email or phone"
                />
              </div>
            </div>
            <div className="col-3 text-center">
              <div className="form-group form-inline">
                <input
                  type="date"
                  className="form-control w-100 border-0 inputBackground"
                  data-cy="start_date"
                  defaultValue={state.date}
                  onChange={handleDateChange}
                />
              </div>
            </div>
            <div className="col-3 text-center">
              <div className="form-group form-inline">
                <button
                  className="lb-w-100 px-2 lb-btn lb-submitButton"
                  data-cy="search-appointments-button"
                  onClick={getAppointmentList}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          {state.status === "loading" && (
            <div className="loading" data-cy="loading-message">
              <div className="lb-spinner" />
            </div>
          )}
          {state.status === "ready" && (
            <>
              <div className="row justify-content-between my-2">
                <div className="col my-auto">
                  {appointmentsData.length > 0 && (
                    <h1 data-cy="page-title-appointments">Appointments</h1>
                  )}
                  {appointmentsData.length === 0 && (
                    <h1 data-cy="page-title-appointments">
                      Please enter a mail or phone number
                    </h1>
                  )}
                </div>
              </div>
              <div className="row mx-0 mx-md-2 mx-lg-0 mb-4">
                {appointmentsData.map((appointment) => {
                  return (
                    <div
                      key={appointment.AppointmentId}
                      className="col-xs-12 col-md-6 col-lg-4 mb-4"
                    >
                      <div className="appt-card">
                        <div className="lb-w-100 px-3 py-2 lb-text-right lb-card-header lb-card-1-arrived">
                          <span>{state.clientName}</span>
                        </div>
                        <div className="lb-appt-lb-card-info lb-inputBackground px-3 py-3">
                          <ul className="px-3 m-0">
                            <li className="lb-w-100 lb-text-muted2">
                              {state.clientPhone}.
                            </li>
                            <li className="lb-w-100 lb-text-muted2">
                              {state.clientEmail}
                            </li>
                            <li className="lb-w-100 lb-text-muted2">
                              {appointment.formatedDate}
                            </li>
                            <li className="lb-w-100 lb-text-muted2">
                              {appointment.ServiceName}
                            </li>
                          </ul>
                        </div>
                        <div className="no-gutters lb-w-100 row">
                          <Link
                            className="lb-btn col-12 lbno-hover mx-0 lb-btn-full lb-card-1-arrived"
                            data-cy={
                              "remake-video-" + appointment.AppointmentId
                            }
                            to={{
                              pathname: `/imgupload/${appointment.AppointmentId}`,
                              state: {
                                email: state.clientEmail,
                                name: state.clientName,
                                service: appointment.ServiceName,
                                startDateTime: appointment.StartDateTime,
                                endDateTime: appointment.EndDateTime,
                                locationId: ""+appointment.LocationId,

                                programId: "",
                                sessionTypeId: "",
                              },
                            }}
                          >
                            Remake video
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
        {(state.status === "error") && (
          <div className="container" data-cy="error">
            <div className="row">
              <div className="col">
                <h1 className="lb-text-center">Error</h1>
                <h2 className="lb-text-center">
                  Seems like there has been an error
                </h2>
                <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                  <span>
                    {state.message}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {state.status === "no-data-found" && (
          <div className="container" data-cy="not-found-message">
            <div className="row">
              <div className="col">
                <h1 className="lb-text-center">Nothing found</h1>
                <h2 className="lb-text-center">Seems like nothing was found</h2>
                <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                  <span>{state.message}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

VideoRemakePage.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default VideoRemakePage;
