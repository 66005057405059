import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  faEye,
  faPen,
  faPaperclip,
  faUpload,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/dailyReport.css";

function ContentManagerServices({ appState }) {
  const [show, setShow] = useState(false);
  const [servicesData, setServices] = useState([]);
  const [fetchData, setFetchData] = useState(false);
  const [selectedService, setSelectedService] = useState("All");
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    service: "Ultrasounds",
    name: "",
    weeks: "",
    price: "",
    price1: "",
    price2: "",
    price3: "",
    time1: "",
    time2: "",
    icon: null,
    image1: null,
    image1Title: "",
    image2: null,
    image2Title: "",
    abstract: "",
    image3: null,
    image4: null,
    type: "type1",
  });

  const [state, setState] = useState({
    status: "idle",
    message: "",
  });

  const handleModalClose = (e) => {
    if (e.target.id === "serviceModal") {
      setShow(false);
    }
  };

  const handleModalOpen = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const modalOpenForService =
    ({ uniqueId }) =>
    () => {
      const service = servicesData.find(
        (service) => service.uniqueId === uniqueId
      );
      setFormData({
        uniqueId,
        service: service.service,
        name: service.name,
        weeks: service.weeks,
        price: service.price,
        price1: service.price1,
        price2: service.price2,
        price3: service.price3,
        time1: service.time1,
        time2: service.time2,
        icon: null,
        image1: null,
        image1Title: service.image1Title || "",
        image2: null,
        image2Title: service.image2Title || "",
        abstract: service.abstract,
        iconUrl: service.iconUrl,
        image1Url: service.image1,
        image2Url: service.image2,
        image3: null,
        image3Url: service.image3,
        image4: null,
        image4Url: service.image4,
        type: service.type,
      });
      handleModalOpen();
    };

  const handleChange = ({ name, value }) => {
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedService(selectedValue);
  };

  useEffect(() => {
    const filteredServiceData = servicesData.filter(
      (item) => item.service === selectedService
    );

    if (selectedService === " " || filteredServiceData.length === 0) {
      setData(servicesData);
    } else {
      setData(filteredServiceData);
    }
  }, [selectedService, servicesData]);

  const getServices = async () => {
    setState((state) => ({
      ...state,
      status: "loading",
    }));
    try {
      const getMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/content/service`,
        getMethod
      );

      if (getResponse.ok && getResponse.status === 200) {
        const servicesData = await getResponse.json();
        const services = servicesData.services.map((service) => {
          const item = {
            ...service,
            visible: service.visible === "true",
          };
          if (item.image1Title === "null") {
            delete item.image1Title;
          }
          if (item.image2Title === "null") {
            delete item.image2Title;
          }
          return item;
        });
        setServices(
          services.filter((service) => service.siteId === appState.nav.siteId)
        );
      } else {
        setServices([]);
      }
    } catch (error) {
      setServices([]);
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const postVisible = async ({ uniqueId, visible }) => {
    try {
      const data = new FormData();
      data.append("visible", visible);

      const postMethod = {
        method: "PUT",
        body: data,
      };

      const responseInfo = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/api/content/service/${uniqueId.trim()}`,
        postMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setFetchData((val) => !val);
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const postForm = async () => {
    try {
      const data = new FormData();
      data.append("service", formData.service);
      data.append("name", formData.name);
      data.append("weeks", formData.weeks);
      data.append("price", formData.price);
      data.append("price1", formData.price1);
      data.append("price2", formData.price2);
      data.append("price3", formData.price3);
      data.append("time1", formData.time1);
      data.append("time2", formData.time2);
      if (formData.icon)
        data.append("icon", formData.icon, formData?.icon?.name);
      if (formData.image1)
        data.append("image1", formData.image1, formData?.image1?.name);
      if (formData.image2)
        data.append("image2", formData.image2, formData?.image2?.name);
      data.append("abstract", formData.abstract);
      if (formData.uniqueId) data.append("uniqueId", formData.uniqueId);
      data.append("siteId", appState.nav.siteId);
      data.append("image1Title", formData.image1Title);
      data.append("image2Title", formData.image2Title);
      if (formData.image3)
        data.append("image3", formData.image3, formData?.image3?.name);
      if (formData.image4)
        data.append("image4", formData.image4, formData?.image4?.name);
      data.append("type", formData.type);

      data.append("visible", "true");

      const postMethod = {
        method: "POST",
        body: data,
      };

      const responseInfo = await fetch(
        `${process.env.REACT_APP_API_URL}/api/content/service`,
        postMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setFetchData((val) => !val);
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const putForm = async ({ uniqueId }) => {
    try {
      const data = new FormData();
      data.append("service", formData.service);
      data.append("name", formData.name);
      data.append("weeks", formData.weeks);
      data.append("price", formData.price);
      data.append("price1", formData.price1);
      data.append("price2", formData.price2);
      data.append("price3", formData.price3);
      data.append("time1", formData.time1);
      data.append("time2", formData.time2);
      if (formData.icon)
        data.append("icon", formData.icon, formData?.icon?.name);
      if (formData.image1)
        data.append("image1", formData.image1, formData?.image1?.name);
      if (formData.image2)
        data.append("image2", formData.image2, formData?.image2?.name);
      data.append("abstract", formData.abstract);
      data.append("siteId", appState.nav.siteId);
      data.append(
        "image1Title",
        formData.image1Title === "" ? null : formData.image1Title
      );
      data.append(
        "image2Title",
        formData.image2Title === "" ? null : formData.image2Title
      );
      if (formData.image3)
        data.append("image3", formData.image3, formData?.image3?.name);
      if (formData.image4)
        data.append("image4", formData.image4, formData?.image4?.name);
      data.append("type", formData.type);

      const putMethod = {
        method: "PUT",
        body: data,
      };

      const responseInfo = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/api/content/service/${uniqueId.trim()}`,
        putMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setFetchData((val) => !val);
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  useEffect(() => {
    getServices();
  }, [appState.nav.siteId, fetchData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.uniqueId) {
      putForm({ uniqueId: formData.uniqueId });
    } else {
      postForm();
    }
    closeModal();
  };

  const handleNewService = () => {
    setFormData({
      service: "Ultrasounds",
      name: "",
      weeks: "",
      price: "",
      price1: "",
      price2: "",
      price3: "",
      time1: "",
      time2: "",
      icon: null,
      image1: null,
      image1Title: "",
      image2: null,
      image2Title: "",
      abstract: "",
      image3: null,
      image4: null,
      type: "type1",
    });
    handleModalOpen();
  };

  console.log(state);

  return (
    <div className="container">
      <div className="my-5 d-flex justify-content-center">
        <h4 className="lb-title-daily">Service Content Manager</h4>
      </div>
      <div className="mx-auto w-50">
        <label htmlFor="service-category-select d-block">
          Please select a service category
        </label>
        <select
          className="form-select d-block"
          aria-label="service category select"
          onChange={handleSelectChange}
          value={selectedService}
        >
          <option value="All">All</option>
          <option value="Ultrasounds">Ultrasounds</option>
          <option value="DNA">DNA</option>
          <option value="Massages">Massages</option>
        </select>
      </div>
      <table className="table mt-5">
        <colgroup>
          <col style={{ width: "70%" }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">
              <h5 className="lb-not-bold">Available Services</h5>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.uniqueId}>
              <td>{item.name}</td>
              <td>
                <button
                  className="lb-button-faq"
                  onClick={() =>
                    postVisible({
                      uniqueId: item.uniqueId,
                      visible: !item.visible,
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={item.visible ? faEye : faEyeSlash}
                    style={{ fontSize: 20 }}
                  />
                </button>
              </td>
              <td>
                <button
                  className="lb-button-faq"
                  type="button"
                  onClick={modalOpenForService({ uniqueId: item.uniqueId })}
                >
                  <FontAwesomeIcon icon={faPen} style={{ fontSize: 20 }} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-3 d-flex justify-content-end">
        <button
          className="lb-button-daily px-5"
          type="button"
          onClick={handleNewService}
        >
          Create
        </button>
      </div>

      <div hidden={!show}>
        <div
          className="modal-background"
          id="serviceModal"
          onClick={handleModalClose}
        >
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header mx-auto lb-divider-hide">
                <h5
                  className="modal-title lb-title-daily"
                  id="serviceModalLabel"
                >
                  Edit Services content
                </h5>
              </div>
              <div className="modal-body">
                <div className="mx-auto mb-3">
                  <label htmlFor="service-category-select d-block">
                    Please select a service category
                  </label>
                  <select
                    className="form-select d-block"
                    aria-label="service category select"
                    value={formData.service}
                    onChange={(e) =>
                      handleChange({
                        name: "service",
                        value: e.target.value,
                      })
                    }
                  >
                    <option value="Ultrasounds">Ultrasounds</option>
                    <option value="DNA">DNA</option>
                    <option value="Massages">Massages</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name service
                  </label>
                  <input
                    className="form-control"
                    id="name"
                    placeholder="Meet your baby"
                    value={formData.name}
                    onChange={(e) =>
                      handleChange({
                        name: "name",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="row">
                  <div className="mb-3 col">
                    <label htmlFor="weeks" className="form-label">
                      Weeks
                    </label>
                    <input
                      className="form-control"
                      id="weeks"
                      placeholder="15 to 17"
                      value={formData.weeks}
                      onChange={(e) =>
                        handleChange({
                          name: "weeks",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="mb-3 col">
                    <label htmlFor="price" className="form-label">
                      Price
                    </label>
                    <input
                      className="form-control"
                      id="price"
                      placeholder="$100"
                      value={formData.price}
                      onChange={(e) =>
                        handleChange({
                          name: "price",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="ml-1">
                    Icon{" "}
                    {formData.iconUrl && (
                      <a href={formData.iconUrl}>View Icon</a>
                    )}
                  </div>
                  <label
                    htmlFor="lbFile1"
                    className="lb-file-label d-flex justify-content-between"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        style={{ fontSize: 15, marginRight: 10 }}
                      />
                      {formData.icon?.name
                        ? formData.icon.name
                        : formData.iconUrl
                        ? "icon.png"
                        : "No file selected"}
                    </span>
                    <span className="lb-add-file">
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ fontSize: 10, marginRight: 10 }}
                      />
                      select file
                    </span>
                  </label>
                  <input
                    type="file"
                    id="lbFile1"
                    hidden
                    onChange={(e) =>
                      handleChange({
                        name: "icon",
                        value: e.target.files[0],
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <div className="ml-1">
                    Image 1{" "}
                    {formData.image1Url && (
                      <a href={formData.image1Url}>View Image1</a>
                    )}
                  </div>
                  <label
                    htmlFor="lbFile2"
                    className="lb-file-label d-flex justify-content-between"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        style={{ fontSize: 15, marginRight: 10 }}
                      />
                      {formData.image1?.name
                        ? formData.image1.name
                        : formData.image1Url
                        ? "image1.png"
                        : "No file selected"}
                    </span>
                    <span className="lb-add-file">
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ fontSize: 10, marginRight: 10 }}
                      />
                      select file
                    </span>
                  </label>
                  <input
                    type="file"
                    id="lbFile2"
                    hidden
                    onChange={(e) =>
                      handleChange({
                        name: "image1",
                        value: e.target.files[0],
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="image1Title" className="form-label">
                    Weeks Description Image 1
                  </label>
                  <input
                    className="form-control"
                    id="image1Title"
                    placeholder="6 - 8"
                    value={formData.image1Title}
                    onChange={(e) =>
                      handleChange({
                        name: "image1Title",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <div className="ml-1">
                    Image 2{" "}
                    {formData.image2Url && (
                      <a href={formData.image2Url}>View Image2</a>
                    )}
                  </div>
                  <label
                    htmlFor="lbFile3"
                    className="lb-file-label d-flex justify-content-between"
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faPaperclip}
                        style={{ fontSize: 15, marginRight: 10 }}
                      />
                      {formData.image2?.name
                        ? formData.image2.name
                        : formData.image2Url
                        ? "image2.png"
                        : "No file selected"}
                    </span>
                    <span className="lb-add-file">
                      <FontAwesomeIcon
                        icon={faUpload}
                        style={{ fontSize: 10, marginRight: 10 }}
                      />
                      select file
                    </span>
                  </label>
                  <input
                    type="file"
                    id="lbFile3"
                    hidden
                    onChange={(e) =>
                      handleChange({
                        name: "image2",
                        value: e.target.files[0],
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="image2Title" className="form-label">
                    Weeks Description Image 2
                  </label>
                  <input
                    className="form-control"
                    id="image2Title"
                    placeholder="10 - 12"
                    value={formData.image2Title}
                    onChange={(e) =>
                      handleChange({
                        name: "image2Title",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="abstract" className="form-label">
                    Abstract
                  </label>
                  <textarea
                    className="form-control"
                    id="abstract"
                    rows="3"
                    placeholder="Add abstract..."
                    value={formData.abstract}
                    onChange={(e) =>
                      handleChange({
                        name: "abstract",
                        value: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div className="mb-3">Type of mobile service: </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    id="type1"
                    value="type1"
                    checked={formData.type === "type1"}
                    onChange={(e) =>
                      handleChange({
                        name: "type",
                        value: e.target.value,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="type1">
                    General service
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    id="type2"
                    value="type2"
                    checked={formData.type === "type2"}
                    onChange={(e) =>
                      handleChange({
                        name: "type",
                        value: e.target.value,
                      })
                    }
                  />
                  <label className="form-check-label" htmlFor="type2">
                    Best seller service
                  </label>
                </div>
                {formData.type === "type1" && (
                  <>
                    <div className="mb-3">
                      <div className="ml-1">
                        Image 3{" "}
                        {formData.image3Url && (
                          <a href={formData.image3Url}>View Image3</a>
                        )}
                      </div>
                      <label
                        htmlFor="lbFile4"
                        className="lb-file-label d-flex justify-content-between"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faPaperclip}
                            style={{ fontSize: 15, marginRight: 10 }}
                          />
                          {formData.image3?.name
                            ? formData.image3.name
                            : formData.image3Url
                            ? "image3.png"
                            : "No file selected"}
                        </span>
                        <span className="lb-add-file">
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ fontSize: 10, marginRight: 10 }}
                          />
                          select file
                        </span>
                      </label>
                      <input
                        type="file"
                        id="lbFile4"
                        hidden
                        onChange={(e) =>
                          handleChange({
                            name: "image3",
                            value: e.target.files[0],
                          })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <div className="ml-1">
                        Image 4{" "}
                        {formData.image4Url && (
                          <a href={formData.image4Url}>View Image4</a>
                        )}
                      </div>
                      <label
                        htmlFor="lbFile5"
                        className="lb-file-label d-flex justify-content-between"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faPaperclip}
                            style={{ fontSize: 15, marginRight: 10 }}
                          />
                          {formData.image4?.name
                            ? formData.image4.name
                            : formData.image4Url
                            ? "image4.png"
                            : "No file selected"}
                        </span>
                        <span className="lb-add-file">
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ fontSize: 10, marginRight: 10 }}
                          />
                          select file
                        </span>
                      </label>
                      <input
                        type="file"
                        id="lbFile5"
                        hidden
                        onChange={(e) =>
                          handleChange({
                            name: "image4",
                            value: e.target.files[0],
                          })
                        }
                      />
                    </div>
                  </>
                )}
                {formData.type === "type2" && (
                  <>
                    <div className="row">
                      <div className="mb-3 col">
                        <label htmlFor="time1" className="form-label">
                          Service time 1
                        </label>
                        <input
                          className="form-control"
                          id="time1"
                          placeholder="15 Min"
                          value={formData.time1}
                          onChange={(e) =>
                            handleChange({
                              name: "time1",
                              value: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="mb-3 col">
                        <label htmlFor="price1" className="form-label">
                          Price for time 1
                        </label>
                        <input
                          className="form-control"
                          id="price1"
                          placeholder="109"
                          value={formData.price1}
                          onChange={(e) =>
                            handleChange({
                              name: "price1",
                              value: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col">
                        <label htmlFor="time2" className="form-label">
                          Service time 2
                        </label>
                        <input
                          className="form-control"
                          id="time2"
                          placeholder="25 Min"
                          value={formData.time2}
                          onChange={(e) =>
                            handleChange({
                              name: "time2",
                              value: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="mb-3 col">
                        <label htmlFor="price2" className="form-label">
                          Price for time 2
                        </label>
                        <input
                          className="form-control"
                          id="price2"
                          placeholder="149"
                          value={formData.price2}
                          onChange={(e) =>
                            handleChange({
                              name: "price2",
                              value: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="price3" className="form-label">
                        Price before promotion
                      </label>
                      <input
                        className="form-control"
                        id="price3"
                        placeholder="169"
                        value={formData.price3}
                        onChange={(e) =>
                          handleChange({
                            name: "price3",
                            value: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <div className="ml-1">
                        Image 3{" "}
                        {formData.image3Url && (
                          <a href={formData.image3Url}>View Image3</a>
                        )}
                      </div>
                      <label
                        htmlFor="lbFile4"
                        className="lb-file-label d-flex justify-content-between"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faPaperclip}
                            style={{ fontSize: 15, marginRight: 10 }}
                          />
                          {formData.image3?.name
                            ? formData.image3.name
                            : formData.image3Url
                            ? "image3.png"
                            : "No file selected"}
                        </span>
                        <span className="lb-add-file">
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ fontSize: 10, marginRight: 10 }}
                          />
                          select file
                        </span>
                      </label>
                      <input
                        type="file"
                        id="lbFile4"
                        hidden
                        onChange={(e) =>
                          handleChange({
                            name: "image3",
                            value: e.target.files[0],
                          })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <div className="ml-1">
                        Image 4{" "}
                        {formData.image4Url && (
                          <a href={formData.image4Url}>View Image4</a>
                        )}
                      </div>
                      <label
                        htmlFor="lbFile5"
                        className="lb-file-label d-flex justify-content-between"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faPaperclip}
                            style={{ fontSize: 15, marginRight: 10 }}
                          />
                          {formData.image4?.name
                            ? formData.image4.name
                            : formData.image4Url
                            ? "image4.png"
                            : "No file selected"}
                        </span>
                        <span className="lb-add-file">
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ fontSize: 10, marginRight: 10 }}
                          />
                          select file
                        </span>
                      </label>
                      <input
                        type="file"
                        id="lbFile5"
                        hidden
                        onChange={(e) =>
                          handleChange({
                            name: "image4",
                            value: e.target.files[0],
                          })
                        }
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="modal-footer d-flex justify-content-between lb-divider-hide">
                <button
                  type="button"
                  className="btn btn-outline-secondary lb-w-btn"
                  data-bs-dismiss="modal"
                  onClick={closeModal}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="lb-button-daily lb-w-btn py-2"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ContentManagerServices.propTypes = {
  appState: PropTypes.object.isRequired,
};

export default ContentManagerServices;
