import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSpinner,
  faEraser,
} from "@fortawesome/free-solid-svg-icons";
import "../styles/bootstrap-grid.min.css";
import "../styles/global.css";
import "../App.css";
import { PropTypes } from "prop-types";
import moment from "moment";
import VisitsTable from "../components/visitsTable";

function VisitsPage({ appState }) {
  const [clientVisits, setClientVisits] = useState({
    status: "IDLE",
    requesting: "IDLE",
    message: "",
    inputValue: "",
    visits: [],
    client: {},
    startDate: new Date(),
  });

  const handleSearchClientInput = (event) => {
    setClientVisits((clientVisits) => ({
      ...clientVisits,
      inputValue: event.target.value,
    }));
  };

  const searchClientVisits = async () => {
    if (clientVisits.requesting === "REQUESTING") {
      return;
    }
    try {
      setClientVisits((clientVisits) => ({
        ...clientVisits,
        requesting: "REQUESTING",
        status: "IDLE",
        message: "",
        visits: [],
        client: {},
      }));

      const selectedOption = appState.bookingAuth.selectedSite;

      const siteId = selectedOption.value.split("|")[0];
      const locationId = selectedOption.value.split("|")[1];

      const authObject = appState.bookingAuth.authorizationTokens.find(
        (item) =>
          item.siteId.toString() === siteId && item.locationId === locationId
      );

      const authorization = authObject.token;

      const searchClientsRequest = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: authorization,
          siteid: siteId,
        },
      };
      const queryStartDate = moment(clientVisits.startDate)
        .subtract(6, "months")
        .format("MM/DD/YYYY")
        .toString();
      const queryEndDate = moment(clientVisits.startDate)
        .add(6, "months")
        .format("MM/DD/YYYY")
        .toString();
      const searchClientsVisitsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/clients/${clientVisits.inputValue}/visits?startDate=${queryStartDate}&endDate=${queryEndDate}`,
        searchClientsRequest
      );

      // only added a comment
      const searchClientsData = await searchClientsVisitsResponse.json();
      console.log(searchClientsData);
      if (searchClientsVisitsResponse.ok) {
        setClientVisits((clientVisits) => ({
          ...clientVisits,
          status: "SHOW",
          requesting: "IDLE",
          visits: searchClientsData.visits,
          client: searchClientsData.clients[0],
        }));
      } else {
        setClientVisits((clientVisits) => ({
          ...clientVisits,
          status: "VISITS-NO-FOUND",
          requesting: "IDLE",
          message:
            "No visits found for this client: " +
            JSON.stringify(searchClientsData),
          visits: [],
        }));
      }
    } catch (error) {
      setClientVisits((clientVisits) => ({
        ...clientVisits,
        status: "VISITS-ERROR",
        requesting: "IDLE",
        message: "Visits search error: " + JSON.stringify(error),
      }));
    }
  };

  const clearClientVisits = () => {
    setClientVisits((clientVisits) => ({
      ...clientVisits,
      status: "IDLE",
      requesting: "IDLE",
      message: "",
      visits: [],
      client: {},
    }));
  };

  return (
    <div className="container pb-5">
      <div className="row mt-2 mb-4">
        <div className="col">
          <h1 className="display-6 fw-normal">Clients appointment search</h1>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-6">
          <input
            data-cy="search-bar"
            type="text"
            className="lb-form-control lb-border-0 lb-inputBackground"
            placeholder="Search"
            onChange={handleSearchClientInput}
          />
        </div>
        <div className="col-auto">
          <div className="lb-form-group">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={searchClientVisits}
            >
              {clientVisits.requesting === "REQUESTING" && (
                <>
                  <FontAwesomeIcon spin icon={faSpinner} />
                  <span className="ml-1">Loading</span>
                </>
              )}
              {clientVisits.requesting !== "REQUESTING" && (
                <>
                  <FontAwesomeIcon icon={faSearch} />
                  <span className="ml-1">Search</span>
                </>
              )}
            </button>
            {(clientVisits.status === "SHOW" ||
              clientVisits.status === "VISITS-NO-FOUND" ||
              clientVisits.status === "VISITS-ERROR") && (
              <button
                className="btn btn-outline-secondary ml-2"
                type="button"
                onClick={clearClientVisits}
              >
                <FontAwesomeIcon icon={faEraser} />
                <span className="ml-1">Clear</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        {clientVisits.status === "VISITS-NO-FOUND" && (
          <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-warning">
            <span>{clientVisits.message}</span>
          </div>
        )}
        {clientVisits.status === "VISITS-ERROR" && (
          <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-error">
            <span>{clientVisits.message}</span>
          </div>
        )}
      </div>

      {clientVisits.status === "SHOW" && (
        <div className="row">
          <div className="col">
            <VisitsTable
              visits={clientVisits.visits}
              client={clientVisits.client}
            />
          </div>
        </div>
      )}
    </div>
  );
}
VisitsPage.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default VisitsPage;
