import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import socketIOClient from "socket.io-client";

import NavBar from "./components/navbar";
import ErrorBoundary from "./ErrorBoundary";
import {
  Home,
  FormPage,
  AppointmentsListPage,
  VideoRemakePage,
  CheckoutPage,
  ImgUpload,
  NotePage,
  LoginPage,
  LogsDashboardPage,
  FaqPage,
  DailyReport,
  BookingPage,
  BookingLoginPage,
  ApproveDailyReport,
  ConsultTechnicians,
  OperationalDashboard,
  OperationalDetails,
  MenuDashboard,
  DirectorsLogin,
  ConfigSetup,
  DailyCloseout,
  IncompleteAppointments,
  EditReviewLinksPage,
  VisitsPage,
  ManagementDashboard,
  ContentManagerReviews,
  ContentManagerServices,
} from "./pages/index.js";

import "./App.css";
import ProjectionReport from "./pages/projectionReport";

function App() {
  const [appState, setAppState] = useState({
    authType: "",
    nav: {
      status: "loading",
      message: "",
      siteId: null,
      locationId: null,
      siteName: "",
      redirect: false,
    },
    singleAuth: {
      token: null,
      userId: null,
      userName: null,
      role: null,
      allowedPermissions: null,
    },
    bookingAuth: {
      status: "",
      message: "",
      authorizationTokens: [],
      sitesSelect: [],
      selectedSite: {},
      prevSelectedSite: {},
    },
    directorsAuth: {
      status: "",
      message: "",
      authorizationTokens: [],
      locationsAuth: [],
      siteId: "",
      locationId: "",
      siteName: "",
      token: "",
    },
    managementMode: false,
  });

  if (appState.authType === "single") {
    return (
      <ErrorBoundary>
        <Router>
          <NavBar appState={appState} setAppState={setAppState} />
          <Switch>
            <Route path="/config">
              <ConfigSetup appState={appState} />
            </Route>
            <Route path="/dailycloseout">
              <DailyCloseout appState={appState} setAppState={setAppState} />
            </Route>
            <Route path="/remakevideo">
              <VideoRemakePage appState={appState} />
            </Route>
            <Route path="/loginmenu">
              <MenuDashboard />
            </Route>
            <Route path="/remakevideo">
              <VideoRemakePage appState={appState} />
            </Route>
            <Route path="/appointments/:siteId/:appointmentId?">
              <FormPage />
            </Route>
            <Route path="/checkout/:appointmentid">
              <CheckoutPage appState={appState} />
            </Route>
            <Route path="/appointments">
              <AppointmentsListPage appState={appState} displayForms={false} />
            </Route>
            <Route path="/forms">
              <AppointmentsListPage appState={appState} displayForms={true} />
            </Route>
            <Route path="/imgupload/:appointmentid">
              <ImgUpload appState={appState} />
            </Route>
            <Route path="/notepage/:id">
              <NotePage appState={appState} />
            </Route>
            <Route path="/faq">
              <FaqPage appState={appState} />
            </Route>
            <Route path="/login/:siteId?">
              <LoginPage appState={appState} setAppState={setAppState} />
            </Route>
            <Route path="/dailyreport/:closeDate?">
              <DailyReport appState={appState} />
            </Route>
            <Route path="/reviewdailyreport">
              <ApproveDailyReport
                appState={appState}
                setAppState={setAppState}
              />
            </Route>
            <Route path="/snapshots">
              <ConsultTechnicians appState={appState} />
            </Route>
            <Route path="/:siteId?">
              <Home appState={appState} />
            </Route>
          </Switch>
        </Router>
      </ErrorBoundary>
    );
  } else {
    if (appState.authType === "director") {
      return (
        <ErrorBoundary>
          <Router>
            <NavBar appState={appState} setAppState={setAppState} />
            <Switch>
              <Route path="/managementDashboard">
                <ManagementDashboard />
              </Route>
              <Route path="/managementlogin">
                <DirectorsLogin appState={appState} setAppState={setAppState} />
              </Route>
              <Route path="/directorslogin">
                <DirectorsLogin appState={appState} setAppState={setAppState} />
              </Route>
              <Route path="/appointments/:siteId/:appointmentId?">
                <FormPage />
              </Route>
              <Route path="/dailycloseout">
                <DailyCloseout appState={appState} setAppState={setAppState} />
              </Route>
              <Route path="/incompleteappointments">
                <IncompleteAppointments
                  appState={appState}
                  setAppState={setAppState}
                />
              </Route>
              <Route path="/operationaldashboard">
                <OperationalDashboard appState={appState} />
              </Route>
              <Route path="/operationaldetails">
                <OperationalDetails />
              </Route>
              <Route path="/config">
                <ConfigSetup appState={appState} />
              </Route>
              <Route path="/logs">
                <LogsDashboardPage />
              </Route>
              <Route path="/appointments">
                <AppointmentsListPage
                  appState={appState}
                  displayForms={false}
                />
              </Route>
              <Route path="/forms">
                <AppointmentsListPage appState={appState} displayForms={true} />
              </Route>
              <Route path="/remakevideo">
                <VideoRemakePage appState={appState} />
              </Route>
              <Route path="/checkout/:appointmentid">
                <CheckoutPage appState={appState} />
              </Route>
              <Route path="/forms">
                <AppointmentsListPage appState={appState} displayForms={true} />
              </Route>
              <Route path="/imgupload/:appointmentid">
                <ImgUpload appState={appState} />
              </Route>
              <Route path="/notepage/:id">
                <NotePage appState={appState} />
              </Route>
              <Route path="/dailyreport/:closeDate?">
                <DailyReport appState={appState} />
              </Route>
              <Route path="/projectionreport">
                <ProjectionReport appState={appState} />
              </Route>
              <Route path="/reviewdailyreport">
                <ApproveDailyReport
                  appState={appState}
                  setAppState={setAppState}
                />
              </Route>
              <Route path="/snapshots">
                <ConsultTechnicians appState={appState} />
              </Route>
              <Route path="/editreviewlinks">
                <EditReviewLinksPage appState={appState} />
              </Route>
              <Route path="/contentmanagereviews">
                <ContentManagerReviews appState={appState} />
              </Route>
              <Route path="/contentmanagerservices">
                <ContentManagerServices appState={appState} />
              </Route>
            </Switch>
          </Router>
        </ErrorBoundary>
      );
    } else {
      if (appState.authType === "booking") {
        return (
          <ErrorBoundary>
            <Router>
              <NavBar appState={appState} setAppState={setAppState} />
              <Switch>
                <Route path="/bookinglogin">
                  <BookingLoginPage
                    appState={appState}
                    setAppState={setAppState}
                  />
                </Route>
                <Route path="/booking">
                  <BookingPage appState={appState} setAppState={setAppState} />
                </Route>
                <Route path="/visits">
                  <VisitsPage appState={appState} />
                </Route>
                <Route path="/faq">
                  <FaqPage appState={appState} />
                </Route>
              </Switch>
            </Router>
          </ErrorBoundary>
        );
      } else {
        return (
          <ErrorBoundary>
            <Router>
              <Switch>
                <Route path="/appointments/:siteId/:appointmentId?">
                  <FormPage />
                </Route>
                <Route path="/loginmenu">
                  <MenuDashboard />
                </Route>
                <Route path="/managementlogin">
                  <DirectorsLogin
                    appState={appState}
                    setAppState={setAppState}
                  />
                </Route>
                <Route path="/directorslogin">
                  <DirectorsLogin
                    appState={appState}
                    setAppState={setAppState}
                  />
                </Route>
                <Redirect from="/remakevideo" to="/loginmenu" />
                <Redirect from="/checkout/:appointmentid" to="/loginmenu" />
                <Redirect from="/appointments" to="/login" />
                <Redirect from="/imgupload/:appointmentid" to="/loginmenu" />
                <Redirect from="/notepage/:id" to="/loginmenu" />
                <Redirect from="/dailyreport/:siteId" to="/loginmenu" />
                <Redirect from="/reviewdailyreport" to="/loginmenu" />
                <Redirect from="/snapshots" to="/loginmenu" />
                <Redirect from="/operationaldashboard" to="/loginmenu" />
                <Redirect from="/operationaldetails" to="/loginmenu" />
                <Redirect from="/faq" to="/loginmenu" />
                <Redirect path="/config" to="/loginmenu" />
                <Redirect from="/logs" to="/loginmenu" />
                <Redirect from="/dailycloseout" to="/loginmenu" />
                <Redirect from="/contentmanagereviews" to="/loginmenu" />
                <Redirect from="/contentmanagerservices" to="/loginmenu" />

                <Route path="/bookinglogin">
                  <BookingLoginPage
                    appState={appState}
                    setAppState={setAppState}
                  />
                </Route>
                <Redirect from="/booking" to="/bookinglogin" />
                <Route path="/login/:siteId?">
                  <LoginPage appState={appState} setAppState={setAppState} />
                </Route>
                <Route path="/:siteId?">
                  <Home appState={appState} />
                </Route>
              </Switch>
            </Router>
          </ErrorBoundary>
        );
      }
    }
  }
}

export default App;
