import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import logo from "../logo.png";
import { PropTypes } from "prop-types";
import { sitesList } from "../config/constants.js";
import * as crypto from "crypto-js";
import { transformSites } from "../util";

function LoginPage({ appState, setAppState }) {
	const [localAuthState, setLocalAuthState] = useState({
		status: "facilityStep",
		username: "",
		password: "",
		siteId: "",
		siteName: "",
	});
	const [search, setSearch] = useState({
		searchTerm: "",
		locationTerm: "",
		facilities: [],
	});
	const [locations, setLocations] = useState([]);
	const [submitStatus, setSubmitStatus] = useState({
		status: "IDLE",
		message: "",
	});
	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		console.log("14-aug-2023 V-0.6.6 deploy 1");
		const results = sitesList;

		const paramSite = results.find((item) => item.siteId === params.siteId);
		if (paramSite !== undefined) {
			setLocalAuthState((localAuthState) => ({
				...localAuthState,
				status: "loginStep",
				siteId: "" + paramSite.siteId,
				siteName: "" + paramSite.name,
			}));
			setAppState((appState) => ({
				...appState,
				nav: {
					...appState.nav,
					siteId: "" + paramSite.siteId,
					siteName: paramSite.name,
				},
			}));
		} else {
			history.replace(`/login/`);
		}
	}, [appState.nav.siteId, history, params.siteId, setAppState]);

	useEffect(() => {
		if (appState.nav.status === "logedOut") {
			setAppState((appState) => ({
				...appState,
				nav: {
					...appState.nav,
					status: "",
					message: "",
					siteName: "",
				},
			}));
		}
		const results = sitesList.filter((facility) =>
			facility.name.toLowerCase().includes(search.searchTerm.toLowerCase())
		);
		setSearch((search) => ({
			...search,
			facilities: results,
		}));
	}, [search.searchTerm, appState.nav, setAppState]);

	const handleTermChange = (event) => {
		setSearch((search) => ({
			...search,
			searchTerm: event.target.value,
		}));
	};

	const handleClick = (siteId, siteName) => (event) => {
		event.preventDefault();
		setLocalAuthState((localAuthState) => ({
			...localAuthState,
			status: "loginStep",
			siteId: siteId,
			siteName: siteName,
		}));
		setAppState((appState) => ({
			...appState,
			nav: {
				...appState.nav,
				siteId: "" + siteId,
				siteName: siteName,
			},
		}));
		history.replace(`/login/${siteId}`);
	};

	const handleBack = (event) => {
		event.preventDefault();
		setLocalAuthState((localAuthState) => ({
			...localAuthState,
			status: "facilityStep",
			username: "",
			password: "",
		}));
		setAppState((appState) => ({
			...appState,
			nav: {
				...appState.nav,
				siteId: null,
			},
		}));
		setSubmitStatus((submitStatus) => ({
			...submitStatus,
			status: "IDLE",
			message: "",
		}));
		history.replace("/login");
	};
	const handleLocationClick = (locId) => (event) => {
		event.preventDefault();
		setAppState((appState) => ({
			...appState,
			nav: {
				...appState.nav,
				locationId: "" + locId,
			},
		}));
		setLocalAuthState((localAuthState) => ({
			...localAuthState,
			status: "logedIn",
		}));
		setSubmitStatus((submitStatus) => ({
			...submitStatus,
			status: "success",
			message: "Success. You will be redirected soon.",
		}));
		setAppState((appState) => ({
			...appState,
			authType: "single",
			nav: {
				...appState.nav,
				redirect: true,
			},
		}));
	};

	const handleLoginInput = (attribute) => (event) => {
		if (attribute === "username") {
			setLocalAuthState((localAuthState) => ({
				...localAuthState,
				username: event.target.value,
			}));
		} else {
			setLocalAuthState((localAuthState) => ({
				...localAuthState,
				password: event.target.value,
			}));
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (setSubmitStatus.status === "sending") {
			return;
		}

		try {
			setSubmitStatus((submitStatus) => ({
				...submitStatus,
				status: "sending",
			}));
			const payload = {
				Username: localAuthState.username,
				Password: localAuthState.password,
			};
			const key = process.env.REACT_APP_ENCRYPTION_KEY;
			const encrypted = crypto.AES.encrypt(JSON.stringify(payload), key).toString();
			const body = { data: encrypted };

			const putMethod = {
				method: "PUT",
				headers: {
					"Content-type": "application/json; charset=UTF-8",
					siteid: appState.nav.siteId,
				},
				body: JSON.stringify(body),
			};
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/userToken/`, putMethod);
			const data = await response.json();
			if (response.ok) {
				setAppState((appState) => ({
					...appState,
					singleAuth: {
						...appState.singleAuth,
						token: data.accesssToken,
						userId: data.userId,
						userName: data.userName,
						role: data.userRol,
					},
				}));

				const getPermissionsMethod = {
					method: "GET",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						siteid: appState.nav.siteId,
						authorization: data.accesssToken,
					},
				};
				const getPermissionsResponse = await fetch(
					`${process.env.REACT_APP_API_URL}/api/user/permissions/${data.userId}`,
					getPermissionsMethod
				);
				const permissionsData = await getPermissionsResponse.json();
				if (getPermissionsResponse.ok) {
					setAppState((appState) => ({
						...appState,
						singleAuth: {
							...appState.singleAuth,
							allowedPermissions: permissionsData.allowedPermissions,
						},
					}));
					const getMethod = {
						method: "GET",
						headers: {
							"Content-type": "application/json; charset=UTF-8",
							siteid: appState.nav.siteId,
							authorization: data.accesssToken,
						},
					};
					const getResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/config/sites`, getMethod);
					const siteResponse = await getResponse.json();
					const locationData = transformSites({
						sites: siteResponse.sites,
						filterSiteId: appState.nav.siteId,
					});
					if (getResponse.ok) {
						if (locationData.length > 1) {
							setLocations(locationData);
							setLocalAuthState((localAuthState) => ({
								...localAuthState,
								status: "locationstep",
							}));
						} else {
							setAppState((appState) => ({
								...appState,
								nav: {
									...appState.nav,
									locationId: "" + locationData[0].id,
								},
							}));
							setLocalAuthState((localAuthState) => ({
								...localAuthState,
								status: "logedIn",
							}));
							setAppState((appState) => ({
								...appState,
								authType: "single",
								nav: {
									...appState.nav,
									redirect: true,
								},
							}));
						}
						setSubmitStatus((submitStatus) => ({
							...submitStatus,
							status: "success",
							message: "Success. You will be redirected soon.",
						}));
					} else {
						setSubmitStatus((submitStatus) => ({
							...submitStatus,
							status: "fail",
							message:
								locationData.Error.Message !== undefined
									? locationData.Error.Message
									: locationData.message !== undefined
									? locationData.message
									: JSON.stringify(locationData),
						}));
					}
				} else {
					setSubmitStatus((submitStatus) => ({
						...submitStatus,
						status: "fail",
						message:
							permissionsData.Error.Message !== undefined
								? permissionsData.Error.Message
								: permissionsData.message !== undefined
								? permissionsData.message
								: JSON.stringify(permissionsData),
					}));
				}
			} else {
				setSubmitStatus((submitStatus) => ({
					...submitStatus,
					status: "fail",
					message:
						data.Error.Message !== undefined
							? data.Error.Message
							: data.message !== undefined
							? data.message
							: JSON.stringify(data),
				}));
			}
		} catch (error) {
			console.error(error);
			setSubmitStatus((submitStatus) => ({
				...submitStatus,
				status: "error",
				message:
					"Woops, this is weird. Something went wrong with the connection, please reload the page and try again." +
					JSON.stringify(error.message),
			}));
		}
	};

	return (
		<div className="content">
			<div>
				<div className="container mt-3">
					<div className="row ">
						<div className="col-4 col-md-3 col-lg-2 mx-auto">
							<nav className="lb-navbar">
								<a className="lb-navbar-brand" href="/">
									<img src={logo} width="100%" alt="Little Bellies Logo"></img>
								</a>
							</nav>
						</div>
					</div>
				</div>
				<div>
					{localAuthState.status === "facilityStep" && (
						<div className="container">
							<div className="row mt-4 ">
								<div className="col">
									<h4 className="mx-5 lb-font-bold">Select the facility:</h4>
								</div>
							</div>
							<div className="row px-5">
								<div className="col lb-text-center">
									<div className="lb-form-group">
										<input
											data-cy="search-bar"
											type="text"
											className="lb-form-control lb-border-0 lb-inputBackground"
											placeholder="Search"
											onChange={handleTermChange}
											value={search.searchTerm}
										/>
									</div>
								</div>
							</div>
							<hr className="mb-3"></hr>
							{search.facilities.map((facility) => {
								return (
									<div key={facility.siteId}>
										<div className="row px-2 px-md-5">
											<div className="col col-md-2 d-none d-md-block lb-text-center my-auto">
												<img src={logo} width="55%" alt="Little Bellies Logo"></img>
											</div>
											<div className="col-7 col-md-8 my-auto">
												<h5 className="my-0 lb-font-bold">{facility.name}</h5>
											</div>
											<div className="col-4 col-md-2 lb-text-center my-auto">
												<button
													className="lb-btn lb-submitButton lb-rounded-0 py-2 px-3"
													onClick={handleClick(facility.siteId, facility.name)}
													data-cy={"select-" + facility.siteId}
												>
													SELECT
												</button>
											</div>
										</div>
										<hr className="mb-3"></hr>
									</div>
								);
							})}
						</div>
					)}
					{localAuthState.status === "loginStep" && (
						<div className="container">
							<div className="row mt-4 px-5">
								<div className="col-6 my-auto">
									<h4 className="mx-5 lb-font-bold">Login</h4>
								</div>
								<div className="col-6 my-auto lb-text-right">
									<button className="lb-btn lb-submitButton lb-rounded-0 py-2 mx-5 px-3" onClick={handleBack}>
										BACK
									</button>
								</div>
							</div>
							<div className="row" data-cy={"site-id-" + localAuthState.siteId}>
								<div className="col-6 mx-auto lb-text-center">
									<input
										data-cy="username"
										type="text"
										className="lb-form-control lb-border-0 my-3 lb-inputBackground"
										placeholder="Username"
										onChange={handleLoginInput("username")}
										value={localAuthState.username}
									/>
									<input
										data-cy="password"
										type="password"
										className="lb-form-control lb-border-0 my-3 lb-inputBackground"
										placeholder="Password"
										onChange={handleLoginInput("password")}
										value={localAuthState.password}
									/>

									{submitStatus.status === "success" && (
										<div>
											<div className="mt-1 mx-auto mb-3 lb-form-submited-success">
												<span>{submitStatus.message}</span>
											</div>
										</div>
									)}

									{(submitStatus.status === "fail" || submitStatus.status === "error") && (
										<div className="mt-1 mx-auto mb-3 lb-form-submited-error">
											<span>{submitStatus.message}</span>
										</div>
									)}

									<button
										className="lb-btn lb-submitButton lb-rounded-0 py-2 px-3"
										onClick={handleSubmit}
										data-cy="loginButton"
									>
										{submitStatus.status === "sending" ? (
											<span className="text-lb-numberButton mx-4 my-2">
												<FontAwesomeIcon spin icon={faSpinner} />
											</span>
										) : (
											<span className="text-lb-numberButton mx-4 my-2">LOGIN</span>
										)}
									</button>
								</div>
							</div>
						</div>
					)}

					{localAuthState.status === "locationstep" && (
						<div className="container">
							<div className="row mt-4 ">
								<div className="col">
									<h4 className="mx-5 lb-font-bold">Select the location</h4>
								</div>
							</div>
							<hr className="mb-3"></hr>
							{locations.map((location) => {
								return (
									<div key={location.id}>
										<div className="row px-2 px-md-5">
											<div className="col col-md-2 d-none d-md-block lb-text-center my-auto">
												<img src={logo} width="55%" alt="Little Bellies Logo"></img>
											</div>
											<div className="col-7 col-md-8 my-auto">
												<h5 className="my-0 lb-font-bold">{location.name}</h5>
											</div>
											<div className="col-4 col-md-2 lb-text-center my-auto">
												<button
													className="lb-btn lb-submitButton lb-rounded-0 py-2 px-3"
													onClick={handleLocationClick(location.id)}
													data-cy={"select-location-" + location.id}
												>
													SELECT
												</button>
											</div>
										</div>
										<hr className="mb-3"></hr>
									</div>
								);
							})}
						</div>
					)}
					{appState.nav.redirect === true && (
						<>
							<Redirect push to="/appointments" />
						</>
					)}
				</div>
			</div>
		</div>
	);
}

LoginPage.propTypes = {
	appState: PropTypes.object,
	setAppState: PropTypes.func,
};
export default LoginPage;
