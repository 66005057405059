import { PropTypes } from "prop-types";
import React, { useEffect, useState, Suspense } from "react";
import { faEye, faCheck, faDownload, faSpinner, faRedoAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink } from '@react-pdf/renderer';
import DynamicReportPdf from "./pdfs/dynamicReportPDF";
import moment from "moment";
import { formatAmount, parseFloatLocale } from "../util/formatNumbers";


function DynamicTable({ headers, rows, tfoot, fileNamePdf,setReloadData, reloadData,titlePdf,subTitlePdf}) {
  const [dir, setDir] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [selectRows, setSelectRows] = useState(false);
  const [bodyTable, setBodyTable] = useState(rows);
  const [footTable, setFootTable] = useState(tfoot);
  const [buttonSelectRows, setButtonSelectRows] = useState({ disabled: true, action: false });
  const [btnPdf, setBtnPdf] = useState(false);
  const reportDate = moment(new Date).format("MM/DD/YY hh:mm:ss A");

  const handleSort = (column, direction) => {
    setIndex(column);
    setDir(direction);

    bodyTable.sort((a, b) => {
      const valA = Object.values(a);
      const valB = Object.values(b);
      if(dir){ 
        if (valA[column] < valB[column]) return 1;
        if (valA[index] > valB[index]) return -1;
        return 0;
      }else{
        if (valA[column] > valB[column]) return 1;
        if (valA[column] < valB[column]) return -1;
        return 0;
      }
  });
  };

  const handleSubTotals = (listData) => {

    const listTotals = [];
    const tfootShowTotals = [];

    listData.forEach((e, i) => {
      Object.values(e).forEach((value, d) => {
        if (typeof value !== "boolean") listTotals.push({ id: d, value: parseFloatLocale(value) });
      });
    });

    listTotals.forEach((a) => {
      if (tfoot)
        tfoot?.forEach((e, i) => {
          if (a.id === i) {
            tfootShowTotals.push({ id: i, show: e.show, amount: e.amount, value: a.value});
          }
        });
    });

    const subTotals = tfootShowTotals.reduce((accumulator, i) => {
      const findById = accumulator.find(e => e.id === i.id);
      if (findById) {
        return accumulator.map((e) => {
          if (e.id === i.id) {
            return {
              ...e,
              value: e.value + i.value
            }
          }
          return e;
        });
      }
      return [...accumulator, i];
    }, []);

    setFootTable(subTotals);
  }

  useEffect(() => {
    const newRows = [];
    rows.forEach((e, i) => {
      newRows.push(e);
      newRows[i].checked = false;
    });
    handleSubTotals(newRows);
    setBodyTable(newRows);
  }, [rows,reloadData.action]);

  const handleSelectRow = (index) => {
    const newBody = [...bodyTable];
    newBody[index].checked = !newBody[index].checked;
    setBodyTable(newBody)
    const filterBody = newBody.filter((i) => { return i.checked === true })
    if (filterBody.length !== 0)
      setButtonSelectRows((buttonSelectRows) => ({
        ...buttonSelectRows,
        disabled: false
      }));
    else
      setButtonSelectRows((buttonSelectRows) => ({
        ...buttonSelectRows,
        disabled: true
      }));
  }

  const handleSelectRows = () => {
    const selectedSitesRows = bodyTable.filter((i) => { return i.checked === true });
    if (selectedSitesRows.length !== 0) {
      setBodyTable(selectedSitesRows)
      setButtonSelectRows((buttonSelectRows) => ({
        ...buttonSelectRows,
        action: true,
      }));
      handleSubTotals(selectedSitesRows);
    }
  }

  const handleViewAllRows = () => {
    const newRows = [];
    rows.forEach((e, i) => {
      newRows.push(e);
      newRows[i].checked = false;
    });

    handleSubTotals(newRows);
    setBodyTable(newRows);
    setButtonSelectRows((buttonSelectRows) => ({
      ...buttonSelectRows,
      action: false,
      disabled: true
    }));
  }

  useEffect(() => {
    if (buttonSelectRows.action) {
      const filterBody = bodyTable.filter((i) => { return i.checked === true })
      handleSubTotals(filterBody);
      setBodyTable(filterBody)
    }
  }, [buttonSelectRows.action])

  useEffect(() => {
    const clickedButtonTrigger = document.getElementById('btn-create-pdf');
    if (clickedButtonTrigger) {
      setTimeout(() => {
        clickedButtonTrigger.click();
        clickedButtonTrigger.addEventListener('mousedown', () => { });
      }, 500);
    }
  }, [btnPdf]);

  const handleReloadData = () => {
    setReloadData((reloadData) => ({
      ...reloadData,
      action: true
    }
  ));
}

  return (
    <div >
        <>
          <div className="d-flex">
            <div className="col-9">
            <>
            {!btnPdf ?
              <button data-cy="btn-create-pdf-projection-report"
                onClick={(e) => setBtnPdf(true)}
                disabled={reloadData.action || rows.length === 0}
                className="btn pt-2 pb-2 border text rounded-pill m-2 btn-sm"
                style={{ color: "white", width: 65, backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff" }}
              ><FontAwesomeIcon icon={faDownload} style={{ fontSize: 20 }} /></button>
              :
              <Suspense>
                <PDFDownloadLink document={
                  <DynamicReportPdf
                    headers={headers}
                    bodyTable={bodyTable}
                    reportDate={reportDate}
                    footTable={footTable}
                    tfoot={true}
                    titlePdf={titlePdf}
                    subTitlePdf={subTitlePdf}
                  />
                } fileName={fileNamePdf}>
                  {({ loading }) => (loading
                    ?
                    <button
                      className="btn  border text rounded-pill pt-2 pb-2 m-2 btn-sm"
                      style={{ color: "white", width: 65, backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff" }}>
                      <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: 20 }} />
                    </button>
                    :
                    <button
                      data-cy="btn-create-pdf" id="btn-create-pdf"
                      onClick={(e) => setBtnPdf(false)}
                      className="btn border pt-2 pb-2 text rounded-pill m-2 btn-sm"
                      style={{ color: "white", width: 65, backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff" }}>
                      <FontAwesomeIcon spin icon={faSpinner} style={{ fontSize: 20 }} />
                    </button>
                  )}
                </PDFDownloadLink>
              </Suspense>
            }
            </>
            </div>
            <div className="justify-content-end d-flex col-3">
            <button name="button-graph"
                  className={"border text-white pt-2 pb-2 border text rounded-pill m-2 btn-sm"}
                  onClick={(e) => {handleReloadData(e)}}
                  disabled={reloadData.action || rows.length === 0}
                  data-cy="btn-reload-dashbaoard"
                  style={{ backgroundColor: "#c2608e", borderColor: "#c2608e #c2608e #fff", color: "white" }}>
                  &nbsp;&nbsp;<FontAwesomeIcon icon={faRedoAlt} style={{ fontSize: 20 }} />&nbsp;&nbsp;
                </button>
            </div>
          </div>
        </>

      {buttonSelectRows.action && (
        <button className="btn text border rounded-pill m-2"
          data-cy="btn-view-all-rows"
          style={{ backgroundColor: "#c2608e", color: "white", borderColor: "#c2608e #c2608e #fff" }}
          onClick={handleViewAllRows}>
          <FontAwesomeIcon className="mt-1" icon={faEye} style={{ fontSize: 20 }} /> &nbsp;&nbsp;View All
        </button>
      )}
      <div className="d-flex justify-content-center">
        <div className="col-11">

          <table className={`table-responsive table table-striped table-sm mt-5 
            ${rows.length !== 0 && (!reloadData.action && ("shadow"))}`}
            data-cy="table-projection-report">
            <thead className="lb-thead-lila">
              <tr>
                <th scope="col" className="text-center" style={{ cursor: "pointer" }} data-cy="select-rows"
                  onClick={(e) => { setSelectRows(!selectRows) }}
                >
                  {!selectRows ? <FontAwesomeIcon className="mt-1" icon={faEye} />
                    : <FontAwesomeIcon className="mt-1 text-white" icon={faCheck} />
                  }
                </th>
                {headers?.map((header, i) => {
                  return (
                    <th scope="col" className="text-center" key={i}>
                      <span onClick={(e) => handleSort(i, !dir)} style={{ cursor: "pointer", fontSize: "12px" }}>
                        <span className="text-white" style={{ fontSize: "15px" }}>{header}</span>
                        &nbsp;&nbsp;{index === i && dir ? "▲" : "▼"}
                      </span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            {!reloadData.action && (rows.length !== 0 && (
              <tbody>
                {
                  bodyTable?.map((data, index) => {
                    const value = Object.values(data);
                    return (
                      <tr key={index} style={selectRows ? { cursor: "pointer" } : { color: "black" }}
                        data-cy={`row-${index}`}
                        onClick={(e) => { selectRows && (handleSelectRow(index)) }}>
                        <td className="text-center">
                          {!selectRows ?
                            <FontAwesomeIcon className="mt-1" icon={faEye} />
                            :
                            <input type="checkbox" className="lb-form-check-input form-check-input"
                              checked={data.checked} readOnly />
                          }
                        </td>
                        {value.map((e, i) => {
                          return (
                            typeof e !== 'boolean' && (
                              <td className="text-center" key={i}>
                                {e}
                              </td>
                            )
                          )
                        })}
                      </tr>
                    )
                  })
                }
              </tbody>
            ))}

            {!reloadData.action && (rows.length !== 0 && (tfoot && (
              <tfoot>
                <tr>
                  <td />
                  {footTable.map((e, i) => {
                    return (
                      e.show === false ?
                        <td className="text-center" key={i} />
                        :
                        <td className="text-center" key={i}>
                          {e.amount === false ?
                            <>{e.value}</>
                            :
                            <>${formatAmount(e.value)}</>
                          }
                        </td>
                    )
                  })}
                </tr>
              </tfoot>
            )))
            }
          </table>
          {rows.length === 0 && (
            <div className="d-flex justify-content-center mt-2 pb-2 text-center bg-white shadow">
              <h5><div className="spinner-border  text-info" role="status" /></h5>
            </div>
          )}
          {reloadData.action && (
            <div className="d-flex justify-content-center mt-2 pb-2 text-center bg-white shadow">
              <h5><div className="spinner-border  text-info" role="status" /></h5>
            </div>
          )}

        </div>
      </div>
      {selectRows && (
        <div className="d-flex justify-content-center">
          <button className="btn"
            disabled={buttonSelectRows.disabled}
            data-cy="btn-select-rows"
            onClick={handleSelectRows}
            style={{ backgroundColor: "#c2608e", color: "white", borderColor: "#c2608e #c2608e #fff" }}>
            Select Rows
          </button>
        </div>
      )}
    </div>
  );
}

DynamicTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  tfoot: PropTypes.array,
  fileNamePdf: PropTypes.string,
  setReloadData: PropTypes.func,
  reloadData: PropTypes.object,
  titlePdf: PropTypes.string,
  subTitlePdf: PropTypes.string
};

export default DynamicTable;
