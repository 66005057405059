import React from "react";
import { PropTypes } from "prop-types";
import moment from "moment";
import Visibility from '@material-ui/icons/Visibility';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import "../styles/booking.css";

/**
 * Booking dashboard for call center employees to book and manage clients appoointments.
 * @param {object} appState Required object from app state that stores authorization tokens for all locations.
 */

function VisitsTable({ visits, client }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h3 className="display-6 fw-normal mt-2 mb-3">Client</h3>
          <h4 className="h6 fw-normal"> <b>Name:</b> {client.FirstName} {client.LastName}</h4>
          <h4 className="h6 fw-normal"> <b>Email:</b> {client.Email}</h4>
          <h4 className="h6 fw-normal"> <b>Phone:</b> {client.MobilePhone}</h4>
          <br/>
          <h3 className="display-6 fw-normal my-3">Appointments</h3>
          <table className="table">
            <thead className="table-light">
              <tr>
                <td className="fw-bold">ID</td>
                <td className="fw-bold">Date</td>
                <td className="fw-bold">Start</td>
                <td className="fw-bold">End</td>
                <td className="fw-bold">Status</td>
                <td className="fw-bold">Service</td>
                <td className="fw-bold">Staff</td>
                <td className="fw-bold">Total</td>
                <td className="fw-bold">Method</td>
                <td className="fw-bold">Tip</td>
                <td className="fw-bold">Notes</td>
              </tr>
            </thead>
            <tbody className="table-hover">
              {visits.map((visit, index) => {
                return (
                  <tr key={visit.appointmentId}>
                    <td> {visit.appointmentId} </td>
                    <td> {moment(visit.startDateTime).format("MM/DD/YYYY").toString()}</td>
                    <td> {moment(visit.startDateTime).format("LT").toString()}</td>
                    <td> {moment(visit.endDateTime).format("LT").toString()}</td>
                    <td> {visit.appointmentStatus} </td>
                    <td> {visit.name} </td>
                    <td> {visit.infoCheckout?.staffName??"-"} </td>
                    <td className="text-center"> {visit.infoCheckout?.totalPurchase??"-"} </td>
                    <td className="text-center"> 
                      {visit.infoCheckout?.creditCard > 0 ? "Credit Card " : ""}
                      {visit.infoCheckout?.cash > 0 ? "Cash " : ""} 
                      {visit.infoCheckout?.giftCard > 0 ? "GiftCard " : ""} 
                      {(visit.infoCheckout?.giftCard === 0 && visit.infoCheckout?.cash === 0 && visit.infoCheckout?.creditCard === 0) ? "-" : ""}
                      {(visit.infoCheckout?.totalPurchase === undefined) ? "-" : ""} 
                    </td>
                    <td className="text-center"> {visit.infoCheckout?.tip??"-"} </td>
                    <td className="text-center"> 
                      <Tooltip title={visit.notes ?? "-"} placement="left">
                        <IconButton>
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

VisitsTable.propTypes = {
  visits: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
};
export default VisitsTable;
