import React from "react";
import { Bar } from "react-chartjs-2";
import { PropTypes } from "prop-types";

const HorizontalBarChart = ({
  labels,
  values,
  colors,
  borderColors,
  chartName,
}) => {
  /*
  const labels = ["Call Center", "Online"];
  const values = ["75", "85"];
  const colors = ["rgba(255, 205, 86, 0.2)", "rgba(75, 192, 192, 0.2)"];
  const borderColors = ["rgb(255, 205, 86)", "rgb(75, 192, 192)"];
  */

  const createDataSets = (labels, values) => {
    return labels.map((label, i) => {
      return {
        label: label,
        data: [values[i]],
        borderColor: borderColors[i],
        backgroundColor: colors[i],
        borderWidth: 1,
      };
    });
  };

  const data = {
    labels: [""],
    datasets: createDataSets(labels, values),
  };

  const options = {
    indexAxis: "y",
    plugins: {
      title: {
        display: true,
        text: chartName,
      },
      legend: {
        position: "bottom",
      },
    },
  };

  return <Bar options={options} data={data} />;
};

HorizontalBarChart.propTypes = {
  labels: PropTypes.array,
  values: PropTypes.array,
  colors: PropTypes.array,
  borderColors: PropTypes.array,
  chartName: PropTypes.string,
};
export default HorizontalBarChart;
