import React from "react";
import PropTypes from 'prop-types';
import logo from "../logo.png";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSpinner,} from "@fortawesome/free-solid-svg-icons";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const VerifyOtpCode = ({
    msgData,
    state,
    setState,
    formData
}) =>{


    const expiredCodeRequest = async (event) => {
        event.preventDefault();
        setState((state) => ({
          ...state,
          requestOtpStatus: "validating",
          regenerateToken: "",
        }));
        try {
          const payload = {
            email: state.otpTarget === "email",
            clientId: "" + formData.client_id,
            appointmentId: "" + state.appointmentId,
            contact:
              state.otpTarget === "email"
                ? formData.email
                : parseInt(formData.phone_cel.replace(/[^0-9.]+/g, '')),
          };
    
          const requestCode = {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              siteid: state.siteId,
            },
            body: JSON.stringify(payload),
          };
    
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/oneTimePassword/generate`,
            requestCode
          );
          const data = await response.json();
          if (response.ok) {
            setState((state) => ({
              ...state,
              requestOtpStatus: "",
              regenerateToken: "success",
            }));
          } else {
            setState((state) => ({
              ...state,
              requestOtpStatus: "error",
              regenerateToken: "",
              message: JSON.stringify(data),
            }));
          }
        } catch (error) {
          setState((state) => ({
            ...state,
            requestOtpStatus: "error",
            regenerateToken: "",
            message: "Error: " + JSON.stringify(error.message),
          }));
        }
      };
    

    const handleOtpChange = (values) => {
        setState((state) => ({
          ...state,
          otpToken: values.value,
        }));
      };
    
      const verifyOtpCode = async (event) => {
        event.preventDefault();
    
        setState((state) => ({
          ...state,
          checkOtpStatus: "validating",
        }));
        try {
          const payload = {
            ip: "" + formData.ipv4,
            clientId: "" + formData.client_id,
            appointmentId: "" + state.appointmentId,
            otpToken: "" + state.otpToken,
          };
    
          const verifyCode = {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              siteid: state.siteId,
            },
            body: JSON.stringify(payload),
          };
    
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/oneTimePassword/verify`,
            verifyCode
          );
          const data = await response.json();
          if (response.ok) {
            setState((state) => ({
              ...state,
              checkOtpStatus: "",
              status: "form",
            }));
          } else {
            setState((state) => ({
              ...state,
              checkOtpStatus: "wrong",
              message: JSON.stringify(data),
            }));
          }
        } catch (error) {
          setState((state) => ({
            ...state,
            checkOtpStatus: "error",
            message: "Error: " + JSON.stringify(error.message),
          }));
        }
      };

      const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
          return (
            <a href="#" onClick={expiredCodeRequest} className="text">
              Request a new code
            </a>
          );
        }
        return (
          <div className="timer">
            <div className="text">Your code</div>
            <div className="text"> will expire</div>
            <div className="value">in {remainingTime}s</div>
          </div>
        );
      };


return(

    <div>

    <div className="lb-container-vcenter container mt-5">
    <div className="row">
      <div className="col lb-text-center">
        <img src={logo} alt="little bellies" width="125" />
        <h1 className="lb-text-title">Welcome to {msgData.siteName}</h1>
      </div>
    </div>
  </div>
  <div className="container lb-w-70 mb-5">
    <div className="row justify-content-center mb-5">
      <div className="col col-md-5 lb-text-center">
        <div className="lb-form-group mx-auto">
          <div className="lb-text-left mb-2">
            Please enter the code that we sent you
          </div>
          <NumberFormat
            value={state.otpToken}
            className="lb-form-control lb-border-0 lb-inputBackground mb-2"
            decimalScale={0}
            allowNegative={false}
            allowLeadingZeros={true}
            onValueChange={handleOtpChange}
            data-cy="otpTokenInput"
            placeholder="Enter your secret code"
          />
          <button
            className={
              state.otpToken.length !== 6
                ? "lb-button-primary-disabled lb-cta-lb-start-form"
                : "lb-button-primary lb-cta-lb-start-form"
            }
            disabled={state.otpToken.length !== 6}
            onClick={verifyOtpCode}
            data-cy="ctaVerifyOtpCode"
          >
            {state.checkOtpStatus === "validating" ? (
              <div>
                <FontAwesomeIcon spin icon={faSpinner} /> Loading...
              </div>
            ) : (
              "Verify code"
            )}
          </button>
        </div>
      </div>
      <div className="col col-md-4 d-flex justify-content-center lb-text-center lb-timer-wrapper">
        <CountdownCircleTimer
          isPlaying
          className="mx-auto my-auto"
          key={state.regenerateToken === "success"}
          duration={900}
          colors={[
            ["#004777", 0.33],
            ["#F7B801", 0.33],
            ["#A30000", 0.33],
          ]}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
    </div>

    {state.checkOtpStatus === "wrong" && (
      <span className="lb-container-center">
        <div
          className="lb-form-submited-warning mt-2"
          data-cy="wrong-code-message"
        >
          <strong>Sorry</strong>{" "}
          {
            "The code you entered is incorrect, please check the code and try again. If it expired don't worry we've sent you a brand new one to your email or phone, just click the request new code button"
          }
        </div>
      </span>
    )}
    {state.checkOtpStatus === "error" && (
      <span className="lb-container-center">
        <div className="lb-form-submited-warning mt-2">
          Whoops an error has occurred. <br />
          <span>{state.message}</span>
        </div>
      </span>
    )}
  </div>


  </div>
    
);





};


VerifyOtpCode.propTypes = {
    msgData : PropTypes.object,
    state : PropTypes.object,
    setState: PropTypes.func,
    formData : PropTypes.object,

};

export default VerifyOtpCode;