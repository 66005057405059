import "../styles/bootstrap-grid.min.css";
import React, { useEffect, useState, useRef, useCallback } from "react";
import "../styles/global.css";
import "../App.css";
import logo from "../logo.png";
import { useParams, Link, useLocation } from "react-router-dom";
import UploadImages from "../components/upload-images.component";
import { PropTypes } from "prop-types";
import moment from "moment";
const REGION = "us-east-2";
const albumBucketName = "littlebellies-landing.storage";
const href = "https://s3." + REGION + ".amazonaws.com/";
const bucketUrl = href + albumBucketName + "/";
const baseImagesUrl = [
  "https://dl.dropboxusercontent.com/s/25t8iooqjm4lt8e/image01.jpg",
  "https://dl.dropboxusercontent.com/s/2nyizfd0ib5dmky/image02.jpg",
  "https://dl.dropboxusercontent.com/s/ujnikms4uj69lln/image03.jpg",
  "https://dl.dropboxusercontent.com/s/eprautry181ez5x/image04.jpg",
  "https://dl.dropboxusercontent.com/s/6kr2vf48j5vi42m/image05.jpg",
];
const baseLogoUrl = [
  "https://dl.dropboxusercontent.com/s/cam54htmyr3umud/littlebellies.jpg",
];
const minImageCount = 1;
function ImgUploadPage({ appState }) {
  const params = useParams();
  const [isSending, setIsSending] = useState(false);
  const isMounted = useRef(true);
  const [imgCount, setImgCount] = useState(0);
  const [images, setImages] = useState([]);
  const [statusVideo, setStatusVideo] = useState("");
  const location = useLocation();
  const [state, setState] = useState({
    clientName: null,
    email: null,
    services: null,
    dateStartAppointment: null,
    dateEndAppointment: null,
    apptDateYearStart: null,
    apptDateMonthStart: null,
    apptDateDateStart: null,
    apptHourStart: null,
    apptHourFinish: null,
    locationId: null,
    status: "loading",
    message: "",
  });

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const sendRequest = useCallback(async () => {
    if (isSending) return;
    setIsSending(true);
    const dynamoPayload = {
      email: "" + state.email,
      dateTime: moment(new Date()).toString(),
      locationId: "" + state.locationId,
      siteId: appState.nav.siteId,
      images: images,
    };
    const putDynamo = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(dynamoPayload),
    };

    const dynamoResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/api/dynamoDB/video`,
      putDynamo
    );
    if (dynamoResponse.ok) {
      setStatusVideo("Video created successfully");
    }

    if (isMounted.current) setIsSending(false);
  }, [isSending, state.email, state.locationId, appState.nav.siteId, images]);

  useEffect(() => {
    try {
      const months = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
      };

      const clientName = location.state.clientName;
      const newEmail = location.state.email;
      const services = location.state.service;
      const dateStartAppointment = new Date(location.state.startDateTime);
      const dateEndAppointment = new Date(location.state.endDateTime);
      const locationId = location.state.locationId;
      const apptDateYearStart = dateStartAppointment.getFullYear();
      const apptDateMonthStart = months[dateStartAppointment.getMonth()];
      const apptDateDateStart = dateStartAppointment.getDate();
      let apptHourStart = dateStartAppointment.getDate();
      let apptHourFinish = dateStartAppointment.getDate();

      const minutes =
        dateStartAppointment.getMinutes() === 0
          ? "00"
          : dateStartAppointment.getMinutes();
      if (dateStartAppointment.getHours() >= 12) {
        if (dateStartAppointment.getHours() === 12) {
          apptHourStart = "12:" + minutes + " PM";
        } else {
          apptHourStart =
            dateStartAppointment.getHours() - 12 + ":" + minutes + " PM";
        }
      } else {
        if (dateStartAppointment.getHours() === 0) {
          apptHourStart = "12:" + minutes + " AM";
        } else {
          apptHourStart =
            dateStartAppointment.getHours() + ":" + minutes + " AM";
        }
      }
      /* PARA LA HORA FINAL */

      const minutesEnd =
        dateEndAppointment.getMinutes() === 0
          ? "00"
          : dateEndAppointment.getMinutes();
      if (dateEndAppointment.getHours() >= 12) {
        if (dateEndAppointment.getHours() === 12) {
          apptHourFinish = "12:" + minutesEnd + " PM";
        } else {
          apptHourFinish =
            dateEndAppointment.getHours() - 12 + ":" + minutesEnd + " PM";
        }
      } else {
        if (dateEndAppointment.getHours() === 0) {
          apptHourFinish = "12:" + minutesEnd + " AM";
        } else {
          apptHourFinish =
            dateEndAppointment.getHours() + ":" + minutesEnd + " AM";
        }
      }

      setState((state) => ({
        ...state,
        clientName: clientName,
        email: newEmail,
        services: services,
        dateStartAppointment: dateStartAppointment,
        dateEndAppointment: dateEndAppointment,
        apptDateYearStart: apptDateYearStart,
        apptDateMonthStart: apptDateMonthStart,
        apptDateDateStart: apptDateDateStart,
        apptHourStart: apptHourStart,
        apptHourFinish: apptHourFinish,
        locationId: locationId,
        status: "ready",
      }));
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: "Onload page Error: " + JSON.stringify(error.message),
      }));
    }
  }, [params.appointmentid, location]);

  const _renderLoading = () => (
    <div className="loading" data-cy="loading-message">
      <div className="lb-spinner" />
    </div>
  );
  const _renderError = () => (
    <div>
      <div className="container mt-3">
        <div className="row ">
          <div className="col-4 col-md-3 col-lg-2 mx-auto">
            <nav className="lb-navbar">
              <nav className="lb-navbar">
                <Link className="lb-navbar-brand" to="/">
                  <img src={logo} width="100%" alt="Little Bellies Logo"></img>
                </Link>
              </nav>
            </nav>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          <div className="col">
            <h1 className="lb-text-center">Error</h1>
            <h2 className="lb-text-center">
              Seems like there has been an error
            </h2>
            <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
              <span>
                { state.message }
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const _updateImages = (images) => {
    if (images) {
      const imageUrl = images.map(
        (item) => bucketUrl + encodeURIComponent(item.Key)
      );

      setImages([
        ...baseLogoUrl,
        ...baseImagesUrl,
        ...imageUrl,
        ...baseLogoUrl,
      ]);
      setImgCount(imageUrl.length);
    }
  };
  return (
    <div className="content">
      {state.status === "loading" && _renderLoading()}
      {(state.status === "error") &&
        _renderError()}
      {state.status === "ready" && (
        <div className="container pb-1">
          <div className="row px-4">
            <div className="col-6">
              <div className="lb-appt-lb-card-info px-3 py-3">
                <strong data-cy="client">{state.clientName}</strong>
                <br />
                <strong>Services:</strong>{" "}
                <span data-cy="services" className="lb-text-muted2">
                  {state.services}
                </span>
                <br />
                <strong>Appointment date:</strong>
                <br />
                <span data-cy="dateStartAppointment" className="lb-text-muted2">
                  {state.apptDateMonthStart} {state.apptDateDateStart},{" "}
                  {state.apptDateYearStart}{" "}
                </span>
                <span data-cy="dateEndAppointment" className="lb-text-muted2">
                  {state.apptHourStart} {"-"} {state.apptHourFinish}
                </span>
                <br />
              </div>
            </div>
            <div className="col-6 lb-text-right">
              {location.state.programId !== "" && (
                <Link
                  to={{
                    pathname: `/notepage/${params.appointmentid}`,
                    state: {
                      programId: location.state.programId,
                      sessionTypeId: location.state.sessionTypeId,
                    },
                  }}
                  className="lb-btn lb-submitButton lb-no-hover lb-rounded-0 mt-4 mb-2"
                >
                  BACK
                </Link>
              )}
              {location.state.programId === "" && (
                <Link
                  to={{
                    pathname: `/remakevideo`,
                  }}
                  className="lb-btn lb-submitButton lb-no-hover lb-rounded-0 mt-4 mb-2"
                >
                  BACK
                </Link>
              )}
            </div>
          </div>
          <UploadImages
            appointmentid={params.appointmentid}
            updateImages={_updateImages}
            statusVideo={statusVideo}
            imgCount={imgCount}
            minImageCount={minImageCount}
            sendRequest={sendRequest}
            isSending={isSending}
          />
        </div>
      )}
    </div>
  );
}
ImgUploadPage.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default ImgUploadPage;
