import React from "react";
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

function HoverCard({ cardClass, appointment, segment, endSegment }) {

  return (
    <>
      <div className="lb-booking-popover-card-visible">
        <div className={ `d-flex justify-content-around ${cardClass}` }>
          <span>{appointment.name}</span>
          <span>{appointment.phone}</span>
        </div>

        <div className="mt-1">
          <span>{appointment.sessionTypeName}</span>
        </div>

        <div className="mt-1">
          <span>{`${segment} - ${endSegment}`}</span>
        </div>

        <div className="mt-1 lb-notes-popover">
          <textarea
            rows="4"
            className="w-100 m-0 p-0"
            value={appointment.notes ===null? "": appointment.notes}
            disabled
            readOnly
          />
        </div>

        <div className={"d-flex justify-content-around"}>
          <span>
            <FontAwesomeIcon className="lb-fontAwesome-flag-red" icon={faExclamationTriangle}/>
            &nbsp; {appointment.redAlert}
          </span>

          <span>
            <FontAwesomeIcon className="lb-fontAwesome-flag-yellow" icon={faExclamationTriangle} />
            &nbsp;{appointment.formFilled}
          </span>
        </div>
      </div>
  </>
  )

};

HoverCard.propTypes = {
  cardClass: PropTypes.string.isRequired,
  appointment: PropTypes.object.isRequired,
  segment: PropTypes.string.isRequired,
  endSegment: PropTypes.string.isRequired,
};

export default HoverCard;