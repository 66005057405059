import "../styles/bootstrap-grid.min.css";
import { fromBuffer } from "file-type/core";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faExclamationTriangle,
	faSpinner,
	faCheck,
	faTimes,
	faEye,
	faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../styles/global.css";
import "../App.css";
import { PropTypes } from "prop-types";
import moment from "moment";

// import pdfFile from "./sample.pdf";

import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AppointmentsListPage = ({ appState, displayForms }) => {
	const [apidata, setApiData] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [state, setState] = useState({
		status: "loading",
		message: "",
	});
	const [hoverIndex, setHoverIndex] = useState("");
	const [hoverIndexNotes, setHoverIndexNotes] = useState("");
	const [arrivedStatus, setArrivedStatus] = useState({
		status: "OK",
		message: "",
	});
	const [showForm, setShowForm] = useState(false);

	const [modalURL, setModalURL] = useState("");
	const appointmentStatusClass = [
		"lb-card-1-arrived",
		"lb-card-1-booked",
		"lb-card-1-confirmed",
		"lb-card-1-noShow",
		"lb-card-2-arrived",
		"lb-card-2-booked",
		"lb-card-2-confirmed",
		"lb-card-2-noShow",
		"lb-card-3-arrived",
		"lb-card-3-booked",
		"lb-card-3-confirmed",
		"lb-card-3-noShow",
		"lb-card-4-arrived",
		"lb-card-4-booked",
		"lb-card-4-confirmed",
		"lb-card-4-noShow",
	];
	const tabsStatusClass = ["lb-room-1", "lb-room-2", "lb-room-3", "lb-room-4"];
	const [search, setSearch] = useState({
		searchTerm: "",
		appointments: [],
		room: -1,
	});

	const maskedPhoneNumber = (phoneNumber) => {
		const maskedPhone = phoneNumber.replace(/\d(?=\d{4})/g, "*");
		return maskedPhone;
	};

	useEffect(() => {
		const months = {
			0: "January",
			1: "February",
			2: "March",
			3: "April",
			4: "May",
			5: "June",
			6: "July",
			7: "August",
			8: "September",
			9: "October",
			10: "November",
			11: "December",
		};

		const getAppointmentList = async () => {
			setState((state) => ({
				...state,
				status: "loading",
			}));
			try {
				const getMethod = {
					method: "GET",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						siteid: appState.nav.siteId,
						authorization: appState.singleAuth.token,
						locationid: appState.nav.locationId,
					},
				};
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/appointments`, getMethod);
				const data = await response.json();

				if (response.ok) {
					const appointmentsData = data.map((item) => {
						const mutableItem = item;
						const apptDate = new Date(mutableItem.StartDateTime);
						mutableItem.apptDateYear = apptDate.getFullYear();
						mutableItem.arrived = "";
						mutableItem.apptDateMonth = months[apptDate.getMonth()];
						mutableItem.apptDateDate = apptDate.getDate();
						const minutes = apptDate.getMinutes() === 0 ? "00" : apptDate.getMinutes();
						if (apptDate.getHours() >= 12) {
							if (apptDate.getHours() === 12) {
								mutableItem.apptHour = "12:" + minutes + " PM";
							} else {
								mutableItem.apptHour = apptDate.getHours() - 12 + ":" + minutes + " PM";
							}
						} else {
							if (apptDate.getHours() === 0) {
								mutableItem.apptHour = "12:" + minutes + " AM";
							} else {
								mutableItem.apptHour = apptDate.getHours() + ":" + minutes + " AM";
							}
						}
						return mutableItem;
					});

					const filteredRooms = new Set();
					const filteredArr = appointmentsData.filter((el) => {
						const duplicate = filteredRooms.has(el.staffId);
						filteredRooms.add(el.staffId);
						return !duplicate;
					});

					if (appState.nav.siteId === "549974") {
						setRooms(filteredArr.sort((a, b) => (a.staffId < b.staffId ? 1 : -1)));
					} else {
						setRooms(filteredArr);
					}

					appointmentsData.forEach((item) => {
						switch (item.status) {
							case "Arrived":
								item.class = filteredArr.findIndex((x) => x.staffId === item.staffId) * 4 + 0;
								break;
							case "Booked":
								item.class = filteredArr.findIndex((x) => x.staffId === item.staffId) * 4 + 1;
								break;
							case "Confirmed":
								item.class = filteredArr.findIndex((x) => x.staffId === item.staffId) * 4 + 2;
								break;
							case "NoShow":
								item.class = filteredArr.findIndex((x) => x.staffId === item.staffId) * 4 + 3;
								break;
							case "Completed":
								item.class = filteredArr.findIndex((x) => x.staffId === item.staffId) * 4 + 4;
								break;
						}
					});

					const arrivedAppointments = appointmentsData.filter((appointment) => appointment.status === "Arrived");

					const notArrivedAppointments = appointmentsData.filter((appointment) => appointment.status !== "Arrived");

					const sortedAppointments = arrivedAppointments
						.sort((a, b) => (a.StartDateTime > b.StartDateTime ? 1 : -1))
						.concat(notArrivedAppointments.sort((a, b) => (a.StartDateTime > b.StartDateTime ? 1 : -1)));

					setApiData(sortedAppointments);
					setSearch((search) => ({
						...search,
						appointments: sortedAppointments,
					}));
					setState((state) => ({
						...state,
						status: "ready",
					}));
				} else {
					setState((state) => ({
						...state,
						status: "no-data-found",
						message: JSON.stringify(data),
					}));
				}
			} catch (error) {
				setState((state) => ({
					...state,
					status: "error",
					message: "Onload page Error: " + JSON.stringify(error.message),
				}));
			}
		};
		getAppointmentList();
	}, [appState.nav.locationId, appState.nav.siteId, appState.singleAuth.token, displayForms]);

	useEffect(() => {
		const list =
			search.room === -1
				? apidata
				: apidata.filter((appointment) => appointment.staffId === rooms[search.room].staffId);
		const results = list.filter((appointment) =>
			appointment.name.toLowerCase().includes(search.searchTerm.toLowerCase())
		);
		setSearch((search) => ({
			...search,
			appointments: results,
		}));
	}, [apidata, search.searchTerm, search.room, rooms]);

	const handleOnChange = (event) => {
		setSearch((search) => ({
			...search,
			searchTerm: event.target.value,
		}));
	};

	const handleCheckin = (id) => async (event) => {
		event.preventDefault();
		const appointment = apidata.find((item) => item.id === id);
		if (appointment.status !== "Arrived") {
			let appointments = apidata.map((item) => {
				if (item.id === id) {
					return { ...item, status: "Sending" };
				} else {
					return item;
				}
			});
			setApiData(appointments);
			try {
				const payload = {
					Execute: "arrive",
				};
				const putMethod = {
					method: "PUT",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
						siteid: appState.nav.siteId,
						authorization: appState.singleAuth.token,
						locationid: appState.nav.locationId,
						allowedpermissions: appState.singleAuth.allowedPermissions,
					},
					body: JSON.stringify(payload),
				};
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/appointments/${id}`, putMethod);
				const data = await response.json();
				if (response.ok) {
					let selectedAppointment = {};
					appointments = apidata.map((item) => {
						if (item.id === id) {
							selectedAppointment = item;
							return {
								...item,
								status: "Arrived",
								class: rooms.findIndex((x) => x.staffId === item.staffId) * 4,
								arrived: moment(new Date()).toString(),
							};
						} else {
							return item;
						}
					});
					setApiData(appointments);
					try {
						const dynamoPayload = {
							appointmentId: parseInt(selectedAppointment.id),
							clientName: "" + selectedAppointment.name,
							locationId: parseInt(appState.nav.locationId),
							staffId: selectedAppointment.staffId,
							appointmentDate: selectedAppointment.StartDateTime,
							arrived: moment(new Date()).toString(),
							clientId: data.Appointment.ClientId,
							formFilled: selectedAppointment.formFilled === id,
						};
						const putDynamo = {
							method: "POST",
							headers: {
								"Content-type": "application/json; charset=UTF-8",
							},
							body: JSON.stringify(dynamoPayload),
						};
						const dynamoResponse = await fetch(
							`${process.env.REACT_APP_API_URL}/api/dynamoDB/sites/${appState.nav.siteId}/sales`,
							putDynamo
						);
						const dynamoData = await dynamoResponse.json();
						if (dynamoResponse.ok) {
							console.log(dynamoResponse);
							setArrivedStatus((arrivedStatus) => ({
								...arrivedStatus,
								status: "OK",
								message: "",
							}));
						} else {
							setArrivedStatus((arrivedStatus) => ({
								...arrivedStatus,
								status: "fail",
								message: JSON.stringify(dynamoData),
							}));
						}
					} catch (error) {
						setArrivedStatus((arrivedStatus) => ({
							...arrivedStatus,
							status: "error",
							message: "Arrived error: " + JSON.stringify(error.message),
						}));
					}
				} else {
					appointments = apidata.map((item) => {
						if (item.id === id) {
							return appointment;
						} else {
							return item;
						}
					});
					setApiData(appointments);
					setArrivedStatus((arrivedStatus) => ({
						...arrivedStatus,
						status: "fail",
						message: JSON.stringify(data),
					}));
				}
			} catch (error) {
				appointments = apidata.map((item) => {
					if (item.id === id) {
						return appointment;
					} else {
						return item;
					}
				});
				setApiData(appointments);
			}
		}
	};

	const handleRoomChange = (index) => (event) => {
		event.preventDefault();
		setSearch((search) => ({
			...search,
			room: index,
		}));
	};

	const handleModalCloseForm = () => {
		setShowForm(false);
	};

	/**
	 * its in charge of updating the modal's data with the appointment's form URL and make the modal visible
	 * @param {object} appointment it contains all information of an aapointment
	 *
	 */
	const handleModalOpenForm = (appointment) => () => {
		setModalURL(appointment.urlForms);
		setShowForm(true);
	};

	return (
		<div className="content">
			{state.status === "loading" && (
				<div className="loading" data-cy="loading-message">
					<div className="lb-spinner" />
				</div>
			)}
			{state.status !== "loading" && (
				<div>
					<div className="container">
						{arrivedStatus.status === "fail" && (
							<div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-error">
								<span className="">{arrivedStatus.message}</span>
							</div>
						)}
						{arrivedStatus.status === "error" && (
							<div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-error">
								<span className="">{arrivedStatus.message}</span>
							</div>
						)}
						{(state.status === "error" || appState.nav.status === "logOutError") && (
							<div className="container" data-cy="error">
								<div className="row">
									<div className="col">
										<h1 className="lb-text-center">Error</h1>
										<h2 className="lb-text-center">Seems like there has been an error</h2>
										<div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
											<span>{appState.nav.status === "logOutError" ? appState.nav.message : state.message}</span>
										</div>
									</div>
								</div>
							</div>
						)}
						{state.status === "no-data-found" && (
							<div className="container" data-cy="not-found-message">
								<div className="row">
									<div className="col">
										<h1 className="lb-text-center">Nothing found</h1>
										<h2 className="lb-text-center">Seems like nothing was found</h2>
										<div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
											<span>{state.message}</span>
										</div>
									</div>
								</div>
							</div>
						)}
						{state.status === "ready" && (
							<>
								<div className="container mt-3">
									<div className="row">
										<div className="col lb-text-center">
											<div className="lb-form-group">
												<input
													data-cy="search-bar"
													type="text"
													className="lb-form-control lb-border-0 lb-inputBackground"
													name="spouse-partner"
													placeholder="Search"
													onChange={handleOnChange}
												/>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-auto lb-nav lb-nav-tabs">
											<div className="lb-nav-item">
												<button
													className={search.room === -1 ? "lb-nav-link active" : "lb-nav-link"}
													onClick={handleRoomChange(-1)}
												>
													ALL
												</button>
											</div>
											{rooms.map((room, index) => {
												return (
													<div key={room.staffId} className="lb-nav-item">
														<button
															className={
																search.room === index ? "lb-nav-link " + tabsStatusClass[index] : "lb-nav-link "
															}
															onClick={handleRoomChange(index)}
														>
															Room: {index + 1}
														</button>
													</div>
												);
											})}
										</div>
									</div>
								</div>
								<div className="row justify-content-between my-2">
									<div className="col my-auto">
										<h2 data-cy="page-title-appointments">
											<b>Appointments</b>
										</h2>
									</div>
									<div className="my-auto col-auto ">
										<strong className="mr-3 lb-legend">Form filled status: </strong> <br />
										<span className="mr-3 lb-legend">
											<FontAwesomeIcon className="mr-1" icon={faCheck} />
											Completed{" "}
										</span>
										<span className="mr-3 lb-legend">
											<FontAwesomeIcon className="mr-1" icon={faTimes} />
											Incomplete
										</span>
									</div>
								</div>
								<div className="row mx-0 mx-md-2 mx-lg-0 mb-4">
									{search.appointments.map((appointment) => {
										return (
											<div
												key={appointment.id}
												className={
													appointment.status === "Completed" ||
													(displayForms && appointment.formFilled !== null && appointment.formFilled !== "")
														? "d-none"
														: "col-xs-12 col-md-6 col-lg-4 mb-4"
												}
											>
												<br />
												<br />
												<div className="appt-card mt-5">
													<div
														className={
															"lb-w-100 px-3 py-2 lb-text-right lb-card-header " +
															appointmentStatusClass[appointment.class]
														}
													>
														<span className="text-truncate">{appointment.sessionTypeName}</span>
													</div>
													<div className="lb-appt-lb-card-info lb-inputBackground px-3 py-3">
														<div className="lb-w-100 pb-2 d-flex justify-content-between">
															<strong className="lb-text-muted2">{appointment.apptHour}</strong>
															<span className="pr-2">
																<strong>Form: </strong>
																<button
																	className="lb-btn-modal-form btn-sm"
																	onClick={handleModalOpenForm(appointment)}
																	data-cy={"btn-form-" + appointment.id}
																	disabled={appointment.formFilled === null || appointment.formFilled === ""}
																>
																	<FontAwesomeIcon
																		icon={
																			appointment.formFilled === null || appointment.formFilled === ""
																				? faTimes
																				: faCheck
																		}
																	/>
																</button>
															</span>
														</div>

														<div className="row">
															<div className="col-sm-auto">
																<div className="lb-w-100 lb-text-muted2">{appointment.name}</div>
															</div>
														</div>

														<div className="row">
															<div className="col-sm-auto">
																<div className="lb-w-100 lb-text-muted2">
																	{appointment.redAlert ? (
																		<FontAwesomeIcon className="mt-1" icon={faExclamationTriangle} color="red" />
																	) : (
																		<div></div>
																	)}
																	<small>{appointment.redAlert?.split("before").join("")}</small>
																</div>
															</div>
															<div className="col d-flex">
																<div className="lb-w-100 pr-3 lb-text-right">
																	<FontAwesomeIcon
																		data-cy={"hover-notes-" + appointment.id}
																		className="mt-1"
																		onMouseOver={() => setHoverIndexNotes(appointment.id)}
																		onMouseLeave={() => setHoverIndexNotes("")}
																		icon={faStickyNote}
																	/>
																</div>
																<div className={"lb-w-15 lb-text-right"}>
																	<FontAwesomeIcon
																		className="mt-1 "
																		onMouseOver={() => setHoverIndex(appointment.id)}
																		onMouseLeave={() => setHoverIndex("")}
																		icon={faEye}
																	/>
																</div>
															</div>
														</div>
													</div>
													{!displayForms && (
														<div className="no-gutters lb-w-100">
															<button
																className={"lb-btn col-6 mx-0 lb-btn-left " + appointmentStatusClass[appointment.class]}
																data-cy={"checkin-" + appointment.id}
																disabled={appointment.status === "Arrived"}
																onClick={handleCheckin(appointment.id)}
															>
																{appointment.status === "Arrived" ? (
																	<span>Arrived</span>
																) : appointment.status === "Sending" ? (
																	<span className="text-lb-numberButton mx-4 my-2">
																		<FontAwesomeIcon spin icon={faSpinner} />
																	</span>
																) : (
																	<span>Check-in</span>
																)}
															</button>

															{appointment.status === "Arrived" && (
																<Link
																	className={
																		"lb-btn checkout-lb-btn col-6 mx-0 lb-btn-right " +
																		appointmentStatusClass[appointment.class]
																	}
																	data-cy={"appointment-" + appointment.id}
																	to={{
																		pathname: `/notepage/${appointment.id}`,
																		state: {
																			programId: appointment.programId,
																			sessionTypeId: appointment.sessionTypeId,
																			clientName: appointment.name,
																			arrived:
																				appointment.arrived !== ""
																					? appointment.arrived
																					: moment(new Date()).toString(),
																		},
																	}}
																>
																	Checkout
																</Link>
															)}
															{appointment.status !== "Arrived" && (
																<span
																	className={
																		"lb-btn checkout-lb-btn col-6 mx-0 lb-btn-right " +
																		appointmentStatusClass[appointment.class]
																	}
																>
																	Checkout
																</span>
															)}
														</div>
													)}
													{displayForms && (
														<div className="no-gutters lb-w-100">
															<Link
																className={
																	"lb-btn checkout-lb-btn col-12 lb-no-hover mx-0 lb-btn-right lb-btn-left " +
																	appointmentStatusClass[appointment.class]
																}
																data-cy={"form-" + appointment.id}
																to={{
																	pathname: `/appointments/${appState.nav.siteId}/${appointment.id}`,
																	state: {
																		skip2fa: true,
																	},
																}}
															>
																Fill form
															</Link>
														</div>
													)}
												</div>

												<div className={hoverIndex === appointment.id ? "lb-preview-card-visible" : "lb-preview-card"}>
													{appointment.name} <br />
													{appointment.email} <br />
													{appointment.status === "Arrived"
														? appointment.phone
														: maskedPhoneNumber(appointment.phone)}{" "}
													<br />
													{"Last Visit: " + (appointment.lastVisitDate ?? "N/R")}
													<br />
													{appointment.lastVisitServiceName}
												</div>

												<div
													className={
														hoverIndexNotes === appointment.id ? "lb-preview-card-visible-notes" : "lb-preview-card"
													}
												>
													{appointment.lastVisitNote !== "" ? (
														appointment.lastVisitNote?.split("\n").map((i) => (
															<>
																<p>{`${i}\n`}</p>
															</>
														))
													) : (
														<p></p>
													)}
												</div>
											</div>
										);
									})}

									{search.appointments.length === 0 && search.searchTerm !== "" && (
										<div className="col" data-cy="no-search-results">
											<h3>No appointments that match this search</h3>
										</div>
									)}
								</div>
							</>
						)}
						<div hidden={!showForm} className="lb-modal-zindex " data-cy="modal-form">
							<div className="modal-background" id="modal-background">
								<div className="modal-dialog modal-fullscreen">
									<div className="modal-content ">
										<div className="modal-body d-flex justify-content-center">
											<DocumentsViewer link={modalURL} />
										</div>
										<div className="modal-footer d-flex justify-content-center">
											<button
												type="button"
												className="lb-button-daily"
												data-dismiss="modal"
												onClick={handleModalCloseForm}
												data-cy="btn-form-ok"
											>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

AppointmentsListPage.defaultProps = {
	displayForms: false,
};

AppointmentsListPage.propTypes = {
	appState: PropTypes.object.isRequired,
	displayForms: PropTypes.bool.isRequired,
};
export default AppointmentsListPage;

/**
 * A Wrapper component that its in charge of downloading the file as a blob
 * and creates a URL for it to avoid CORS issues
 * @param {object} link contains a URL that points to the client's PDF form
 * @returns a JSX fragment that will contain a PDFViewer component
 */
const DocumentsViewer = ({ link }) => {
	const [file, setFile] = React.useState({});
	React.useEffect(() => {
		/**
		 * its in charge of downloading the file and updating the component's state when its ready
		 * @param {string} link a URL for a PDF file
		 */
		const getBlob = async (link) => {
			try {
				const blob = await getBlobFromS3URL(link);
				const buffer = await blob.arrayBuffer();
				const fileType = await fromBuffer(buffer);
				setFile({ blob, fileType });
			} catch (err) {
				console.log(err);
			}
		};
		getBlob(link);
	}, [link]);
	const { blob, fileType } = file;
	return (
		<>
			{!fileType && <span>Loading...</span>}
			{fileType && <PDFViewer link={URL.createObjectURL(blob)} />}
		</>
	);
};
DocumentsViewer.propTypes = {
	link: PropTypes.string,
};

/**
 * its a component to present a PDF file and navigate it
 * @param {object} object.link an object with a link property that contains a PDF URL
 * @returns a JSX object with one page of the document and two buttons to move between pages
 */
const PDFViewer = ({ link }) => {
	const [numPages, setNumPages] = React.useState(null);
	const [pageNumber, setPageNumber] = React.useState(1);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	function changePage(offset) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}

	function previousPage() {
		changePage(-1);
	}

	function nextPage() {
		changePage(1);
	}

	return (
		<div style={{ overflow: "auto" }}>
			<Document
				file={link}
				onLoadError={console.error}
				options={{
					cMapUrl: "cmaps/",
					cMapPacked: true,
				}}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				<div data-cy="document-zoom" className="zoom-cursor">
					<Page pageNumber={pageNumber} />
				</div>
			</Document>
			<div>
				<div className="d-flex justify-content-center">
					<p>
						Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
					</p>
				</div>
				<div className="my-3 d-flex justify-content-center">
					<button className="lb-button-daily" type="button" disabled={pageNumber <= 1} onClick={previousPage}>
						Anterior
					</button>
					<button type="button" className="ms-3 lb-button-daily" disabled={pageNumber >= numPages} onClick={nextPage}>
						Siguiente
					</button>
				</div>
			</div>
		</div>
	);
};

PDFViewer.propTypes = {
	link: PropTypes.string,
};

const getBlobFromS3URL = async (url) => {
	try {
		const blob = await fetch(url).then((r) => {
			return r.blob();
		});
		return Promise.resolve(blob);
	} catch (err) {
		console.log(err);
	}
};
