import React from "react";
import { PropTypes } from "prop-types";
import { useDrag } from 'react-dnd';
import { ItemTypes } from './ItemTypes'

function DraggableBlock({ appointment, isWeekRange, displayAll, blocks, indice, customClass }) {
  const [{isDragging}, drag] = useDrag(() => ({
    type: ItemTypes.APPOINTMENT,
    item: appointment,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const hideName = ( appointment,  blocks, indice) => {
    let hide = false;
    
    if(indice > 0 && indice < blocks.length-1){
      if(blocks[indice-1].appointment.id !== undefined){
        if(blocks[indice-1].appointment.id === appointment.id){
          hide = true;
        }
      }
    }
    if(indice === blocks.length){
      if(blocks[indice-1].appointment.id !== undefined){
        if(blocks[indice-1].appointment.id === appointment.id){
          hide = true;
        }
      }
    }
    return hide;
  };
  
  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
      className="text-truncate"
    >
      {isWeekRange && !hideName(appointment, blocks, indice) && (appointment.name +": " +appointment.sessionTypeName)}
      {displayAll && !hideName(appointment, blocks, indice) && !isWeekRange && (appointment.name +": " +appointment.sessionTypeName)}
      {!displayAll && !hideName(appointment, blocks, indice) && !isWeekRange && (appointment.name +": " +appointment.sessionTypeName)}
      {hideName(appointment, blocks, indice) && ("\u00a0")}
    </div>
  );
}

DraggableBlock.propTypes = {
  appointment: PropTypes.object,
  isWeekRange: PropTypes.bool,
  displayAll: PropTypes.bool,
  blocks: PropTypes.array,
  indice: PropTypes.number,
  type: PropTypes.string,
  customClass: PropTypes.string,
};
export default DraggableBlock;