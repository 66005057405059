import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, Redirect } from "react-router-dom";
import {
  singleMenuOptions,
  bookingMenuOptions,
  directorsMenuOptions,
  managementMenuOptions,
} from "../config/constants.js";
import Select from "react-select";
import logo from "../logo.png";
import "../styles/bootstrap-grid.min.css";
import "../styles/global.css";
import "../App.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";

function NavBar({ appState, setAppState }) {
  const [menuOptions, setMenuOptions] = useState([]);
  const [authType, setAuthType] = useState("");
  const [authRole, setAuthRole] = useState("");
  const [sites, setSites] = useState([]);

  useEffect(() => {
    let menuOptions = "";
    const type = appState.authType;

    if (type === "director") {
      menuOptions = appState.managementMode ? managementMenuOptions : directorsMenuOptions;
      setSites(
        appState.directorsAuth.locationsAuth.map((el) => {
          return {
            value: el.siteLocation,
            label: el.siteName,
          };
        })
      );
    } else if (type === "booking") {
      menuOptions = bookingMenuOptions;
      setSites(appState.bookingAuth.sitesSelect);
    } else if (type === "single") {
      menuOptions = singleMenuOptions;
      setAuthRole(appState.singleAuth.role);
    }
    setAuthType(type);
    setMenuOptions(menuOptions);
  }, []);

  useEffect(() => {
    if (sites.length > 0 && authType === "director") {
      handleLocationChange(sites[0]);
    }
  }, [sites]);

  const logOut = async () => {
    if (appState.nav.status !== "logingOut") {
      try {
        setAppState((appState) => ({
          ...appState,
          nav: {
            ...appState.nav,
            status: "logingOut",
          },
        }));
        const logOutMethod = {
          method: "DELETE",
          headers: {
            siteid: appState.nav.siteId,
            authorization: appState.singleAuth.token,
          },
        };
        const logOutResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/userToken/revoke`,
          logOutMethod
        );
        const data = await logOutResponse.json();
        if (logOutResponse.ok) {
          setAppState((appState) => ({
            ...appState,
            authType: "",
            nav: {
              status: "loading",
              message: "",
              siteId: null,
              locationId: null,
              siteName: "",
              redirect: false,
            },
            singleAuth: {
              token: null,
              userId: null,
              userName: null,
              role: null,
              allowedPermissions: null,
            },
            bookingAuth: {
              status: "",
              message: "",
              authorizationTokens: [],
            },
            directorsAuth: {
              status: "",
              message: "",
              authorizationTokens: [],
              locationsAuth: [],
              siteId: "",
              locationId: "",
              siteName: "",
              token: "",
            },
          }));
        } else {
          setAppState((appState) => ({
            ...appState,
            nav: {
              ...appState.nav,
              status: "logOutError",
              message: "Request Error: " + JSON.stringify(data),
            },
          }));
        }
      } catch (error) {
        setAppState((appState) => ({
          ...appState,
          nav: {
            ...appState.nav,
            status: "error",
            message: "Logout Error: " + JSON.stringify(error.message),
          },
        }));
      }
    }
  };

  const multiLogOut = async () => {
    const singleLogout = async ({ siteId, token }) => {
      const logOutMethod = {
        method: "DELETE",
        headers: {
          siteid: siteId,
          authorization: token,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/userToken/revoke`,
        logOutMethod
      );
      if (response.ok) {
        return await response.json();
      } else {
        return Promise.reject(
          new Error("Failed to log out against " + siteId.toString())
        );
      }
    };

    const auths = appState.directorsAuth.authorizationTokens;

    try {
      const logoutAttempts = auths.map((auth) => {
        const siteId = auth.siteId;
        const token = auth.auth.accesssToken;
        return singleLogout({ siteId: siteId, token: token });
      });
      await Promise.all(logoutAttempts);

      setAppState((appState) => ({
        ...appState,
        authType: "",
        directorsAuth: {
          ...appState.directorsAuth,
          authorizationTokens: [],
        },
        nav: {
          ...appState.nav,
          status: "MultilogedOut",
        },
      }));
    } catch (e) {
      console.error(e);
      setAppState((appState) => ({
        ...appState,
        nav: {
          ...appState.nav,
          message: "There was an error while login out",
          status: "error",
        },
      }));
    }
  };

  const handleLocationChange = (selectedOption) => {
    const siteId = selectedOption.value.split("-")[0];
    const locationId = selectedOption.value.split("-")[1];
    const siteName = selectedOption.label;
    const authObject = appState.directorsAuth.authorizationTokens.find(
      (el) => el.siteId.toString() === siteId
    );
    setAppState((appState) => ({
      ...appState,
      nav: {
        ...appState.nav,
        siteId: siteId,
        locationId: locationId,
        siteName: siteName,
      },
      directorsAuth: {
        ...appState.directorsAuth,
        siteId: siteId,
        locationId: locationId,
        siteName: siteName,
        token: authObject.auth.accesssToken,
      },
      singleAuth: {
        ...appState.singleAuth,
        token: authObject.auth.accesssToken,
        userId: authObject.auth.userId,
        userName: authObject.auth.userName,
        role: authObject.auth.userRol,
      },
    }));
  };

  const handleBookingLocationChange = (selectedOption) => {
    if('label' in appState.bookingAuth.selectedSite){
      setAppState((appState) => ({
        ...appState,
        bookingAuth: {
          ...appState.bookingAuth,
          selectedSite: selectedOption,
        },
      }));
    }
    else{
      setAppState((appState) => ({
        ...appState,
        bookingAuth: {
          ...appState.bookingAuth,
          selectedSite: selectedOption,
          prevSelectedSite: selectedOption,
        },
      }));
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-light bg-transparent pb-3">
        <div className="container-fluid">
          {/* Brand Logo button for returning home */}
          <div className="col-9 col-md-4">
            {(authType === "director" || authType === "booking") && (
              <div className="d-flex">
                <Link
                  className="my-auto lb-navbar-nav w-25"
                  to={
                    authType === "single"
                      ? "/appointments"
                      : authType === "director"
                      ? (appState.managementMode ? "/managementDashboard" : "/operationaldashboard")
                      : "/booking"
                  }
                >
                  <img src={logo} width="100%" alt="Little Bellies Logo"></img>
                </Link>
                {authType === "director" && (
                  <Select
                    options={sites}
                    isSearchable={true}
                    onChange={(selectedOption) => {
                      handleLocationChange(selectedOption);
                    }}
                    placeholder="Select a location"
                    defaultValue={sites[0]}
                    className="w-100 smaller-text my-auto ml-2"
                  />
                )}
                {authType === "booking" && (
                  <Select
                    options={sites}
                    isSearchable={true}
                    onChange={(selectedOption) => {
                      handleBookingLocationChange(selectedOption);
                    }}
                    value={appState.bookingAuth.selectedSite}
                    placeholder="Select a location"
                    className="w-100 smaller-text my-auto ml-2"
                  />
                )}
              </div>
            )}
            {authType !== "director" && authType !== "booking" && (
              <Link
                className="my-auto d-flex lb-navbar-nav lb-siteName-noHover"
                to={
                  authType === "single"
                    ? "/appointments"
                    : authType === "director"
                    ? "/operationaldashboard"
                    : "/booking"
                }
              >
                <img src={logo} width="25%" alt="Little Bellies Logo"></img>
                <span className="my-auto lb-nav-link">
                  {appState.nav.siteName}
                </span>
              </Link>
            )}
          </div>

          {/* Responsive burger menu */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Menu Items */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 lb-navbar-nav">
              {/* Menu Items Loop */}
              {menuOptions.map((option, index) => {
                // For single Items
                if (option.type === "single") {
                  if (
                    !option.restricted ||
                    (option.restricted && (authRole === "Management" || authRole === ""))
                  ) {
                    const userName = appState.singleAuth.userName;
                    let foundUser = false;
                    if (option.users !== null) {
                      foundUser = option.users.find(
                        (user) => user === userName
                      );
                      if (foundUser !== undefined) {
                        return (
                          <li className="nav-item" key={index}>
                            <Link
                              className="lb-nav-link nav-link"
                              data-cy={option.dataCy}
                              to={option.link}
                            >
                              {option.label}
                            </Link>
                          </li>
                        );
                      } else {
                        return "";
                      }
                    } else {
                      return (
                        <li className="nav-item" key={index}>
                          <Link
                            className="lb-nav-link nav-link"
                            data-cy={option.dataCy}
                            to={option.link}
                          >
                            {option.label}
                          </Link>
                        </li>
                      );
                    }
                  } else {
                    return "";
                  }
                }
                // For Dropdown Items
                else {
                  return (
                    <li className="nav-item dropdown" key={index}>
                      <a
                        className="lb-nav-link nav-link dropdown-toggle"
                        href={option.link}
                        id="navbarDropdown"
                        data-cy={option.dataCy}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {option.label}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        {option.items.map((item, index) => {
                          if( !item.restricted || (item.restricted && authRole === "Management")){
                            return (
                              <li key={index}>
                                <Link
                                  className="lb-nav-link dropdown-item"
                                  data-cy={item.dataCy}
                                  to={item.link}
                                >
                                  {item.label}
                                </Link>
                              </li>
                            );
                          }
                          else{
                            return <></>
                          }
                        })}
                      </ul>
                    </li>
                  );
                }
              })}

              {/* Logout button */}
              <li className="nav-item my-auto">
                <button
                  className="lb-logoutButton my-auto lb-greenText"
                  data-cy="logout-nav"
                  onClick={authType === "single" ? logOut : multiLogOut}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {appState.nav.status === "error" && (
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <span
                className="mt-1 mx-auto mb-3 lb-form-submited-error"
                data-cy="logout-error"
              >
                {appState.nav.message}
              </span>
            </div>
          </div>
        </div>
      )}

      {appState.nav.status === "logedOut" && (
        <Redirect to={`/login/${appState.nav.siteId}`} />
      )}

      {appState.nav.status === "MultilogedOut" && (
        <Redirect to={`/loginmenu`} />
      )}
    </>
  );
}

NavBar.propTypes = {
  appState: PropTypes.object.isRequired,
  setAppState: PropTypes.func.isRequired,
};
export default NavBar;
