// import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const { S3Client, PutObjectCommand, ListObjectsCommand, DeleteObjectCommand  } = require("@aws-sdk/client-s3");

// Set the AWS Region
const REGION = "us-east-2"; // REGION
const albumPhotosKey = "client_pictures/";
// Initialize the Amazon Cognito credentials provider
const s3 = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: "us-east-2:cf6ad14e-0c13-4dab-bfeb-a171931d18fa", // IDENTITY_POOL_ID
  }),
});

const albumBucketName = "littlebellies-landing.storage"; // BUCKET_NAME

// const aws = require('aws-sdk');
// const AWS_ACCESS_KEY = "AKIAZVNPBUVTXPBPIRPB";
// const AWS_SECRET_KEY = "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"
// aws.config.update({
//     accessKeyId: AWS_ACCESS_KEY,
//     secretAccessKey: AWS_SECRET_KEY
// });

// var albumBucketName = "BUCKET_NAME";
// var bucketRegion = "REGION";
// var IdentityPoolId = "IDENTITY_POOL_ID";

// AWS.config.update({
//   region: bucketRegion,
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: IdentityPoolId
//   })
// });

// const s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: { Bucket: albumBucketName }
// });
    export const getAlbum = async (appointmentid) => {
        const data = await s3.send(
            new ListObjectsCommand({
              Prefix: albumPhotosKey + appointmentid + '/',
              Bucket: albumBucketName,
            })
        );
        return data;
    };

    export const remove = async (file, appointmentid) => {
        
      // const s3 = new aws.S3();
      const photoKey = file;
      const uploadParams = {
          Bucket: albumBucketName,
          Key: photoKey,
         // Body: file,
          // ACL: 'public-read'
          // Expires: 60,
          // ContentType: filetype
      };
      console.log(uploadParams);
      try {
      await s3.send(new DeleteObjectCommand(uploadParams));
      return {message: "Successfully remove photo."}
      } catch (err) {
          return {message: "There was an error uploading your photo", error: err.message};
      }
      
      // const command = new GetObjectCommand(uploadParams);
      // console.log(command);
      // // const data = await s3.send(
      // //     new ListObjectsCommand({ Delimiter: "/", Bucket: albumBucketName })
      // // );
      // console.log("data:");
      // console.log(data);
      // const result = await getSignedUrl(s3, command, { expiresIn: 3600 });
      // console.log(result)
      // return result;
  }

    export const sign = async (file, appointmentid) => {
        
        // const s3 = new aws.S3();
        const photoKey = albumPhotosKey + appointmentid + '/' + file.name;
        const uploadParams = {
            Bucket: albumBucketName,
            Key: photoKey,
            Body: file,
            ACL: 'public-read'
            // Expires: 60,
            // ContentType: filetype
        };
        // console.log(params);
        try {
        await s3.send(new PutObjectCommand(uploadParams));
        return {message: "Successfully uploaded photo."}
        } catch (err) {
            return {message: "There was an error uploading your photo", error: err.message};
        }
        
        // const command = new GetObjectCommand(uploadParams);
        // console.log(command);
        // // const data = await s3.send(
        // //     new ListObjectsCommand({ Delimiter: "/", Bucket: albumBucketName })
        // // );
        // console.log("data:");
        // console.log(data);
        // const result = await getSignedUrl(s3, command, { expiresIn: 3600 });
        // console.log(result)
        // return result;
    }
