import React from 'react'
import { ItemTypes } from './ItemTypes'
import { useDrop } from 'react-dnd'
import { PropTypes } from "prop-types";

function DroppableSidebar({ updateFunction }) {
  const [{canDrop }, drop] = useDrop(() => ({
    accept: ItemTypes.APPOINTMENT,
    drop: (item, monitor) => {
      updateFunction(item)},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    > &nbsp;
      {canDrop && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
          className="text-center"
        >
        Drop appointment here
        </div>
      )}
    </div>
  );
}

DroppableSidebar.propTypes = {
  block: PropTypes.object,
  updateFunction: PropTypes.func,
};
export default DroppableSidebar