import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import logo from "../logo.png";
import { PropTypes } from "prop-types";
import * as crypto from "crypto-js";

const createRequest = (method, headers, body) => {
  return {
    method: method,
    headers: headers,
    body: body,
  };
};

function BookingLoginPage({ appState, setAppState }) {
  const [state, setState] = useState({
    status: "idle",
  });
  const [Username, setUserName] = useState("");
  const [Password, setPassword] = useState("");

  const getSites = async () => {
    let sites;

    const getSitesQuery = createRequest("GET", {
      "Content-type": "application/json; charset=UTF-8",
    });
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/sites/sites/`,
      getSitesQuery
    );
    if (response.ok) {
      sites = await response.json();
      return sites;
    } else {
      return Promise.reject(
        new Error("Failed to retrieve the sitelist from /api/sites/sites")
      );
    }
  };

  const multiLogin = async ({ Username, Password, sites }) => {
    const singleLogin = async ({ Username, Password, siteId, siteName }) => {
      const payload = {
        Username: Username,
        Password: Password,
      };
      const key = process.env.REACT_APP_ENCRYPTION_KEY;
      const encrypted = crypto.AES.encrypt(
        JSON.stringify(payload),
        key
      ).toString();
      const body = { data: encrypted };
      const putMethod = createRequest(
        "PUT",
        {
          "Content-type": "application/json; charset=UTF-8",
          siteid: siteId,
        },
        JSON.stringify(body)
      );

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/userToken/`,
        putMethod
      );
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData.userRol.trim());
        if (responseData.userRol.toLowerCase().trim() === "technicians") {
          return Promise.resolve({
            error:
              "Failed to authenticate against " +
              siteName +
              " " +
              siteId +
              " NOT AUTHORIZED",
            siteId: siteId,
            siteName: siteName,
          });
        } else {
          const data = {
            auth: responseData,
            siteId: siteId,
            siteName: siteName,
          };
          return Promise.resolve(data);
        }
      } else {
        return Promise.resolve({
          error: "Failed to authenticate against " + siteName + " " + siteId,
          siteId: siteId,
          siteName: siteName,
        });
      }
    };

    const authAttemps = sites.map((site) =>
      singleLogin({
        Username: Username,
        Password: Password,
        siteId: site.id,
        siteName: site.name,
      })
    );
    const authResults = await Promise.all(authAttemps);
    const successfulAuths = authResults.filter((item) => {
      return "auth" in item;
    });
    return Promise.resolve(successfulAuths);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (state.status === "loading") {
      return;
    }

    setState((state) => ({
      ...state,
      status: "loading",
      message: "Loggin in",
    }));
    try {
      const sites = await getSites();

      const auths = await multiLogin({
        Username: Username,
        Password: Password,
        sites: sites,
      }).catch((error) => {
        console.log(error);
      });

      const getAllLocations = async () => {
        const getMethod = createRequest("GET", {
          "Content-type": "application/json; charset=UTF-8",
          siteid: auths[0].siteId,
          authorization: auths[0].auth.accesssToken,
        });
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/sites/all/locations/`,
          getMethod
        );
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(
            new Error(
              "Failed to get location lists from: /api/sites/all/locations/"
            )
          );
        }
      };
      const locations = await getAllLocations();
      const sitesForSelect = [];
      const sitesAuth = locations.siteslist
        .filter((site) => site.id !== "0-0")
        .map((site) => {
          const siteId = site.id.split("-")[0];
          const locationId = site.id.split("-")[1];
          const siteName = site.name;
          const authObject = auths.find(
            (el) => el.siteId.toString() === siteId
          );
          if (authObject !== undefined) {
            const siteForSelect = {
              value: `${siteId}|${locationId}`,
              label: siteName,
            };
            sitesForSelect.push(siteForSelect);
            return {
              token: authObject.auth.accesssToken,
              siteId: siteId,
              locationId: locationId,
              siteName: siteName,
              userId: authObject.auth.userId,
              userName: authObject.auth.userName,
              userRol: authObject.auth.userRol,
              status: "OK",
            };
          } else {
            return {
              token: null,
              siteId: siteId,
              locationId: locationId,
              siteName: siteName,
              siteLocation: site.id,
            };
          }
        });

      setState((state) => ({
        ...state,
        status: "success",
        message: "Success. You will be redirected soon.",
      }));

      setAppState((appState) => ({
        ...appState,
        authType: "booking",
        bookingAuth: {
          ...appState.bookingAuth,
          status: "redirect",
          authorizationTokens: sitesAuth.filter((el) => el.token !== null),
          locationsAuth: sitesAuth.filter((el) => el.token !== null),
          sitesSelect: sitesForSelect,
        },
      }));
    } catch (e) {
      console.error(e);
      setAppState((appState) => ({
        ...appState,
        bookingAuth: {
          ...appState.bookingAuth,
          authorizationTokens: [],
        },
      }));
      setState((state) => ({
        ...state,
        status: "failed",
        message: "You failed to authenticate in all sites",
      }));
    }
  };

  return (
    <>
      {appState.bookingAuth.status === "redirect" && (
        <>
          <Redirect push to="/booking" />
        </>
      )}
      <div className="content">
        <div className="container mt-3">
          <div className="row ">
            <div className="col-4 col-md-3 col-lg-2 mx-auto">
              <nav className="lb-navbar">
                <a className="lb-navbar-brand" href="/">
                  <img src={logo} width="100%" alt="Little Bellies Logo"></img>
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-4 px-5">
            <div className="col-6 my-auto">
              <h4 className="mx-5 lb-font-bold">Login</h4>
            </div>
            <div className="col-6 my-auto lb-text-right"></div>
          </div>
          <div className="justify-content-center">
            <div className="row">
              <div className="col-6 mx-auto lb-text-center">
                <input
                  data-cy="user-input"
                  type="text"
                  className="lb-form-control lb-border-0 my-3 lb-inputBackground"
                  placeholder="Username"
                  value={Username}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <input
                  data-cy="password-input"
                  type="password"
                  className="lb-form-control lb-border-0 my-3 lb-inputBackground"
                  placeholder="Password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {state.status === "failed" && (
                  <div
                    className="mt-1 mx-auto mb-3 lb-form-submited-error"
                    data-cy="login-error"
                  >
                    <span>{state.message}</span>
                  </div>
                )}
                {state.status === "success" && (
                  <div className="mt-1 mx-auto mb-3 lb-form-submited-success">
                    <span>{state.message}</span>
                  </div>
                )}
                {state.status !== "loading" ? (
                  <button
                    className="lb-btn lb-submitButton lb-rounded-0 py-2 px-3"
                    data-cy="submit-login-input"
                    onClick={handleLogin}
                  >
                    <span className="text-lb-numberButton mx-4 my-2">
                      LOGIN
                    </span>
                  </button>
                ) : (
                  <span className="text-lb-numberButton mx-4 my-2">
                    <FontAwesomeIcon spin icon={faSpinner} />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

BookingLoginPage.propTypes = {
  appState: PropTypes.object.isRequired,
  setAppState: PropTypes.func.isRequired,
};
export default BookingLoginPage;
