import React from "react";
import PropTypes from 'prop-types';
import logo from "../logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMobile,faEnvelope,} from "@fortawesome/free-solid-svg-icons";

const RequestOtpCode = ({
msgData,
formData,
setState,
state
}) =>{

    const requestOtpCode = (target) => async (event) => {
        event.preventDefault();
    
        setState((state) => ({
          ...state,
          otpTarget: target,
          requestOtpStatus: "validating",
        }));
        try {
          const payload = {
            email: target === "email",
            clientId: "" + formData.client_id,
            appointmentId: "" + state.appointmentId,
            contact:
              target === "email" ? formData.email : parseInt(formData.phone_cel.replace(/[^0-9.]+/g, '')),
          };
    
          const requestCode = {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              siteid: state.siteId,
            },
            body: JSON.stringify(payload),
          };
    
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/oneTimePassword/generate`,
            requestCode
          );
          const data = await response.json();
          if (response.ok) {
            setState((state) => ({
              ...state,
              requestOtpStatus: "",
              status: "verifyOtpCode",
            }));
          } else {
            setState((state) => ({
              ...state,
              requestOtpStatus: "error",
              message: JSON.stringify(data),
            }));
          }
        } catch (error) {
          setState((state) => ({
            ...state,
            requestOtpStatus: "error",
            message: "Error: " + JSON.stringify(error.message),
          }));
        }
      };

return(

    <div className="lb-container-vcenter container mt-5">
    <div className="row">
      <div className="col lb-text-center">
        <img src={logo} alt="little bellies" width="125" />
        <h1 className="lb-text-title">Welcome to {msgData.siteName}</h1>
        <h3 className="lb-w-60 mx-auto">
          To continue with the checkin form process we will send you a
          secret code to your email or phone
        </h3>
      </div>
    </div>
    <div className="row">
      <div className="col lb-text-center">
        <div className="lb-form-group otpTargetSelect mx-auto">
          <div className="lb-text-left mb-2">
            Where would you like to recieve the code
          </div>
        </div>
      </div>
    </div>
    <br />
    <div className="row lb-tokenTargetRow justify-content-center">
      {formData.phone_cel !== "" && formData.phone_cel !== null && (
        <div className="col col-md-6 lb-text-center">
          <button
            onClick={requestOtpCode("phone")}
            className="lb-otpButton lb-text-center"
            data-cy="otpTargetPhone"
          >
            <FontAwesomeIcon className="lb-card-icon" icon={faMobile} />
            <br />
            <span className="lb-card-tittle">Get it on your phone</span>
            <br />
            <span className="lb-card-info">
              {formData.phone_cel}
            </span>
          </button>
        </div>
      )}
      {formData.email !== "" && formData.email !== null && (
        <div className="col col-md-6 lb-text-center">
          <button
            onClick={requestOtpCode("email")}
            className="lb-otpButton lb-text-center"
            data-cy="otpTargetMail"
          >
            <FontAwesomeIcon
              className="lb-card-icon"
              icon={faEnvelope}
            />
            <br />
            <span className="lb-card-tittle">Get it on your email</span>
            <br />
            <span className="lb-card-info">
              {formData.email}
            </span>
          </button>
        </div>
      )}
    </div>
    {state.requestOtpStatus === "error" && (
      <span
        className="lb-container-center"
        data-cy="requestOtpErrorMessage"
      >
        <div className="lb-form-submited-warning mt-2">
          Whoops an error has occurred. <br />
          <span>{state.message}</span>
        </div>
      </span>
    )}
  </div>


);
};

RequestOtpCode.propTypes = {
msgData : PropTypes.object,
formData: PropTypes.object,
setState: PropTypes.func,
state: PropTypes.object

};

export default RequestOtpCode;
