import React from "react";
import { PropTypes } from "prop-types";
import { useDrag } from 'react-dnd';
import { ItemTypes } from './ItemTypes'

function DraggableSidebar({ appointment}) {
  const [{isDragging}, drag] = useDrag(() => ({
    type: ItemTypes.APPOINTMENT,
    item: appointment,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      {appointment.name +": " +appointment.sessionTypeName}
    </div>
  );
}

DraggableSidebar.propTypes = {
  appointment: PropTypes.object,
};
export default DraggableSidebar;