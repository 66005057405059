import React from "react";
import { PropTypes } from "prop-types";
import { Bar } from "react-chartjs-2";

const BarChart = ({ labels, values, maxHeight }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Appointments",
        data: values,
        borderColor: "#720b98",
        backgroundColor: "#c2608e",
        maxBarThickness: 50,
        barThickness: 40,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        max: maxHeight,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
  };

  return <Bar options={options} data={data} />;
};
BarChart.propTypes = {
  labels: PropTypes.array,
  values: PropTypes.object,
  maxHeight: PropTypes.number,
};
export default BarChart;
