import "../styles/bootstrap-grid.min.css";
import React from "react";
import "../styles/global.css";
import "../App.css";
import { ExpandMore } from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { PropTypes } from "prop-types";
import { questions, adminQuestionsOnly } from "../config/constants.js"

function FaqPage({ appState }) {
  
  let questionsDisplay = questions;

  if (appState.singleAuth.role === "Directiva") {
    questionsDisplay = questions.concat(adminQuestionsOnly);
  }

  return (
    <div className="content">
      <div>
        <div className="container mt-3 pb-4">
          {questionsDisplay.map((category) => {
            return (
              <div key={category.id} className="row mx-0 mx-md-2 mx-lg-0 mb-4">
                <div className="col-12">
                  <h2>{category.categoryName}</h2>
                </div>
                {category.items.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="col-12 col-md-12 col-lg-6 mb-2"
                    >
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <b className="lb-accordion-text">{item.question}</b>
                        </AccordionSummary>
                        <AccordionDetails className="lb-answerBackground">
                          <span className="lb-accordion-answer">
                            {item.answer}
                          </span>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

FaqPage.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default FaqPage;
