import React from "react";
import { PropTypes } from "prop-types";
import { Pie } from "react-chartjs-2";

const PieChart = ({ labels, values, colors, borders }) => {
  const data = {
    
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: values,
        backgroundColor: colors,
        borderColor: borders,
        borderWidth: 1,
        radius: 100,
      },
    ],
  };

  function dataOptions(values) {
    const options = {
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "start",
        },
      },
      options: {
        maintainAspectRatio: false,
      },
    };
    return options;
  }

  return (
    <>
      <Pie data={data} options={dataOptions(data)} />
    </>
  );
};
PieChart.propTypes = {
  labels: PropTypes.array,
  values: PropTypes.array,
  colors: PropTypes.array,
  borders: PropTypes.array,
};
export default PieChart;
