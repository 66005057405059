import React from "react";
import { PropTypes } from "prop-types";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import logo from "../../logo.png";
import { formatAmount } from "../../util/formatNumbers.js";

const DynamicReportPdf = ({ headers, bodyTable, reportDate, footTable,tfoot, titlePdf, subTitlePdf}) => {
  const borderColor = '#bfbfbf';
  const borderStyle = 'solid';
  const widthStyle = "28%";

  const styles = StyleSheet.create({
    body: {
      padding: 10,
    },
    table: {
      display: "table",
      width: "100%",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row"
    },
    tableCol1Header: {
      width: widthStyle,
      borderStyle: borderStyle,
      borderColor: borderColor,
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#c2608ea9',
      color: "white",
      textAlign: "center",
    },
    tableCol1: {
      width: widthStyle,
      borderStyle: borderStyle,
      borderColor: borderColor,
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "center",
    },
    total: {
      width: widthStyle,
      borderWidth: 1,
      borderTopWidth: 0,
      textAlign: "center",
    },
    totalCol: {
      width: widthStyle,
    },
    tableCellHeader: {
      margin: 5,
      fontSize: 12,
      fontWeight: 500
    },
    tableCell: {
      margin: 5,
      fontSize: 10
    },
    logo: {
      width: 70,
      marginTop: 10,
      marginBottom: 20,
      heigth: 50,
      marginLeft: 10,
    },
    dateText: {
      marginTop: "30%",
      fontSize: 12,
      fontWeight: 500,
      marginLeft: "13%"
    },
    titleText: {
      fontSize: 15,
      fontWeight: 500,
      marginBottom: subTitlePdf ? "2%" : "27%"
    },
    subTitleText: {
      fontSize: 12,
      fontWeight: 497,
      marginBottom: "10%"
    },
    dateTextDiv: {
      width: "15%",
    },
    divOneTittle: {
      width: "37%",
    },
    divTwoTittle: {
      width: "30%",
    },
    divThreeTittle: {
      width: "30%",
    },
    divOneSubTittle: {
      width: "25%",
    },
    divTwoSubTittle: {
      width: "25%",
    },
    divThreeSubTittle: {
      width: "25%",
    },
    logoDiv: {
      width: "78%",
    }
  });
  return (
    <Document>
      <Page size={"A4"} style={styles.body}>

        <View style={styles.table}>
          <View style={styles.tableRow}>

            <View style={styles.logoDiv}>
              <Image src={logo} style={styles.logo} alt="Little Bellies Logo" />
            </View>
            <View style={styles.dateTextDiv}>
              <Text style={styles.dateText}>Date: {reportDate}</Text>
            </View>

          </View>
        </View>


        {titlePdf && (
          <View style={styles.table}>
          <View style={styles.tableRow}>

            <View style={styles.divOneTittle}>
            <Text style={styles.titleText}></Text>
            </View>
            <View style={styles.divTwoTittle}>
            <Text style={styles.titleText}>{titlePdf}</Text>
            </View>
            <View style={styles.divThreeTittle}>
            <Text style={styles.titleText}></Text>
            </View>
          </View>
        </View>
        )}

        {subTitlePdf && (
          <View style={styles.table}>
          <View style={styles.tableRow}>

            <View style={styles.divOneSubTittle}>
            <Text style={styles.titleText}></Text>
            </View>
            <View style={styles.divTwoSubTittle}>
            <Text style={styles.subTitleText}>{subTitlePdf}</Text>
            </View>
            <View style={styles.divThreeSubTittle}>
            <Text style={styles.titleText}></Text>
            </View>
          </View>
        </View>
        )}


        <View style={styles.table}>
        <View style={styles.tableRow}>
          {headers.map((header, i) => {
            return (
                <View style={styles.tableCol1Header} key={i}>
                  <Text style={styles.tableCellHeader}>{header}</Text>
                </View>
            )
          })}
          </View>
          {bodyTable.map((data, index) => {
            const value = Object.values(data);
            return (
              <View style={styles.tableRow} key={index}>
              {value.map((e, i) => {
                return (
                  typeof e !== 'boolean' && (
                  <View style={styles.tableCol1} key={i}>
                  <Text style={styles.tableCell}>{e}</Text>
                </View>
                  )
                )
              })}
              </View>
            )
          })}
          {tfoot && (
          <View style={styles.tableRow}>
            {footTable.map((e,i) => {
              return(
              e.show === false ?
              <View style={styles.totalCol} key={i}>
                <Text style={styles.tableCell}></Text>
              </View>
              :
              <View style={styles.total} key={i}>
               <Text style={styles.tableCell}>
               {e.amount === false ? 
                <>{e.value}</>
                :
                <>${formatAmount(e.value)}</>
                }
                </Text>
              </View>
              )
            })}
          </View>
          )}
        </View>
      </Page>
    </Document>)
};

DynamicReportPdf.propTypes = {
  headers: PropTypes.array,
  bodyTable: PropTypes.array,
  reportDate: PropTypes.string,
  footTable: PropTypes.array,
  tfoot: PropTypes.bool,
  titlePdf: PropTypes.string,
  subTitlePdf: PropTypes.string
};
export default DynamicReportPdf;
