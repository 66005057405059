import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { formatDate2string } from "../util/index.js";
import DatePicker from "react-datepicker";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import axios from "axios";

function IncompleteAppointments({ appState, setAppState }) {
  const today = new Date();
  const [hoverIndex, setHoverIndex] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [locations, setLocations] = useState([]);
  const [selection, setSelection] = useState({});
  const [includeAppointments, setIncludeAppointments] = useState(false);
  const [state, setState] = useState({
    message: "",
    status: "ready",
    success: false,
    show: false,
  });

  const getLocations = async () => {
    try {
      const getMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: appState.nav.siteId,
          authorization: appState.singleAuth.token,
        },
      };
      const getResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sites/all/locations/`,
        getMethod
      );
      const locationData = await getResponse.json();

      if (getResponse.ok && getResponse.status === 200) {
        setLocations(locationData.siteslist);
        const firstSite = locationData.siteslist[0].id;
        const value = firstSite.split("-");
        const siteId = value[0];
        const locationId = value[1];
        setSelection((selection) => ({
          ...selection,
          siteId: siteId,
          locationId: locationId,
          locationName: locationData.siteslist[0].name,
        }));
      } else {
        setLocations([]);
        setState((state) => ({
          ...state,
          show: true,
          status: "ready",
          message: locationData.message ?? JSON.stringify(locationData),
          success: false,
        }));
      }
    } catch (error) {
      console.error(error);
      setLocations([]);
      setState((state) => ({
        ...state,
        show: true,
        status: "ready",
        message: JSON.stringify(error),
        success: false,
      }));
    }
  };

  const handleLocationChange = (event) => {
    let { value } = event.target;
    const locationItem = locations.find((item) => item.id === value);
    value = value.split("-");
    const siteId = value[0];
    const locationId = value[1];

    setSelection((selection) => ({
      ...selection,
      siteId: event.target.value === "0-0" ? "0" : siteId,
      locationId: locationId,
      locationName: locationItem.name,
    }));
  };

  const handleButtonDownloadReport = async () => {
    try {
      setState((state) => ({
        ...state,
        status: "loading",
      }));
      const date = moment(startDate).format("YYYYMMDD").toString();
      const dateName = moment(startDate).format("YYYY-MM-DD").toString();
      const siteId = selection.siteId;
      const booked = includeAppointments;
      const requestSCV = `api/incomplete/booking?date=${date}&siteId=${siteId}&booked=${booked}&format=excel`;

      // Inicio: Axios
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/${requestSCV}`,
        responseType: "blob",
      })
        .then(function (response) {
          try {
            if (response.status === 200) {
              const url = window.URL.createObjectURL(response.data);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `incompletebooking-${selection.locationName}-${dateName}.xlsx`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
              setState((state) => ({
                ...state,
                show: true,
                status: "ready",
                message: "The report has been successfully downloaded",
                success: true,
              }));
            }
          } catch (errorE) {
            console.error(errorE);
            setState((state) => ({
              ...state,
              show: true,
              status: "ready",
              message:
                "The file couldn't be fetched: " + JSON.stringify(errorE),
              success: false,
            }));
          }
        })
        .catch(async (error) => {
          try {
            const { response } = error;
            const text = await response.data.text();
            const jsonResponse = await JSON.parse(text);
            setState((state) => ({
              ...state,
              show: true,
              status: "ready",
              message: jsonResponse.message ?? "No data available for download",
              success: false,
            }));
          } catch (errorE) {
            console.error(errorE);
            setState((state) => ({
              ...state,
              show: true,
              status: "ready",
              message: "The file couldn't be fetched: " + JSON.stringify(error),
              success: false,
            }));
          }
        });
      // Fin: Axios
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        show: true,
        status: "ready",
        message: "The file couldn't be fetched: " + JSON.stringify(error),
        success: false,
      }));
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setState((state) => ({
        ...state,
        show: false,
        message: "",
      }));
    }, 9000);
  }, [state.show]);

  return (
    <div data-cy="incomplete-appointments">
      <div className="container">
        <div className="mt-5 lb-title-daily d-flex justify-content-center">
          <h4>Incomplete Booking Report</h4>
        </div>

        <div className="mt-3 lb-title-daily d-flex justify-content-end">
          <h5>{formatDate2string(today)}</h5>
        </div>

        <div className="row mt-4 d-flex justify-content-center">
          <div className="col-12 col-md-3">
            <label className="ml-3">Site</label>
          </div>
          <div className="col-12 col-md-3">
            <label className="ml-5">Date</label>
          </div>
          <div className="col-12 col-md-3">
            <label
              className="ml-5"
              onMouseOver={() => setHoverIndex(true)}
              onMouseLeave={() => setHoverIndex(false)}
              style={{ cursor: "pointer" }}
            >
              Include Booked
            </label>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-3">
            <select
              data-cy="location-select"
              className="form-select"
              value={selection.siteId + "-" + selection.locationId}
              onChange={handleLocationChange}
            >
              {locations.map((option) => (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div
            className="col-12 ml-3 col-md-3 text-center"
            data-cy="start-date"
          >
            <DatePicker
              className="lb-datepicker-closeout col-10 ml-4"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              value={startDate}
            />
          </div>
          <div className="col-12 col-md-3">
            <div className="form-check form-switch">
              <input
                style={{
                  width: 80,
                  height: 25,
                  cursor: "pointer",
                }}
                data-cy="btn-switch-include-appoiments"
                onChange={(e) => setIncludeAppointments(e.target.checked)}
                className="form-check-input ml-3"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </div>

          <div className="row mt-5 d-flex justify-content-center">
            <div className="col-4 text-center">
              <button
                className={"lb-button-daily"}
                data-cy="btn-download-incomplete-appointments"
                onClick={handleButtonDownloadReport}
                disabled={state.status === "loading"}
              >
                {state.status === "loading" && (
                  <span
                    style={{
                      paddingLeft: 64,
                      paddingRight: 64,
                    }}
                  >
                    <FontAwesomeIcon
                      spin
                      icon={faSpinner}
                      style={{ fontSize: 20 }}
                    />
                  </span>
                )}

                {state.status === "ready" && <span>Download Report.</span>}
              </button>
            </div>
          </div>

          <div
            className={
              hoverIndex
                ? "lb-preview-info-card-visible col-3"
                : "lb-preview-card"
            }
            style={{ fontSize: 13 }}
          >
            Check all incomplete appointments even if the client booked later
          </div>

          {state.show && (
            <div
              data-cy="alert-message"
              className={
                state.success
                  ? "alert alert-success mt-5 col-6 text-center"
                  : "alert alert-danger mt-5 col-6 text-center"
              }
              role="alert"
            >
              {state.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

IncompleteAppointments.propTypes = {
  appState: PropTypes.object,
  setAppState: PropTypes.func,
};

export default IncompleteAppointments;
