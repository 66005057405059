import "../styles/bootstrap-grid.min.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faTrash,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import "../styles/global.css";
import "../App.css";
import logo from "../logo.png";
import { useParams, useLocation, Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { PropTypes } from "prop-types";
import roundHalfEven from "round-half-even";
import { formatDate2object } from "../util";
import moment from "moment";

function CheckoutPage({ appState }) {
  const discountPercentage = 20;
  const [cart, setCart] = useState([]);
  const [additionals, setAdditionals] = useState([]);
  const [staff, setStaff] = useState([]);
  const [totalPurchase, setTotalPurchase] = useState({
    beforeTip: 0,
    afterTip: 0,
  });
  const [state, setState] = useState({
    status: "loading",
    message: "",
    dicomStatus: "IDLE",
    dicomMessage: "",
  });

  const { appointmentid } = useParams();
  const [formSubmited, setFormSubmited] = useState({
    status: "",
    message: "",
  });
  const [tip, setTip] = useState({
    amount: 0,
    staffId: "",
    value: 0,
  });
  const [summary, setSummary] = useState({
    subtotal: 0,
    discount: 0,
    tax: 0,
  });
  const [paymentMethods, setPaymentMethods] = useState({
    cash: 0,
    cashEnabled: false,
    creditCard: 0,
    creditCardEnabled: false,
    giftCard: 0,
    giftCardEnabled: false,
    giftCardBalance: 0,
    giftCardCode: "",
    status: "",
    message: "",
  });

  const [modalData, setModalData] = useState({
    itemId: "",
    discountReason: "",
  });

  const [receipt, setReceipt] = useState({
    enabled: true,
  });

  const handleReceiptCheckbox = (event) => {
    setReceipt((receipt) => ({
      ...receipt,
      enabled: !receipt.enabled,
    }));
  };

  const [checkoutStatus, setCheckoutStatus] = useState("not-ready");

  const [errorDiscount, showErrorDiscount] = useState(false);

  const withValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value <= discountPercentage) {
      showErrorDiscount(false);
      return inputObj;
    } else {
      showErrorDiscount(true);
    }
  };

  const withSumLimit = (onlinePrice) => (inputObj) => {
    const { value } = inputObj;
    if (value <= onlinePrice * (discountPercentage / 100)) {
      showErrorDiscount(false);
      return inputObj;
    } else {
      showErrorDiscount(true);
    }
  };

  const [show, setShow] = useState(false);

  const handleModalClose = (e) => {
    if (e.target.id === "modal-background") {
      const data = {
        itemId: "",
        discountReason: "",
      };
      setModalData(data);
      setShow(false);
    }
  };

  const handleModalOpen = (item) => () => {
    const reason = item.discountReason;
    const modal = {
      itemId: item.Id,
      discountReason: reason,
    };
    setModalData(modal);
    setShow(true);
  };

  const closeModal = () => {
    const data = {
      itemId: "",
      discountReason: "",
    };
    setModalData(data);
    setShow(false);
  };

  const giftCardValueLimit = (inputObj) => {
    const { value } = inputObj;
    if (value <= paymentMethods.giftCardBalance) return inputObj;
  };
  const location = useLocation();

  useEffect(() => {
    const getAppt = async () => {
      try {
        const getMethod = {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            siteid: appState.nav.siteId,
            authorization: appState.singleAuth.token,
            locationid: appState.nav.locationId,
          },
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/checkout/${appointmentid}`,
          getMethod
        );
        const data = await response.json();
        if (response.ok) {
          console.log(data);
          const filteredProducts = new Set();
          const arr = data.products;
          const filteredArr = arr.filter((el) => {
            const duplicate = filteredProducts.has(el.Id);
            filteredProducts.add(el.Id);
            return !duplicate;
          });
          const servicesArray = data.services.map((item) => {
            return {
              ...item,
              Id: item.ProductId,
              Name: item.Name,
              OnlinePrice: item.Price,
              multiple: false,
              qty: 1,
              removable: false,
              type: "Service",
              discount: 0,
              discountedPrice: item.Price,
              discountType: "percentage",
              taxedPrice: item.Price + item.Price * (item.TaxRate ?? 0),
              TaxRate: item.TaxRate ?? 0,
            };
          });
          const cartItem = servicesArray[0];
          setCart((cart) => [...cart, cartItem]);

          const newTotal =
            data.services[0].Price +
            data.services[0].Price *
              (data.services[0].Price * (data.services[0].TaxRate ?? 0));
          setTotalPurchase((totalPurchase) => ({
            ...totalPurchase,
            beforeTip: roundWithEpsilon(newTotal),
            afterTip: roundWithEpsilon(newTotal),
          }));

          const productsArray = filteredArr.map((item) => {
            return {
              ...item,
              OnlinePrice: item.Price,
              qty: 1,
              multiple: true,
              removable: true,
              type: "Product",
              discount: 0,
              discountedPrice: item.Price,
              discountType: "percentage",
              // TaxRate: taxRate,
              taxedPrice: item.Price + item.Price * item.TaxRate,
            };
          });
          setAdditionals(productsArray);
          setStaff(data.staffMembers);
          setState((state) => ({
            ...state,
            status: "ready",
          }));
        } else {
          setState((state) => ({
            ...state,
            status: "no-data-found",
            message: JSON.stringify(data),
          }));
        }
      } catch (error) {
        setState((state) => ({
          ...state,
          status: "error",
          message: "Onload page Error: " + JSON.stringify(error.message),
        }));
      }
    };
    getAppt();
  }, [
    appointmentid,
    appState.nav.locationId,
    appState.singleAuth.token,
    appState.nav.siteId,
  ]);

  const handleAddToCart = (Id) => (event) => {
    event.preventDefault();
    const item = additionals.find((item) => item.Id === Id);
    setCart((cart) => [...cart, item]);
    setAdditionals(additionals.filter((item) => item.Id !== Id));
  };

  const handleRemoveFromCart = (Id) => (event) => {
    event.preventDefault();
    const item = cart.find((item) => item.Id === Id);
    if (item.removable) {
      setAdditionals((additionals) => [...additionals, item]);
      setCart(cart.filter((item) => item.Id !== Id));
    }
  };

  const handleMinusQty = (Id) => (event) => {
    event.preventDefault();
    const items = [...additionals];
    const index = items.findIndex((item) => item.Id === Id);
    if (items[index].qty > 1) {
      items[index].qty = items[index].qty - 1;
    }
    setAdditionals(items);
  };

  const handlePlusQty = (Id) => (event) => {
    event.preventDefault();
    const items = [...additionals];
    const index = items.findIndex((item) => item.Id === Id);
    items[index].qty = items[index].qty + 1;
    setAdditionals(items);
  };

  const handleNumChange = (event) => {
    event.preventDefault();
  };

  const processMedia = async (dataInfo) => {
    const date = formatDate2object(new Date());

    const payload = {
      siteId: appState.nav.siteId,
      locationId: appState.nav.locationId,
      clientId: appointmentid,
      email: location.state.clientEmail,
      clientName: location.state.clientName,
      date: date.yyyy + date.mm + date.dd,
      sendMail: location.state.sendMail,
      files: location.state.files,
      staffName: dataInfo.staffName,
    };

    try {
      const targetURL = `api/dicom/images/${location.state.clientPhone}`;
      const postMethod = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: appState.nav.siteId,
          authorization: appState.singleAuth.token,
          locationid: appState.nav.locationId,
          allowedpermissions: appState.singleAuth.allowedPermissions,
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${targetURL}`,
        postMethod
      );
      if (!response.ok) {
        setState((state) => ({
          ...state,
          dicomStatus: "failed",
          dicomMessage: "Could not connect with DICOM server",
        }));
      }
    } catch (e) {
      setState((state) => ({
        ...state,
        dicomStatus: "error",
        dicomMessage: "DICOM Error: " + JSON.stringify(e),
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!(tip.value > 0 && (tip.staffId === "" || tip.staffId === "null"))) {
      setFormSubmited((formSubmited) => ({
        ...formSubmited,
        status: "sending",
      }));
      try {
        let payLoaddItems = cart;
        const tipItem = {
          Item: {
            Type: "Tip",
            Metadata: {
              StaffId: tip.staffId,
              Amount: tip.amount,
            },
          },
          Quantity: 1,
        };
        const cashPaymentItem = {
          Type: "Cash",
          Metadata: {
            Amount: paymentMethods.cash,
          },
        };
        const giftCardPaymentItem = {
          Type: "GiftCard",
          Metadata: {
            Amount: paymentMethods.giftCard,
            CardNumber: paymentMethods.giftCardCode,
          },
        };
        const creditCardPaymentItem = {
          Type: "Custom",
          Metadata: {
            Amount: paymentMethods.creditCard,
            Id: 17,
          },
        };
        const paymentItem = [];
        if (paymentMethods.cash !== 0 || totalPurchase.afterTip === 0) {
          paymentItem.push(cashPaymentItem);
        }
        if (paymentMethods.giftCard !== 0) {
          paymentItem.push(giftCardPaymentItem);
        }
        if (paymentMethods.creditCard !== 0) {
          paymentItem.push(creditCardPaymentItem);
        }

        if (tip.amount > 0) {
          payLoaddItems = cart.concat(tipItem);
        }

        const staffObject = staff.find(
          (item) => item.Id === Number.parseInt(tip.staffId)
        );

        const payload = {
          ClientId: location.state.clientId,
          totalAmount:
            tip.amount > 0 ? totalPurchase.afterTip : totalPurchase.beforeTip,
          tip: tip.amount,
          staffName: staffObject.Name,
          Items: payLoaddItems.map((item) => {
            if (item.type === "Service") {
              return {
                Item: {
                  Type: item.type,
                  Metadata: {
                    Id: item.Id,
                  },
                },
                AppointmentIds: [appointmentid],
                Quantity: item.qty,
                DiscountAmount:
                  (item.OnlinePrice - item.discountedPrice) * item.qty,
              };
            } else {
              if (item.type === "Product") {
                return {
                  Item: {
                    Type: item.type,
                    Metadata: {
                      Id: item.Id,
                    },
                  },
                  Quantity: item.qty,
                  DiscountAmount:
                    (item.OnlinePrice - item.discountedPrice) * item.qty,
                };
              } else {
                return tipItem;
              }
            }
          }),
          Payments: paymentItem,
          notes: location.state.notes,
          discount: roundWithEpsilon(summary.discount),
        };
        const putMethod = {
          method: "PUT",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            siteid: appState.nav.siteId,
            authorization: appState.singleAuth.token,
            locationid: appState.nav.locationId,
            allowedpermissions: appState.singleAuth.allowedPermissions,
          },
          body: JSON.stringify(payload),
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/checkout/${appointmentid}`,
          putMethod
        );

        const data = await response.json();
        if (response.ok) {
          if (location.state.programId !== 3) {
            const dataInfo = {
              staffName: staffObject.Name,
            };

            processMedia(dataInfo);
          }

          try {
            const discountReasons = cart
              .map((item) => {
                return item.discountReason;
              })
              .filter((discountReason) => discountReason)
              .join("-,-");

            const dynamoPayload = {
              appointmentId: parseInt(appointmentid),
              staffName: "" + staffObject.Name,
              tip: tip.value,
              totalPurchase: totalPurchase.afterTip,
              completed: moment(new Date()).toString(),
              cash: paymentMethods.cash,
              giftCard: paymentMethods.giftCard,
              creditCard: paymentMethods.creditCard,
              locationId: appState.nav.locationId,
              appointmentDate: location.state.startDateTime,
              discount: roundWithEpsilon(summary.discount),
              discountReasons: discountReasons,
              clientName: location.state.clientName,
              arrived: location.state.arrived,
              clientPhone: location.state.clientPhone,
              formFilled:
                parseInt(appointmentid) === location.state.YellowAlert,
            };
            console.log("put checkout", dynamoPayload);

            const putDynamo = {
              method: "PUT",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
              body: JSON.stringify(dynamoPayload),
            };
            const dynamoResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/api/dynamoDB/sites/${
                "" + appState.nav.siteId
              }/sales`,
              putDynamo
            );
            const dynamoData = await dynamoResponse.json();
            if (dynamoResponse.ok) {
              try {
                const clientPayload = {
                  Client: {
                    Id: location.state.clientId,
                    YellowAlert: "",
                  },
                  SendEmail: true,
                  CrossRegionalUpdate: false,
                  Test: false,
                };
                const clientPutMethod = {
                  method: "PUT",
                  headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    siteid: appState.nav.siteId,
                    authorization: appState.singleAuth.token,
                    locationid: appState.nav.locationId,
                  },
                  body: JSON.stringify(clientPayload),
                };
                const clientResponse = await fetch(
                  `${process.env.REACT_APP_API_URL}/api/clients/${location.state.clientId}`,
                  clientPutMethod
                );
                const clientData = await clientResponse.json();
                if (clientResponse.ok) {
                  if (receipt.enabled) {
                    const receiptOfPayment = {
                      method: "GET",
                      headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        siteid: appState.nav.siteId,
                        authorization: appState.singleAuth.token,
                        locationid: appState.nav.locationId,
                      },
                    };
                    const invoiceDate = moment(new Date().toString());
                    const receiptResponse = await fetch(
                      `${process.env.REACT_APP_API_URL}/api/clients/${location.state.clientId}/invoices?startDate=${invoiceDate}`,
                      receiptOfPayment
                    );
                    const receiptData = await receiptResponse.json();
                    if (!receiptResponse.ok) {
                      setFormSubmited((formSubmited) => ({
                        ...formSubmited,
                        status: "responseError",
                        message: JSON.stringify(receiptData),
                      }));
                    }
                  }

                  setFormSubmited((formSubmited) => ({
                    ...formSubmited,
                    status: "success",
                  }));
                } else {
                  setFormSubmited((formSubmited) => ({
                    ...formSubmited,
                    status: "responseError",
                    message: JSON.stringify(clientData),
                  }));
                }
              } catch (error) {
                setFormSubmited((formSubmited) => ({
                  ...formSubmited,
                  status: "requestError",
                  message:
                    "Client request Error: " + JSON.stringify(error.message),
                }));
              }
            } else {
              setFormSubmited((formSubmited) => ({
                ...formSubmited,
                status: "responseError",
                message: JSON.stringify(dynamoData),
              }));
            }
          } catch (error) {
            setFormSubmited((formSubmited) => ({
              ...formSubmited,
              status: "requestError",
              message: "Dynamo request Error: " + JSON.stringify(error.message),
            }));
          }
        } else {
          setFormSubmited((formSubmited) => ({
            ...formSubmited,
            status: "responseError",
            message: JSON.stringify(data),
          }));
        }
      } catch (error) {
        setFormSubmited((formSubmited) => ({
          ...formSubmited,
          status: "requestError",
          message: "Main request error: " + JSON.stringify(error.message),
        }));
      }
    }
  };

  const handleValueChange = (values) => {
    if (values.value === "") {
      setTip((tip) => ({
        ...tip,
        value: 0,
        amount: 0,
      }));
    } else {
      setTip((tip) => ({
        ...tip,
        value: values.floatValue,
        amount: values.floatValue,
      }));
    }
  };

  const handleSelectChange = (event) => {
    setTip((tip) => ({
      ...tip,
      staffId: event.target.value,
    }));
  };

  const handleRadioChange = (Id) => (event) => {
    const updatedCart = cart.map((item) => {
      if (item.Id === Id) {
        const updatedItem = {
          ...item,
          discountType: event.target.value,
          discount: 0,
          discountedPrice: item.OnlinePrice,
          taxedPrice: item.OnlinePrice + item.OnlinePrice * item.TaxRate,
        };
        return updatedItem;
      } else {
        return item;
      }
    });
    showErrorDiscount(false);
    setCart(updatedCart);
  };

  const handleDiscountReasonChange = (Id) => (event) => {
    const input = event.target.value;
    const updatedCart = cart.map((item) => {
      if (item.Id === Id) {
        item.discountReason = input;
      }
      return item;
    });
    setCart(updatedCart);
  };

  const handleDiscountChange = (Id) => (values) => {
    const updatedCart = cart.map((item) => {
      if (item.Id === Id) {
        let discountedPrice = 0;
        let taxedPrice = 0;
        if (item.discountType === "percentage") {
          discountedPrice = roundWithEpsilon(
            values.value === ""
              ? item.OnlinePrice
              : item.OnlinePrice - (item.OnlinePrice * values.floatValue) / 100
          );
          taxedPrice = discountedPrice + discountedPrice * item.TaxRate;
        } else {
          discountedPrice = roundWithEpsilon(
            values.value === ""
              ? item.OnlinePrice
              : item.OnlinePrice - values.floatValue
          );
          taxedPrice =
            discountedPrice + roundHalfEven(discountedPrice * item.TaxRate);
        }
        const updatedItem = {
          ...item,
          discount:
            values.value === ""
              ? 0
              : values.floatValue <= 100
              ? values.floatValue
              : 100,
          discountedPrice: discountedPrice,
          taxedPrice: taxedPrice,
        };
        return updatedItem;
      } else {
        return item;
      }
    });
    setCart(updatedCart);
  };

  const handlePaymentChange = (payment) => (values) => {
    setPaymentMethods((paymentMethods) => ({
      ...paymentMethods,
      [payment]: values.value === "" ? 0 : values.floatValue,
    }));
  };

  const handleGiftCardCode = (event) => {
    setPaymentMethods((paymentMethods) => ({
      ...paymentMethods,
      giftCardCode: event.target.value,
    }));
  };

  const clearGiftCardStatus = (event) => {
    event.preventDefault();
    setPaymentMethods((paymentMethods) => ({
      ...paymentMethods,
      giftCard: 0,
      giftCardBalance: 0,
      giftCardCode: "",
      status: "",
      message: "",
    }));
  };

  const handleGiftCardSearch = async (event) => {
    event.preventDefault();
    try {
      const getMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: appState.nav.siteId,
          authorization: appState.singleAuth.token,
          locationid: appState.nav.locationId,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/giftCardBalance/${paymentMethods.giftCardCode}`,
        getMethod
      );
      const data = await response.json();
      if (response.ok) {
        setPaymentMethods((paymentMethods) => ({
          ...paymentMethods,
          status: "card-balance-ready",
          giftCardBalance: data.RemainingBalance,
        }));
      } else {
        setPaymentMethods((paymentMethods) => ({
          ...paymentMethods,
          status: "card-balance-failed",
          message: JSON.stringify(data),
        }));
      }
    } catch (error) {
      setPaymentMethods((paymentMethods) => ({
        ...paymentMethods,
        status: "card-balance-error",
        message: "GiftCard request Error: " + JSON.stringify(error.message),
      }));
    }
  };

  useEffect(() => {
    if (tip.staffId === "" || tip.staffId === "null") {
      setCheckoutStatus("not-ready");
    } else {
      if (
        roundWithEpsilon(
          paymentMethods.creditCard +
            paymentMethods.giftCard +
            paymentMethods.cash
        ) !== totalPurchase.afterTip
      ) {
        setCheckoutStatus("not-ready");
      } else {
        setCheckoutStatus("ready");
      }
    }
  }, [tip, paymentMethods, totalPurchase]);

  useEffect(() => {
    let newTotal = 0;
    let newSubtotal = 0;
    let newDiscount = 0;
    let newTax = 0;
    cart.forEach((item) => {
      newTotal += roundHalfEven(item.taxedPrice * item.qty, 2);
      newSubtotal += item.OnlinePrice * item.qty;
      newDiscount += (item.OnlinePrice - item.discountedPrice) * item.qty;
      newTax += roundHalfEven(
        item.discountedPrice * item.TaxRate * item.qty,
        2
      );
    });

    setSummary((summary) => ({
      ...summary,
      subtotal: newSubtotal,
      discount: newDiscount,
      tax: newTax,
    }));

    setTotalPurchase((totalPurchase) => ({
      ...totalPurchase,
      beforeTip: roundWithEpsilon(newTotal),
      afterTip: roundWithEpsilon(newTotal + tip.amount),
    }));
  }, [tip.amount, cart]);

  const handleGiftCheckbox = (event) => {
    const checkboxName = event.target.name;
    const currentIpuntEnabled = paymentMethods[checkboxName];
    setPaymentMethods((paymentMethods) => ({
      ...paymentMethods,
      [checkboxName]: !currentIpuntEnabled,
    }));
  };

  const handleCheckbox = (event) => {
    const checkboxName = event.target.name;
    const currentIpuntEnabled = paymentMethods[checkboxName];
    const currentInput = checkboxName.split("Enabled")[0].trim();
    let currentInputValue = 0;
    const otherInput = currentInput === "cash" ? "creditCard" : "cash";
    const otherInputEnabled = paymentMethods[otherInput + "Enabled"];
    const otherInputValue = paymentMethods[otherInput];

    if (otherInputEnabled) {
      currentInputValue =
        totalPurchase.afterTip - otherInputValue > 0
          ? totalPurchase.afterTip - otherInputValue
          : 0;
    } else {
      currentInputValue = totalPurchase.afterTip;
    }

    setPaymentMethods((paymentMethods) => ({
      ...paymentMethods,
      [checkboxName]: !currentIpuntEnabled,
      [currentInput]: !currentIpuntEnabled ? currentInputValue : 0,
    }));
  };

  return (
    <div className="content">
      {state.status === "loading" && (
        <div className="loading" data-cy="loading-message">
          <div className="lb-spinner" />
        </div>
      )}
      {state.status === "error" && (
        <div className="container mt-3" data-cy="error-message">
          <div className="row">
            <div className="col">
              <h1 className="lb-text-center">Error</h1>
              <h2 className="lb-text-center">
                Seems like there has been an error
              </h2>
              <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                <span>{state.message}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.status === "no-data-found" && (
        <div>
          <div className="container mt-3">
            <div className="row ">
              <div className="col-4 col-md-3 col-lg-2 mx-auto">
                <nav className="lb-navbar">
                  <nav className="lb-navbar">
                    <Link className="lb-navbar-brand" to="/">
                      <img
                        src={logo}
                        width="100%"
                        alt="Little Bellies Logo"
                      ></img>
                    </Link>
                  </nav>
                </nav>
              </div>
            </div>
          </div>
          <div className="container mt-3" data-cy="not-found-message">
            <div className="row">
              <div className="col">
                <h1 className="lb-text-center">Nothing found</h1>
                <h2 className="lb-text-center">Seems like nothing was found</h2>
                <div className="mt-1 mb-3 mx-auto lb-w-50 lb-form-submited-error">
                  <span>{state.message}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.status === "ready" && (
        <div className="container">
          <div data-cy="cart-section" className="my-2 px-3 mr-2">
            <div className="row">
              <div className="col-6">
                <h4 className="lb-font-bold">
                  Client: {location.state.clientName}
                </h4>
              </div>
              <div className="col-6 lb-text-right">
                <Link
                  to={`/appointments`}
                  className="lb-btn lb-submitButton lb-no-hover lb-rounded-0 mx-3 mt-4 mb-2"
                >
                  BACK
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3 className="lb-font-bold">Cart</h3>
              </div>
              {errorDiscount === true && (
                <div className="d-flex justify-content-center col-12">
                  <div className="alert alert-danger  " role="alert">
                    The discount must be less than {discountPercentage}% of the
                    price
                  </div>
                </div>
              )}
            </div>
            <div className="row justify-content-between my-2">
              <div className="col-3">
                <h6 className="lb-font-bold">Product name</h6>
              </div>
              <div className="col-2">
                <h6 className="lb-font-bold">Unit Price</h6>
              </div>
              <div className="col-1">
                <h6 className="lb-font-bold">TAX%</h6>
              </div>
              <div className="col-1">
                <h6 className="lb-font-bold">Qty</h6>
              </div>
              <div className="col-2">
                <h6 className="lb-font-bold">Discount</h6>
              </div>
              <div className="col-1">
                <h6 className="lb-font-bold">Reason</h6>
              </div>
              <div className="col-1">
                <h6 className="lb-font-bold">Amount</h6>
              </div>
              <div className="col-1">
                <h6 className="lb-font-bold">Actions</h6>
              </div>
            </div>
            {cart.map((item) => {
              return (
                <div key={item.Id} className="my-3">
                  <div className="row justify-content-between my-2">
                    <div className="col-3 my-auto">
                      <h5 className="lb-font-bold">{item.Name}</h5>
                    </div>
                    <div className="col-2 my-auto">
                      <h5 className="lb-font-bold">
                        ${roundWithEpsilon(item.OnlinePrice)}
                      </h5>
                    </div>
                    <div className="col-1 my-auto">
                      <h5 className="lb-font-bold">
                        {(item.TaxRate * 100).toFixed(2)}
                      </h5>
                    </div>

                    <div className="col-1 my-auto">
                      <h5 className="lb-font-bold">{item.qty}</h5>
                    </div>
                    <div className="col-2 mt-0">
                      {item.discountType === "value" && (
                        <NumberFormat
                          decimalScale={2}
                          allowNegative={false}
                          isAllowed={withSumLimit(item.OnlinePrice)}
                          prefix={item.discountType === "value" ? "$" : ""}
                          suffix={item.discountType === "percentage" ? "%" : ""}
                          className="ml-2  lb-form-control lb-discountInput lb-inputBackground lb-text-center lb-rounded-0"
                          onValueChange={handleDiscountChange(item.Id)}
                          data-cy={"discount-" + item.Id}
                        />
                      )}
                      {item.discountType === "percentage" && (
                        <NumberFormat
                          decimalScale={2}
                          isAllowed={withValueLimit}
                          allowNegative={false}
                          prefix={item.discountType === "value" ? "$" : ""}
                          suffix={item.discountType === "percentage" ? "%" : ""}
                          className="ml-2  lb-form-control lb-discountInput lb-inputBackground lb-text-center lb-rounded-0"
                          onValueChange={handleDiscountChange(item.Id)}
                          data-cy={"discount-" + item.Id}
                        />
                      )}
                      <div className="d-flex lb-text-center my-2">
                        <div className="lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom my-auto">
                          <label className="lb-form-check-label mr-2">%</label>
                          <input
                            type="radio"
                            value="percentage"
                            name={"discountType" + item.Id}
                            className="lb-form-check-input my-auto"
                            checked={item.discountType === "percentage"}
                            onChange={handleRadioChange(item.Id)}
                            data-cy={"radioDiscountTypePercentage" + item.Id}
                          />
                        </div>
                        <div className="lb-form-check lb-form-check-inline lb-text-muted lb-border-bottom my-auto">
                          <label className="lb-form-check-label mr-2">$</label>
                          <input
                            type="radio"
                            value="value"
                            name={"discountType" + item.Id}
                            className="lb-form-check-input my-auto"
                            checked={item.discountType === "value"}
                            onChange={handleRadioChange(item.Id)}
                            data-cy={"radioDiscountTypeValue-" + item.Id}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-1">
                      <button
                        className="lb-button-daily btn-sm"
                        onClick={handleModalOpen(item)}
                        data-cy={"btn-discount-reason-" + item.Id}
                      >
                        +
                      </button>
                    </div>

                    <div className="col-1">
                      <h5 className="lb-font-bold">
                        ${roundHalfEven(item.taxedPrice * item.qty, 2)}
                      </h5>
                    </div>
                    <div className="col-1 my-auto">
                      {item.removable && (
                        <div className="mr-2 mx-auto lb-removeButton">
                          <button
                            className="lb-btn lb-submitButton lb-rounded-0 mx-auto"
                            onClick={handleRemoveFromCart(item.Id)}
                            data-cy={"remove-" + item.Id}
                          >
                            <span className="text-lb-numberButton">
                              <FontAwesomeIcon icon={faTrash} />
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="row justify-content-end">
              <div className="col-3 px-1">
                <select
                  className={
                    tip.staffId === "" || tip.staffId === "null"
                      ? "lb-lb-form-control-validation-error"
                      : "lb-form-control lb-inputBackground"
                  }
                  onChange={handleSelectChange}
                  data-cy="staffSelect"
                >
                  <option value="null">Select a staff member</option>
                  {staff.map((item) => {
                    return (
                      <option key={item.Id} value={item.Id} className="my-3">
                        {item.Name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-4 d-flex">
                <div className="lb-form-inline">
                  <div className="lb-form-group justify-content-center">
                    <label>
                      <strong>Tip:</strong>{" "}
                    </label>
                    <NumberFormat
                      value={tip.value}
                      decimalScale={2}
                      allowNegative={false}
                      className="ml-2 lb-form-control lb-inputBackground lb-w-50 lb-text-center lb-rounded-0"
                      onValueChange={handleValueChange}
                      defaultValue={"0"}
                      data-cy="tipsInput"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <h4 className="lb-font-bold">Cart summary</h4>
              </div>
              <div className="col">
                <h4 className="lb-font-bold">Payment methods</h4>
              </div>
            </div>

            <div className="row my-2 pl-5 pr-3">
              <div className="col-6 my-auto">
                <h5>
                  <strong>SubTotal: </strong>
                  <strong>{summary.subtotal.toFixed(2)}</strong>
                </h5>
                <h5>
                  <strong>Discount: </strong>
                  <strong>{summary.discount.toFixed(2)}</strong>
                </h5>
                <h5>
                  <strong>TAX: </strong>
                  <strong>{summary.tax.toFixed(2)}</strong>
                </h5>
                <h4 className="lb-font-bold">
                  Total Amount: ${totalPurchase.afterTip}
                </h4>
              </div>

              <div className="col-6 px-1">
                <div className="lb-form-group d-flex justify-content-between">
                  <div>
                    <input
                      type="checkbox"
                      className="lb-form-check-input "
                      onChange={handleCheckbox}
                      name="cashEnabled"
                      id="cashEnabled"
                      data-cy="cashEnabled"
                      checked={paymentMethods.cashEnabled}
                    />
                    <label>Cash</label>
                  </div>
                  <NumberFormat
                    decimalScale={2}
                    allowNegative={false}
                    prefix={"$"}
                    value={paymentMethods.cash > 0 ? paymentMethods.cash : ""}
                    className="ml-2 lb-w-60 lb-form-control lb-inputBackground lb-text-center lb-rounded-0"
                    onValueChange={handlePaymentChange("cash")}
                    disabled={!paymentMethods.cashEnabled}
                    data-cy="cash-payment"
                  />
                </div>
                <div className="lb-form-group d-flex justify-content-between">
                  <div>
                    <input
                      type="checkbox"
                      className="lb-form-check-input "
                      onChange={handleCheckbox}
                      name="creditCardEnabled"
                      id="creditCardEnabled"
                      data-cy="creditCardEnabled"
                      checked={paymentMethods.creditCardEnabled}
                    />
                    <label>Credit Card</label>
                  </div>

                  <NumberFormat
                    decimalScale={2}
                    allowNegative={false}
                    disabled={!paymentMethods.creditCardEnabled}
                    value={
                      paymentMethods.creditCard > 0
                        ? paymentMethods.creditCard
                        : ""
                    }
                    prefix={"$"}
                    className="ml-2 lb-w-60 lb-form-control lb-inputBackground lb-text-center lb-rounded-0"
                    onValueChange={handlePaymentChange("creditCard")}
                    data-cy="credit-card-payment"
                  />
                </div>

                {paymentMethods.status === "" && (
                  <div className="lb-form-group d-flex justify-content-between">
                    <div>
                      <input
                        type="checkbox"
                        className="lb-form-check-input "
                        onChange={handleGiftCheckbox}
                        name="giftCardEnabled"
                        id="giftCardEnabled"
                        data-cy="giftCardEnabled"
                        checked={paymentMethods.giftCardEnabled}
                      />
                      <label>Gift Card</label>
                    </div>
                    <div className="ml-2 lb-w-60 d-flex">
                      <input
                        className="lb-w-70 lb-form-control lb-inputBackground lb-text-center lb-rounded-0"
                        placeholder="Gift card code"
                        value={paymentMethods.giftCardCode}
                        onChange={handleGiftCardCode}
                        disabled={!paymentMethods.giftCardEnabled}
                        data-cy="giftCardCode"
                      ></input>
                      <button
                        onClick={handleGiftCardSearch}
                        className="lb-btn lb-submitButton lb-rounded-0 mx-auto"
                        data-cy="searchGiftCard"
                      >
                        <span className="text-lb-numberButton">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </button>
                    </div>
                  </div>
                )}
                {paymentMethods.status === "card-balance-failed" ||
                  (paymentMethods.status === "card-balance-error" && (
                    <div className="mt-1 mx-auto mb-3 lb-form-submited-error">
                      <span className="">
                        {formSubmited.message === ""
                          ? "Woops, this is weird. Something went wrong with the connection, please reload the page and try again."
                          : formSubmited.message}
                      </span>
                    </div>
                  ))}
                {paymentMethods.status === "card-balance-ready" && (
                  <>
                    <div className="mt- mx-auto mb-3 lb-form-submited-success">
                      <span className="">
                        The gift card balance is:{" "}
                        {paymentMethods.giftCardBalance}
                      </span>
                      <br />
                      <a href="" className="a" onClick={clearGiftCardStatus}>
                        clear
                      </a>
                    </div>
                    <div className="lb-form-group d-flex justify-content-between">
                      <label className="my-auto">Gift Card</label>
                      <NumberFormat
                        decimalScale={2}
                        isAllowed={giftCardValueLimit}
                        allowNegative={false}
                        prefix={"$"}
                        className="ml-2 lb-w-60 lb-form-control lb-inputBackground lb-text-center lb-rounded-0"
                        onValueChange={handlePaymentChange("giftCard")}
                        data-cy="giftCardPayment"
                      />
                    </div>
                  </>
                )}

                {paymentMethods.creditCard +
                  paymentMethods.giftCard +
                  paymentMethods.cash !==
                  totalPurchase.afterTip && (
                  <div className="lb-form-group lb-text-right">
                    <h4 className="lb-font-bold">
                      {paymentMethods.creditCard +
                        paymentMethods.giftCard +
                        paymentMethods.cash <
                      totalPurchase.afterTip
                        ? "Pending Balance: "
                        : "Over Balance: "}
                      <span
                        className="lb-form-check-label-error"
                        data-cy="purchaseTotal"
                      >
                        $
                        {Math.abs(
                          roundWithEpsilon(
                            totalPurchase.afterTip -
                              (paymentMethods.creditCard +
                                paymentMethods.giftCard +
                                paymentMethods.cash)
                          )
                        )}
                      </span>
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>

          {tip.value > 0 && (tip.staffId === "" || tip.staffId === "null") && (
            <div className="mt-1 lb-w-50 mx-auto lb-text-center mb-3 lb-form-submited-error">
              <span>Please select a staff member.</span>
            </div>
          )}
          <div className="lb-form-group lb-form-check lb-text-center">
            <input
              type="checkbox"
              className="lb-form-check-input"
              id="receiptEnabled"
              data-cy="receiptEnabled"
              onChange={handleReceiptCheckbox}
              checked={receipt.enabled}
            ></input>
            <label className="lb-form-check-label mt-1">
              <strong>Receipt of payment</strong>
            </label>
          </div>
          <div className="row my-2">
            <div className="col lb-text-center">
              {(state.dicomStatus === "failed" ||
                state.dicomStatus === "error") && (
                <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-warning d-none">
                  <span>{state.dicomMessage}</span>
                </div>
              )}
              {formSubmited.status === "success" && (
                <>
                  <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-success">
                    <span className="">
                      Thank you, your purchase is now being processed
                    </span>
                  </div>
                  <Link
                    to={`/appointments`}
                    className="lb-btn lb-submitButton lb-no-hover lb-rounded-0 mx-3 mt-4 mb-2"
                  >
                    GO BACK
                  </Link>
                </>
              )}
              {formSubmited.status === "responseError" && (
                <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-error">
                  <span className="">
                    {formSubmited.message === ""
                      ? "Woops, this is weird. Something went wrong with the connection, please reload the page and try again."
                      : formSubmited.message}
                  </span>
                </div>
              )}
              {formSubmited.status === "requestError" && (
                <div className="mt-1 lb-w-50 mx-auto mb-3 lb-form-submited-error">
                  <span className="">
                    {formSubmited.message === ""
                      ? "Woops, this is weird. Something went wrong with the connection, please reload the page and try again."
                      : formSubmited.message}
                  </span>
                </div>
              )}
              {formSubmited.status !== "success" && (
                <button
                  className={
                    checkoutStatus !== "ready"
                      ? "lb-btn lb-submitButtonDisabled lb-rounded-0 mx-3 mt-4 mb-2"
                      : "lb-btn lb-submitButton lb-rounded-0 mx-3 mt-4 mb-2"
                  }
                  data-cy="cta-checkout"
                  onClick={handleSubmit}
                  disabled={checkoutStatus !== "ready"}
                >
                  {formSubmited.status === "sending" ? (
                    <span className="text-lb-numberButton mx-4 my-2">
                      <FontAwesomeIcon spin icon={faSpinner} /> Loading...
                    </span>
                  ) : (
                    <span className="text-lb-numberButton mx-4 my-2">
                      CHECKOUT
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>

          <hr className="mb-4"></hr>

          <div data-cy="additionals-section" className="my-5 px-1">
            <h3 className="lb-font-bold">Additional Items</h3>
            <h4 className="lb-font-bold">Product name</h4>
            <ul>
              {additionals.map((item) => {
                return (
                  <li key={item.Id} className="my-3">
                    <div className="row justify-content-between no-gutters my-auto">
                      <div className="col-md-5 col-lg-6 no-gutters">
                        <h6 className="content ml-2 lb-font-bold">
                          {item.Name}
                          {". "}
                          {item.ShortDescription}
                        </h6>
                      </div>
                      <div className="col-2 lb-text-right">
                        <h5 className="lb-font-bold">${item.OnlinePrice}</h5>
                      </div>
                      <div className="col-md-5 col-lg-4 my-auto pl-3 justify-content-end">
                        {item.multiple === true && (
                          <div>
                            <div className="d-flex">
                              <button
                                className="lb-btn lb-numberButton lb-rounded-0"
                                onClick={handleMinusQty(item.Id)}
                                data-cy={"minus-" + item.Id}
                              >
                                -
                              </button>
                              <input
                                className="lb-form-control lb-text-center lb-w-20 lb-rounded-0"
                                value={item.qty}
                                onChange={handleNumChange}
                              ></input>
                              <button
                                className="lb-btn lb-numberButton lb-rounded-0"
                                onClick={handlePlusQty(item.Id)}
                                data-cy={"plus-" + item.Id}
                              >
                                +
                              </button>
                              <button
                                data-cy={"add-" + item.Id}
                                className="lb-btn lb-submitButton lb-rounded-0 mx-1"
                                onClick={handleAddToCart(item.Id)}
                              >
                                <span className="text-lb-numberButton">
                                  Add to cart
                                </span>
                              </button>
                            </div>
                          </div>
                        )}
                        {item.multiple === false && (
                          <div className="lb-form-inline">
                            <div className="lb-form-group">
                              <button
                                data-cy={"add-" + item.Id}
                                className="lb-btn lb-submitButton lb-rounded-0"
                                onClick={handleAddToCart(item.Id)}
                              >
                                <span className="text-lb-numberButton">
                                  Add to cart
                                </span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      <div hidden={!show} className="lb-modal-zindex">
        <div
          className="modal-background"
          id="modal-background"
          onClick={handleModalClose}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body py-5">
                <div className="mb-3">
                  <label htmlFor="discount-reason" className="form-label">
                    Discount reason
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="discount-reason"
                    name="discountReason"
                    value={modalData.discountReason}
                    onChange={handleDiscountReasonChange(modalData.itemId)}
                    data-cy="input-discount-reason"
                  />
                </div>
              </div>
              <div className="modal-footer d-flex d-flex justify-content-center">
                <button
                  type="button"
                  className="lb-button-daily"
                  data-dismiss="modal"
                  onClick={closeModal}
                  data-cy="btn-ok"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CheckoutPage.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default CheckoutPage;

function roundWithEpsilon(numbeToRound) {
  return Math.round((numbeToRound + Number.EPSILON) * 100) / 100;
}
