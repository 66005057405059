import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { formatAmount, parseFloatLocale } from "../util/formatNumbers.js";
import { formatDate2object } from "../util/index.js";
import NumberFormat from "react-number-format";
import { PropTypes } from "prop-types";
import "../styles/global.css";
import "../styles/dailyReport.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmationAction from "../components/modals/confirmationAction"

const initState = {
  status: "loading",
  message: "",
  siteId: "",
  locationId: "",
  date: "",
  approved: false,
};

const initTotals = {
  giftCardTotal: "",
  cashTotal: "",
  creditCardTotal: "",
  fullTotal: "",
};
const initFormData = {
  openingCash: "",
  cashPayments: "",
  endingCashStore: "",
};
const initFormDataClover = {
  cloverCreditCard: 0,
  cloverCash: 0,
  cloverTips: 0,
};
const initEndingCashDif = formatAmount(0);
const initTotalEnding = {
  withdrawals: "",
  endingCash: "",
  tipTotal: "",
};
const initEmployees = [];
const initEmployeesReview = [];
const initTotalEmployees = {
  totalEndingCashStore: 0,
  totalOpeningCash: 0,
  totalTips: 0,
};
const initAppointmentTable = [];
const initPaymentAppointments = {
  cashAppointments: [],
  creditAppointments: [],
  discountAppointments: [],
  acumTipCash: 0,
  acumAmountCash: 0,
  acumTipCC: 0,
  acumAmountCC: 0,
};
const initReasons = [{ reason: "", value: "" }];
const initObservations = "";
const initCreatedBy = "";
const initTipped = 0;

function DailyReport({ appState }) {
  const [state, setState] = useState(initState);
  const [totals, setTotals] = useState(initTotals);
  const [formData, setFormData] = useState(initFormData);
  const [formDataClover, setFormDataClover] = useState(initFormDataClover);
  const [endigCashDiferent, setEndigCashDiferent] = useState(initEndingCashDif);
  const [totalEnding, setTotalEnding] = useState(initTotalEnding);
  const [employees, setEmployees] = useState(initEmployees);
  const [employeesReview, setEmployeesReview] = useState(initEmployeesReview);
  const [totalEmployees, setTotalEmployees] = useState(initTotalEmployees);
  const [appointmentsTable, setAppointmentsTable] =
    useState(initAppointmentTable);
  const [paymentAppointments, setPaymentAppointments] = useState(
    initPaymentAppointments
  );
  const [reasons, setReasons] = useState(initReasons);
  const [observations, setObservations] = useState(initObservations);
  const [createdBy, setCreatedBy] = useState(initCreatedBy);
  const [tipped, setTipped] = useState(initTipped);
  const [confirmationAction, setConfirmationAction] = useState({
    action: false,
    text: "",
    response: "default",
    nameAction: "",
    data: "",
    info: true,
    infoTittle: "",
    infoBody: "",
  });
  const [userSession,setUserSession] = useState({
    info: "",
    date: "",
  });
  
  const textResponse = {
    error: "Oops, an error has occurred, contact your support team",
    delete: "Are you sure to delete appointment?",
    successCancel: "Appointment canceled successfully!",
  };
  const params = useParams();

  const location = useLocation();

  const review = params.closeDate !== undefined;

  const handleReason = () => {
    setReasons([...reasons, { reason: "", value: "" }]);
  };

  const resetPage = () => {
    setTotals(initTotals);
    setFormDataClover(initFormDataClover);
    setEndigCashDiferent(initEndingCashDif);
    setFormData(initFormData);
    setEmployees(initEmployees);
    setTotalEnding(initTotalEnding);
    setTotalEmployees(initTotalEmployees);
    setEmployeesReview(initEmployeesReview);
    setPaymentAppointments(initPaymentAppointments);
    setAppointmentsTable(initAppointmentTable);
    setObservations(initObservations);
    setReasons(initReasons);
    setTipped(initTipped);
    setCreatedBy(initCreatedBy);
  };

  const clearForm = () => {
    setReasons([{ reason: "", value: "" }]);
    setFormData((formData) => ({
      ...formData,
      openingCash: "",
      endingCashStore: "",
      cashPayments: "",
      drawnerNotes: "",
    }));
    setShow(false);
  };

  const handleReasonsChange = ({ index, name, value }) => {
    const list = [...reasons];
    list[index][name] = value;
    setReasons(list);
  };

  const handleReasonDelete = (index) => {
    const list = [...reasons];
    list.splice(index, 1);
    setReasons(list);
  };

  const handleEmployeeChange = ({ index, name, value }) => {
    const listEmployee = [...employees];
    listEmployee[index][name] = value;

    const newListEmploye = [];
    let addTotalOpeningCashEmployee = 0;
    let addTotalEndingCashStoreEmployee = 0;
    let addCashEmployee = 0;
    let cashEmployee = 0;
    let employeEndingCashStore = 0;

    listEmployee.forEach((i) => {
      const employeeOpeningCashParseNumber = parseFloatLocale(
        i.employeeOpeningCash
      );

      if (review) {
        const apoimentsInfoCheckout = appointmentsTable
          .map((e) => {
            return e.infoCheckout;
          })
          .filter((e) => {
            return e.staffName === i.employeeName;
          });

        apoimentsInfoCheckout.forEach((eCash) => {
          addCashEmployee += eCash.cash;
        });

        cashEmployee = parseFloatLocale(roundWithEpsilon(addCashEmployee));
        employeEndingCashStore = employeeOpeningCashParseNumber + cashEmployee;
      } else {
        cashEmployee = parseFloatLocale(roundWithEpsilon(i.CashEmployee));
        employeEndingCashStore = employeeOpeningCashParseNumber + cashEmployee;
      }
      addTotalOpeningCashEmployee += isNaN(employeeOpeningCashParseNumber)
        ? 0
        : employeeOpeningCashParseNumber;
      addTotalEndingCashStoreEmployee += isNaN(employeEndingCashStore)
        ? 0
        : employeEndingCashStore;

      newListEmploye.push({
        arrivalEmployee: i.arrivalEmployee,
        checkOutEmployee: i.checkOutEmployee,
        employeeName: i.employeeName,
        employeeNotes: i.employeeNotes,
        tipEmployee: i.tipEmployee,
        CashEmployee: i.CashEmployee,
        employeeOpeningCash: employeeOpeningCashParseNumber,
        employeeEndingCashStore: isNaN(employeeOpeningCashParseNumber)
          ? formatAmount(0)
          : formatAmount(employeEndingCashStore),
      });
    });

    setEmployees(newListEmploye);
    setTotalEmployees((totalEmployees) => ({
      ...totalEmployees,
      totalOpeningCash: formatAmount(addTotalOpeningCashEmployee),
      totalEndingCashStore: formatAmount(addTotalEndingCashStoreEmployee),
    }));
  };

  const handleObservations = (e) => {
    const value = e.target.value;
    setObservations(value);
  };

  const handleCreatedBy = (e) => {
    const value = e.target.value;
    setCreatedBy(value);
  };

  const history = useHistory();
  const handleReject = () => {
    history.push("/reviewdailyreport");
  };

  const reviewDate = params.closeDate !== undefined ? location.state.date : "";

  const handleCheckbox = (e, index) => {
    const { name } = e.target;
    const list = [...appointmentsTable];
    list[index][name] = !list[index][name];
    setAppointmentsTable(list);
  };

  const HandleAllEmailWhatsAppCheckboxes = (e) => {
    const { checked } = e.target;
    const newList = [];

    appointmentsTable.forEach((i) => {
      if (i.infoCheckout) {
        newList.push({
          appointmentDate: i.appointmentDate,
          appointmentId: i.appointmentId,
          arrived: i.arrived,
          cbff: i.cbff,
          clientName: i.clientName,
          email: i.email,
          emailSend: checked,
          completed: i.completed,
          infoCheckout: i.infoCheckout,
          files: i.files,
          formFilled: i.formFilled,
          locationId: i.locationId,
          notes: i.notes,
          serviceName: i.serviceName,
          siteId: i.siteId,
          sortDate: i.sortDate,
          weeks: i.weeks,
        });
      } else {
        newList.push({
          appointmentDate: i.appointmentDate,
          appointmentId: i.appointmentId,
          arrived: i.arrived,
          cbff: i.cbff,
          clientName: i.clientName,
          email: i.email,
          emailSend: checked,
          completed: i.completed,
          files: i.files,
          formFilled: i.formFilled,
          locationId: i.locationId,
          notes: i.notes,
          serviceName: i.serviceName,
          siteId: i.siteId,
          sortDate: i.sortDate,
          weeks: i.weeks,
        });
      }
    });

    setAppointmentsTable(newList);
  };

  const [show, setShow] = useState(false);

  const handleModalClose = (e) => {
    if (e.target.id === "modal-background") {
      setShow(false);
    }
  };

  const handleModalOpen = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleChange = ({ name, value }) => {
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleChangeClover = ({ name, value }) => {
    setFormDataClover((formDataClover) => ({
      ...formDataClover,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    if (review) {
      putReview();
    } else {
      postForm();
    }
  };

  function timeComparison(a, b) {
    const timeFormat = /[0-9]{2}:[0-9]{2}/;
    try {
      if ("appointmentDate" in a) {
        a = a.appointmentDate;
      } else {
        return 1;
      }

      if ("appointmentDate" in b) {
        b = b.appointmentDate;
      } else {
        return -1;
      }

      let time = a.match(timeFormat);
      time = time[0];
      let tokens = time.split(":");
      let hours = parseInt(tokens[0], 10);
      let minutes = parseInt(tokens[1], 10);
      a = 60 * hours + minutes;

      time = b.match(timeFormat);
      time = time[0];
      tokens = time.split(":");
      hours = parseInt(tokens[0], 10);
      minutes = parseInt(tokens[1], 10);
      b = 60 * hours + minutes;

      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  const getReport = async () => {
    setState((state) => ({
      ...state,
      status: "loading",
    }));
    try {
      const ReportGetMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };
      const qDate = moment(state.date).format("MM/DD/YYYY").toString();
      console.log("date", state.date);
      // console.log("qDate", qDate);
      const reportResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sites/${appState.nav.siteId}/locations/${appState.nav.locationId}/sales?sortDate=${qDate}`,
        ReportGetMethod
      );

      const reportData = await reportResponse.json();
      if (reportResponse.ok) {
        const list = [...reportData.Items];
        const eList = [];

        const paymentAppointments = {
          cashAppointments: [],
          creditAppointments: [],
          discountAppointments: [],
          acumTipCash: 0,
          acumAmountCash: 0,
          acumTipCC: 0,
          acumAmountCC: 0,
        };

        list.forEach((appointment) => {
          switch (appointment.infoCheckout) {
            case undefined:
              break;
            default:
              if (appointment.infoCheckout.cash > 0) {
                paymentAppointments.cashAppointments.push(appointment);
                paymentAppointments.acumTipCash += appointment.infoCheckout.tip;
                paymentAppointments.acumAmountCash +=
                  appointment.infoCheckout.totalPurchase;
              }
              if (appointment.infoCheckout.creditCard > 0) {
                paymentAppointments.creditAppointments.push(appointment);
                paymentAppointments.acumTipCC += appointment.infoCheckout.tip;
                paymentAppointments.acumAmountCC +=
                  appointment.infoCheckout.creditCard;
              }
              if (appointment.infoCheckout.discount > 0) {
                paymentAppointments.discountAppointments.push(appointment);
              }
              break;
          }
        });

        setPaymentAppointments(paymentAppointments);

        for (let i = 0; i < list.length; i++) {
          list[i].emailSend = false;

          if (
            "cbff" in list[i] &&
            list[i].cbff !== null &&
            list[i].cbff !== undefined
          ) {
            if (list[i].cbff.length < 6) {
              list[i].cbff = "";
            }
          }
          // if ("arrived" in list[i]) {
          //   list[i].arrived = getTime(list[i].arrived);
          // }
          // if ("completed" in list[i]) {
          //   list[i].completed = getTime(list[i].completed);
          // }
          // lista de empleados

          if ("infoCheckout" in list[i]) {
            const ttip = list[i].infoCheckout.tip;
            const tname = list[i].infoCheckout.staffName ?? "Walkin";
            const cash = list[i].infoCheckout.cash;
            let index = -1;
            for (let z = 0; z < eList.length; z++) {
              if (eList[z].employeeName === tname) {
                index = z;
                eList[z].tipEmployee += ttip;
                eList[z].CashEmployee += cash;
              }
            }
            if (index === -1 && tname !== "Walkin") {
              const temp = {};
              temp.employeeName = tname;
              temp.tipEmployee = ttip;
              temp.CashEmployee = cash;
              temp.arrivalEmployee = "";
              temp.checkOutEmployee = "";
              temp.employeeNotes = "";
              eList.push(temp);
            }
          }
        }

        list.sort(timeComparison);
        setAppointmentsTable(list);

        let addTipsEmployees = 0;
        for (let i = 0; i < eList.length; i++) {
          eList[i].tipEmployee = roundWithEpsilon(eList[i].tipEmployee);
          eList[i].CashEmployee = roundWithEpsilon(eList[i].CashEmployee);
          addTipsEmployees += eList[i].tipEmployee;
        }

        setTotalEmployees((totalEmployees) => ({
          ...totalEmployees,
          totalEndingCashStore: 0,
          totalOpeningCash: 0,
          totalTips: parseFloatLocale(roundWithEpsilon(addTipsEmployees)),
        }));

        setEmployees(eList);

        setTipped(eList.length);

        setFormData((formData) => ({
          ...formData,
          openingCash: !isNaN(reportData.openingCash)
            ? formatAmount(
                parseFloatLocale(roundWithEpsilon(reportData.openingCash))
              )
            : "",
        }));

        let localCloverCreditCard = 0;
        let localCloverTips = 0;
        let localCloverCash = 0;

        if ("cloverCreditCard" in reportData) {
          localCloverCreditCard = reportData.cloverCreditCard;
        }
        if ("cloverTips" in reportData) {
          localCloverTips = reportData.cloverTips;
        }
        if ("cloverCash" in reportData) {
          localCloverCash = reportData.cloverCash;
        }
        setFormDataClover((formDataClover) => ({
          ...formDataClover,
          cloverCreditCard: formatAmount(localCloverCreditCard),
          cloverTips: formatAmount(localCloverTips),
          cloverCash: formatAmount(localCloverCash),
        }));
      }
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
    setState((state) => ({
      ...state,
      status: "ready",
    }));
  };

  function getTime(timeString) {
    const timeFormat = /[0-9]{2}:[0-9]{2}/;
    let mTime;
    try {
      let time = timeString.match(timeFormat);
      time = time[0];
      const tokens = time.split(":");
      let hours = parseInt(tokens[0]);
      let minutes = parseInt(tokens[1]);
      let m = "AM";
      if (hours === 12) {
        m = "PM";
      } else if (hours === 24) {
        hours = 0;
        m = "AM";
      } else if (hours > 12) {
        hours -= 12;
        m = "PM";
      }
      hours = hours.toString();
      minutes = minutes.toString();
      while (hours.length < 2) hours = "0" + hours;
      while (minutes.length < 2) minutes = "0" + minutes;
      mTime = hours + ":" + minutes + " " + m;
    } catch (error) {
      mTime = "-";
    }
    return mTime;
  }

  function roundWithEpsilon(numbeToRound) {
    return Math.round((numbeToRound + Number.EPSILON) * 100) / 100;
  }

  const updateTotals = () => {
    let giftCardTotal = 0;
    let cashTotal = 0;
    let creditCardTotal = 0;
    let fullTotal = 0;

    for (let i = 0; i < appointmentsTable.length; i++) {
      if ("infoCheckout" in appointmentsTable[i]) {
        if ("giftCard" in appointmentsTable[i].infoCheckout) {
          giftCardTotal += parseFloatLocale(
            appointmentsTable[i].infoCheckout.giftCard
          );
        }
        if ("cash" in appointmentsTable[i].infoCheckout) {
          cashTotal += parseFloatLocale(appointmentsTable[i].infoCheckout.cash);
        }
        if ("creditCard" in appointmentsTable[i].infoCheckout) {
          creditCardTotal += parseFloatLocale(
            appointmentsTable[i].infoCheckout.creditCard
          );
        }
      }
    }
    fullTotal = cashTotal + creditCardTotal;
    setTotals((totals) => ({
      ...totals,
      giftCardTotal: formatAmount(giftCardTotal),
      cashTotal: formatAmount(cashTotal),
      creditCardTotal: formatAmount(creditCardTotal),
      fullTotal: formatAmount(fullTotal),
    }));
  };

  const endingCashTotal = () => {
    let withdrawals = 0;
    let endingCash = 0;
    let endigCashDiferent = 0;

    let opening = parseFloatLocale(formData.openingCash);
    let payments = parseFloatLocale(totals.cashTotal);
    let endingCashStor = parseFloatLocale(formData.endingCashStore);

    opening = isNaN(opening) ? 0 : opening;
    payments = isNaN(payments) ? 0 : payments;
    endingCashStor = isNaN(endingCashStor) ? 0 : endingCashStor;

    const sum = opening + payments;

    for (let i = 0; i < reasons.length; i++) {
      if (reasons[i].value !== null && reasons[i].value.toString().length > 0) {
        withdrawals += parseFloatLocale(reasons[i].value);
      }
    }

    if (withdrawals === "0") {
      endingCash = sum;
    }
    if (withdrawals !== "0" && withdrawals !== "") {
      endingCash = sum - withdrawals;
    }
    if (withdrawals === "") {
      endingCash = 0;
    }

    const dif = endingCashStor - endingCash;
    endigCashDiferent = roundWithEpsilon(dif);
    setEndigCashDiferent(formatAmount(endigCashDiferent));

    let tipTotal = 0;
    for (let i = 0; i < employees.length; i++) {
      if (
        employees[i].tipEmployee !== null &&
        employees[i].tipEmployee.toString().length > 0
      ) {
        tipTotal += parseFloatLocale(employees[i].tipEmployee);
      }
    }

    setTotalEnding((totalEnding) => ({
      ...totalEnding,
      withdrawals: formatAmount(withdrawals),
      endingCash: formatAmount(endingCash),
      tipTotal: formatAmount(tipTotal),
    }));
  };

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    const qDate = formatDate2object(new Date(state.date));
    const name =
      "DailyReport-" +
      appState.nav.siteId +
      "-" +
      appState.nav.locationId +
      "-" +
      qDate.mm +
      "" +
      qDate.dd +
      "" +
      qDate.yyyy +
      ".pdf";
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const postForm = async () => {
    try {
      const valueReasonsParseFloat = [];
      reasons.forEach((i) => {
        valueReasonsParseFloat.push({
          reason: i.reason,
          value: isNaN(parseFloatLocale(i.value))
            ? 0
            : parseFloatLocale(i.value),
        });
      });

      const infoForm = {
        sortDate: moment(state.date).format("MM/DD/YYYY").toString(),
        cashTotal: parseFloatLocale(totals.cashTotal),
        creditCardTotal: parseFloatLocale(totals.creditCardTotal),
        giftCardTotal: !totals.giftCard ? 0 : parseFloatLocale(totals.giftCard),
        tipTotal: parseFloatLocale(totalEnding.tipTotal),
        openingCash: parseFloatLocale(formData.openingCash),
        endingCashDiferen: parseFloatLocale(endigCashDiferent),
        endingCashStore: !formData.endingCashStore
          ? 0
          : parseFloatLocale(formData.endingCashStore),
        endingCash: parseFloatLocale(totalEnding.endingCash),
        withdrawals: parseFloatLocale(totalEnding.withdrawals),
        fullTotal: parseFloatLocale(totals.fullTotal),
        cashPayments: parseFloatLocale(totals.cashTotal),
        infoReasons: valueReasonsParseFloat,
        infoEmployee: employees,
        appointments: appointmentsTable,
        locationName: appState.nav.siteName,
        observation: observations,
        createdBy: createdBy,
        cloverTips: parseFloatLocale(formDataClover.cloverTips),
        cloverCreditCard: parseFloatLocale(formDataClover.cloverCreditCard),
        cloverCash: parseFloatLocale(formDataClover.cloverCash),
      };

      const postMethod = {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(infoForm),
      };
      const qDate = moment(state.date).format("MM/DD/YYYY").toString();

      const responseInfo = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sites/${appState.nav.siteId}/locations/${appState.nav.locationId}/close?sortDate=${qDate}`,
        postMethod
      );
      if (responseInfo.ok) {
        const info = await responseInfo.json();
        setState((state) => ({
          ...state,
          status: "success",
          message: info.message,
        }));
        if ("url" in info) {
          downloadFile(info.url);
        }
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const defineDisplay = (time) => {
    if (!time) return "";
    const dt = new Date("9999-12-31 " + time);
    let hours = dt.getHours();
    const AmOrPm = hours >= 12 ? "p.m" : "a.m";
    hours = hours % 12 || 12;
    hours = hours <= 9 ? "0" + hours : hours;
    let minutes = dt.getMinutes();
    minutes = minutes <= 9 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + AmOrPm;
  };

  const setArrivalCheckoutDisplays = () => {
    const employeesReviewSend = employeesReview;
    employeesReviewSend.forEach((employee, i) => {
      employeesReviewSend[i] = {
        ...employee,
        arrivalEmployeeDisplay: defineDisplay(employee.arrivalEmployee),
        checkOutEmployeeDisplay: defineDisplay(employee.checkOutEmployee),
      };
    });
    setEmployeesReview(employeesReviewSend);
  };

  const putReview = async () => {
    try {
      const valueReasonsParseFloat = [];
      reasons.forEach((i) => {
        valueReasonsParseFloat.push({
          reason: i.reason,
          value: isNaN(parseFloatLocale(i.value))
            ? 0
            : parseFloatLocale(i.value),
        });
      });
      setArrivalCheckoutDisplays();

      const infoReview = {
        sortDate: location.state.date,
        cashTotal: parseFloatLocale(totals.cashTotal),
        creditCardTotal: parseFloatLocale(totals.creditCardTotal),
        giftCardTotal: !totals.giftCard ? 0 : parseFloatLocale(totals.giftCard),
        tipTotal: parseFloatLocale(totalEnding.tipTotal),
        openingCash: parseFloatLocale(formData.openingCash),
        endingCash: parseFloatLocale(totalEnding.endingCash),
        endingCashDiferen: parseFloatLocale(endigCashDiferent),
        endingCashStore: parseFloatLocale(formData.endingCashStore),
        infoReasons: valueReasonsParseFloat,
        fullTotal: parseFloatLocale(totals.fullTotal),
        cashPayments: parseFloatLocale(totals.cashTotal),
        infoEmployee: employeesReview,
        withdrawals: parseFloatLocale(totalEnding.withdrawals),
        approved: true,
        appointments: appointmentsTable,
        locationName: location.state.locationName,
        observation: observations,
        createdBy: createdBy,
        cloverTips: parseFloatLocale(formDataClover.cloverTips),
        cloverCreditCard: parseFloatLocale(formDataClover.cloverCreditCard),
        cloverCash: parseFloatLocale(formDataClover.cloverCash),
      };

      const putMethodReview = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(infoReview),
      };

      const responsePut = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sites/${location.state.siteId}/locations/${location.state.locationId}/dailyClose`,
        putMethodReview
      );

      if (responsePut.ok) {
        console.log("se envio");
        getPDF();
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const getPDF = async () => {
    try {
      const ReportGetPDF = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };

      const reportPDF = await fetch(
        `${process.env.REACT_APP_API_URL}/api/pdf/sites/${location.state.siteId}/locations/${location.state.locationId}/dailyClosePdf?sortDate=${location.state.date}`,
        ReportGetPDF
      );

      if (reportPDF.ok) {
        const responsePDF = await reportPDF.json();
        setState((state) => ({
          ...state,
          approved: true,
          status: "success",
          message:
            responsePDF.message || "El review fue procesado exitosamente",
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  const getLocationTime = async () => {
    try {
      setState((state) => ({
        ...state,
        status: "loading",
      }));

      const getSitesQuery = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/config/sites`,
        getSitesQuery
      );
      const data = await response.json();
      if (response.ok) {
        const mySite = `${appState.nav.siteId}-${appState.nav.locationId}`;

        const foundedSite = data.sites.find((item) => item.site === mySite);
        if (foundedSite === undefined) {
          alert("No existe el sitio");
        } else {
          const localDate = new Date();
          const siteDate = new Date(
            localDate.toLocaleString("en-US", {
              timeZone: foundedSite.timeZone,
            })
          );
          console.table([
            { name: "hora local", value: localDate },
            { name: "hora sitio", value: siteDate },
          ]);
          setState((state) => ({
            ...state,
            date: siteDate,
          }));
        }
      } else {
        console.log("Fallo al obtener las fechas");
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }
  };

  useEffect(() => {
    clearForm();
    resetPage();
    if (review) {
      setState((state) => ({
        ...state,
        siteId: location.state.siteId,
        locationId: location.state.locationId,
        date: location.state.date,
        approved: location.state.approved,
      }));
     calculateReviewReport();
    } else {
      getLocationTime();
    }
  }, [params.closeDate, appState.nav.siteId]);

  useEffect(() => {
    if (review && confirmationAction.response === "success") {
      clearForm();
      resetPage();
     calculateReviewReport();
    } 
  }, [confirmationAction.response]);

  const calculateReviewReport = async () => {
      setState((state) => ({
        ...state,
        status: "loading",
      }));
    try {
      const ReportGetMethod = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      };
      const reportResponseReview = await fetch(
        `${process.env.REACT_APP_API_URL}/api/sites/${location.state.siteId}/locations/${location.state.locationId}/dailyClose?sortDate=${location.state.date}`,
        ReportGetMethod
      );

      const data = await reportResponseReview.json();

      if (reportResponseReview.ok) {
        const list = [...data.Item.appointments];
        const listReviewEmloyee = [];
        const listInfoEmployee = [data.Item];
        const eList = [];

        listInfoEmployee.forEach((i) => {
          i.infoEmployee.forEach((e) => {
            listReviewEmloyee.push({
              CashEmployee: e.CashEmployee,
              arrivalEmployee: e.arrivalEmployee,
              checkOutEmployee: e.checkOutEmployee,
              employeeEndingCashStore: parseFloatLocale(
                e.employeeEndingCashStore
              ),
              employeeName: e.employeeName,
              employeeNotes: e.employeeNotes,
              employeeOpeningCash: isNaN(e.employeeOpeningCash)
                ? 0
                : parseFloatLocale(e.employeeOpeningCash),
              tipEmployee: e.tipEmployee,
            });
          });
        });

        setEmployeesReview(listReviewEmloyee);

        const paymentAppointments = {
          cashAppointments: [],
          creditAppointments: [],
          discountAppointments: [],
          acumTipCash: 0,
          acumAmountCash: 0,
          acumTipCC: 0,
          acumAmountCC: 0,
        };

        setCreatedBy(data.Item.createdBy);

        list.forEach((appointment) => {
          switch (appointment.infoCheckout) {
            case undefined:
              break;
            default:
              if (appointment.infoCheckout.cash > 0) {
                paymentAppointments.cashAppointments.push(appointment);
                paymentAppointments.acumTipCash += appointment.infoCheckout.tip;
                paymentAppointments.acumAmountCash +=
                  appointment.infoCheckout.cash;
              }
              if (appointment.infoCheckout.creditCard > 0) {
                paymentAppointments.creditAppointments.push(appointment);
                paymentAppointments.acumTipCC += appointment.infoCheckout.tip;
                paymentAppointments.acumAmountCC +=
                  appointment.infoCheckout.creditCard;
              }
              if (appointment.infoCheckout.discount > 0) {
                paymentAppointments.discountAppointments.push(appointment);
              }
              break;
          }
        });
        setPaymentAppointments(paymentAppointments);

        for (let i = 0; i < list.length; i++) {
          if (
            "cbff" in list[i] &&
            list[i].cbff !== null &&
            list[i].cbff !== undefined
          ) {
            if (list[i].cbff.length < 6) {
              list[i].cbff = "";
            }
          }
          // if ("arrived" in list[i]) {
          //   list[i].arrived = getTime(list[i].arrived);
          // }
          // if ("completed" in list[i]) {
          //   list[i].completed = getTime(list[i].completed);
          // }
          // lista de empleados

          if ("infoCheckout" in list[i]) {
            const ttip = list[i].infoCheckout.tip;
            const tname = list[i].infoCheckout.staffName ?? "Walkin";
            const cash = list[i].infoCheckout.cash;

            let index = -1;
            for (let z = 0; z < eList.length; z++) {
              if (eList[z].employeeName === tname) {
                index = z;
                eList[z].tipEmployee += ttip;
                eList[z].CashEmployee += cash;
              }
            }
            if (index === -1 && tname !== "Walkin") {
              const temp = {};
              temp.employeeName = tname;
              temp.tipEmployee = ttip;
              temp.CashEmployee = cash;
              temp.arrivalEmployee = "";
              temp.checkOutEmployee = "";
              temp.employeeNotes = "";
              eList.push(temp);
            }
          }
        }
        list.sort(timeComparison);
        setAppointmentsTable(list);

        let addTipsEmployees = 0;
        for (let i = 0; i < eList.length; i++) {
          eList[i].tipEmployee = roundWithEpsilon(eList[i].tipEmployee);
          eList[i].CashEmployee = roundWithEpsilon(eList[i].CashEmployee);
          addTipsEmployees += eList[i].tipEmployee;
        }

        let totalEmployeeEndingCashStore = 0;
        let totalEmployeeOpeningCash = 0;

        listReviewEmloyee.forEach((e) => {
          totalEmployeeEndingCashStore += e.employeeEndingCashStore;
          totalEmployeeOpeningCash += e.employeeOpeningCash;
        });

        setTotalEmployees((totalEmployees) => ({
          ...totalEmployees,
          totalEndingCashStore: isNaN(totalEmployeeEndingCashStore)
            ? 0
            : parseFloatLocale(roundWithEpsilon(totalEmployeeEndingCashStore)),
          totalOpeningCash: isNaN(totalEmployeeOpeningCash)
            ? 0
            : parseFloatLocale(roundWithEpsilon(totalEmployeeOpeningCash)),
          totalTips: parseFloatLocale(roundWithEpsilon(addTipsEmployees)),
        }));

        setEmployees(eList);

        setTipped(eList.length);

        setFormData((formData) => ({
          ...formData,
          openingCash: !isNaN(data.Item.openingCash)
            ? formatAmount(
                parseFloatLocale(roundWithEpsilon(data.Item.openingCash))
              )
            : "",
          endingCashStore: !isNaN(data.Item.endingCashStore)
            ? formatAmount(
                parseFloatLocale(roundWithEpsilon(data.Item.endingCashStore))
              )
            : "",
        }));

        const reasons = [...data.Item.infoReasons];
        // reasons[0].value = parseFloatLocale(data.Item.withdrawals);
        setReasons(reasons);

        setObservations(data.Item.observation);

        let localCloverCreditCard = 0;
        let localCloverTips = 0;
        let localCloverCash = 0;

        if ("cloverCreditCard" in data.Item) {
          localCloverCreditCard = data.Item.cloverCreditCard;
        }
        if ("cloverTips" in data.Item) {
          localCloverTips = data.Item.cloverTips;
        }
        if ("cloverCash" in data.Item) {
          localCloverCash = data.Item.cloverCash;
        }
        setFormDataClover((formDataClover) => ({
          ...formDataClover,
          cloverCreditCard: formatAmount(localCloverCreditCard),
          cloverTips: formatAmount(localCloverTips),
          cloverCash: formatAmount(localCloverCash),
        }));
      }
    } catch (error) {
      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));
    }

    setState((state) => ({
      ...state,
      status: "ready",
    }));
    // END OF CALCULATE REVIEW REPORT
  };

  useEffect(() => {
    if (!review && state.date !== "") {
      getReport();
    }
  }, [state.date]);

  useEffect(() => {
    if (!review) {
      updateTotals();
    } else {
      updateTotals();
      endingCashTotal();
    }
  }, [appointmentsTable, review]);

  useEffect(() => {
    endingCashTotal();
  }, [
    reasons,
    employees,
    formData.openingCash,
    formData.endingCashStore,
    formData.cashPayments,
    review,
  ]);

  useEffect(() => {
    endingCashTotal();
  }, [formData.openingCash]);

  useEffect(() => {
    function reasonUpdate() {
      let totalTip = 0;
      for (let i = 0; i < employees.length; i++) {
        if (
          employees[i].tipEmployee !== null &&
          employees[i].tipEmployee.toString().length > 0
        ) {
          totalTip += parseFloatLocale(employees[i].tipEmployee);
        }
      }
      totalTip = roundWithEpsilon(totalTip);
      const r = reasons;
      r[0].reason = "Tip";
      r[0].value = totalTip;
      setReasons(r);
      endingCashTotal();
    }
    if (!review) {
      reasonUpdate();
    }
  }, [employees]);

  
  useEffect(() => {

     let userName;

      const auth = {
        single: appState.singleAuth,
        directors: appState.directorsAuth,
        booking: appState.bookingAuth
      }

      const searchBySite = `${state.siteId}-${state.locationId}`;
      if (auth.single) {
        if (auth.single.userName !== null) userName = auth.single;
      }
      if (auth.booking.authorizationTokens.length > 0) {
        userName = auth.booking.authorizationTokens.find(i => `${i.siteId}-${i.locationId}` === searchBySite);
      }
      if (auth.directors.locationsAuth.length > 0) {
        userName = auth.directors.locationsAuth.find(i => `${i.siteId}-${i.locationId}` === searchBySite);
      }

      setUserSession((userSession) => ({
        ...userSession,
        info : userName
      }));

  },[state,userSession.info])

  const handleDeleteServiceWalkin = async (data) => {

    setConfirmationAction((confirmationAction) => ({
      ...confirmationAction,
      nameAction: ""
    }))

    try {
      setConfirmationAction((confirmationAction) => ({
        ...confirmationAction,
        response: "process",
      }))
      const payload = {
        appointmentId: data.appointmentId,
        locationId: state.locationId,
        userName: userSession.info.userName,
        userRol: userSession.info.userRol
      };
  
      const request = {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          siteid: state.siteId,
        },
        body: JSON.stringify(payload),
      };
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/dynamoDB/sites/${state.siteId}/sales`,
        request
      );
  
      if (response.ok && response.status === 202) {

       setConfirmationAction((confirmationAction) => ({
           ...confirmationAction,
           action: false,
           response: "success",
           text: textResponse.successCancel,
           data: "",
           nameAction: ""
         }))
  
      }else{
        setConfirmationAction((confirmationAction) => ({
          ...confirmationAction,
          action: false,
          response: "error",
          text: textResponse.error,
          data: "",
          nameAction: ""
          }));

      }
    } catch (error) {

          setConfirmationAction((confirmationAction) => ({
          ...confirmationAction,
          action: false,
          response: "error",
          text: error.message,
          data: "",
          nameAction: ""
          }));

      setState((state) => ({
        ...state,
        status: "error",
        message: error.message,
      }));

    }

  }

  useEffect(() => {

    if (confirmationAction.action && confirmationAction.nameAction === "delete") {
      handleDeleteServiceWalkin(confirmationAction.data)
    }

  }, [confirmationAction, confirmationAction.nameAction,confirmationAction.response])

  const estiloDiv = {
    color: "red",
  };

  const estiloOk = {
    color: "green",
  };

  const estiloNormal = {
    color: "black",
  };

  return (

    <div className="content pb-4">
      <ConfirmationAction
      confirmationAction={confirmationAction}
      setConfirmationAction={setConfirmationAction}
      />

      {state.status === "loading" && (
        <div className="loading" data-cy="loading-message">
          <div className="lb-spinner" />
        </div>
      )}
      {(state.status === "ready" || state.status === "success") && (
        <div>
          <div className="container mt-4">
            {review && (
              <h2
                className="text-center lb-title-daily extraclass"
                data-cy="daily-report"
              >
                Review Daily Report
              </h2>
            )}
            {!review && (
              <h2
                className="text-center lb-title-daily extraclass"
                data-cy="daily-report"
              >
                Daily Report
              </h2>
            )}

            <section id="siteid">
              <div className="row">
                <h5 className="col-6 text-left lb-title-daily">
                  {review && <>{location.state.locationName}</>}
                  {!review && <>{appState.nav.siteName}</>}
                </h5>
                {review && (
                  <h5 className="col-6 lb-text-right lb-title-daily">
                    {reviewDate}
                  </h5>
                )}
                {!review && (
                  <h5 className="col-6 lb-text-right lb-title-daily">
                    {moment(state.date).format("MM/DD/YYYY hh:mm A")}
                  </h5>
                )}
              </div>
            </section>

            <section id="billing" className="mt-4">
              <div className="card">
                <div className="card-body my-2">
                  <h5 className="card-title lb-title-daily">Billing Report</h5>
                  <form>
                    <div className="row">
                      <div className="form-group col-4">
                        <label htmlFor="cash">Cash</label>
                        <input
                          type="text"
                          className="form-control text-end"
                          aria-describedby="cash"
                          value={totals.cashTotal}
                          readOnly
                        ></input>
                      </div>
                      <div className="form-group col-4">
                        <label htmlFor="creditCard">Credit Card</label>
                        <input
                          type="text"
                          className="form-control text-end"
                          aria-describedby="creditCard"
                          value={totals.creditCardTotal}
                          readOnly
                        ></input>
                      </div>
                      <div className="form-group col-4">
                        <label htmlFor="billingTotal">Total</label>
                        <input
                          type="text"
                          className="form-control text-end"
                          aria-describedby="billingTotal"
                          value={totals.fullTotal}
                          readOnly
                        ></input>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>

            <section id="clover" className="mt-4">
              <div className="card">
                <div className="card-body my-2">
                  <h5 className="card-title lb-title-daily">Clover Control</h5>
                  <form>
                    <div className="row">
                      <div className="form-group col-4">
                        <label htmlFor="cloverCreditCard">
                          Credit Card (Total){" "}
                        </label>
                        <NumberFormat
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control text-end"
                          aria-describedby="cloverCreditCard"
                          thousandSeparator={true}
                          onValueChange={(e) =>
                            handleChangeClover({
                              name: "cloverCreditCard",
                              value: e.formattedValue,
                            })
                          }
                          data-cy="input-clover-credit-card"
                          value={formDataClover.cloverCreditCard}
                        />
                      </div>

                      <div className="form-group col-4">
                        <label htmlFor="tre">Cash</label>
                        <NumberFormat
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control text-end"
                          aria-describedby="cloverCash"
                          thousandSeparator={true}
                          onValueChange={(e) =>
                            handleChangeClover({
                              name: "cloverCash",
                              value: e.formattedValue,
                            })
                          }
                          data-cy="input-clover-cash"
                          value={formDataClover.cloverCash}
                        />
                      </div>

                      <div className="form-group col-4">
                        <label htmlFor="tre">Tips</label>
                        <NumberFormat
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control text-end"
                          aria-describedby="cloverTips"
                          thousandSeparator={true}
                          onValueChange={(e) =>
                            handleChangeClover({
                              name: "cloverTips",
                              value: e.formattedValue,
                            })
                          }
                          data-cy="input-clover-tips"
                          value={formDataClover.cloverTips}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>

            <section id="drawnerControl" className="mt-4">
              <div className="card">
                <div className="card-body my-2">
                  <h5 className="card-title lb-title-daily">Drawer Control</h5>
                  <form>
                    <div className="row">
                      <div className="form-group col-2">
                        <label htmlFor="openingCash">Opening Cash</label>
                        <NumberFormat
                          decimalScale={2}
                          allowNegative={false}
                          thousandSeparator={true}
                          data-cy="input-opening-cash"
                          value={formData.openingCash}
                          type="text"
                          className="form-control text-end"
                          onValueChange={(e) =>
                            handleChange({
                              name: "openingCash",
                              value: e.formattedValue,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-2">
                        <label htmlFor="cashPayments">Cash Payments</label>
                        <input
                          type="text"
                          className="form-control text-end"
                          aria-describedby="cashPayments"
                          name="cashPayments"
                          value={totals.cashTotal}
                          readOnly
                        />
                      </div>
                      <div className="form-group col-2">
                        <label htmlFor="withdrawals">Withdrawals</label>
                        <input
                          type="text"
                          className="form-control text-end"
                          aria-describedby="withdrawals"
                          name="withdrawals"
                          value={totalEnding.withdrawals}
                          readOnly
                        />
                      </div>
                      <div className="form-group col-2">
                        <label htmlFor="endingCash">Ending Cash</label>
                        <input
                          type="text"
                          className="form-control text-end"
                          aria-describedby="endingCash"
                          name="endingCash"
                          value={totalEnding.endingCash}
                          readOnly={!review}
                        />
                      </div>

                      <div className="form-group col-2">
                        <label htmlFor="endingCashStore">
                          Ending Cash Store
                        </label>
                        <NumberFormat
                          decimalScale={2}
                          allowNegative={false}
                          className="form-control text-end"
                          aria-describedby="endingCashStore"
                          thousandSeparator={true}
                          onValueChange={(e) =>
                            handleChange({
                              name: "endingCashStore",
                              value: e.formattedValue,
                            })
                          }
                          data-cy="input-ending-cash-store"
                          value={formData.endingCashStore}
                        />
                      </div>

                      <div className="form-group col-2">
                        <label htmlFor="endigCashDiferent">
                          Cash Difference
                        </label>
                        <input
                          style={
                            parseFloatLocale(endigCashDiferent) >= 3
                              ? estiloOk
                              : parseFloatLocale(endigCashDiferent) <= -3
                              ? estiloDiv
                              : estiloNormal
                          }
                          type="text"
                          className="form-control text-end"
                          aria-describedby="endigCashDiferent"
                          name="endigCashDiferent"
                          value={endigCashDiferent}
                          data-cy="input-ending-cash-diferent"
                          readOnly
                        />
                      </div>

                      {reasons.map((reason, i) => {
                        return (
                          <div className="col-3 mt-3" key={i}>
                            <label htmlFor="reason">
                              Reason
                              {i === reasons.length - 1 && (
                                <input
                                  className="lb-button-reason"
                                  type="button"
                                  value="add withdrawal"
                                  data-cy="add-reason"
                                  onClick={handleReason}
                                ></input>
                              )}
                              {i >= 1 && (
                                <input
                                  className="lb-button-reason"
                                  type="button"
                                  value="delete"
                                  onClick={() => handleReasonDelete(i)}
                                ></input>
                              )}
                            </label>
                            <div className="row">
                              <div className="col-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  data-cy={`reason-${i}`}
                                  value={reason.reason}
                                  placeholder="Reason"
                                  onChange={(e) =>
                                    handleReasonsChange({
                                      index: i,
                                      name: "reason",
                                      value: e.target.value,
                                    })
                                  }
                                  readOnly={i === 0}
                                />
                              </div>
                              <div className="col-6">
                                <NumberFormat
                                  decimalScale={2}
                                  allowNegative={false}
                                  className="form-control text-end"
                                  data-cy={`value-${i}`}
                                  placeholder="Amount"
                                  thousandSeparator={true}
                                  onValueChange={(e) =>
                                    handleReasonsChange({
                                      index: i,
                                      name: "value",
                                      value: e.formattedValue,
                                    })
                                  }
                                  value={reason.value}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </section>

            <section id="employeeControl" className="mt-4">
              <div className="card">
                <div className="card-body my-2">
                  <h5 className="card-title lb-title-daily">
                    Employee Schedule Control
                  </h5>
                  <table className="table table-striped table-sm mt-4">
                    <thead className="lb-thead-lila">
                      <tr>
                        <th scope="col" className="lb-text-right mt-5 col-1">
                          Employee
                        </th>
                        <th scope="col" className="text-center col-3">
                          Arrival
                        </th>
                        <th scope="col" className="lb-text-center mt-5 col-2">
                          Check-out
                        </th>
                        <th scope="col" className="text-center"></th>
                        <th scope="col" className="lb-text-left mt-5 col-1">
                          Tip
                        </th>
                        <th scope="col" className="text-center"></th>
                        <th scope="col" className="lb-text-left mt-5 col-1">
                          OpeningCash
                        </th>
                        <th scope="col" className="text-center"></th>
                        <th scope="col" className="text-center mt-5 col-2">
                          EndingCash
                        </th>
                      </tr>
                    </thead>
                  </table>
                  {review && (
                    <form>
                      {employeesReview.map((employee, i) => {
                        return (
                          <div className="row mt-3" key={i}>
                            <div className="form-group col-2">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby="employeeName"
                                data-cy={`input-name-employee-${i}`}
                                name="employeeName"
                                value={employee.employeeName}
                                onChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "employeeName",
                                    value: e.target.value,
                                  })
                                }
                                readOnly={i < tipped || review}
                              />
                            </div>
                            <div className="form-group col-2">
                              <input
                                type="time"
                                className="form-control"
                                aria-describedby="arrivalEmployee"
                                data-cy={`input-arrival-employee-${i}`}
                                name="arrivalEmployee"
                                value={employee.arrivalEmployee}
                                onChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "arrivalEmployee",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-group col-2">
                              <input
                                type="time"
                                className="form-control"
                                aria-describedby="checkOutEmployee"
                                data-cy={`input-checkout-employee-${i}`}
                                name="checkOutEmployee"
                                value={employee.checkOutEmployee}
                                onChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "checkOutEmployee",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-group col-2">
                              <NumberFormat
                                decimalScale={2}
                                allowNegative={false}
                                className="form-control text-end"
                                aria-describedby="tipEmployee"
                                name="tipEmployee"
                                thousandSeparator={true}
                                onValueChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "tipEmployee",
                                    value: e.formattedValue,
                                  })
                                }
                                data-cy={`input-tip-employee-${i}`}
                                value={employee.tipEmployee}
                                readOnly={
                                  i < tipped ||
                                  (employee.employeeName !== null &&
                                    employee.employeeName.length < 5) ||
                                  review
                                }
                              />
                            </div>
                            <div className="form-group col-2">
                              <NumberFormat
                                decimalScale={2}
                                allowNegative={false}
                                className="form-control text-end"
                                aria-describedby="employeeOpeningCash"
                                name="employeeOpeningCash"
                                thousandSeparator={true}
                                onValueChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "employeeOpeningCash",
                                    value: e.formattedValue,
                                  })
                                }
                                data-cy={`input-openingcash-employee-${i}`}
                                value={employee.employeeOpeningCash}
                              />
                            </div>
                            <div className="form-group col-2">
                              <input
                                type="text"
                                className="form-control text-end"
                                aria-describedby="endigCashDiferent"
                                name="employeeEndingCash"
                                value={
                                  !employee.employeeEndingCashStore
                                    ? 0
                                    : employee.employeeEndingCashStore
                                }
                                data-cy={`input-endingcash-employee-${i}`}
                                readOnly
                              />
                            </div>
                          </div>
                        );
                      })}
                    </form>
                  )}
                  {!review && (
                    <form>
                      {employees.map((employee, i) => {
                        return (
                          <div className="row mt-3" key={i}>
                            <div className="form-group col-2">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby="employeeName"
                                data-cy={`input-name-employee-${i}`}
                                name="employeeName"
                                value={employee.employeeName}
                                onChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "employeeName",
                                    value: e.target.value,
                                  })
                                }
                                readOnly={i < tipped || review}
                              />
                            </div>
                            <div className="form-group col-2">
                              <input
                                type="time"
                                className="form-control"
                                aria-describedby="arrivalEmployee"
                                data-cy={`input-arrival-employee-${i}`}
                                name="arrivalEmployee"
                                value={employee.arrivalEmployee}
                                onChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "arrivalEmployee",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-group col-2">
                              <input
                                type="time"
                                className="form-control"
                                aria-describedby="checkOutEmployee"
                                data-cy={`input-checkout-employee-${i}`}
                                name="checkOutEmployee"
                                value={employee.checkOutEmployee}
                                onChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "checkOutEmployee",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-group col-2">
                              <NumberFormat
                                decimalScale={2}
                                allowNegative={false}
                                className="form-control text-end"
                                aria-describedby="tipEmployee"
                                name="tipEmployee"
                                thousandSeparator={true}
                                onValueChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "tipEmployee",
                                    value: e.formattedValue,
                                  })
                                }
                                data-cy={`input-tip-employee-${i}`}
                                value={employee.tipEmployee}
                                readOnly={
                                  i < tipped ||
                                  (employee.employeeName !== null &&
                                    employee.employeeName.length < 5) ||
                                  review
                                }
                              />
                            </div>
                            <div className="form-group col-2">
                              <NumberFormat
                                decimalScale={2}
                                allowNegative={false}
                                className="form-control text-end"
                                aria-describedby="employeeOpeningCash"
                                name="employeeOpeningCash"
                                thousandSeparator={true}
                                onValueChange={(e) =>
                                  handleEmployeeChange({
                                    index: i,
                                    name: "employeeOpeningCash",
                                    value: e.formattedValue,
                                  })
                                }
                                data-cy={`input-openingcash-employee-${i}`}
                                value={employee.openingCash}
                              />
                            </div>
                            <div className="form-group col-2">
                              <input
                                type="text"
                                className="form-control text-end"
                                aria-describedby="endigCashDiferent"
                                name="employeeEndingCash"
                                value={
                                  !employee.employeeEndingCashStore
                                    ? 0
                                    : employee.employeeEndingCashStore
                                }
                                data-cy={`input-endingcash-employee-${i}`}
                                readOnly
                              />
                            </div>
                          </div>
                        );
                      })}
                    </form>
                  )}
                  <div className="container">
                    <div className="row d-flex border-dark border-top border-bottom mt-2">
                      <div className="m-1 d-flex">
                        <div className="col-7 fw-bold text-start pl-5">
                          Total
                        </div>
                        <div className="col-2 fw-bold">
                          {totalEmployees.totalTips}
                        </div>
                        <div className="col-2 fw-bold">
                          {totalEmployees.totalOpeningCash}
                        </div>
                        <div className="col-1 fw-bold">
                          {totalEmployees.totalEndingCashStore}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="observations" className="mt-4">
              <div className="card">
                <div className="card-body my-2">
                  <h5 className="card-title lb-title-daily mb-3">
                    Observations
                  </h5>
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      value={observations}
                      aria-describedby="observationsNotes"
                      data-cy="input-observations-notes"
                      name="observationsNotes"
                      onChange={handleObservations}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section id="clientsCheck">
              <div className="mt-5">
                <h4 className="text-center lb-title-daily">
                  Clients Check in - Check out Times
                </h4>
                <div className="table-responsive">
                  <table className="table table-striped table-sm mt-4">
                    <thead className="lb-thead-lila">
                      <tr>
                        <th scope="col" className="text-center">
                          Appoitment Time
                        </th>
                        <th scope="col" className="text-center">
                          Name
                        </th>
                        <th scope="col" className="text-center">
                          Check-in
                        </th>
                        <th scope="col" className="text-center">
                          Check-out
                        </th>
                        <th scope="col" className="text-center">
                          Files
                        </th>
                        <th scope="col" className="text-center">
                          Email &nbsp;
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={(e) =>
                              HandleAllEmailWhatsAppCheckboxes(e)
                            }
                            data-cy={`check-all-forms-table`}
                            name="checkAll"
                            id="checkAll"
                          />
                          WhatsApp
                        </th>
                        <th scope="col" className="text-center">
                          Form
                        </th>
                        <th scope="col" className="text-center">
                          CBFF
                        </th>
                        <th scope="col" className="text-center">
                          Service
                        </th>
                        {review && (
                        <th scope="col" className="pr-4 text-center">
                          {""}
                        </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {appointmentsTable.map((appointment, i) => {
                        return (
                          <tr key={i}>
                            <td align="center" className="lb-time-format">
                              {getTime(appointment.appointmentDate)}
                            </td>
                            <td className="pr-4 pl-2">
                              {appointment.clientName}
                            </td>
                            <td align="center" className="px-3 lb-time-format">
                              {getTime(appointment.arrived)}
                            </td>
                            <td align="center" className="px-3 lb-time-format">
                              {getTime(appointment.completed)}
                            </td>
                            <td align="center">{appointment.files}</td>
                            <td align="center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={(e) => handleCheckbox(e, i)}
                                data-cy={`check-email-table-${i}`}
                                name="emailSend"
                                id="emailSend"
                                checked={appointment.emailSend}
                              />
                            </td>
                            <td align="center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={(e) => handleCheckbox(e, i)}
                                data-cy={`check-form-table-${i}`}
                                name="formFilled"
                                id="form"
                                checked={appointment.formFilled}
                              />
                            </td>
                            <td align="center">{appointment.cbff}</td>
                            <td className="pr-4 text-center">
                              {appointment.serviceName}
                            </td>
                            {review && (
                            <td className="pr-4 text-center"> 
                              { /* appointment.clientName?.toLowerCase().replace(/[-.()+\s]/g, "").search("walkin") === 0 ? */
                              !state.approved ?
                              <FontAwesomeIcon
                                style={{cursor: 'pointer'}}
                                icon={faTrash}
                                data-bs-toggle="modal"
                                data-bs-target="#modalConfirmationAction"
                                data-cy={`btn-delete-service-walkin-${i}`}
                                className="text-danger"
                                onClick={() => {
                                  setConfirmationAction((confirmationAction) => ({
                                  ...confirmationAction,
                                  nameAction: "delete",
                                  text: textResponse.delete,
                                  response: "default",
                                  data: appointment,
                                  info: true,
                                  infoTittle: `${appointment.clientName}`,
                                  infoBody:
                                    <>
                                      <div className="mt-2">
                                      <span className="pr-5 "><b>Email:</b> {appointment.email}</span>
                                      </div>
                                      <div className="mt-2">
                                      <span className="pr-5"><b>Service:</b> {appointment.serviceName}</span>
                                      </div>
                                    </>
                                  }))
                                }} 
                              />
                              :
                              <div></div> 
                              }
                            </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section id="clientsCheck">
              <div className="mt-5">
                <h4 className="text-center lb-title-daily">Cash payments</h4>
                <div className="table-responsive">
                  <table className="table table-striped table-sm mt-4">
                    <thead className="lb-thead-lila">
                      <tr>
                        <th scope="col" className="text-center">
                          Client Name
                        </th>
                        <th scope="col" className="text-center">
                          Service
                        </th>
                        <th scope="col" className="text-center">
                          Staff Name
                        </th>
                        <th scope="col" className="text-center">
                          Tip
                        </th>
                        <th scope="col" className="text-center">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentAppointments.cashAppointments.map(
                        (appointment, i) => {
                          return (
                            <tr key={i}>
                              <td className="pr-4 pl-2 text-center">
                                {" "}
                                {appointment.clientName}{" "}
                              </td>
                              <td className="pr-4 text-center">
                                {appointment.serviceName}
                              </td>
                              <td className="pr-4 text-center">
                                {appointment.infoCheckout.staffName}
                              </td>
                              <td className="pr-4 text-center">
                                {formatAmount(
                                  parseFloatLocale(appointment.infoCheckout.tip)
                                )}
                              </td>
                              <td className="pr-4 text-center">
                                {formatAmount(
                                  parseFloatLocale(
                                    appointment.infoCheckout.cash
                                  )
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>

                    <tfoot className="border-top border-bottom border-dark">
                      <tr>
                        <th scope="row" className="pr-4 text-center">
                          Total
                        </th>

                        <td className="fw-bold text-center"></td>
                        <td className="fw-bold text-center"></td>
                        <td className="fw-bold text-center">
                          {roundWithEpsilon(paymentAppointments.acumTipCash)}
                        </td>
                        <td className="fw-bold text-center">
                          {roundWithEpsilon(paymentAppointments.acumAmountCash)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </section>

            <section id="clientsCheck">
              <div className="mt-5">
                <h4 className="text-center lb-title-daily">
                  Credit Card payments
                </h4>
                <div className="table-responsive">
                  <table className="table table-striped table-sm mt-4">
                    <thead className="lb-thead-lila">
                      <tr>
                        <th scope="col" className="text-center">
                          Client Name
                        </th>
                        <th scope="col" className="text-center">
                          Service
                        </th>
                        <th scope="col" className="text-center">
                          Staff Name
                        </th>
                        <th scope="col" className="text-center">
                          Tip
                        </th>
                        <th scope="col" className="text-center">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentAppointments.creditAppointments.map(
                        (appointment, i) => {
                          return (
                            <tr key={i}>
                              <td className="pr-4 pl-2 text-center">
                                {" "}
                                {appointment.clientName}{" "}
                              </td>
                              <td className="pr-4 text-center">
                                {appointment.serviceName}
                              </td>
                              <td className="pr-4 text-center">
                                {appointment.infoCheckout.staffName}
                              </td>
                              <td className="pr-4 text-center">
                                {formatAmount(
                                  parseFloatLocale(appointment.infoCheckout.tip)
                                )}
                              </td>
                              <td className="pr-4 text-center">
                                {formatAmount(
                                  parseFloatLocale(
                                    appointment.infoCheckout.creditCard
                                  )
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                    <tfoot className="border-top border-bottom border-dark">
                      <tr>
                        <th scope="row" className="pr-4 text-center">
                          Total
                        </th>

                        <td className="fw-bold text-center"></td>
                        <td className="fw-bold text-center"></td>
                        <td className="fw-bold text-center">
                          {roundWithEpsilon(paymentAppointments.acumTipCC)}
                        </td>
                        <td className="fw-bold text-center">
                          {roundWithEpsilon(paymentAppointments.acumAmountCC)}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </section>

            <section id="discount">
              <div className="mt-5">
                <h4 className="text-center lb-title-daily">Discounts</h4>
                <div className="table-responsive">
                  <table className="table table-striped table-sm mt-4">
                    <thead className="lb-thead-lila">
                      <tr>
                        <th scope="col" className="text-center">
                          Client Name
                        </th>
                        <th scope="col" className="text-center">
                          Service
                        </th>
                        <th scope="col" className="text-center">
                          Staff Name
                        </th>
                        <th scope="col" className="text-center">
                          Reason
                        </th>
                        <th scope="col" className="text-center">
                          Discount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentAppointments.discountAppointments.map(
                        (appointment, i) => {
                          return (
                            <tr key={i}>
                              <td className="pr-4 pl-2 text-center">
                                {" "}
                                {appointment.clientName}{" "}
                              </td>
                              <td className="pr-4 text-center">
                                {appointment.serviceName}
                              </td>
                              <td className="pr-4 text-center">
                                {appointment.infoCheckout.staffName}
                              </td>
                              <td className="pr-4 text-center">
                                {appointment.infoCheckout.discountReasons}
                              </td>
                              <td className="pr-4 text-center">
                                {formatAmount(
                                  parseFloatLocale(
                                    appointment.infoCheckout.discount
                                  )
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section id="buttons">
              <div className="row">
                <div className="form-group col-4 mt-4">
                  <label htmlFor="createBy" className="lb-title-daily">
                    Created By
                  </label>
                  <input
                    type="text"
                    readOnly={review}
                    value={createdBy}
                    className="form-control"
                    aria-describedby="createdBy"
                    data-cy="input-created-by"
                    name="createdBy"
                    onChange={handleCreatedBy}
                  />
                </div>
                <div className="lb-text-right mt-5 col-8">
                  <button
                    className={
                      review
                        ? state.approved
                          ? "mr-3 lb-button-daily-disabled"
                          : "mr-3 lb-button-daily"
                        : state.status === "ready"
                        ? "mr-3 lb-button-daily"
                        : "mr-3 lb-button-daily-disabled "
                    }
                    onClick={handleSubmit}
                    data-cy="btn-create-pdf"
                    // disabled={state.status === "success"}
                    disabled={
                      review ? state.approved : state.status === "success"
                    }
                  >
                    {review && <>Approve</>}
                    {!review && <>Accept</>}
                  </button>
                  {review && (
                    <button
                      className="lb-button-daily"
                      data-cy="btn-reject"
                      onClick={handleReject}
                    >
                      Reject
                    </button>
                  )}
                  {!review && (
                    <button
                      className="lb-button-daily"
                      onClick={handleModalOpen}
                      data-cy="btn-cancel"
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
              {state.status === "success" && (
                <div
                  data-cy="success-message"
                  className="my-3 mx-auto lb-w-50 lb-form-submited-success text-center"
                >
                  <span>{state.message}</span>
                </div>
              )}
            </section>
          </div>

          <div hidden={!show}>
            <div
              className="modal-background"
              id="modal-background"
              onClick={handleModalClose}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body text-center py-5">
                    Are you sure you want to cancel and lose the data entered?
                  </div>
                  <div className="modal-footer d-flex d-flex justify-content-center">
                    <button
                      type="button"
                      className="lb-button-daily"
                      data-dismiss="modal"
                      onClick={closeModal}
                      data-cy="btn-cancel-no"
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="lb-button-daily"
                      onClick={clearForm}
                      data-cy="btn-cancel-yes"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

DailyReport.propTypes = {
  appState: PropTypes.object.isRequired,
};
export default DailyReport;
